import "./style.css";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import store from "../../../store/store";

import BackPage from "../../../assets/images/common/back-page.png";
import BugReport from "../../../assets/images/menu/bug-report.png";
import Phone from "../../../assets/images/common/phone.png";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Help() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Menu",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();

  return (
    <div
      className="Help Page headline"
      style={{ height: windowInnerSize.height }}
    >
      <img
        src={BackPage}
        alt=""
        className="back-btn"
        onClick={() => {
          navigate(-1);
        }}
      />
      <p className="Help__Title">Help</p>

      <Link to={store.getState().profile?"/report":"/auth/log-in"}>
        <img src={BugReport} alt="" className="Help__BugReportPic" />
        <p className="Help__ReportTxt">Report A Bug</p>
      </Link>

      <img src={Phone} alt="" className="Help__PhonePic" />
      <p className="Help__ContactUsTxt">Contact Us</p>
      <p className="Help__Mail">info@passionstocks.com</p>
      {/* <p className="Help__PhoneNumber">074 430 3000</p> */}
    </div>
  );
}
