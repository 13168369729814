import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";
import AgentIPOedCreativeCard from "../../../../components/common/creative-cards/Agent";
import GraphUserData from "../../../../components/common/graphs/GraphUserData";
import StockResponses from "../../../../components/common/StockEngagement";
import UpdateDeliveredStoryMention from "../../../../components/common/UpdateDeliveredStoryMention";
import SocialMediaValues from "../../../../components/common/SocialMediaValues";
import AgencyMembersSlider from "./components/MembersSlider";
import SharePopUp from "../../../../components/common/SharePopUp";
import PassionPrdct from "../../../../components/common/PassionPrdct";

import "./style.css";

import store from "../../../../store/store";

import AgencyProfileBckgImage from "../../../../assets/images/profile/cover-img-default.jpg";
import EditIcon from "../../../../assets/images/common/edit.png";
import FullStar from "../../../../assets/images/common/star-full.png";
import EmptyStar from "../../../../assets/images/common/star-empty.png";
import SharingBtn from "../../../../assets/images/common/share.png";
import Bull from "../../../../assets/images/common/going-up.png";
import Bear from "../../../../assets/images/common/going-down.png";

import loadProfile from "../../../../loaders/profile";
import loadWallet from "../../../../loaders/wallet";
import fixFractionDigits from "../../../../helpers/fixFractionDigits";

const selectProfile = (state) => state.profile;
const selectTokens = (state) => state.tokens;
const selectWallet = (state) => state.wallet;
const selectPPTPendingList = (state) => state.pptPendingList;
const selectPPTDeliveryList = (state) => state.pptDeliveryList;
const selectAgencyTokenStorage = (state) => state.token_storage;
const selectToRefreshed = (state) => state.toRefresh;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Profile(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: true },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Profile",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const tokens = useSelector(selectTokens);
  const profile = useSelector(selectProfile);
  const wallet = useSelector(selectWallet);
  const PPTPendingList = useSelector(selectPPTPendingList);
  const PPTDeliveryList = useSelector(selectPPTDeliveryList);
  const isRefreshed = useSelector(selectToRefreshed);

  // console.log(tokens);
  useEffect(() => {
    if (tokens) {
      loadProfile(tokens);
      if (profile?.agent) {
        loadWallet(tokens, profile.accountType);
      }
    }
  }, []);

  const [StatsOrPrdct, setStatsOrPrdct] = useState("Stats");
  const [StatsAgencyOrClients, setStatsAgencyOrClients] = useState("Agency");
  //
  // const [UserProfile, setUserProfile] = useState(null);

  let cardData = profile
    ? {
        name: profile.name,
        city: profile.city,
        profilePic: profile.profilePic,
        ringColour: profile.influencer.ringColour,
        ranking: profile.influencer.ranking,
        currentPrice: profile.issuer.stock.marketPrice
          ? profile.influencer.stock.marketPrice
          : profile.influencer.stock.initialIPOprice,
        currency: profile.influencer.stock.currency.symbol,
        lastChange: profile.influencer.stock.lastChange,
      }
    : null;

  // if (profile !== null) {
  //     if ((UserProfile === null) || (UserProfile !== profile)) {
  //         //let uu = profile;
  //         setUserProfile(profile);
  //         // console.log(profile);
  //         setTimeout(function () { // console.log("eeee" + UserProfile); }, 1000);

  //     }
  // }

  // const [AgencyProfile, setAgencyProfile] = useState(store.getState().profile)

  // const [ToUpdateProfileCounter, setToUpdateProfileCounter] = useState(0)
  // if (props.EditProfileCounter !== ToUpdateProfileCounter) {
  //     setToUpdateProfileCounter(props.EditProfileCounter);
  //     // console.log('ppopopopopop');
  //     setAgencyProfile(...AgencyProfile);
  // }

  // don't delete it:

  // const [yuyu, setyuyu] = useState(null)
  // const [ProfilePhotoUpdate, setProfilePhotoUpdate] = useState(null)
  // useEffect(() => {
  //     if (yuyu !== props.EditImage) {
  //         setyuyu(props.EditImage);
  //         setProfilePhotoUpdate(null);
  //         setTimeout(setProfilePhotoUpdate(store.getState().profile.profile_photo_path), 1500)
  //         // window.location.reload();
  //     }

  // }, [props.EditImage])

  // let AML = props.agencyMembersList;
  // let AgencyProfile = ;

  const [SharePopUpDisplay, setSharePopUpDisplay] = useState(false);

  const isAgencyStats = useSelector(selectAgencyTokenStorage);

  //Animation:
  const [animateSlide, setanimateSlide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setanimateSlide(true);
    }, 0);
    return () => {
      setanimateSlide(false);
    };
  }, []);
  return profile && !isAgencyStats /* needed!*/ && isRefreshed ? (
    <div
      className={
        "AgentProfile Page headline " +
        (!animateSlide ? "animate-to-left" : null)
      }
      style={{ height: windowInnerSize.height }}
    >
      <div
        className="AgentProfile__Background"
        style={{
          backgroundImage: `url(${
            profile.coverPic ? profile.coverPic : AgencyProfileBckgImage
          })`,
        }}
      />

      <Link to="/profile/edit">
        <img src={EditIcon} alt="" className="AgentProfile__EditIcon" />
      </Link>
      <div style={{ position: "relative" }}>
        <div className="AgentProfile__CreativeCardPosition">
          <AgentIPOedCreativeCard cardData={cardData} />
        </div>

        <div className="AgentProfile__ResponsesPos">
          <StockResponses engagement={profile.influencer.engagement} />
        </div>
      </div>

      <div className="AgentProfile__BtnContainer">
        <Link to={"/unavailable" /*"/Profile/Agency/Dashboard"*/}>
          <p className="AgentProfile__DashboardBtn">Dashboard</p>
        </Link>
        <Link to="/profile/edit">
          <p className="AgentProfile__EditBtn">Edit</p>
        </Link>

        {/* <img src={SharingBtn} alt="" id="AgencySharingBtn" /> */}
        <img
          src={SharingBtn}
          alt=""
          className="AgentProfile__SharingBtn"
          onClick={() => {
            if (!SharePopUpDisplay) {
              setSharePopUpDisplay(true);
            }
          }}
        />
      </div>
      {SharePopUpDisplay && (
        <div
          className="AgentProfile__BackFromShare"
          onClick={() => {
            if (SharePopUpDisplay) {
              setSharePopUpDisplay(false);
            }
          }}
        />
      )}
      {SharePopUpDisplay && (
        <div>
          <SharePopUp
            Agencyid={store.getState().profile.agent.id}
            Agencyname={store.getState().profile.name}
            brandUsername={store.getState().profile.username}
            sharingType={0x2}
          />
        </div>
      )}
      <div className="AgentProfile__StarsContainer">
        {getRateStars(profile.stars || profile.stars === 0 ? profile.stars : 1)}
      </div>
      <p className="AgentProfile__Metrics headline">
        Growth{" "}
        <span>{fixFractionDigits(profile.influencer.stock.growth)}%</span>
        ROI made for investors{" "}
        <span>{fixFractionDigits(profile.ROI) + "% "}</span>
        <img
          src={profile.ROI > 0 ? Bull : profile.ROI < 0 ? Bear : null}
          alt=""
          id="TradeVolumePic"
        />{" "}
        <br />
        Trade Frequency <span>{profile.influencer.tradeFrequency}</span>
        Highest price{" "}
        <span>
          {profile.influencer.stock.currency.symbol}
          {fixFractionDigits(profile.influencer.stock.highestPrice)}
        </span>{" "}
        <br />
        Trade Volume <span>{profile.influencer.tradeVolume}</span>{" "}
        <img
          src={profile.tradeVolume > 0 ? Bull : null}
          alt=""
          id="TradeVolumePic"
        />{" "}
        {"  "}
        Stock views <span>{profile.influencer.engagement.stockViews}</span>
      </p>

      <p className="AgentProfile__RemainingStocks">
        Remaining Passionstocks
        <span> / {profile.influencer.release.leftIssued}</span>
      </p>

      <p className="AgentProfile__Describe">{profile.bio}</p>

      <div className="AgentProfile__SocialMPos">
        <SocialMediaValues
          SocialMedia={profile.influencer.socialMedia}
          dominatingPlatform={profile.influencer.dominatingPlatform.platform}
        />
      </div>

      <div className="AgentProfile__StatsPrdctContainer">
        <div className="AgentProfile__PrdctStatsBtns">
          <p className="AgentProfile__StatsBtn" onClick={setAsStats}>
            Stats
          </p>
          <p className="AgentProfile__ProductsBtn" onClick={setAsProducts}>
            Passion Products & Projects
          </p>
        </div>
        <svg height="0.5vw" width="84vw" className="AgentProfile__BasicLine">
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="#ffbf12"
            stroke-width="10%"
            stroke-linecap="butt"
          />
        </svg>
        <svg
          height="0.85vw"
          width={StatsOrPrdct === "Stats" ? "22vw" : "40vw"}
          className={
            StatsOrPrdct === "Stats"
              ? "AgentProfile__StatsSwitchLine"
              : "AgentProfile__PrdctSwitchLine"
          }
        >
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="#ffbf12"
            stroke-width="10%"
            stroke-linecap="butt"
          />
        </svg>
        {StatsOrPrdct === "Stats" && (
          <div className={"AgentProfile__StockInformation"}>
            <select
              className="AgentProfile__SelectStatsFilter headline"
              onChange={(e) => {
                setStatsAgencyOrClients(e.target.value);
              }}
            >
              <option value={"AgencyStock"} selected>
                Agency stock
              </option>
              <option value={"TotalMembers"}>Total members</option>
              <option value={"Members"}>Members</option>
            </select>

            {StatsAgencyOrClients !== "Members" && (
              <div style={{ width: "100%" }}>
                <GraphUserData
                  Balance={wallet ? wallet.withdrawalValue : 0}
                  Currency={wallet ? wallet.currency.symbol : ""}
                />
                <div
                  className="AgentProfile__FlexContainer"
                  style={{ marginTop: "10vw" }}
                >
                  <div className="AgentProfile__SquareData">
                    <p>{"--"}</p>
                    <p>Days</p>
                  </div>
                  <div className="AgentProfile__SquareData">
                    <p>
                      {wallet ? wallet.currency.symbol : ""}
                      {"--"}
                    </p>
                    <p>Made this week</p>
                  </div>
                  <div className="AgentProfile__SquareData">
                    <p>{"--"}%</p>
                    <p>Growth Rate</p>
                  </div>
                </div>
              </div>
            )}
            {StatsAgencyOrClients === "Members" && (
              <div className="ClientsStatsDiv">
                <AgencyMembersSlider
                  agencyMembersList={profile.agent.influencers}
                  isAgentAccount={true}
                />

                {/* <Link to={"/Unavailable"}>
                                    <p id="AddSMIBtn">Add SMI</p>
                                </Link> */}
              </div>
            )}
          </div>
        )}

        {StatsOrPrdct !== "Stats" && (
          <div style={{ position: "absolute", top: "5vw", left: "-8vw" }}>
            <div id="UDSMPos">
              <UpdateDeliveredStoryMention deliveryList={PPTDeliveryList} />
            </div>
            {PPTPendingList ? (
              <div id="PrdctPendingListDivAP">
                <p
                  id={!profile ? "PrdctsPendingTxt" : "None"}
                  className="headline"
                >
                  Passionproducts pending:
                </p>

                {getPassionPrdctsList(profile)}
              </div>
            ) : null}
            {/*<div id="SMConsulation">
                            <p>Social Media<br/>Consulation</p>
                            <img src={EditIcon} alt="" id="SMConsulationEdit"/>
                            <p>{"3"}</p>
                            <p>Passion <br/> Stock</p>
                        </div>*/}
          </div>
        )}
      </div>
    </div>
  ) : null;
  function setAsStats() {
    if (StatsOrPrdct !== "Stats") {
      setStatsOrPrdct("Stats");
    }
  }
  function setAsProducts() {
    if (StatsOrPrdct !== "Products") {
      setStatsOrPrdct("Products");
    }
  }
  function setAgencyClickedBtn() {
    if (StatsAgencyOrClients !== "Agency") {
      setStatsAgencyOrClients("Agency");
    }
  }
  function setMembersClickedBtn() {
    if (StatsAgencyOrClients === "Agency") {
      setStatsAgencyOrClients("Members");
    }
  }

  function getPassionPrdctsList() {
    let PPTPLElements = [];
    for (let i = 0; i < PPTPendingList.length; i++) {
      PPTPLElements.push(<PassionPrdct PPTData={PPTPendingList[i]} />);
    }

    return PPTPLElements;
  }
}
function getRateStars(Rating) {
  let FullStars = Rating;
  let EmptyStars = 5 - Rating;
  let StarsCounter = 5;
  let StarsLine = [];

  for (let i = 0; i < FullStars; i++, StarsCounter--) {
    StarsLine.push(
      <img
        key={StarsCounter}
        className="RateStars"
        src={FullStar}
        alt=""
        id="FullStar"
      />
    );
  }
  for (let i = 0; i < EmptyStars; i++, StarsCounter--) {
    StarsLine.push(
      <img
        key={StarsCounter}
        className="RateStars"
        src={EmptyStar}
        alt=""
        id="FullStar"
      />
    );
  }

  return StarsLine;
}
