import "./style.css";

import store from "../../../../store/store";

import BackPage from "../../../../assets/images/common/back-page.png";
import PCPic1 from "../../../../assets/images/education/passion-coin/pc-coin.png";
import PCPic2 from "../../../../assets/images/education/passion-coin/pic-2.png";
import PCPic3 from "../../../../assets/images/education/passion-coin/pic-3.png";
import PCPic4 from "../../../../assets/images/education/passion-coin/pic-4.png";
import ArrowNext from "../../../../assets/images/common/arrow-down-yellow-circle.png";
import ArrowGoBack from "../../../../assets/images/common/arrow-up-yellow-circle.png";

import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";
import { Link } from "react-router-dom";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function PassionCoin() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Notification",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();
  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        id="PassionCoinDiv"
        className="Page headline"
        style={{ height: windowInnerSize.height }}
      >
        <div style={{ height: "350vw" }}>
          <img
            src={BackPage}
            alt=""
            id="BackPageNot"
            onClick={() => {
              navigate(-1);
            }}
          />
          <p id="PCTitle" className="unnamed-character-style-1">
            PASSIONCOIN
          </p>
          <img src={PCPic1} alt="" id="PCPic1" />

          <p id="WhatQstTitlePC">
            <span>What is passioncoin?</span>
            <br />
            The digital token used on the platform,
            <br /> which can be converted from any standard
            <br /> currency into PassionCoin when depositing
            <br /> or to any standard currency upon
            <br /> withdrawal.{" "}
          </p>

          <img src={PCPic2} alt="" id="PCPic2" />

          <p id="HowQstTitlePC">
            <span>How do I get passioncoin?</span> <br />
            In your wallet you can deposit money which
            <br /> will then automatically converted to
            <br /> passioncoin.{" "}
          </p>

          <img src={PCPic3} alt="" id="PCPic3" />

          <p id="WhatDoQstTPC">
            <span>What do I use passioncoin for?</span>
            <br />
            On our platform you can use this digital <br /> token to purchase
            passionstocks of your <br />
            favourite influencer.
          </p>

          <img src={PCPic4} alt="" id="PCPic4" />

          <Link to="/education/passion-stock">
            <p id="WhatNextPC">What next?</p>
            <img src={ArrowNext} alt="" id="NextArrowPC" />
          </Link>
          <Link to="/education/how-does-it-work">
            <p id="GoBackPC">Go back</p>
            <img src={ArrowGoBack} alt="" id="GoBackArrowPC" />
          </Link>
        </div>
      </div>
    </AnimateKeyframes>
  );
}
