import "./style.css";

import { Link } from "react-router-dom";

import InstagramBigIcon from "../../../assets/images/common/instagram-icon.png";

export default function StoryMentionProduct() {
  return (
    <div className="StoryMentionProduct">
        <div className="StoryMentionProduct__Card">
          <p className="StoryMentionProduct__DefaultTxt">Default</p>
          <img src={InstagramBigIcon} alt="" className="StoryMentionProduct__InstagramIcon" />
          <p className="StoryMentionProduct__InstaTxt">
            Instagram <br /> Story Mention
          </p>
          <p className="StoryMentionProduct__OneTxt">1</p>
          <p className="StoryMentionProduct__PSTxt">
            Passion <br />
            Stock
          </p>
        </div>
        <Link to="/Unavailable">
          <p className="StoryMentionProduct__BuyBtn">BUY</p>
        </Link>
    </div>
  );
}
