import React, { useState } from "react";
import Chart from "react-apexcharts";
import store from "../../../../store/store";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const selectExchangeSearchDM = (state) => state.exchangeSearchDM;

export default function ChartExchange() {

  const exchangeSearchDM = useSelector(selectExchangeSearchDM);

  const token = store?.getState().tokens.accessToken;

  const [series, setSeries] = useState([]);
  const [opt, setOps] = useState({
    chart: {
      width: "100vw",
      height: "40vw",
      // height: 350,
      type: "line",
      id: "areachart-2",
      toolbar: {
        show: false,
      },
    },
    annotations: {
      points: [
        {
          x: 90,
          y: 27000,
          // marker: {
          //     size: 12,
          //     fillColor: '#fff',
          //     strokeColor: 'red',
          //     cssClass: 'apexcharts-custom-class'
          // },
          image: {
            // path: ScottG,
            // offsetX: 3,
            // offsetY: 15,
            // width: 40,
            // height: 30,
          },
        },
        {
          x: 75,
          y: 35000,
          image: {
            // path: NeevanF,
            // offsetX: 0,
            // offsetY: 13,
            // width: 80,
            // height: 30,
          },
        },
        {
          x: 77,
          y: 19000,
          image: {
            // path: GabrielB,
            // offsetX: 0,
            // offsetY: 13,
            // width: 80,
            // height: 30,
          },
        },
      ],
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },

    fill: {
      // type: 'gradient',
      // gradient: {
      //     shadeIntensity: 0,
      //     inverseColors: true,
      //     opacityFrom: 1,
      //     opacityTo: 1,
      //     stops: [0, 90, 100]
      // },
    },
    stroke: {
      show: true,
      // curve: 'smooth',
      lineCap: "butt",
      colors: ["#34c100", "#902366", "#24beff"],
      width: 3,
      dashArray: 0,
    },
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },
    title: {
      text: "",
      align: "left",
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val;
        },
        style: {
          colors: "#909090",
          fontSize: "1vw",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 100,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    //   labels: series.monthDataSeries1.dates,
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: "#909090",
          fontSize: "0vw",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 200,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
  });

  useEffect(() => {
    const getChartData = async () => {
      const res = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/api/stocks/top-3-graph",
        {
          headers: { Authorization: token },
        }
      );
      const inf = res.data.map((inf) => ({
        pic: inf.influencer.user.profilePic,
        name: inf.influencer.user.name,
        graph: inf.graph,
      }));

      const graphs = inf.map((i) => ({
        name: i.name,
        data: i.graph.map((g) => ({ y: g.value, x: g.date })),
      }));

      graphs.forEach((g) => {
        g.data.push({ y: g.data[g.data.length - 1].y, x: new Date() });
      });

      setSeries(graphs);
      setOps({
        ...opt,
        annotations: {
          ...opt.annotations,
          points: [
            ...inf.map(({ pic, graph }, i) => {
              const point = { ...opt.annotations.points[i] };
              point.image.path = pic;
              point.x = new Date().getTime();
              point.y = graph[graph.length - 1].value;
              return point;
            }),
          ],
        },
      });
    };

    getChartData();
  }, []);

  return (
    !exchangeSearchDM && (
      <div id="Chart">
        <Chart
          options={opt}
          series={series}
          type="line"
          width={355}
          height={203}
        />
      </div>
    )
  );
}
