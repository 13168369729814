import React from 'react';
import ImageGallery from 'react-image-gallery';

import facebook from '../../../../../assets/images/common/facebook-icom-small.png';
import instagram from '../../../../../assets/images/common/instagram-icon-small.png';
import youtube from '../../../../../assets/images/common/youtube-icon-rec.png';
import tiktok from '../../../../../assets/images/common/tiktok-icon-small.png';
import tesla from  '../../../../../assets/images/education/ps-vs-sm/tesla.png';
import SmiExmp from "../../../../../assets/images/education/ps-vs-sm/smiexmp.png";
import ElonM from '../../../../../assets/images/education/ps-vs-sm/ElonMusk.png';

import './VSSlider.css';

export default class VSSlider extends React.Component {
    constructor(){
    super()
    this.images = [
        {
          thumbnail: ``,
          original: ``,
          embedUrl: '',
          description: '',
          renderItem: this._renderSlide1.bind(this)
        },
        {
          thumbnail: ``,
          original: ``,
          embedUrl: '',
          description: '',
          renderItem: this._renderSlide2.bind(this)

        },
        {
            thumbnail: ``,
            original: ``,
            embedUrl: '',
            description: '',
            renderItem: this._renderSlide3.bind(this)
  
          },
          {
            thumbnail: ``,
            original: ``,
            embedUrl: '',
            description: '',
            renderItem: this._renderSlide4.bind(this)
  
          },
          {
            thumbnail: ``,
            original: ``,
            embedUrl: '',
            description: '',
            renderItem: this._renderSlide5.bind(this)
  
          },
          {
            thumbnail: ``,
            original: ``,
            embedUrl: '',
            description: '',
            renderItem: this._renderSlide6.bind(this)
  
          },
    ]
    }
    _renderSlide1(item){
        return(
        <div className="VSSlide">
        {
            <div>
            <p id="VSS1WhatTxt" className="headline">What do you invest in?</p>
            <p id="VSS1StocksinTxt" className="headline">stocks in:</p>
            <svg id="VSS1Circle" height="31vw" width="31vw">
                <circle cx="34%" cy="34%" r="32%" stroke="black" strokeWidth="3" fill="black" />
            </svg>
            <img src={facebook} alt='facebook' id='VSS1facebook'></img>
            <img src={instagram} alt='instegram' id='VSS1instegram'></img>
            <img src={youtube} alt='youtube' id='VSS1youtube'></img>
            <img src={tiktok} alt='tiktok' id='VSS1tiktok'></img>
            <p id="CharlisTxt" className="headline">Charlis' <br/> Social Media <br/> Brand</p>
            <svg id="VSS1Line" height="30%" width="2%">
                <line x1="0" x2="0%" y1="0" y2="100%" stroke="white" stroke-width="25%" stroke-linecap="butt"/>
            </svg>
            <p id="VSS1Stocksin2Txt" className="headline">stocks in:</p>
            <img src={tesla} alt='tesla' id='tesla'></img>
            </div>
        }
        </div>);
        
    }
    _renderSlide2(){
        return(
            <div  className="VSSlide">
                <p id="VSS2WhyTxt" className="headline">Why do you invest?</p>
                <p id="VSS2Because1Txt" className="headline">Because you<br/> believe in:</p>
                <img src={SmiExmp} alt="smiexmp" id="VSS2SmiExpPic" width="30%" height="70%"></img>
                <svg id="VSS2Line" height="30%" width="2%">
                    <line x1="0" x2="0%" y1="0" y2="100%" stroke="white" stroke-width="25%" stroke-linecap="butt"/>
                </svg>
                <p id="VSS2Because2Txt" className="headline">Because you<br/> believe in:</p>
                <img src={ElonM} alt="ElomMusk" id="VSS2ElonMPic" width="30%" height="70%"></img>
            </div>
        );
    }
    _renderSlide3(){
        return(
            <div  className="VSSlide">
                <p id="VSS3WhenQstnTxt" className="headline">When are dividends shared?</p>
                <p id="VSS3When1Txt" className="headline">When Charli decides to</p>
                <svg id="VSS2Line" height="30%" width="2%">
                    <line x1="0" x2="0%" y1="0" y2="100%" stroke="white" stroke-width="25%" stroke-linecap="butt"/>
                </svg>
                <p id="VSS3When2Txt" className="headline">When Tesla decides to</p>
            </div>
        );
    }
    _renderSlide4(){
        return(
            <div  className="VSSlide">
                <p id="VSS4WhyTxt" className="headline">Why would they share divident?</p>
                <p id="VSS4To1Txt" className="headline">To give back to her<br/> community</p>
                <svg id="VSS2Line" height="30%" width="2%">
                    <line x1="0" x2="0%" y1="0" y2="100%" stroke="white" stroke-width="25%" stroke-linecap="butt"/>
                </svg>
                <p id="VSS4To2Txt" className="headline">To satisfy shareholders</p>
            </div>
        );
    }
    _renderSlide5(){
        return(
            <div  className="VSSlide">
                <p id="VSS5WhereTxt" className="headline">Where does their money come from?</p>
                <p id="VSS5Ans1Txt" className="headline">Sponsorships {'&'} services</p>
                <svg id="VSS2Line" height="30%" width="2%">
                    <line x1="0" x2="0%" y1="0" y2="100%" stroke="white" stroke-width="25%" stroke-linecap="butt"/>
                </svg>
                <p id="VSS5Ans2Txt" className="headline">Selling Tesla Cars</p>
            </div>
        );
    }
    _renderSlide6(){
        return(
            <div  className="VSSlide">
                <p id="VSS6IfTxt" className="headline">If I want to cash out now?</p>
                <p id="VSS6AnsTxt" className="headline">Sell it on PassionStocks</p>
                <svg id="VSS2Line" height="30%" width="2%">
                    <line x1="0" x2="0%" y1="0" y2="100%" stroke="white" stroke-width="25%" stroke-linecap="butt"/>
                </svg>
                <p id="VSS6Ans2Txt" className="headline">Sell it on a stock market</p>
            </div>
        );
    }
    
    
    render() {
        return (
          <div>
            <ImageGallery items={this.images} showNav={false} showThumbnails={false} showPlayButton={false} showFullscreenButton={false}  autoPlay={false} infinite={true} />
          </div>
        );
        }
    }