import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AnimateKeyframes } from "react-simple-animate";

import axios from "axios";

import "./style.css";

import store from "../../../../store/store";

import StockCreativeCard from "../../../../components/common/creative-cards/Influencer";
import Loader from "../../../../components/common/Loader";

// import unauthorized from "../../functions/unauthorized";

import BackPage from "../../../../assets/images/common/back-page.png";
// import ArrowUp from "../../images/icons/ArrowUp.png";
// import ArrowDown from "../../images/icons/ArrowDown.png";
import Clock from "../../../../assets/images/common/clock.png";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import scrollContainersToTop from "../../../../utiles/scrollContainersToTop";
import loadWallet from "../../../../loaders/wallet";
import { ROLES } from "../../../../tools/role";
import AgentCreativeCard from "../../../../components/common/creative-cards/Agent";
import isVarNegative from "../../../../helpers/isVarNegative";

const selectTokens = (state) => state.tokens;
const selectProfile = (state) => state.profile;
const selectWallet = (state) => state.wallet;
const selectInvestmentStocks = (state) => state.investedShares;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function CreateTrade() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();

  // prevent enter to the page by insert the url in undesirable cases
  const profile = useSelector(selectProfile);
  const location = useLocation();
  const [processController, setProcessController] = useState(null);
  useEffect(() => {
    if (!isVarNegative(profile)) {
      navigate("/");
    } else {
      if (location.state?.process !== "CELL") {
        navigate("/exchange");
      } else {
        setProcessController(true);
      }
    }
  }, [location, profile]);

  const [SaleParam, setSaleParam] = useState({ volume: null, price: null });
  const [StocksVolume, setStocksVolume] = useState(true);
  const [StocksPrice, setStocksPrice] = useState(true);
  const [hashtags, sethashtags] = useState([]);

  const [ShowLoaderCT, setShowLoaderCT] = useState(false);

  const tokens = useSelector(selectTokens);
  const wallet = useSelector(selectWallet);
  const investmentStocks = useSelector(selectInvestmentStocks);

  let { stockId } = useParams();

  useEffect(() => {
    if (tokens) {
      loadWallet(tokens, profile.accountType);
      // loadMySales(tokens);
    }
  }, []);

  // console.log(investmentStocks);
  const [StockTrade, setStockTrade] = useState(null);
  useEffect(() => {
    let temp = investmentStocks.filter((obj) => obj.stock.id === +stockId);
    setStockTrade(temp[0]);
  }, [investmentStocks]);

  // for (let i = 0; i < StockTrade.owner_sale_offers.length; i++) {
  //     tempTotalSS -= StockTrade.owner_sale_offers[i].volume;
  // }
  const [TotalAvailableStk, setTotalAvailableStk] = useState(null);
  useEffect(() => {
    //for calc total stocks available to sell:
    if (StockTrade) {
      setTotalAvailableStk(StockTrade.volume);
    }
  }, [StockTrade]);

  const setStockNumSell = (event) => {
    let temp = { ...SaleParam, volume: +event.target.value };
    setSaleParam(temp);

    if (+event.target.value <= TotalAvailableStk && +event.target.value > 0) {
      setStocksVolume(true);
    } else {
      setStocksVolume(false);
    }
  };

  const setStockPriceSell = (event) => {
    let temp = { ...SaleParam, price: +event.target.value };
    setSaleParam(temp);

    if (+event.target.value > 0) {
      setStocksPrice(true);
    } else {
      setStocksPrice(false);
    }
  };

  const handleSubmitCreateTrade = async (event) => {
    if (
      SaleParam.volume > 0 &&
      SaleParam.price > 0 &&
      StocksVolume &&
      StocksPrice
    ) {
      setShowLoaderCT(true);

      await axios({
        method: "POST",
        url: process.env.REACT_APP_BACKEND_URL + "/api/sales/p2p-sale",
        headers: {
          Authorization: tokens.accessToken,
        },
        data: {
          stockId: StockTrade.stock.id,
          volume: SaleParam.volume,
          price: SaleParam.price,
          hashtags: hashtags,
        },
      })
        .then(function (response) {
          store.dispatch({
            type: "app/createTradeP2P/add",
            payload: { stock: StockTrade, sale: response.data },
          });

          // console.log("place a stock for sale");
          // console.log(response);
          navigate("/exchange/create-trade/confirmation",{state:{process:'CELL'}});
        })
        .catch(function (error) {
          // console.log("error to place stock for sale");
        });
    }
  };

  //   let date1 = new Date();

  let cardData = StockTrade
    ? {
        profilePic: StockTrade.stock.influencer.user.profilePic,
        name: StockTrade.stock.influencer.user.name,
        username: StockTrade.stock.influencer.user.username,
        ranking: StockTrade.stock.influencer.ranking,
        ringColour: StockTrade.stock.influencer.ringColour,
        socialMedia: StockTrade.stock.influencer.socialMedia,
        niches: StockTrade.stock.influencer.niches,
        city: StockTrade.stock.influencer.user.city,
        currentPrice: StockTrade.stock.marketPrice,
        currency: StockTrade.stock.currency.symbol,
        lastChange: StockTrade.stock.lastChange,
      }
    : null;

  // page height
  if (!store.getState().windowInnerSize) {
    store.dispatch({
      type: "ui/windowInnerSize/add",
      payload: { height: window.innerHeight, width: window.innerWidth },
    });
  }

  return (
    processController &&
    StockTrade && (
      <AnimateKeyframes
        play={true}
        iterationCount={1}
        duration={0.42}
        direction="normal"
        keyframes={[
          "transform: translateX(100vw)",
          "transform: translateX(50vw)",
          "transform: translate(0, 0)",
        ]}
      >
        <div
          className="TradeDetails Page Page--padding-5vw headline"
          style={{ /*height: windowInnerSize.height*/paddingBottom:'25vw' }}
        >
          <img
            src={BackPage}
            alt=""
            className="TradeDetails__BackBtn"
            onClick={() => {
              navigate(-1);
            }}
          />
          {/* <Link to="/Exchange"> */}
          <div
            className="Page--flex"
            style={{ justifyContent: "space-between", paddingTop: "5vw" }}
          >
            {/* </Link> */}
            {/* <div id="wrapTradeStock"> */}
            {StockTrade.stock.influencer.user.accountType & ROLES.AGENT ? (
              <AgentCreativeCard cardData={cardData} />
            ) : (
              <StockCreativeCard cardData={cardData} />
            )}
            <div style={{ paddingTop: "7vw" }}>
              <p className="TradeDetails__SellTxt">
                Sell ({TotalAvailableStk})
              </p>

              <div style={{ position: "relative", height: "9vw" }}>
                <input
                  type="number"
                  min={0}
                  placeholder="0"
                  id="input1"
                  className={
                    "TradeDetails__AmountInput input-default " +
                    (!StocksVolume ? "input-wrong" : "")
                  }
                  onChange={setStockNumSell}
                  onBlur={()=>{scrollContainersToTop()}}
                />
                <p className="TradeDetails__InputPSTxt">PS</p>
              </div>
              <p className="TradeDetails__ForTxt">For</p>
              <div style={{ position: "relative", height: "9vw" }}>
                <input
                  type="number"
                  min={0}
                  placeholder="0"
                  id="input"

                  // id="PriceNumSell"
                  className={
                    "TradeDetails__PriceVolumeInput input-default " +
                    (!StocksPrice ? "input-wrong" : "")
                  }
                  onChange={setStockPriceSell}
                  onBlur={()=>{scrollContainersToTop()}}

                />
                <p className="TradeDetails__CurrencyCodeTxt">
                  {wallet ? wallet.currency.symbol : "?"}
                </p>
                <p className="TradeDetails__EachTxt">each</p>
              </div>
            </div>
            <div style={{ padding: "5vw 0" }}>
              {SaleParam.price > 0 && (
                <p className="TradeDetails__BranfFeeTxt">
                  *Brand fee (2%) = -{wallet ? wallet.currency.symbol : "?"}
                  {(
                    SaleParam.price *
                    0.02 *
                    (SaleParam.volume ? SaleParam.volume : 1)
                  ).toFixed(2)}
                </p>
              )}
              <svg height="2vw" width="40vw" className="TradeDetails__Line">
                <line
                  x1="0"
                  x2="100%"
                  y1="0"
                  y2="0"
                  stroke="white"
                  stroke-width="100%"
                  stroke-linecap="butt"
                />
              </svg>
              {SaleParam.price > 0 && (
                <p className="TradeDetails__TotalTxt">
                  Total = {wallet ? wallet.currency.symbol : "?"}
                  {(
                    SaleParam.price * (SaleParam.volume ? SaleParam.volume : 1)
                  ).toFixed(2)}
                </p>
              )}
            </div>
            <div>
              <p className="TradeDetails__HashtagsTxt">Hashtags</p>
              <p className="TradeDetails__ToIncreaseTxt">
                To increase your chances of trading successful <br /> Insert the
                relevant hashtags below.{" "}
              </p>
              <input
                type="text"
                id="input"
                placeholder="  #photography #tiktok #dancing"
                className="TradeDetails__HashtagsInput input-default "
                onChange={(e) => {
                  sethashtags(e.target.value.split(/#/));
                  // console.log(hashtags);
                }}
                onBlur={()=>{scrollContainersToTop()}}

              />
            </div>
            <div style={{ position: "relative", height: "38vw" }}>
              <div className="TradeDetails__LoaderPosSize">
                {ShowLoaderCT && <Loader />}
              </div>
              <div
                style={{
                  position: "relative",
                  display: "block",
                  width: "fit-content",
                  margin: "0 auto",
                }}
              >
                <img src={Clock} alt="" className="TradeDetails__ClockPic" />
                <p className="TradeDetails__ExpiresTxt">
                  Expires after 24 hours
                </p>
              </div>
              {/* <Link to={"/Exchange/CreateTrade/Confirmation/" + Stock}> */}
              <p
                className="TradeDetails__CreateTradeBtn"
                onClick={handleSubmitCreateTrade}
              >
                Create Trade
              </p>
            </div>
            {/* </Link> */}
          </div>
          {/* </div> */}
        </div>
      </AnimateKeyframes>
    )
  );
}
