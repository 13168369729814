import "./style.css";

import store from "../../../store/store";

import SMIValidationPic from "../../../assets/images/common/emojy-cop.png";
// import LogSignSmily from '../../images/icons/LogSignSmily.png';

// import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function SetAppointment() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("BookMeetingBtn")?.classList.remove("Hide");

    // returned function will be called on component unmount
    return () => {
      document.getElementById("BookMeetingBtn").classList.add("Hide");
    };
  }, []);

  function isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf("calendly") === 0;
  }

  window.addEventListener("message", function (e) {
    if (isCalendlyEvent(e)) {
      // console.log(e.data);
      if (e.data.event === "calendly.event_scheduled") {
        navigate("/money-calculator/contact-info");
        setTimeout(
          document.getElementsByClassName("calendly-popup-close")[0].click(),
          10000
        );
      }
    }
  });

  const [SMIDetails, setSMIDetails] = useState({
    email: null,
    phone_number: null,
  });
  const handleEmailInput = (event) => {
    let temp = { ...SMIDetails, email: event.target.value };
    setSMIDetails(temp);
  };
  const handlePhoneNumberInput = (event) => {
    let temp = { ...SMIDetails, phone_number: event.target.value };
    setSMIDetails(temp);
  };
  const handleAskForMeet = async (event) => {
    // console.log(SMIDetails);
    if (SMIDetails.email && SMIDetails.phone_number) {
      await axios({
        method: "POST",
        url: `https://web.passionstocks.com/api/smi/meeting`,
        params: {
          email: SMIDetails.email,
          phone_number: SMIDetails.phone_number,
          instagram_link: store.getState().moneyCalcUserSMLinks.links.instagram,
          youtube_link: store.getState().moneyCalcUserSMLinks.links.youtube,
          tiktok_link: store.getState().moneyCalcUserSMLinks.links.tiktok,
        },
      })
        .then(function (res) {
          // console.log("set up meeting");
          // console.log(res);

          navigate("/exchange");
        })
        .catch(function (error) {
          // console.log(error.response ? error.response : error);
        });
    }
  };
  return (
    <div id="SMIValidationDiv" className="Page headline"
    style={{ height: windowInnerSize.height }}>
      <img src={SMIValidationPic} alt="" id="SMIValidationPic" />
      <p id="SMIValidationTxt" className="unnamed-character-style-1">
        Validation
      </p>
      <p id="SMIValidationExpTxt">
        To protect our users we need to confirm
        <br /> that you own the social media
        <br /> account that has just been registered.
        <br />
        <br />
        <br />
        Please click the button below and select <br /> a time to virtually
        "meet" one of our <br /> validation agents.
      </p>

      {/* <!-- Calendly link widget begin --> */}
      {/* <!--Calendly link widget end--> */}
      {/* <!-- Calendly link widget begin --> */}
      {/* <a href="" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/passionstocks'});return false;"><button id="BookMeetingBtn" className="headline">Book a meeting</button></a> */}
      {/* <!--Calendly link widget end--> */}

      {/* <!-- Calendly inline widget begin --> */}
      {/* <!-- Calendly inline widget begin --> */}
      {/* <div id="CalendlyEmbeded" className="calendly-inline-widget" data-url={"https://calendly.com/passionstocks?hide_landing_page_details=1&hide_gdpr_banner=1"} ></div> */}
      {/* <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script> */}
      {/* <!-- Calendly inline widget end -->            <!-- Calendly inline widget end --> */}

      {/* <p id="ConfirmTxtV">Confirm your contact information</p>
            <input type="email" placeholder=" Email" id="EmailInputV" onChange={handleEmailInput} />
            <input type="number" placeholder=" Phone Number" id="PhoneNInputV" onChange={handlePhoneNumberInput} />

            <svg height="1.5vw" width="25%" id="LineSMIV">
                <line x1="0" x2="100%" y1="0" y2="0" stroke="#ffbf12" stroke-width="100%" stroke-linecap="butt" />
            </svg>

            <p id="WaitingVExp">Upon validation from our agents our<br /> account will be updated to a social<br /> media influencer.<br /><br /> Until then you’ll be able to operate as a <br />trader.</p>
            <img src={LogSignSmily} alt="" id="LogSignSmilyV" />

            <p id="DoneBtnValid" onClick={handleAskForMeet}>Done</p> */}
    </div>
  );
}
