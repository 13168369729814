import "./style.css";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";

import store from "../../store/store";

import BackPage from "../../assets/images/common/back-page.png";
import AboutUsBckgImg from "../../assets/images/about-us/background.png";
import CircleAU from "../../assets/images/about-us/circle.png";
import RectaAU from "../../assets/images/about-us/recta.png";
import ChartIceAU from "../../assets/images/about-us/chart-ice.png";
import ArrowRightAU from "../../assets/images/common/arrow-right.png";
import WeBelieveImgAU from "../../assets/images/about-us/we-believe.png";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function About() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Home",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(-100vw)",
        "transform: translateX(-50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="About Page headline"
        style={{ height: windowInnerSize.height }}
      >
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          style={{ top: "5vw" }}
          onClick={() => {
            navigate(-1);
          }}
        />

        <img src={AboutUsBckgImg} alt="" className="About__Img" />
        <p className="About__Headline unnamed-character-style-1">
          We’re on a Mission
          <br />
          To bring some
          <br />
          <span>Passion</span>
          <br />
          To the financial world
        </p>

        <div className="About__Shadow" />

        <svg className="About__Line" height="2vw" width="20%">
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="white"
            stroke-width="100%"
            stroke-linecap="butt"
          />
        </svg>

        <img src={CircleAU} alt="" className="About__Circle" />

        <p className="About__Paragraph1">
          Humans are beings brimming with passion, embodying emotions, energy,
          and a sense of community. We radiate laughter and embrace vibrant
          colors, encompassing everything in-between, yet each of us is
          exquisitely distinct.
          <br />
          <br />
          Every individual is born with a remarkable array of talents and
          boundless potential.
          <br />
          <br />
          Like a circle, we represent a contained unit bursting with infinite
          possibilities, waiting to be explored and unleashed.
        </p>

        <img src={RectaAU} alt="" className="About__Rect" />

        <p className="About__Paragraph2">
          When contemplating the finance world, one’s initial mental image might
          conjure an image of an aged gentleman sporting a distinguished
          mustache and a cigar clenched between his lips 👀
          <br />
          <br />
          Alternatively, it might evoke the image of someone with a hoodie,
          their head covered, sitting in a messy room, surrounded by the glow of
          computer screens running endless lines of unclear code.
          <br />
          <br />
          Over the years, not much has fundamentally altered, except that it has
          grown more convoluted and daunting.
          <br />
          <br />
          It remains inflexible, impersonal, and dreary, even though we, as
          humans, wield it as a tool. Unfortunately, it fails to accommodate our
          unique needs and aspirations.
        </p>

        <img src={ChartIceAU} alt="" className="About__Chart" />

        <p className="About__Paragraph3">
          Our objective is to ingeniously merge the realms of passion and
          finance, forging an entirely new entity that transcends traditional
          boundaries.
          <br />
          <br />
          Who can resist ice cream? 😌
        </p>

        <Link to="/education">
          <div className="About__LearnBtnContainer">
            <p className="About__LearnBtn unnamed-character-style-1">
              Learn more
            </p>
            <img src={ArrowRightAU} alt="" className="About__LearnBtnArrow" />
          </div>
        </Link>

        <img src={WeBelieveImgAU} alt="" className="About__WeBelieve" />
      </div>
    </AnimateKeyframes>
  );
}
