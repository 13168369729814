import "./style.css";

import store from "../../../../store/store";

import { useNavigate } from "react-router-dom";
// import axios from 'axios';
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";

import BackPage from "../../../../assets/images/common/back-page.png";
import ArrowLeft from "../../../../assets/images/common/arrow-pointer-left.png";
import ArrowRight from "../../../../assets/images/common/arrow-pointer-right.png";
import InstaLogo from "../../../../assets/images/common/instagram-icon.png";

import load3rdProfile from "../../../../loaders/3rdProfile";
import { ROLES } from "../../../../tools/role";
import AgentCreativeCard from "../../../../components/common/creative-cards/Agent";
import StockCreativeCard from "../../../../components/common/creative-cards/Influencer";

const selectTokens = (state) => state.tokens;
const selectInfluencer3rdProfile = (state) => state.influencer3rdProfile;
const selectStockData = (state) => state.pptConvertionToProductData;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function ConvertToPPT(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();

  // let temp = props.saleOffers.filter(obj => obj.id === +Stock)
  // // console.log(temp);

  const tokens = useSelector(selectTokens);
  const influencer3rdProfile = useSelector(selectInfluencer3rdProfile);
  const stock = useSelector(selectStockData);

  useEffect(() => {
    if (tokens && stock) {
      load3rdProfile(tokens, stock.influencer.user.id);
      // console.log("kkllllkkk");
    }
    return () => {
      store.dispatch({ type: "domain/influencer3rdProfile/delete", payload: null });
    };
  }, []);

  const [cardData, setcardData] = useState(null);
  useEffect(() => {
    if (influencer3rdProfile) {
      let tempCardData = {
        profilePic: influencer3rdProfile.user.profilePic,
        name: influencer3rdProfile.user.name,
        username: influencer3rdProfile.user.username,
        ranking: influencer3rdProfile.ranking,
        ringColour: influencer3rdProfile.ringColour,
        socialMedia: {
          tiktok: null,
          instagram: { follows: 120 },
          youtube: null,
        } /* profile.data.influencer.socialMedia */,
        niches: influencer3rdProfile.niches,
        city: influencer3rdProfile.user.city,
        currentPrice: influencer3rdProfile.stock.marketPrice
          ? influencer3rdProfile.stock.marketPrice
          : 0,
        lastChange: influencer3rdProfile.stock.lastChange,
      };
      setcardData(tempCardData);
    }
  }, [influencer3rdProfile]);

  const [displayErrorMessage, setdisplayErrorMessage] = useState(false);

  const handleConfirmTrading = () => {
    // console.log(stock.stockId);
    var axios = require("axios");
    var data = {
      stockId: stock.id,
    };

    var config = {
      method: "post",
      url: process.env.REACT_APP_BACKEND_URL + "/api/ppt-trades/trade-sm-stock",
      headers: {
        Authorization: tokens.accessToken,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        store.dispatch({
          type: "app/pptConvertionToProductData/add",
          payload: { tradeId: response.data.id },
        });
        navigate("/ppt/convert-stock-to-product/successful", { replace: true });
      })
      .catch(function (error) {
        // console.log(error);
        setdisplayErrorMessage(true);
        setTimeout(() => {
          navigate("/wallet", { replace: true });
        }, 3000);
      });
  };

  return influencer3rdProfile && cardData ? (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        id="ConvertToPPTDiv"
        className="Page headline"
        style={{ height: windowInnerSize.height }}
      >
        <img
          src={BackPage}
          alt=""
          id="BackPageCTPPT"
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className="StockCardPosCTPPT">
          {influencer3rdProfile.user.accountType & ROLES.AGENT ? (
            <AgentCreativeCard cardData={cardData} />
          ) : (
            <StockCreativeCard cardData={cardData} />
          )}
        </div>

        <p id="ForTxtCTPPT">For</p>

        <img src={ArrowLeft} alt="" id="ArrowLeftCTPPT" />
        <img src={ArrowRight} alt="" id="ArrowRightCTPPT" />

        <div id="SMCardCTPPT">
          <img src={InstaLogo} alt="" id="InstaLogoCTPPT" />
          <p id="InstaTxtCTPPT">
            Instagram <br />
            Story
            <br />
            Mention
          </p>
        </div>

        <svg height="1.8vw" width="41%" id="LineCTPPT">
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="white"
            stroke-width="100%"
            stroke-linecap="butt"
          />
        </svg>

        <p id="TradingInTxtCTPPT">
          Trading in 1x @{stock.influencer.user.username} <br />
          Passionstocks into
          <br />
          1x Instagram story mention
        </p>

        <p
          id="CancelBtnCTPPT"
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </p>
        <p id="ConfirmBtnCTPPT" onClick={handleConfirmTrading}>
          Confirm trading
        </p>

        {displayErrorMessage ? (
          <p id="ErrorMessageCTPPT">Error to convert to PassionProduct</p>
        ) : null}
      </div>
    </AnimateKeyframes>
  ) : null;
}
