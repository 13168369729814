import "./NetWorth.css";
import "./Breakdown.css";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

import fixFractionDigits from "../../../../helpers/fixFractionDigits";

import Loader from "../../../../components/common/Loader";

import store from "../../../../store/store";
import ArrowDropDown from "../../../../assets/images/common/arrow-dropdown-2.png";

const selectWallet = (state) => state.wallet;
// const selectWalletSliderIndex = (state) => state.walletSliderIndex;
// const selectInvestorShares = (state) => state.investorShares;
const selectSliderHeight = (state) => state.walletSliderHeight;
// const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Breakdown(props) {
  const wallet = useSelector(selectWallet);
  // const walletSliderIndex = useSelector(selectWalletSliderIndex);
  // const investorShares = useSelector(selectInvestorShares);
  const walletSliderHeight = useSelector(selectSliderHeight);
  // const windowInnerSize = useSelector(selectWindowInnerSize);

  const [ShowWalletBDetails, setShowWalletBDetails] = useState(false);

  //   <div id="LoaderPosNoti">
  //     <Loader />
  //   </div>
  // ) :

  useEffect(() => {
    console.log(walletSliderHeight);
  }, [walletSliderHeight]);

  return (
    <div
      style={{
        position: "relative",
        /*top: walletSliderHeight*/ height: ShowWalletBDetails
          ? "220vw"
          : "60vw",
      }}
    >
      <div
        id="WalletBWrapMS"
        className="headline"
        style={{
          // > 0.65 * windowInnerSize.width
          //   ? walletSliderHeight
          //   : 0.65 * windowInnerSize.width,
          transition: "all 0.5s",
        }}
      >
        <svg className="Breakdown__Line" height="0.5vw" width="25%">
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="#707070"
            stroke-width="100%"
            stroke-linecap="butt"
          />
        </svg>
        <div id="WalletBTitle"></div>
        <p id="WalletBText">Wallet Breakdown</p>
        <img
          src={ArrowDropDown}
          alt=""
          id="ArrowDropDown"
          style={
            ShowWalletBDetails
              ? { transform: "rotate(180deg)", transition: "all .5s ease-in" }
              : { transform: "rotate(0deg)", transition: "all .3s ease-in" }
          }
          onClick={() =>
            ShowWalletBDetails
              ? setShowWalletBDetails(false)
              : setShowWalletBDetails(true)
          }
        />
        <div id="RatioWalletB">
          {wallet.breakDown.ratio.positive &&
          wallet.breakDown.ratio.positive > 0 ? (
            <p
              id="PositiveLineRWB"
              style={{ width: `${wallet.breakDown.ratio.positive + 2}%` }}
            >
              <span style={{ position: "relative", zIndex: "8" }}>
                {" "}
                + {wallet.breakDown.ratio.positive.toFixed(1)}%
              </span>
            </p>
          ) : null}
          {wallet.breakDown.ratio.negative &&
          wallet.breakDown.ratio.negative > 0 ? (
            <p
              id="NegativeLineRWB"
              style={{ width: `${wallet.breakDown.ratio.negative + 4}%` }}
            >
              <span style={{ position: "relative", zIndex: "18" }}>
                {" "}
                - {wallet.breakDown.ratio.negative?.toFixed(1)}%
              </span>
            </p>
          ) : null}
          {!wallet.breakDown.ratio.positive &&
            !wallet.breakDown.ratio.negative && (
              <p id="EmptyRatioRWB">No ratio</p>
            )}
        </div>

        <div
          className={`WalletBreakdownE ${
            !ShowWalletBDetails ? "HideWalletB" : ""
          }`}
        >
          <p id="AssetsTitle" className="unnamed-character-style-1">
            Assets
            <span
              style={{ float: "right" }}
              id="AssetsValue"
              className={
                (wallet.breakDown.assets.availableBalance +
                  wallet.breakDown.assets.shareHoldings >
                0
                  ? "PositiveNum "
                  : "") + "unnamed-character-style-1"
              }
            >
              {wallet.breakDown.assets.currency.symbol + " "}
              {fixFractionDigits(
                wallet.breakDown.assets.availableBalance +
                  wallet.breakDown.assets.shareHoldings
              )}
            </span>
          </p>
          <div id="AssetsListE" className="WBList">
            <p>
              Available balance
              <span style={{ float: "right" }}>
                {wallet.breakDown.assets.currency.symbol + " "}
                {fixFractionDigits(wallet.breakDown.assets.availableBalance)}
              </span>
            </p>
            <p>
              Share Holding{" "}
              <span style={{ float: "right" }}>
                {wallet.breakDown.assets.currency.symbol + " "}
                {fixFractionDigits(wallet.breakDown.assets.shareHoldings)}
              </span>
            </p>
            {store.getState().profile.accountType >= 8 && (
              <p>
                Issued Stocks{" "}
                <span style={{ float: "right" }}>
                  {wallet.breakDown.assets.currency.symbol + " "}
                  {fixFractionDigits(wallet.breakDown.assets.issuedStocks)}
                </span>
              </p>
            )}
          </div>
          <p id="MoneyTransfersTitleE" className="unnamed-character-style-1">
            Money Transfers
          </p>
          <div id="MoneyTransfersListE" className="WBList">
            <p>
              Deposits
              <span style={{ float: "right" }}>
                {wallet.breakDown.assets.currency.symbol + " "}
                {wallet.breakDown.moneyTransfers.deposits
                  ? fixFractionDigits(wallet.breakDown.profits.investmentsROI)
                  : 0}
              </span>
            </p>
            <p>
              Withdrawals
              <span style={{ float: "right" }}>
                {wallet.breakDown.moneyTransfers.currency.symbol + " "}
                {fixFractionDigits(wallet.breakDown.moneyTransfers.withdrawals)}
              </span>
            </p>
            {/* <p>Broker returns</p><p>{'P' + toDigits(Math.abs('2400')}</p> */}
          </div>
          <p id="ProfitsTitleE" className="WBList unnamed-character-style-1">
            Profits
            <span
              id="ProfitsValueE"
              className={
                (wallet.breakDown.profits.total < 0
                  ? "NegativeNum "
                  : wallet.breakDown.profits.total > 0
                  ? "PositiveNum "
                  : "") + "WBList unnamed-character-style-1"
              }
            >
              {(wallet.breakDown.profits.total < 0 ? "-" : "") +
                wallet.breakDown.profits.currency.symbol +
                " " +
                fixFractionDigits(wallet.breakDown.profits.total)}
            </span>
          </p>
          <div id="ProfitsListE" className="WBList">
            <p>
              ROI
              <span style={{ float: "right" }}>
                {wallet.breakDown.profits.currency.symbol + " "}
                {wallet.breakDown.profits.investmentsROI
                  ? fixFractionDigits(wallet.breakDown.profits.investmentsROI)
                  : 0}
              </span>
            </p>
            {store.getState().profile.accountType >= 8 && (
              <p>
                IPO Sales
                <span style={{ float: "right" }}>
                  {wallet.breakDown.profits.currency.symbol +
                    " " +
                    fixFractionDigits(wallet.breakDown.profits.ipoSales)}
                </span>
              </p>
            )}
            {store.getState().profile.accountType >= 8 && (
              <p>
                Received Fees
                <span style={{ float: "right" }}>
                  {wallet.breakDown.profits.currency.symbol +
                    " " +
                    fixFractionDigits(wallet.breakDown.profits.receivedFees)}
                </span>
              </p>
            )}
          </div>
          <p id="AngelDustTitleE" className="WBList unnamed-character-style-1">
            Angel Dust
            <span
              id="AngelDustValueE"
              className={
                (wallet.breakDown.angelDust.total < 0
                  ? "NegativeNum "
                  : wallet.breakDown.angelDust.total > 0
                  ? "PositiveNum "
                  : "") + "WBList unnamed-character-style-1"
              }
            >
              {(wallet.breakDown.angelDust.total < 0 ? "-" : "") +
                wallet.breakDown.angelDust.currency.symbol +
                " " +
                fixFractionDigits(wallet.breakDown.angelDust.total)}
            </span>
          </p>
          <div id="AngelDustListE" className="WBList">
            <p>
              Investments Loss
              <span
                style={{ float: "right" }}
                className={
                  wallet.breakDown.angelDust.investmentsLoss < 0
                    ? "NegativeNum"
                    : ""
                }
              >
                {(wallet.breakDown.angelDust.investmentsLoss < 0 ? "-" : "") +
                  wallet.breakDown.angelDust.currency.symbol +
                  " " +
                  fixFractionDigits(wallet.breakDown.angelDust.investmentsLoss)}
              </span>
            </p>
            <p>
              Paid Fees
              <span
                style={{ float: "right" }}
                className={
                  wallet.breakDown.angelDust.paidFees < 0 ? "NegativeNum" : ""
                }
              >
                {(wallet.breakDown.angelDust.paidFees < 0 ? "-" : "") +
                  wallet.breakDown.angelDust.currency.symbol +
                  " " +
                  fixFractionDigits(wallet.breakDown.angelDust.paidFees)}
              </span>
            </p>
            {/* <p>Broker fees</p><p>{'-P' + toDigits('4110')}</p> */}
          </div>
          <p id="TotalTitleE">
            Total
            <span
              id="TotalValueE"
              className={
                wallet.totalEquity > 0
                  ? "PositiveNum"
                  : wallet.totalEquity < 0
                  ? "NegativeNum"
                  : ""
              }
            >
              {wallet.currency.symbol +
                " " +
                fixFractionDigits(wallet.totalEquity)}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
