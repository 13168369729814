import "./style.css";

import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import store from '../../store/store'
import Loader from "../../components/common/Loader";

import BackPage from "../../assets/images/common/back-page.png";
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Loading(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();


  useEffect(() => {
    setTimeout(() => {
      navigate(props.to);
    }, 3000);
  }, []);

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div className="headline" style={{ display: "flex",flexDirection:'column',justifyContent:'center',height: windowInnerSize.height }}>
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          style={{ top: "10vw" }}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div>
          <div style={{ margin: "0 auto", height: "115px", width: "115px" }}>
            <Loader glow width='10px'/>
          </div>
          <p className="unnamed-character-style-1"style={{ textAlign:'center'}} >LOADING...</p>
        </div>
        {props.text}
      </div>
    </AnimateKeyframes>
  );
}
