import "./style.css";

import store from "../../../../store/store";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";

// import LongLogo from "../../../../assets/images/common/ps-logo-one-row.png";
import ForgotPasswordPic from "../../../../assets/images/common/emoji-silence.png";
import Loader from "../../../../components/common/Loader";
import HeaderWithLogo from "../../../../components/common/HeaderWithLogo";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function ForgotPasswordEmailAddress(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const navigate = useNavigate();
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const [Email, setEmail] = useState(null);
  const [displayLoader, setdisplayLoader] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");

  const handleInputEmail = (e) => {
    setErrorMessage("");
    if (validateEmail(e.target.value)) {
      setEmail(e.target.value);
    } else {
      setEmail("");
    }
  };

  const handleSend = () => {
    if (Email && Email !== "Initial") {
      setdisplayLoader(true);

      var axios = require("axios");
      var data = {
        email: Email,
      };

      var config = {
        method: "post",
        url: process.env.REACT_APP_BACKEND_URL + "/auth/forgot-password",
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          store.dispatch({
            type: "app/forgotPasswordEmail/add",
            payload: Email,
          });
          navigate("/auth/reset-password/check-email");
        })
        .catch(function (error) {
          setdisplayLoader(false);
          // console.log(error);
          if (error.response?.data?.message) {
            setErrorMessage(error.response.data.message);
          } else {
            setErrorMessage("An error occurred, please try again later.");
          }
        });
    } else {
      setEmail("");
      setErrorMessage("Please enter a valid email address");
    }
  };
  // page height
  if (!store.getState().windowInnerSize) {
    store.dispatch({
      type: "ui/windowInnerSize/add",
      payload: { height: window.innerHeight, width: window.innerWidth },
    });
  }
  useEffect(() => {
    let optEmail = store.getState().forgotPasswordEmail;
    if (optEmail) {
      document.getElementById("EmailInput").value = optEmail;
      setEmail(optEmail);
    }
  }, []);

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.3}
      direction="normal"
      keyframes={[
        "transform: translateX(-100vw)",
        "transform: translateX(-50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="FPEmailAddress Page Page--padding-8vw  headline"
        style={{ height: windowInnerSize.height }}
      >
        <div
          className="Page--flex"
          style={{ minHeight: windowInnerSize.height }}
        >
          {/* <div id="DivLogo">
                <img src={LongLogo} alt="" id="LongLogo" />
            </div> */}
          <HeaderWithLogo />

          <div className="FPEmailAddress__instruction">
            <img
              src={ForgotPasswordPic}
              alt=""
              className="FPEmailAddress__Emoji"
            />

            <p className="FPEmailAddress__EnterTxt">
              Enter your email <br />
              address below
            </p>

            <input
              id="EmailInput"
              className={
                "FPEmailAddress__EmailInput input-default " +
                (Email === "" ? "input-wrong" : "")
              }
              type="email"
              placeholder="Email"
              onChange={handleInputEmail}
              onBlur={() => window.scrollTo(0, 0)}
            />
          </div>

          <div className="FPEmailAddress__indicate-container">
            {displayLoader ? (
              <div className="loader--5vw">
                <Loader />
              </div>
            ) : (
              <p className="error-message">{ErrorMessage}</p>
            )}
          </div>

          <button
            className="btn btn--white-yellow headline"
            onClick={handleSend}
          >
            Send reset password
          </button>
        </div>
      </div>
    </AnimateKeyframes>
  );
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
