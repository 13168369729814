import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { AnimateKeyframes } from "react-simple-animate";
import loadWallet from "../../../loaders/wallet";
import loadMySales from "../portfolio/loaders/loadSales";
import "./style.css";

import store from "../../../store/store";

import fixFractionDigits from "../../../helpers/fixFractionDigits";
import StockCreativeCard from "../../../components/common/creative-cards/Influencer";

// import unauthorized from "../../functions/unauthorized";

import BackPage from "../../../assets/images/common/back-page.png";
// import ArrowUp from '../../images/icons/ArrowUp.png';
// import ArrowDown from '../../images/icons/ArrowDown.png';
import Eyes from "../../../assets/images/common/eyes.png";
import LogSignSmily from "../../../assets/images/common/smiley-1.png";
import { ROLES } from "../../../tools/role";
import AgentCreativeCard from "../../../components/common/creative-cards/Agent";

const selectTokens = (state) => state.tokens;
const selectInvestmentStocks = (state) => state.investedShares;
const selectIPORelease = (state) => state.ipoSharesInformation;
const selectProfile = (state) => state.profile;
const selectMySales = (state) => state.userSales;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Portfolio(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const navigate = useNavigate();
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const tokens = useSelector(selectTokens);
  const investmentStocks = useSelector(selectInvestmentStocks);
  const IPOrelease = useSelector(selectIPORelease);
  const profile = useSelector(selectProfile);
  const mySales = useSelector(selectMySales);

  useEffect(() => {
    if (tokens) {
      loadWallet(tokens, store.getState().profile.accountType);
      loadMySales(tokens);
    }
  }, []);

  const [IPOSaleOffers, setIPOSaleOffers] = useState(null);
  useEffect(() => {
    if (mySales) {
      let temp;
      let userInfId = store.getState().profile.influencer.id;
      temp = mySales.filter((obj) => obj.stock.influencer.id === +userInfId);
      setIPOSaleOffers(temp);
    }
  }, [mySales]);

  let cardData = {
    profilePic: profile.profilePic,
    name: profile.name,
    username: profile.username,
    ranking: profile.influencer.ranking,
    ringColour: profile.influencer.ringColour,
    socialMedia: {
      tiktok: null,
      instagram: { follows: 120 },
      youtube: null,
    } /* profile.influencer.socialMedia */,
    niches: profile.influencer.niches,
    city: profile.city,
    currentPrice: IPOrelease ? IPOrelease.stock.marketPrice : 0,
    currency: IPOrelease ? IPOrelease.stock.currency.symbol : "?",
    lastChange: IPOrelease ? IPOrelease.stock.lastChange : "",
  };

  if (!store.getState().windowInnerSize) {
    store.dispatch({
      type: "ui/windowInnerSize/add",
      payload: { height: window.innerHeight, width: window.innerWidth },
    });
  }
  return investmentStocks ? (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        // "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="Portfolio Page headline"
        style={{ height: windowInnerSize.height }}
      >
        <img
          src={BackPage}
          alt=""
          className="Portfolio__BackBtn"
          onClick={() => {
            navigate("/exchange");
          }}
        />
        <div id="StocksHolding">
          {(!IPOrelease || IPOrelease.issued === 0) &&
          (investmentStocks.length === 0 || !investmentStocks) ? (
            <div id="NoStocksHoldingDiv">
              <img src={Eyes} alt="" id="EyesNoStcksT" />
              <p className="unnamed-character-style-1">
                You have no <br /> passionstocks <br />
                to trade
              </p>
              <p id="HowTxtNST" className="headline">
                How about buying some on the exchange,
                <br />i dare you
                <img src={LogSignSmily} alt="" id="DareYouSmileyT" />
              </p>
            </div>
          ) : null}
          {IPOrelease ? (
            IPOrelease.issued > 0 ? (
              <div id="IPOTrading" className="StockHDetails">
                <StockCreativeCard cardData={cardData} />

                <Link to={"/my-passionstocks"}>
                  <p className="TradeBtn">Trade</p>
                </Link>

                <div className="StockHoldingNumDiv">
                  <p className="StocksNum">{IPOrelease.leftIssued}</p>
                  <p className="StockHoldingTxt">{"Stock Remaining"}</p>
                  {/* <p className="StkHldnPCChangeFromLastTrnsctn body-copy">{Stock.stock.last_change.sign + Stock.stock.last_change.percent}</p>
                                <p className="StkHldnStockPercentChangeFromLastTrnsctn body-copy">{Stock.stock.change_since_ipo.sign + Stock.stock.change_since_ipo.percent}</p> */}
                </div>
                <div className="OnSaleList">
                  {IPOSaleOffers
                    ? IPOSaleOffers.map((ForSaleItem, i) => {
                        let temp = (
                          <div className="ForSale" style={{ zIndex: -i }}>
                            <p className="ForSaleTxt">
                              <i>{i + 1}</i>
                              <spam>{ForSaleItem.volume + "PS"}</spam> for{" "}
                              <spam>
                                {ForSaleItem.currency.symbol +
                                  fixFractionDigits(ForSaleItem.price)}
                              </spam>
                            </p>
                            <p
                              className="RemoveBtn"
                              onClick={() => {
                                // console.log("lll");
                                removeSaleOffer(ForSaleItem.id);
                              }}
                            >
                              Remove Sale
                            </p>
                          </div>
                        );
                        return (
                          <div className="none" style={{ zIndex: -i }}>
                            {wrapToLocate(temp, i)}
                          </div>
                        );
                      })
                    : null}
                  {IPOSaleOffers
                    ? IPOSaleOffers.map((ForSaleItem, i) => {
                        let temp = (
                          <div className="ForSale22" style={{ zIndex: -i }}>
                            <p className="ForSaleTxt">
                              <i>{i + 1}</i>
                              <spam>{ForSaleItem.volume + "PS"}</spam> for{" "}
                              <spam>
                                {ForSaleItem.currency.symbol +
                                  +fixFractionDigits(ForSaleItem.price)}
                              </spam>
                            </p>
                            <p className="RemoveBtn">Remove Sale</p>
                          </div>
                        );
                        return temp;
                      })
                    : null}
                </div>
              </div>
            ) : null
          ) : null}
          {investmentStocks.length > 0
            ? investmentStocks.map((Stock, i) => {
                let cardDataInvestments = {
                  profilePic: Stock.stock.influencer.user.profilePic,
                  name: Stock.stock.influencer.user.name,
                  username: Stock.stock.influencer.user.username,
                  ranking: Stock.stock.influencer.ranking,
                  ringColour: Stock.stock.influencer.ringColour,
                  socialMedia: Stock.stock.influencer.socialMedia,
                  niches: Stock.stock.influencer.niches,
                  city: Stock.stock.influencer.user.city,
                  currentPrice: Stock.stock.marketPrice,
                  currency: Stock.stock.currency.symbol,
                  lastChange: Stock.stock.lastChange,
                };

                let sales;
                if (mySales) {
                  sales = mySales.filter(
                    (obj) => obj.stock.id === Stock.stock.id
                  );
                } else {
                  sales = [];
                }

                let onPPTTrade = Stock.onPPTTrade;

                return (
                  <div id={i} className="StockHDetails">
                    {Stock.stock.influencer.user.accountType & ROLES.AGENT ? (
                      <AgentCreativeCard cardData={cardDataInvestments} />
                    ) : (
                      <StockCreativeCard cardData={cardDataInvestments} />
                    )}
                    {/* <Link to={"/exchange/create-trade/" + Stock.stock.id}> */}
                    <p
                      className="TradeBtn"
                      onClick={() => {
                        navigate(`/exchange/create-trade/${Stock.stock.id}`, {
                          state: { process: "CELL" },
                        });
                      }}
                    >
                      Trade
                    </p>
                    {/* </Link> */}

                    <div className="StockHoldingNumDiv">
                      <p className="StocksNum">{Stock.volume}</p>
                      <p className="StockHoldingTxt">{"Stock Holding"}</p>
                      {/* <p className="StkHldnPCChangeFromLastTrnsctn body-copy">{Stock.stock.last_change.sign + Stock.stock.last_change.percent}</p>
                                        <p className="StkHldnStockPercentChangeFromLastTrnsctn body-copy">{Stock.stock.change_since_ipo.sign + Stock.stock.change_since_ipo.percent}</p> */}
                    </div>
                    <div className="OnSaleList">
                      {sales.length > 0
                        ? sales.map((ForSaleItem, i) => {
                            let temp = (
                              <div className="ForSale" style={{ zIndex: -i }}>
                                <p className="ForSaleTxt">
                                  <i>{i + 1}</i>
                                  <spam>
                                    {ForSaleItem.volume + "PS"}
                                  </spam> for{" "}
                                  <spam>
                                    {ForSaleItem.currency.symbol +
                                      +fixFractionDigits(ForSaleItem.price)}
                                  </spam>
                                </p>
                                <p
                                  className="RemoveBtn"
                                  onClick={() => {
                                    // console.log("lll");
                                    removeSaleOffer(ForSaleItem.id);
                                  }}
                                >
                                  Remove Sale
                                </p>
                              </div>
                            );
                            return (
                              <div className="none" style={{ zIndex: -i }}>
                                {wrapToLocate(temp, i)}
                              </div>
                            );
                          })
                        : null}
                      {onPPTTrade > 0 ? (
                        <div className="none" style={{ zIndex: -sales.length }}>
                          {wrapToLocate(
                            <div
                              className="ForSale"
                              style={{ zIndex: -sales.length }}
                            >
                              <p
                                className="ForSaleTxt"
                                style={{
                                  position: "relative",
                                  fontSize: "4.5vw",
                                  left: "0vw",
                                  width: "100%",
                                  textAlign: "center",
                                }}
                              >
                                {"Pending for "}
                                <spam>{onPPTTrade}</spam>

                                {" Passion Product"}
                              </p>
                            </div>,
                            sales.length
                          )}
                        </div>
                      ) : null}
                      {sales.length > 0
                        ? sales.map((ForSaleItem, i) => {
                            let temp = (
                              <div className="ForSale22" style={{ zIndex: -i }}>
                                <p className="ForSaleTxt">
                                  <i>{i + 1}</i>
                                  <spam>
                                    {ForSaleItem.volume + "PS"}
                                  </spam> for{" "}
                                  <spam>
                                    {ForSaleItem.currency.symbol +
                                      +fixFractionDigits(ForSaleItem.price)}
                                  </spam>
                                </p>
                                <p className="RemoveBtn">Remove Sale</p>
                              </div>
                            );
                            return temp;
                          })
                        : null}

                      {onPPTTrade > 0 ? (
                        <div
                          className="ForSale22"
                          style={{ zIndex: -(sales.length + 1) }}
                        >
                          <p className="ForSaleTxt">
                            <i>PPT</i>
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </AnimateKeyframes>
  ) : null;

  async function removeSaleOffer(saleId) {
    await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/sales/` + saleId,
      headers: {
        Authorization: tokens.accessToken,
      },
    })
      .then(function (response) {
        // console.log("delete offer sale" + saleId);
        loadWallet(tokens, store.getState().profile.accountType);
        loadMySales(tokens);
      })
      .catch(function (error) {
        // console.log("error to delete offer sale" + saleId);
      });
  }
}
function wrapToLocate(ToLocate, index) {
  let Wrap = ToLocate;
  for (let i = 0; i < index; i++) {
    Wrap = (
      <div className="Wrap" z-index={-i}>
        {Wrap}
      </div>
    );
  }
  return Wrap;
}
