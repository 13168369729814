import "./style.css";

import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import StockCreativeCard from "../../../components/common/creative-cards/Influencer";

import store from "../../../store/store";
import loadProfile from "../../../loaders/profile";

import BackPage from "../../../assets/images/common/back-page.png";
import smileyCelebrates from "../../../assets/images/common/smiley-celebrates.png";

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectProfile = (state) => state.profile;
const selectTokens = (state) => state.tokens;

export default function ListingApproval() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const profile = useSelector(selectProfile);

  const navigate = useNavigate();

  const tokens = useSelector(selectTokens);
  useEffect(() => {
    if (tokens) {
      loadProfile(tokens);
    }
  }, []);

  let cardData = profile
    ? {
        profilePic: profile.profilePic,
        name: profile.name,
        username: profile.username,
        ranking: profile.influencer.ranking,
        ringColour: profile.influencer.ringColour,
        socialMedia: {
          tiktok: null,
          instagram: { follows: 120 },
          youtube: null,
        } /* profile.influencer.socialMedia */,
        niches: profile.influencer.niches,
        city: profile.city,
        currentPrice: profile.issuer.stock.marketPrice,
        currency: profile.influencer.stock.currency.symbol,
        lastChange: profile.influencer.stock.lastChange,
      }
    : null;

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="ListingApproval Page headline"
        style={{ height: windowInnerSize.height, padding: "15vw 0" }}
      >
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          style={{ top: "10vw" }}
          onClick={() => navigate("/profile/creator")}
        />

        <img
          src={smileyCelebrates}
          alt="smiley-celebrates"
          style={{ display: "block", margin: "0 auto", width: "90px" }}
        />
        <h1
          className="unnamed-character-style-1"
          style={{
            fontSize: "38px",
            textTransform: "none",
            textAlign: "center",
            margin: "14px auto",
          }}
        >
          Congratulations!
        </h1>
        <p
          style={{
            fontWeight: "lighter",
            width: "90vw",
            fontSize: "14px",
            lineHeight: "19px",
            textAlign: "center",
            margin: "0 auto",
            width: "80vw",
          }}
        >
          Your brand is approved for listing on PassionStocks Market!
        </p>

        <div style={{ width: "90vw", margin: "0 auto" }}>
          <h2
            style={{
              fontSize: "18px",
              margin: "50px auto 10px",
              textAlign: "center",
            }}
          >
            This is your brand unit
          </h2>
          <StockCreativeCard cardData={cardData} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              fontSize: "11px",
              fontWeight: "lighter",
            }}
          >
            <p style={{ margin: "2px 0" }}>initial price per unit:{null}</p>
            <p style={{ margin: "2px 0" }}>Initial brand units: {null}</p>
          </div>
        </div>

        <div>
          <h2
            style={{
              margin: "0 auto",
              fontSize: "14px",
              textAlign: "center",
              margin: "10px auto 8px",
            }}
          >
            Brand Unit Introduction
          </h2>
          <p
            style={{
              fontSize: "14px",
              lineHeight: "19px",
              fontWeight: "lighter",
              textAlign: "center",
              width: "75vw",
              margin: "0 auto",
            }}
          >
            On PassionStocks, you can earn and boost your brand using "Brand
            Units”.
            <br />
            These units give buyers access to your giveaways, under conditions
            you control.
          </p>
        </div>

        <svg
          width="100px"
          height="1px"
          style={{ display: "block", margin: "30px auto" }}
        >
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="#707070"
            stroke-width="10%"
            stroke-linecap="butt"
          />{" "}
        </svg>

        <div>
          <h2
            style={{
              textAlign: "center",
              fontSize: "14px",
              lineHeight: "18px",
              margin: "0 auto 10px",
            }}
          >
            3 key points
          </h2>
          <p
            style={{
              fontSize: "14px",
              lineHeight: "19px",
              fontWeight: "lighter",
              width: "80vw",
              margin: "25px auto",
            }}
          >
            <span>1. Brand Value Boost</span> <br />
            Whenever you sell a Brand Unit, your brand's value sees a
            significant jump. For instance, if you sell a Brand Unit for $10,
            your brand's value may increase to $100 or even more. This boost can
            be even greater if you sell Brand Units constantly and quickly.
            <br />
            <br />
            <span>2. $1B Brand Value Milestone</span>
            <br />
            When your brand’s value hits $1 billion on PassionStocks, it
            triggers the deliberate process of listing your brand on a stock
            exchange.
            <br />
            <br />
            <span>3.Control Your Strategy</span>
            <br />
            You have full control over the quantity, pricing, and giveaways
            associated with your Brand Units, whether you make these decisions
            before or after listing them on PassionStocks market.
          </p>
        </div>

        <svg
          width="100px"
          height="1px"
          style={{ display: "block", margin: "20px auto 40px" }}
        >
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="#707070"
            stroke-width="10%"
            stroke-linecap="butt"
          />{" "}
        </svg>

        <p
          style={{
            fontSize: "14px",
            lineHeight: "19px",
            fontWeight: "lighter",
            textAlign: "center",
            margin: "10px auto 50px",
          }}
        >
          To list your first brand unit, click 'Go Live’
        </p>

        <div
          className="ListingApproval__ButtonsContainer headline"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            margin: "0",
          }}
        >
          <button
            onClick={() => navigate("/my-brand")}
            className="btn--purple_border-white"
          >
            My Brand
          </button>
          <button
            onClick={() => navigate("/my-passionstocks")}
            className="btn--yellow-black"
          >
            Go Live
          </button>
        </div>

        <svg
          width="80vw"
          height="1px"
          style={{ display: "block", margin: "30px auto 15px" }}
        >
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="#707070"
            stroke-width="10%"
            stroke-linecap="butt"
          />{" "}
        </svg>

        <p
          style={{
            fontSize: "11px",
            lineHeight: "15px",
            fontWeight: "lighter",
            color: "#707070",
            width: "80vw",
            margin: "0 auto",
          }}
        >
          For insights into PassionStocks’ evaluation metrics and benefits,
          click here.
        </p>
      </div>
    </AnimateKeyframes>
  );
}
