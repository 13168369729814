import React from "react";
import { useState, useEffect } from "react";
import "./style.css";

import TikTokCLogo from "../../../assets/images/common/tiktok-icon.png";
import YoutubeCLogo from "../../../assets/images/common/youtube-icon.png";
import InstagramBigIcon from "../../../assets/images/common/instagram-icon.png";

export default function SocialMediaValues(props) {
  const [TikTok, setTikTok] = useState("left");
  const [Instagram, setInstagram] = useState("Ymiddle");
  const [YouTube, setYouTube] = useState("right");

  const [currentSMDisplayed, setcurrentSMDisplayed] = useState(null);
  const [currentSMData, setcurrentSMData] = useState(null);

  useEffect(() => {
    if (props.SocialMedia) {
      changeIconPos(
        props.dominatingPlatform ? props.dominatingPlatform : "Instagram"
      );
    }
  }, [props.SocialMedia]);

  // let SocialMediaC = [
  //     {
  //         social_network: "TikTok",
  //         followers: '--',
  //         username: "--",
  //         engagement_rate: '--',
  //         likes: '--',
  //         verified: 0
  //     },
  //     {
  //         social_network: "Instagram",
  //         followers: '--',
  //         username: "--",
  //         engagement_rate: '--',
  //         likes: '--',
  //         verified: 0
  //     },
  //     {
  //         social_network: "YouTube",
  //         subscribers: '--',
  //         username: "--",
  //         engagement_rate: '--',
  //         likes: '--',
  //         verified: 0
  //     }
  // ];
  // const [SocialMedia, setSocialMedia] = useState(SocialMediaC)

  // const [DisplaySocialMediaValues, setDisplaySocialMediaValues] = useState(SocialMedia[1])

  // let SocialMediaD = [
  //     {
  //         social_network: "TikTok",
  //         followers: '--',
  //         username: "--",
  //         engagement_rate: '--',
  //         likes: '--',
  //         verified: 0
  //     },
  //     {
  //         social_network: "Instagram",
  //         followers: '--',
  //         username: "--",
  //         engagement_rate: '--',
  //         likes: '--',
  //         verified: 0
  //     },
  //     {
  //         social_network: "YouTube",
  //         subscribers: '--',
  //         username: "--",
  //         engagement_rate: '--',
  //         likes: '--',
  //         verified: 0
  //     }
  // ];
  // const [PropsUpdated, setPropsUpdated] = useState(null)

  // useEffect(() => {
  //     // if ((props.SocialMedia) && (PropsUpdated === null)) {
  //         for (let i = 0; i < Object.keys(props.SocialMedia).length; i++) {
  //             if (props.SocialMedia[i].social_network === "TikTok") {
  //                 SocialMediaD = [props.SocialMedia[i], SocialMediaD[1], SocialMediaD[2]];
  //             }
  //             if (props.SocialMedia[i].social_network === "Instagram") {
  //                 SocialMediaD = [SocialMediaD[0], props.SocialMedia[i], SocialMediaD[2]];
  //             }
  //             if (props.SocialMedia[i].social_network === "YouTube") {
  //                 SocialMediaD = [SocialMediaD[0], SocialMediaD[1], props.SocialMedia[i]];
  //             }
  //         }
  //         // setPropsUpdated(true);
  //         setSocialMedia(SocialMediaD);
  //         setDisplaySocialMediaValues(SocialMediaD[1]);

  //         // changeIconPos(props.SocialMedia[0].social_network);
  //     // }
  // }, [props.SocialMedia])
  return (
    <div style={{position:'relative'}}>
      {currentSMData ? (
        <div id="SocialMediaValuesDiv" className="headline">
          <img
            src={TikTokCLogo}
            alt=""
            id={TikTok}
            onClick={() => {
              changeIconPos("TikTok");
            }}
          />
          <img
            src={YoutubeCLogo}
            alt=""
            id={YouTube}
            onClick={() => changeIconPos("YouTube")}
          />
          <img
            src={InstagramBigIcon}
            alt=""
            id={Instagram}
            onClick={() => changeIconPos("Instagram")}
          />

          <div id="SMValuesDiv">
            <p id="SMUserName">
              {currentSMDisplayed === "YouTube"
                ? currentSMData.username
                : currentSMData.handle}
            </p>

            <p id="FollowsV">
              {currentSMDisplayed === "YouTube"
                ? currentSMData.subscribers
                : currentSMData.followers}
            </p>
            <p id="FollowsTxt">
              {currentSMDisplayed === "YouTube" ? "Subscribers" : "Follows"}
            </p>

            <p id="EngRV">
              {currentSMData.engagementRate
                ? currentSMData.engagementRate
                : "--"}
              %
            </p>
            <p id="EngRateTxt">
              Engagement <br /> Rate
            </p>

            <p id="TotalLV">{currentSMData.likes}</p>
            <p id="TotalLikesTxt">Total Likes</p>
          </div>
        </div>
      ) : null}
    </div>
  );
  function changeIconPos(index) {
    let temp;
    // premitive
    if (index === "TikTok") {
      temp = TikTok;
      setTikTok("Ymiddle");
      if (YouTube === "Ymiddle") {
        setYouTube(temp);
      }
      if (Instagram === "Ymiddle") {
        setInstagram(temp);
      }
      setcurrentSMDisplayed("TikTok");
      setcurrentSMData(props.SocialMedia.TikTok);
    }
    if (index === "YouTube") {
      temp = YouTube;
      setYouTube("Ymiddle");
      if (TikTok === "Ymiddle") {
        setTikTok(temp);
      }
      if (Instagram === "Ymiddle") {
        setInstagram(temp);
      }
      setcurrentSMDisplayed("YouTube");
      setcurrentSMData(props.SocialMedia.YouTube);
    }
    if (index === "Instagram") {
      temp = Instagram;
      setInstagram("Ymiddle");
      if (YouTube === "Ymiddle") {
        setYouTube(temp);
      }
      if (TikTok === "Ymiddle") {
        setTikTok(temp);
      }
      setcurrentSMDisplayed("Instagram");
      setcurrentSMData(props.SocialMedia.Instagram);
    }
  }
}
// function numFormatter(num) {
//     if ((num === '--') || (!num)) {
//         return '--'
//     }
//     if (num > 999 && num < 1000000) {
//         return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million
//     } else if (num === 1000000) {
//         return (num / 1000000).toFixed(0) + 'M';
//     } else if (num > 1000000) {
//         return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million
//     } else if (num < 900) {
//         return num; // if value < 1000, nothing to do
//     }
// }
