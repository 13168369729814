import "./style.css";

import store from "../../../store/store";

import BackPage from "../../../assets/images/common/back-page.png";
// import TermsOfUseIcon from '../../images/icons/TermsIcon.png';
import LegalCircle from "../../../assets/images/menu/bag.png";
import PrivatePolicyIcon from "../../../assets/images/menu/protector.png";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Legal(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Menu",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  return (
    <div
      id="LegalDiv"
      className="Page headline"
      style={{ height: windowInnerSize.height }}
    >
      <img
        src={BackPage}
        alt=""
        className="back-btn"
        onClick={() => {
          navigate("/menu");
        }}
      />
      <p id="LegalTitle">Legal</p>

      <Link to={`/t&cs-policy`}>
        <img src={LegalCircle} alt="" id="LegalCircleL" />
        <p id="TermAndCTitle">Terms And Conditions</p>
      </Link>

      {/* <img src={TermsOfUseIcon} alt="" id="TermsOfUseIcon"/>
            <p id="TermOfUseTitle">Terms Of Use</p> */}

      <Link to="/privacy-policy">
        <img src={PrivatePolicyIcon} alt="" id="PrivatePolicyIcon" />
        <p id="PrivatePTitle">Privacy policy</p>
      </Link>
    </div>
  );
}
