import React from 'react';
import './style.css'

import Bull from '../../../assets/images/common/going-up.png';
import Bear from '../../../assets/images/common/going-down.png';

export default function StockEngagement(props) {
    let engagement = props.engagement;
    return(
        <div id="ResponseDetailsDiv">
            <span><img src={Bull} alt="" id="BullSRImg"/>Bull {' ' + props.engagement?.bulls}</span>
            <i>| </i>
            <span>Views {props.engagement?.views}</span>
            <i>|</i>
            <span>Comments {props.engagement?.comments}</span>
            <i>|</i>
            <span><img src={Bear} alt="" id="BearSRImg"/>Bear {' ' + props.engagement?.bears}</span>
        </div>
    );
}