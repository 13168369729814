import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";

import "./style.css";

import store from "../../../../store/store";

import BackPage from "../../../../assets/images/common/back-page.png";

import HeaderWithLogo from "../../../../components/common/HeaderWithLogo";

const selectSignUpToken = (state) => state.signUpToken;
const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectSelectedCurrency = (state) => state.selectedCurrency;
const selectProfile = (state) => state.profile;

export default function YourPassions(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const signUpToken = useSelector(selectSignUpToken);
  const selectedCurrency = useSelector(selectSelectedCurrency);

  const navigate = useNavigate();

  // prevent enter to the page by insert the url in undesirable cases
  const profile = useSelector(selectProfile);
  const location = useLocation();
  const [processController, setProcessController] = useState(null);
  useEffect(() => {
    if (profile) {
      navigate("/profile");
    }
    if (location.state?.process !== "SIGNUP") {
      navigate("/auth/sign-up");
    } else {
      setProcessController(true);
    }
  }, [location, profile]);

  const [MyHashtags, setMyHashtags] = useState([]);

  let HashTagsList = [
    "#Dancing",
    "#Music",
    "#tiktok",
    "#instagram",
    "#model",
    "#fashion",
    "#comedy",
    "#art",
    "#profit",
  ];
  let HashTagsObj = [];
  for (let k = 0; k < HashTagsList.length; k++) {
    HashTagsObj.push({
      id: k,
      hashtag: HashTagsList[k],
      isSelected: false,
    });
  }

  const [HashtagStateList, setHashtagStateList] = useState(HashTagsObj);
  // let temp=new Array(HashTagsList.length);
  // for(let j=0;j<temp.length;j++){temp[j]=0;}

  // const [CheckHashTag,setCheckHashTag] = useState(temp);
  const [ErrorMessage, setErrorMessage] = useState("");
  const handleSubmit = async () => {
    if (MyHashtags.length < 3) {
      setErrorMessage("Please select at least 3 hashtags.");
    } else {
      if (MyHashtags.length > 15) {
        setErrorMessage("Please select maximum 15 hashtags.");
      } else {
        // let temp = "";
        // for (let i = 0; i < MyHashtags.length; i++) {
        //   if (i === 0) {
        //     temp = MyHashtags[i];
        //   } else {
        //     temp += "," + MyHashtags[i];
        //   }
        // }
        await axios({
          method: "POST",
          url:
            process.env.REACT_APP_BACKEND_URL + `/auth/registration-finalize`,
          headers: {
            Authorization: signUpToken,
          },
          data: {
            hashtags: MyHashtags,
            currency: selectedCurrency.target.value,
          },
        })
          .then(async function (res) {
            store.dispatch({
              type: "domain/tokens/addAccessToken",
              payload: res.data.accessToken,
            });

            // console.log("post user hastags");
            // console.log(res);
            await axios({
              method: "GET",
              url: process.env.REACT_APP_BACKEND_URL + `/api/users`,
              headers: {
                Authorization: res.data.accessToken,
              },
            })
              .then(async function (response) {
                // console.log(response);
                store.dispatch({
                  type: "domain/user/addData",
                  payload: response.data,
                });
                // await persistor.purge();
                navigate("/profile", { replace: true });
              })
              .catch(function (error) {});
          })
          .catch(function (error) {
            // console.log("error to post hashtags");
            // console.log(selectedCurrency);
            setErrorMessage(
              <>
                Error to post hashtags,
                <br /> please try again later.
              </>
            );
          });
      }
    }
  };

  const [listUpdated, setlistUpdated] = useState(true); // used to re-render
  const handleInputEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      if (event.target.value.length > 0) {
        setlistUpdated(HashtagStateList.length);
        let temp = HashtagStateList;
        let hashtag =
          (event.target.value[0] !== "#" ? "#" : "") + event.target.value;
        updateMyHashtags(hashtag, true);
        temp.push({
          id: temp.length,
          hashtag: hashtag,
          isSelected: true,
        });
        setHashtagStateList(temp);
        event.target.value = "";
      }
    }
  };
  useEffect(() => {
    // console.log(MyHashtags);
  }, [MyHashtags]);

  if (!store.getState().windowInnerSize) {
    store.dispatch({
      type: "ui/windowInnerSize/add",
      payload: { height: window.innerHeight, width: window.innerWidth },
    });
  }
  return (
    processController && (
      <AnimateKeyframes
        play={true}
        iterationCount={1}
        duration={0.42}
        direction="normal"
        keyframes={[
          "transform: translateX(-100vw)",
          "transform: translateX(-50vw)",
          "transform: translate(0, 0)",
        ]}
      >
        <div
          className="YourPassions Page "
          style={{ height: windowInnerSize.height }}
        >
          <div
            className="Page--flex"
            style={{ minHeight: windowInnerSize.height }}
          >
            <HeaderWithLogo />
            {/* <Link to={"/auth/sign-up/select-currency"}> */}
              <img src={BackPage} alt="" className="back-btn" onClick={()=>{navigate("/auth/sign-up/select-currency",{state:{process:'SIGNUP'}})}}/>
            {/* </Link> */}
            <p className="YourPassions__WhatTxt unnamed-character-style-1">
              WHAT'S YOUR <br /> PASSION?
            </p>
            <div>
              <p className="YourPassions__SelectTxt headline">
                Select at least 3 hashtags that you love.
                <br />
                if you don’t find them, type them in.
              </p>
              <div>
                <div className="YourPassions__HashTagsContainer headline">
                  {listUpdated &&
                    HashtagStateList.map(function (hashtag, i) {
                      // console.log(hashtag.id);
                      return (
                        <p
                          key={i}
                          id={hashtag.id}
                          onClick={() =>
                            setCheckHashtag(
                              hashtag.isSelected,
                              hashtag.id,
                              hashtag.hashtag
                            )
                          }
                          className={
                            HashtagStateList[hashtag.id].isSelected
                              ? "YourPassions__Check"
                              : "YourPassions__UnCheck"
                          }
                        >
                          {hashtag.hashtag}
                        </p>
                      );
                    })}
                </div>
                <div
                  style={{
                    position: "relative",
                    boxShadow: "0 -4vw 2vw 3vw #121212",
                    height: "2vh",
                    background: "#121212",
                    zIndex: "9",
                  }}
                ></div>
              </div>
            </div>
            <div className="YourPassions__ErrorMessageContainer">
              <p className="error-message headline">{ErrorMessage}</p>
            </div>
            {/* {getHasTags(HashTagsList)} */}
            <input
              type="text"
              placeholder="#your_hashtag_here"
              className="YourPassions__HashtagInput input-default headline"
              onKeyDown={handleInputEnter}
              onBlur={() => window.scrollTo(0, 0)}
            />
            <div style={{ padding: "2vh 0 4vh" }}>
              <p
                className="YourPassions__DoneBtn headline"
                onClick={handleSubmit}
              >
                Done
              </p>
              <div
                style={{
                  display: "inline",
                  margin: "0 12vw 0 36vw",
                  width: "fit-content",
                }}
              >
                <svg height="9vw" width="9vw">
                  <circle
                    cx="60%"
                    cy="60%"
                    r="23%"
                    stroke="white"
                    strokeWidth="3"
                  />
                </svg>
                <svg height="9vw" width="9vw">
                  <circle
                    cx="60%"
                    cy="60%"
                    r="23%"
                    stroke="white"
                    strokeWidth="3"
                  />
                </svg>
                <svg height="9vw" width="9vw">
                  <circle
                    cx="60%"
                    cy="60%"
                    r="23%"
                    stroke="white"
                    strokeWidth="3"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </AnimateKeyframes>
    )
  );
  // function getHasTags(){
  //     let HashTagsElements=[];
  //     for(let i=0;i<=HashTagsList.length;i++)
  //     {
  //         HashTagsElements.push(
  //             <p id={toString(i)} key={i} className={CheckHashTag[i]===0?"HashTagBtn":"HashTagCheck"} >{HashTagsList[i]}</p>
  //         );

  //     }
  //     return(
  //         <div id="HashTagsContainer" className="headline">
  //            {HashTagsElements}
  //         </div>
  //     )

  // }
  function setCheckHashtag(HIsSelected, HId, HName) {
    setErrorMessage("");
    let HObj = {
      id: HId,
      hashtag: HName,
      isSelected: !HIsSelected,
    };
    let tempArray = [...HashtagStateList];
    tempArray[HId] = HObj;
    setHashtagStateList(tempArray);
    // console.log(HashtagStateList);
    updateMyHashtags(HName, !HIsSelected);
  }
  function updateMyHashtags(HName, isSelected) {
    let temp;
    if (isSelected) {
      temp = [...MyHashtags, HName];
      setMyHashtags(temp);
    } else {
      temp = MyHashtags;
      temp.splice(temp.indexOf(HName.valueOf()), 1);
      setMyHashtags(temp);
    }
  }
}
