import "./style.css";

import store from "../../../../store/store";

// import unauthorized from '../../../functions/unauthorized';

import BackPage from "../../../../assets/images/common/back-page.png";
// import Done from '../../../images/icons/Done.png';
import ABSAIcon from "../../../../assets/images/wallet/withdraw/absa.png";
import CapitecIcon from "../../../../assets/images/wallet/withdraw/capitec.png";
import FNBIcon from "../../../../assets/images/wallet/withdraw/fnb.png";
import NedbankIcon from "../../../../assets/images/wallet/withdraw/nedbank.png";
import StandardIcon from "../../../../assets/images/wallet/withdraw/standard.png";

// import { Link } from 'react-router-dom';
import { useNavigate } from "react-router";
// import axios from 'axios';
import { useState } from "react";
import { useSelector } from "react-redux";

const selectTokens = (state) => state.tokens;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function AddBankBeneficiary() {
  store.dispatch({
    type: "appConfig/displayNavBar",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  const tokens = useSelector(selectTokens);

  const [Bank, setBank] = useState(false);
  const [Name, setName] = useState(false);
  const [AccountNumber, setAccountNumber] = useState(false);
  const [BranchCode, setBranchCode] = useState(false);

  const handleBank = (event) => {
    if (event.target.value) {
      setBank(event.target.value);
    } else {
      setBank(null);
    }
  };
  const handleName = (event) => {
    if (event.target.value.length > 0) {
      setName(event.target.value);
    } else {
      setName(null);
    }
  };
  const handleAccountNumber = (event) => {
    if (event.target.value.length > 0) {
      setAccountNumber(event.target.value);
    } else {
      setAccountNumber(null);
    }
  };
  const handleBranchCode = (event) => {
    if (event.target.value.length > 0) {
      setBranchCode(event.target.value);
    } else {
      setBranchCode(null);
    }
  };

  const handleSubmitBankAcD = async () => {
    // console.log(99999);
    if (Bank && Name && AccountNumber && BranchCode) {
      var axios = require("axios");
      var data = {
        fullName: Name,
        bankNumber: Bank,
        accountNumber: AccountNumber,
        branchCode: BranchCode,
      };

      var config = {
        method: "post",
        url: process.env.REACT_APP_BACKEND_URL + "/api/banks",
        headers: {
          Authorization: tokens.accessToken,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          navigate("/wallet/withdrawal/amount/" + response.data.id);
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else {
      !Bank && setBank(null);
      !BranchCode && setBranchCode(null);
      !AccountNumber && setAccountNumber(null);
      !Name && setName(null);
    }
  };

  let SABanks = [
    { name: "ABSA", code: 0, pic: ABSAIcon },
    { name: "Capitec", code: 1, pic: CapitecIcon },
    { name: "FNB", code: 2, FNBIcon },
    { name: "Nedbank", code: 3, pic: NedbankIcon },
    { name: "Standard bank", code: 4, pic: StandardIcon },
  ];
  function getBankOptions() {
    let temp = [];
    temp.push(
      <option
        style={{ color: "#b7b7b7 !important" }}
        value={""}
        selected
        disabled="true"
      >
        Bank
      </option>
    );
    for (let i = 0; i < SABanks.length; i++) {
      temp.push(
        <option
          style={{ background: `url(${SABanks[i].pic})` }}
          value={SABanks[i].code}
          key={i}
        >
          {SABanks[i].name}
        </option>
      );
    }
    return temp;
  }
  return (
    <div
      className="AddingBankBen Page Page--padding-8vw headline"
      style={{ height: windowInnerSize.height }}
    >
      <img
        src={BackPage}
        alt=""
        className="AddingBankBen__BackBtn"
        onClick={() => {
          navigate(-1);
        }}
      />
      <div className="Page--flex" style={{ minHeight: windowInnerSize.height }}>
        <p className="AddingBankBen__PleaseTxt">
          Add your bank details
          <br /> to Withdraw your
          <br /> money from PassionStocks
        </p>
        <div style={{ marginBottom: "5vh" }}>
          <select
            className={"AddingBankBen__BankInput input-default "+(Bank===null?"input-wrong":"")}
            placeholder="Bank"
            onChange={handleBank}
          >
            {getBankOptions()}
          </select>
          {/* <input
            type="text"
            placeholder="Bank"
            className="AddingBankBen__BankInput input-default input-type2"
            onChange={handleBank}
          /> */}
          <input
            type="text"
            placeholder="Account Holders Full Name"
            className={"AddingBankBen__AccountNameInput input-default "+(Name===null?"input-wrong":"")}
            onChange={handleName}
          />
          <input
            type="number"
            placeholder="Bank Account Number"
            className={"AddingBankBen__AccountNumberInput input-default "+(AccountNumber===null?"input-wrong":"")}
            onChange={handleAccountNumber}
          />
          <input
            type="text"
            placeholder="Branch Code"
            className={"AddingBankBen__BranchCodeInput input-default "+(BranchCode===null?"input-wrong":"")}
            onChange={handleBranchCode}
          />
        </div>
        {/* <Link to="/Withdraw/Amount"> */}
        <p className="AddingBankBen__NextBtn" onClick={handleSubmitBankAcD}>
          NEXT
        </p>
        {/* </Link> */}
      </div>
    </div>
  );
}
