import React from 'react';
import './CheckBtn.css'
import store from '../../../../store/store'
import { useState } from 'react';
export default function CheckBtn(props) {

    const [isCheck, setisCheck] = useState(false);
    const handleDeliver = () => {
        var axios = require('axios');
        var data = {
            tradeId: props.deliveryItem.id,
            type: 0
        };

        var config = {
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/ppt-trades/deliver',
            headers: {
                Authorization: store.getState().tokens.accessToken
            },
            data: data
        };
 
        axios(config)
            .then(function (response) {
                // // console.log(JSON.stringify(response.data));
                setisCheck(true);
            })
            .catch(function (error) {
                // console.log(error);
            });

    }
    return (
        <div className={isCheck ? "BtnCheck" : "BtnNotCheck"} onClick={handleDeliver}>
            <div className={isCheck ? "SwitchCircleCheck" : "SwitchCircleNotCheck"} />
        </div>
    );
}