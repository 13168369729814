import "./style.css";

import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";

import store from "../../../store/store";

import clipboard from "../../../assets/images/common/clipboard.png";
import { Link } from "react-router-dom";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function IssuerRegIntroMessage() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="IntroMessage headline"
        style={{ height: windowInnerSize.height }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "24vw 10vw 5vw",
          }}
        >
          <div>
            <img
              src={clipboard}
              alt="Clipboard"
              className="IntroMessage__Clipboard"
            />
            <h1 className=" unnamed-character-style-1">
              qualification assessment
            </h1>
          </div>{" "}
          <h2>
            You’re about to check if your brand qualifies for PassionStocks
            market listing.
          </h2>
          <div>
            <p>
              The process consists of 2 parts:
              <br />
              <br />
              1. Creator Verification
              <br />
              2. Brand Calculator
              <br />
              <br />
              Feel free to navigate through these parts and skip any that may
              not be applicable or require your immediate attention.
              <br />
              <br />
              The assessment can be completed without signing up for the
              platform.
              <br />
              <br />
              If you meet the criteria, you will be directed to book a meeting
              with our team for identity and information verification.
              <br />
              <br />
              Our team will guide you through the VIP registration process.
            </p>
            <Link to="/creator-registration/tiktok-username">
              {" "}
              <button
                className="btn--grey_border-yellow headline"
                style={{
                  lineHeight: 0,
                  fontSize: "15px",
                  lineHeight: "32px",
                  background: 0,
                  margin: "40px auto 15px",
                }}
              >
                Get Started
              </button>
            </Link>
          </div>
          <div>
            <svg height="1px" width="80vw">
              <line
                x1="0"
                x2="100%"
                y1="0"
                y2="0"
                stroke="#707070"
                stroke-linecap="butt"
              />
            </svg>
            <p style={{ color: "#707070" }}>
              To learn about the registration process for the PassionStocks
              market and its benefits, click here.
            </p>
          </div>
        </div>
      </div>
    </AnimateKeyframes>
  );
}
