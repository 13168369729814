
import store from '../store/store';
import { persistor } from '../store/store';

// import unauthorized from "../../functions/unauthorized";


export default async function loadWallet(tokens, accountType) {
    // console.log(accountType);
    var axios = require('axios');

    // wallet
    var config = {
        method: 'get',
        url: process.env.REACT_APP_BACKEND_URL + '/api/wallet',
        headers: {
            Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
        }
    };

    await axios(config)
        .then(function (response) {
            // console.log(JSON.stringify(response.data));
            store.dispatch({ type: 'domain/wallet/addData', payload: response.data })
            // await persistor.purge();
        })
        .catch(function (error) {
            // console.log(error);
        });

    // transactions
    var configTransactions = {
        method: 'get',
        url: process.env.REACT_APP_BACKEND_URL + '/api/wallet/history',
        headers: {
            Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
        },
        params: {
            month: new Date().getMonth() + 1
        }
    };
    axios(configTransactions)
        .then(function (response) {
            // console.log(JSON.stringify(response.data));
            store.dispatch({ type: 'domain/transactionHistory/addData', payload: response.data })
            // await persistor.purge();
        })
        .catch(function (error) {
            // console.log(error);
        });

    // shares
    // var configShares = {
    //     method: 'get',
    //     url: process.env.REACT_APP_BACKEND_URL + '/api/investors/shares',
    //     headers: {
    //         Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
    //     }
    // };
    // await axios(configShares)
    //     .then(function (response) {
    //         // console.log(JSON.stringify(response.data));
    //          store.dispatch({ type: 'domain/investedShares/addData', payload: response.data })
    //         // await persistor.purge();

    //     })
    //     .catch(function (error) {
    //         // console.log(error);
    //     });

    // console.log(store.getState().profile.accountType);
    // IPO
    // if ((accountType === 30) || (accountType === 14)) {
    //     var configIPO = {
    //         method: 'get',
    //         url: process.env.REACT_APP_BACKEND_URL + '/api/influencers/shares-to-release',
    //         headers: {
    //             Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
    //         }
    //     };
    //     await axios(configIPO)
    //         .then(function (response) {
    //             console.log(JSON.stringify(response.data));
    //             store.dispatch({ type: 'domain/ipoSharesInformation/addData', payload: response.data })
    //             // await persistor.purge();

    //         })
    //         .catch(function (error) {
    //             // console.log(error);
    //         });
    // }

}