import "./style.css";

import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";

import WellcomeMCBckImg from "../../../assets/images/money-calculator/welcome-background.png";
import ArrowLeftBtn from "../../../assets/images/common/arrow-left.png";
import QuotationMCBegin from "../../../assets/images/common/quotation-begin.png";
import QuotationMCEnd from "../../../assets/images/common/quotation-end.png";
import BackPage from "../../../assets/images/common/back-page.png";

import store from "../../../store/store";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Wellcome(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const [VibeMoneyForms, setVibeMoneyForms] = useState("Vibe");

  const navigate = useNavigate();

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(-100vw)",
        "transform: translateX(-50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="WellcomeMC Page headline"
        style={{ height: windowInnerSize.height }}
      >
        <div
          className="Page--flex"
          style={{ minHeight: windowInnerSize.height }}
        >
          <img
            src={BackPage}
            alt=""
            className="WellcomeMC__BackBtn"
            onClick={() => {
              navigate(-1);
            }}
          />
          <div style={{ height: "128vw", display: "block" }}>
            <div
              style={{ backgroundImage: `url(${WellcomeMCBckImg})` }}
              alt=""
              className="WellcomeMC__BackgroundImg"
            />
            <p className="WellcomeMC__WelcomeTxt unnamed-character-style-1">
              WELCOME ALL <br /> SOCIAL MEDIA INFLUENCERS
            </p>

            <div className="WellcomeMC__Shadow" />
            <img
              src={QuotationMCBegin}
              alt=""
              className="WellcomeMC__QuotationMCBegin"
            />
            <p className="WellcomeMC__EmpoweringTxt">
              Empowering social media influencers to <br />
              capitalise on their social media engagement; <br />
              by enabling their followers to trade their <br />
              passionstocks.
            </p>
            <img
              src={QuotationMCEnd}
              alt=""
              className="WellcomeMC__QuotationMCEnd"
            />
          </div>
          <div>
            <div className="WellcomeMC__BtnsContainer">
              <p
                className={
                  "WellcomeMC__WhatsTheVibeBtn "
                  // (VibeMoneyForms === "Vibe" ? "WellcomeMC__selectedBtn" : null)
                }
                onClick={setAsVibe}
              >
                WHAT'S THE VIBE?
              </p>
              <p
                className={
                  "WellcomeMC__ShowMoneyBtn "
                  // (VibeMoneyForms !== "Vibe" ? "WellcomeMC__selectedBtn" : null)
                }
                onClick={setAsShowMoney}
              >
                SHOW ME THE MONEY
              </p>
              <p
                className="WellcomeMC__selectedBtn"
                style={{
                  left: VibeMoneyForms === "Vibe" ? "5vw" : "47.5vw",
                  width: VibeMoneyForms === "Vibe" ? "40.5vw" : "47.5vw",
                }}
              />
            </div>
          </div>

          {VibeMoneyForms === "Vibe" && (
            <p className="WellcomeMC__WhatsTheVibeExp">
              At PassionStocks we define social media engagement as an asset.
              You have worked hard for where you are, to invest in your craft
              and build your brand.
              <br />
              <br />
              Now that you’re here the only way to capitalise on your work is to
              wait for the next big contract.
              <br />
              Let’s be honest you can’t pay your bills with likes (however great
              that may be).
              <br />
              <br />
              So we’re created a way that you can continue doing what you do
              best; spreading the love, by allowing your followers to invest in
              your potential.{" "}
            </p>
          )}
          {VibeMoneyForms !== "Vibe" && (
            <div className="WellcomeMC__ShowMoneyDiv">
              <p className="WellcomeMC__StatsShowMoney unnamed-character-style-1">
                {80} / <span>{20}</span>
              </p>
              <p className="WellcomeMC__StatsShowMoney__YouWillGetTxt">
                You will get: <br />
                <br />
                80% of all IPO passionstocks sold
                <br />
                1% off all successful Post-IPO passionstock trades
                <br />
                <br />
                Click on next to view how much you can
                <br />
                potentially generate on our platform.
              </p>
            </div>
          )}
          <div
            className="headline"
            style={{
              position: "relative",
              padding: "0 5vw",
              height: "182vw",
              marginTop: "23vw",
            }}
          >
            <p
              className="WellcomeMC__TheProcessTxt unnamed-character-style-1"
              style={{ margin: "0" }}
            >
              THE PROCESS
            </p>
            <svg
              className="WellcomeMC__TheProcessLine"
              style={{ display: "block" }}
              height="2vw"
              width="36%"
            >
              <line
                x1="0"
                x2="100%"
                y1="0"
                y2="0%"
                stroke="white"
                stroke-width="25%"
                stroke-linecap="butt"
              />
            </svg>
            <svg
              className="WellcomeMC__TheProcessStagesLine"
              height="145vw"
              width="2vw"
            >
              <line
                x1="0"
                x2="0%"
                y1="0"
                y2="100%"
                stroke="white"
                stroke-width="25%"
                stroke-linecap="butt"
              />
            </svg>
            <div
              className="WellcomeMC__TheProcess__TxtContainer"
              style={{ position: "absolute", top: "30vw" }}
            >
              <p
                className="WellcomeMC__TheProcessNumbers unnamed-character-style-1"
                // style={{ top: "5vw" }}
              >
                1
              </p>
              <h2>Money Calculator</h2>
              <p>Click “register” to get your PassionStocks evaluation.</p>
            </div>
            <div
              className="WellcomeMC__TheProcess__TxtContainer"
              style={{ position: "absolute", top: "83vw" }}
            >
              <p
                className="WellcomeMC__TheProcessNumbers unnamed-character-style-1"
                style={{ padding: "3.55vw", lineHeight: "4vw" }}
              >
                2
              </p>
              <h2>Join the exchange</h2>
              <p>
                After your validation.
                <br />
                Join the exchange and allow your followers to buy your
                passionstocks
              </p>
            </div>
            <div
              className="WellcomeMC__TheProcess__TxtContainer"
              style={{ position: "absolute", top: "142vw" }}
            >
              <p
                className="WellcomeMC__TheProcessNumbers unnamed-character-style-1"
                style={{ padding: "3.55vw", lineHeight: "4vw" }}
              >
                3
              </p>
              <h2>Social Media</h2>
              <p>
                Make your follows day by letting them know that they can now
                invest in your brand.
              </p>
            </div>
          </div>

          <div className="WellcomeMC__NavBtnsContainer">
            <Link to="/education">
              <img
                src={ArrowLeftBtn}
                alt=""
                className="WellcomeMC__ArrowLeftBtn"
              />
              <p className="WellcomeMC__LearnMoreBtn unnamed-character-style-1">
                LEARN MORE
              </p>
            </Link>
            <Link to="/money-calculator/step-1">
              <p className="WellcomeMC__RegisterBtn">Register</p>
            </Link>
          </div>
        </div>
      </div>
    </AnimateKeyframes>
  );

  function setAsShowMoney() {
    if (VibeMoneyForms !== "ShowMoney") {
      setVibeMoneyForms("ShowMoney");
    }
  }
  function setAsVibe() {
    if (VibeMoneyForms !== "Vibe") {
      setVibeMoneyForms("Vibe");
    }
  }
}
