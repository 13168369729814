import './style.css';

import axios from 'axios';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
const selectTokens = (state) => state.tokens;

export default function SalesLine(props) {

    const tokens = useSelector(selectTokens);

    const handleRemoveSale = async (saleId) => {
        await axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_BACKEND_URL}/api/sales/` + saleId,
            headers: {
                Authorization: tokens.accessToken
            }
        }).then(function (response) {
            // // console.log('delete offer sale' + saleId);
            // loadWallet(tokens, store.getState().profile.data.accountType);
            // loadMySales(tokens);
            props.loadStockH();
        }).catch(function (error) {
            // // console.log('error to delete offer sale' + saleId);

        });
    }

    const [sales, setsales] = useState(null);
    useEffect(() => {
        setsales(props.saleOffers);
    }, [props.saleOffers])

    return (
        sales &&
        sales.map(
            (ForSaleItem, i) => {
                let temp =
                    (
                        <div className="SaleLine" >

                            <p className='SaleLine__Data'>
                                <i>{i + 1}</i>
                                <span>{ForSaleItem.volume}</span> Units for <span>{ForSaleItem.currency.symbol + ForSaleItem.price}</span>
                            </p>
                            <div className='SaleLine__BtnsContainer'>
                                {/* <p className="SaleLine__UpdateBtn" onClick={() => handleRemoveSale(ForSaleItem.id)}>Update</p> */}
                                <p className="SaleLine__RemoveBtn" onClick={() => handleRemoveSale(ForSaleItem.id)}>Remove</p>
                            </div>
                        </div>
                    );
                return (temp);
            }
        )
        // <div className="ForSaleStS" style={{ zIndex: (props.AllSalesCount - props.SaleIndex), height: `${26 + (14 * props.SaleIndex)}vw` }}>
        //     <p className="ForSaleTxtStS"><i>{props.SaleIndex + 1}</i><spam>{props.Sale.volume + 'PS'}</spam> for <spam>{'PC' + props.Sale.price}</spam></p>
        //     <p className="RemoveBtnStS" onClick={() => handleRemoveSale(props.Sale.id)}>Remove Sale</p>
        // </div>
    )


}