import store from '../../../../store/store';

export default function exchangeData(tokens, page, type, order, direction, smi) {

    var axios = require('axios');

    var config = {
        method: 'get',
        url: process.env.REACT_APP_BACKEND_URL + '/api/sales/',
        headers: {
            Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
        },
        params: {
            page: page,
            type: type,
            order: order,
            direction: direction,
            smi: smi
        }
    };

    axios(config)
        .then(function (response) {
            // console.log(JSON.stringify(response.data));
            store.dispatch({ type: 'app/salesFeedNewPageData/add', payload: response.data.sales });
            store.dispatch({
                type: "app/salesFeedWaitingForData/toggle",
                payload: false,
            });

        })
        .catch(function (error) {
            store.dispatch({ type: 'app/salesFeedNewPageData/add', payload: 0 });
            // console.log(error);
            store.dispatch({
                type: "app/salesFeedWaitingForData/toggle",
                payload: false,
            });
        });


}