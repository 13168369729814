import React from "react";
import $ from "jquery";
import { useEffect } from "react";
import { useLocation } from "react-router";
import store from "../../store/store";
export default function SafariView() {
  const location = useLocation();

  useEffect(() => {
    var height = window.innerHeight;
    // var a = setInterval(function () {
      var resize = function () {
        if (window.innerHeight != height) {
          height = window.innerHeight;
          $(".Page").css("height", height + "px");
        }
      };
      // $(window).scrollTop(-1);
      resize();
    // }, 500); // Don't lower more than 500ms, otherwise there will be animation-problems with the  Safari toolbar

    // $(window).on("resize", function () {
    //   resize();
    // });
  }, [location.pathname]);

  
  return <div ></div>;
}
