import "./style.css";

import store from "../../../../store/store";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";

import BackPage from "../../../../assets/images/common/back-page.png";
import PSPic1 from "../../../../assets/images/education/passion-stock/PSPic1.png";
import PSPic2 from "../../../../assets/images/education/passion-stock/PSPic2.png";
import PSPic3 from "../../../../assets/images/education/passion-stock/PSPic3.png";
import IPOCardPS from "../../../../assets/images/education/passion-stock/IPOCardPS.png";
import PostIPOCardPS from "../../../../assets/images/education/passion-stock/PostIPOCardPS.png";
import NeevanPicPS from "../../../../assets/images/education/passion-stock/NeevanPicPS.png";
import UpArrow from "../../../../assets/images/common/arrow-up.png";
import TikTokCLogo from "../../../../assets/images/common/tiktok-icon.png";
import ArrowRightCardPS from "../../../../assets/images/education/passion-stock/ArrowRightCardPS.png";
import ArrowLeftCardPS from "../../../../assets/images/education/passion-stock/ArrowLeftCardPS.png";
import RankExpPicPS from "../../../../assets/images/education/passion-stock/RankExpPicPS.png";
import RateExpPicPS from "../../../../assets/images/education/passion-stock/RateExpPicPS.png";
import MVExpPicPS from "../../../../assets/images/education/passion-stock/MVExpPicPS.png";
import TradingVExpPicPS from "../../../../assets/images/education/passion-stock/TradingVExpPicPS.png";
import RightPicPS from "../../../../assets/images/common/arrow-right-yellow-circle.png";
import LeftPicPS from "../../../../assets/images/common/arrow-left-yellow-circle.png";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function PassionStock() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Notification",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();

  const [ActionForm, setActionForm] = useState("IPO");
  const [PSDescribe, setPSDescribe] = useState("Rank");

  const PSElements = ["Rank", "Rate", "MarketV", "Trade", "Trading"];

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        id="PassionStockDiv"
        className="Page headline"
        style={{ height: windowInnerSize.height }}
      >
        <div style={{ height: "585vw" }}>
          <img
            src={BackPage}
            alt=""
            id="BackPageNot"
            onClick={() => {
              navigate(-1);
            }}
          />
          <p id="PSTitle" className="unnamed-character-style-1">
            PASSIONSTOCK
          </p>
          <img src={PSPic1} alt="" id="PSPic1" />

          <p id="WhatQstTitlePS">
            <span>What is it?</span>
            <br />A limited digital card of a popular social
            <br /> media entity that can be traded in for
            <br /> passionproducts or traded on the <br />
            PassionStocks exchange.{" "}
          </p>

          <img src={PSPic2} alt="" id="PSPic2" />

          <p id="WhereQstPS">
            <span>Where do I get it?</span> <br />
            On the exchange tab you can purchasing
            <br /> passionstocks from fellow traders or buy <br />
            from your favourite influencer.
          </p>

          <img src={PSPic3} alt="" id="PSPic3" />

          <p id="IPOBtnPS" onClick={setAsIPO}>
            IPO passionstocks
          </p>
          <p id="PostIPOBtnPS" onClick={setAsPostIPO}>
            Post-IPO passionstocks
          </p>
          <svg
            height="1vw"
            width="25.5%"
            id={ActionForm === "IPO" ? "IPOSubLine" : "PostIPOSubLine"}
          >
            <line
              x1="0"
              x2="100%"
              y1="0"
              y2="0"
              stroke="white"
              stroke-width="100%"
              stroke-linecap="butt"
            />
          </svg>

          <div
            id={ActionForm === "IPO" ? "IPOExpPS" : "Hide"}
            className="headline"
          >
            <img src={IPOCardPS} alt="" id="IPOCardPS" />
            <p id="IPOCardExpPS">
              Social media influencers have only a limited <br />
              amount of passionstocks authorized to
              <br /> trade. Get in early and buy them when
              <br /> they’re hot; straight from the social media <br />
              influencer.
            </p>
          </div>
          <div
            id={ActionForm === "Post-IPO" ? "Post-IPOExpPS" : "Hide"}
            className="headline"
          >
            <img src={PostIPOCardPS} alt="" id="PostIPOCardPS" />
            <p id="PostIPOCardExpPS">
              These are passionstocks that have been
              <br /> traded before, but you’ll never know when
              <br /> they’ll be the next big thing.
            </p>
          </div>

          <p id="WhatValuesPS" className="unnamed-character-style-1">
            What the values mean
            <br />
            on a passionstock?
          </p>
          <div id="CardExpBtnPS" className="headline">
            <div className="StockCardPS">
              <div
                className={
                  "SMIPicBigSOC purpleRC " +
                  (PSDescribe !== "Rank" ? "OpacityPS" : "BoldPS")
                }
                style={{ backgroundImage: `url(${NeevanPicPS})` }}
              />

              <p
                className={
                  "SMIIPORatingSOC purpleRN " +
                  (PSDescribe !== "Rate" ? "OpacityPS" : "")
                }
              >
                5
              </p>

              <p className={"SMINameSOC body-copy-bold OpacityPS"}>
                Neevan Ferris
              </p>
              <p
                className={
                  "StocksForSaleSOC body-copy " +
                  (PSDescribe !== "Trading" ? "OpacityPS" : "BorderPS")
                }
              >
                <span className="TextYellow">1PS</span> for{" "}
                <span className="TextYellow">290PC</span>
              </p>
              <p
                className={
                  "StockPreviousPriceSOC body-copy " +
                  (PSDescribe !== "MarketV" ? "OpacityPS" : "BorderPS")
                }
              >
                PC285/PS
                <img src={UpArrow} alt="" className="CurrentPriceArrow" />
              </p>
              <div className={"SMHighStats OpacityPS"}>
                <img src={TikTokCLogo} alt="" className={"TikTokHIcon"} />
                <p className="SMHighFollows">1.3M </p>
                <p className="SMHighFollowsSpace">1.3M</p>
              </div>
              <div
                id="LCBorderPS"
                className={
                  PSDescribe !== "Trade" ? "OpacityPS" : "BoldPS BorderTradePS"
                }
              >
                <p
                  className={
                    "PositiveGreenNum PCChangeFromLastTrnsctnPS body-copy " +
                    (PSDescribe !== "Trade" ? "OpacityPS" : "")
                  }
                >
                  {" "}
                  +PC10{" "}
                </p>
                <p
                  className={
                    "PositiveGreenNum StockPercentChangeFromLastTrnsctnPS body-copy " +
                    (PSDescribe !== "Trade" ? "OpacityPS" : "")
                  }
                >
                  +0.5%
                </p>
              </div>
            </div>

            <img
              src={ArrowRightCardPS}
              alt=""
              id="ArrowRightCardPicPS"
              className={
                PSDescribe === "Rank"
                  ? "ARRankPosPS"
                  : PSDescribe === "Rate"
                  ? "ARRatePosPS"
                  : PSDescribe === "MarketV"
                  ? "ARMarketVPosPS"
                  : "Hide"
              }
            />
            <img
              src={ArrowLeftCardPS}
              alt=""
              id="ArrowLeftCardPicPS"
              className={
                PSDescribe === "Trade"
                  ? "ALTradePosPS"
                  : PSDescribe === "Trading"
                  ? "ALTradingPosPS"
                  : "Hide"
              }
            />

            <div id={PSDescribe === "Rank" ? "RankingExpDivPS" : "Hide"}>
              <img src={RankExpPicPS} alt="" id="RankExpPicPS" />

              <p id="RankingExpPS">
                <span>The ranking ring:</span>
                <br />
                Is the designated colour an influencer will
                <br />
                get according to their social media following
                <br />
                which makes up a part of their IPO and
                <br />
                market value.{" "}
              </p>
            </div>
            <div id={PSDescribe === "Rate" ? "RatingExpDivPS" : "Hide"}>
              <img src={RateExpPicPS} alt="" id="RateExpPicPS" />

              <p id="RatingExpPS">
                <span>The ranking range:</span>
                <br />
                The number of passionstocks the social media
                <br />
                influencer has sold from the number of
                <br />
                passionstocks issued.
              </p>
            </div>
            <div id={PSDescribe === "MarketV" ? "MarketValueExpDivPS" : "Hide"}>
              <img src={MVExpPicPS} alt="" id="MVExpPicPS" />

              <p id="MVExpPS">
                <span>Growth Gap=</span>
                <br />
                (price of a previous trade - the last trade) <br />
                <br />
                <br />
                <span>The ranking ring:</span>
                <br />
                Is the designated colour an influencer will
                <br />
                get according to their social media following
                <br />
                which makes up a part of their IPO and
                <br />
                market value.{" "}
              </p>
            </div>
            <div id={PSDescribe === "Trade" ? "TradeValuesExpDivPS" : "Hide"}>
              <p id="TradeVExpPS">
                <span>The trade values:</span>
                <br />
                <br />
                (+PC10):
                <br />
                Price difference between the previous and
                <br />
                last previous successful trade
                <br />
                <br />
                (+0.5%):
                <br />
                Growth from the previous and last
                <br />
                previous successful trade{" "}
              </p>
            </div>
            <div id={PSDescribe === "Trading" ? "TradingVExpPS" : "Hide"}>
              <img src={TradingVExpPicPS} alt="" id="TradingVExpPicPS" />
            </div>

            <img src={RightPicPS} alt="" id="RightPicPS" onClick={setNext} />
            <img src={LeftPicPS} alt="" id="LeftPicPS" onClick={setBack} />
          </div>
        </div>
      </div>
    </AnimateKeyframes>
  );

  function setAsIPO() {
    if (ActionForm !== "IPO") {
      setActionForm("IPO");
    }
  }
  function setAsPostIPO() {
    if (ActionForm !== "Post-IPO") {
      setActionForm("Post-IPO");
    }
  }

  function setNext() {
    let temp = PSElements.indexOf(PSDescribe);
    if (temp === 4) {
      setPSDescribe(PSElements[0]);
    } else {
      setPSDescribe(PSElements[temp + 1]);
    }
  }
  function setBack() {
    let temp = PSElements.indexOf(PSDescribe);
    if (temp === 0) {
      setPSDescribe(PSElements[5]);
    } else {
      setPSDescribe(PSElements[temp - 1]);
    }
  }
}
