import "./style.css";

import store from "../../../../store/store";

import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

const selectBottomNavDM = (state) => state.navBarsDM.bottom;

export default function ActionsMenu(props) {
  const bottomNavBarDM = useSelector(selectBottomNavDM);

  const [actions, setactions] = useState(null);
  useEffect(() => {
    if (props.actions) {
      let temp = props.actions.map((action) => {
        return (
          <p className="ActionsMenu__button" onClick={action.action}>
            {action.text}
          </p>
        );
      });
      setactions(temp);
    }
  }, [props.actions]);


  return (
      <div className="Action__Background" onClick={()=>props.setdisplayMenu(false)}>
        <div
          className="ActionsMenu headline"
          style={{ bottom: bottomNavBarDM ? "25vw" : "3vw" }}
        >
          <div className="ActionsMenu__container" style={{padding:' 0'}}>{actions}</div>

          {props.cancel && (
            <p
              className="ActionsMenu__button ActionsMenu__container"
              onClick={() => props.setdisplayMenu(false)}
            >
              Cancel
            </p>
          )}
        </div>
      </div>
    
  );
}
