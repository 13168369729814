import "./style.css";

import store from "../../../../store/store";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import BackPage from "../../../../assets/images/common/back-page.png";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";
import loadStockView from "../../../../loaders/sale";

import StockComment from "../../../exchange/exchange/components/StockComment";
import SaleOfferCard from "../../../../components/common/SaleOfferCard";
import GeneralAppMessage from "../../../../components/common/GeneralAppMessage";
import fixFractionDigits from "../../../../helpers/fixFractionDigits";
import isVarNegative from "../../../../helpers/isVarNegative";

const selectTokens = (state) => state.tokens;
const selectStockView = (state) => state.sale;
const selectSaleId = (state) => state.saleId;
const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectWallet = (state) => state.wallet;
const selectProfile = (state) => state.profile;

export default function IPOSaleAmountSelection(props) {
  // store.dispatch({
  //   type: "ui/navBarsDM/toggle",
  //   payload: { top: true, bottom: false },
  // });
  const navigate = useNavigate();
  const windowInnerSize = useSelector(selectWindowInnerSize);

  // prevent enter to the page by insert the url in undesirable cases
  const profile = useSelector(selectProfile);
  const location = useLocation();
  const [processController, setProcessController] = useState(null);
  useEffect(() => {
    if (!isVarNegative(profile)) {
      navigate("/");
    } else {
      if (location.state?.process !== "BUY") {
        navigate("/exchange");
      } else {
        setProcessController(true);
      }
    }
  }, [location, profile]);

  // let { saleId } = useParams();
  // let temp = props.saleOffers.filter(obj => obj.id === +saleId)
  // // console.log(temp);

  const tokens = useSelector(selectTokens);
  const wallet = useSelector(selectWallet);
  const saleId = useSelector(selectSaleId);
  const stockView = useSelector(selectStockView);

  useEffect(() => {
    if (tokens) {
      loadStockView(tokens, saleId);
    }
  }, []);

  const [PromtMessage, setPromtMessage] = useState(null);

  const [BuyStocksValue, setBuyStocksValue] = useState(null);
  const handleOnChangeInput = (event) => {
    if (
      event.target.value > 0 &&
      event.target.value <= Math.floor(wallet.withdrawalValue / stockView.price)
    ) {
      setBuyStocksValue(Math.floor(event.target.value));
    } else {
      setBuyStocksValue(0);
    }
  };

  const [confirmPurchaseMessageDM, setconfirmPurchaseMessageDM] =
    useState(false);
  const handleSubmitBuy = async (event) => {
    setconfirmPurchaseMessageDM(false);
    if (BuyStocksValue > 0) {
      await axios({
        method: "POST",
        url:
          `${process.env.REACT_APP_BACKEND_URL}/api/sales/` + saleId + `/buy`,
        headers: {
          Authorization: tokens.accessToken,
        },
        data: {
          volume: BuyStocksValue,
          displayPrice: stockView.price+"u",
        },
      })
        .then(function (response) {
          // console.log("bought IPO Stocks");
          // console.log(response.data);
          store.dispatch({
            type: "app/buyIPOSaleData/add",
            payload: { purchaseData: response.data, saleData: stockView },
          });

          navigate("/exchange/sale-completed", {
            replace: true,
            state: { process: "BUY" },
          });
        })
        .catch(async function (error) {
          if(error.subStatus!==1){
            setPromtMessage(error.message);
          }
          else{
            setPromtMessage("An error ")
          }
        });
    }
  };
  return (
    processController &&
    saleId &&
    stockView && (
      <AnimateKeyframes
        play={true}
        iterationCount={1}
        duration={0.42}
        direction="normal"
        keyframes={[
          "transform: translateX(100vw)",
          "transform: translateX(50vw)",
          "transform: translate(0, 0)",
        ]}
      >
        <div
          className="IPOSelectAmount Page headline"
          style={{ height: windowInnerSize.height }}
        >
          <div
            className="Page--flex"
            style={{ justifyContent: "space-between" }}
          >
            <img
              src={BackPage}
              alt=""
              className="IPOSelectAmount__BackBtn"
              onClick={() => {
                navigate("/exchange/sale-view");
              }}
            />
            <div className="IPOSelectAmount__SaleCardAndComment">
              <SaleOfferCard SaleOffer={stockView} />
              <StockComment SaleOffer={stockView} />
            </div>

            <p className="IPOSelectAmount__OnlyTxt">
              Only <span>{stockView.volume}</span> left
            </p>

            <div>
              <p className="IPOSelectAmount__HowTxt">
                How Many Stocks Would You Like?
              </p>
              <div>
                <input
                  type="number"
                  placeholder="0"
                  className={
                    "IPOSelectAmount__InputAmount input-default " +
                    (BuyStocksValue === 0 ? "input-wrong" : null)
                  }
                  min="0"
                  step="1"
                  onChange={handleOnChangeInput}
                />
                <p className="IPOSelectAmount__PSTxt">PS</p>
              </div>
              <svg className="IPOSelectAmount__Line" height="2.5vw" width="40%">
                <line
                  x1="0"
                  x2="100%"
                  y1="0"
                  y2="0"
                  stroke="white"
                  stroke-width="100%"
                  stroke-linecap="butt"
                />
              </svg>
              <p className="IPOSelectAmount__TotalPCTxt">
                Total{" "}
                {wallet.currency.symbol +
                  " " +
                  fixFractionDigits(wallet.withdrawalValue)}{" "}
              </p>
            </div>
            <p
              className="IPOSelectAmount__BuyBtn"
              onClick={() => {
                setconfirmPurchaseMessageDM(true);
              }}
            >
              BUY
            </p>

            <p
              id={/*PromtMessage ?*/ "MessageStatus" /*: "HideM"*/}
              className="headline"
            >
              {PromtMessage?PromtMessage:null}
            </p>
          </div>
          {confirmPurchaseMessageDM && (
            <GeneralAppMessage
              text={"Do you approve the purchase?"}
              onClickYes={handleSubmitBuy}
              onClickNo={() => {
                setconfirmPurchaseMessageDM(false);
              }}
              class="Width-90vw"
            />
          )}
        </div>
      </AnimateKeyframes>
    )
  );
}
