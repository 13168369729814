// import React, { useState } from "react";
// import axios from 'axios';

import store from '../../../../store/store';
// import { persistor } from '../../../store';
// import { useSelector } from 'react-redux';

export default function loadBankBen(tokens) {

    var axios = require('axios');

    var config = {
        method: 'get',
        url: process.env.REACT_APP_BACKEND_URL + '/api/banks',
        headers: {
            'Authorization': tokens.accessToken // can't call hooks from this function (to get the accessToken)
        }
    };

    axios(config)
        .then( function (response) {
            // console.log(JSON.stringify(response.data));
            store.dispatch({ type: 'app/bankBenificiaryList/add', payload: response.data });

        })
        .catch(function (error) {
            // console.log(error);
        });

}