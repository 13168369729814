import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter,
  createRoutesFromElements,
  Routes,
  Route,
} from "react-router-dom";
// import { useEffect } from 'react';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';

import store from './store/store.js';
import { persistor } from './store/store.js';


import './styles/XD_home.css';
import './styles/app-style.css';
import './styles/animation.css';


import OpeningView from './pages/opening-view';
import CookiesMessage from './components/CookiesMessage';

import NavBarBottom from './components/NavBars/Bottom';
import NavBarTop from './components/NavBars/Top';

import Home from './pages/home';

import AuthForm from './pages/auth/form';
// sign-up process
import Confirm from './pages/auth/signup_process/confirm_2';
import Terms from './pages/auth/signup_process/terms_3';
import SelectACurrency from './pages/auth/signup_process/currency_4';
import YourPassions from './pages/auth/signup_process/your-passions_5';
// reset-password process
import ForgotPasswordEmailAddress from './pages/auth/reset-password_process/email-address_1';
import ForgotPasswordCheckEmail from './pages/auth/reset-password_process/check-email_2';
import ResetPassword from './pages/auth/reset-password_process/reset-password_3';
// money calculator process
import Welcome from './pages/money-calculator_process/welcome_1/index.jsx';
import Step1 from './pages/money-calculator_process/step1_2';
import Sorry from './pages/money-calculator_process/sorry';
import Step2 from './pages/money-calculator_process/step2_3';
import Step3 from './pages/money-calculator_process/step3_4';
import Congratulations from './pages/money-calculator_process/congratulations_5';
import SetAppointment from './pages/money-calculator_process/set-appointment_6';
import MoneyCalcContactInfo from './pages/money-calculator_process/contact-info_7';
// unavailable
import Unavailable from './pages/unavailable';
// menu
import Menu from './pages/menu/menu';
import Legal from './pages/menu/legal';
import Help from './pages/menu/help';
import Security from './pages/menu/security';
import Report from './pages/menu/report';
// profile
import ProfileLayout from './pages/profile/profile';
import Influencer from "./pages/profile/profile/influencer";
import EditProfile from './pages/profile/edit-profile';
import AgentViewStats from './pages/profile/profile/agentIPOed/components/AgentViewStats.jsx';
// ppt
import ConvertToStock from './pages/ppt/to-stock_process/convert_1';
import SuccessfulToStock from './pages/ppt/to-stock_process/successful_2';
import ConvertToPPT from './pages/ppt/to-product_process/convert_1';
import SuccessfulToProduct from './pages/ppt/to-product_process/successful_2';
// wallet
import Wallet from './pages/wallet/wallet';
import Deposit from './pages/wallet/deposit_process/deposit_1';
import DepositSuccess from './pages/wallet/deposit_process/success_2';
import BeneficiarySelection from './pages/wallet/withdrawal_process/beneficiary-selection_1';
import AddingBankBeneficiary from './pages/wallet/withdrawal_process/adding-bank-beneficiary';
import AddingAmount from './pages/wallet/withdrawal_process/adding-amount_2';
import RequestReceived from './pages/wallet/withdrawal_process/request-received_3';
// my-passionstocks
import MyPassionstocks from './pages/my-passionstocks';
// exchange
import Exchange from './pages/exchange/exchange';
import Portfoilio from './pages/exchange/portfolio';
import CreateTrade from './pages/exchange/create-trade_process/trade-details_1';
import CreateTradeConfirmation from './pages/exchange/create-trade_process/confirmation_2';
import SaleView from './pages/exchange/buy-sale_process/sale-view_1';
import IPOSaleAmountSelection from './pages/exchange/buy-sale_process/ipo-select-amount_2/index.jsx';
import SaleCompleted from './pages/exchange/buy-sale_process/success_2_3/index.jsx';
import SaleComments from './pages/exchange/sale-comments';
//notification + education
import NotificationForm from './pages/notification/form';
import HowDoesItWork from './pages/notification/education-articles/how-does-it-work';
import PassionCoin from './pages/notification/education-articles/passion-coin';
import PassionStock from './pages/notification/education-articles/passion-stock';
import PassionProducts from './pages/notification/education-articles/passion-product';
import PSVSSM from './pages/notification/education-articles/ps-vs-sm';
// public-profile
import PublicProfileLayout from './pages/profile-public';
// rewards
import Rewards from './pages/rewards/index.jsx';
// policies
import CookiesPolicy from './pages/policies/cookies';
import PrivacyPolicy from './pages/policies/privacy';
import TermsAndCondition from './pages/policies/t&cs';
import SafariView from './components/SafariView';
import Demo from './pages/demo/index.jsx';
// version
import UpdateVersion from './utiles/updateVersion.jsx';
// about us
import About from './pages/about';
// become-an-agent
import BecomeAnAgent from './pages/become-an-agent';
// my agency
import MyAgencyDetails from './pages/my-agency/agency-details';
import FindYourAgency from './pages/my-agency/find-agency';
// monitor - such Datadog
import Monitor from './monitor';

import IsProcessConsistent from "./utiles/isProcessConsistent.jsx";
import Root from './pages/root';
import Temp from './utiles/temp.jsx';
import GeneralError from './pages/errors/general/index.jsx';
import PortfolioExtendedView from './pages/wallet/portfolio-extended-view/index.jsx';
import Desktop from './pages/desktop/index.jsx';
import MyBrand from './pages/my-brand/index.jsx';
// creators hub and registration
import CreatorsHub from './pages/creators-hub/index.jsx';
import IssuerRegIntroMessage from './pages/issuer-registration/intro-message_1/index.jsx';
import IssuerRegTiktokUsername from './pages/issuer-registration/tiktok-username_2/index.jsx';
import IssuerRegWelcome from './pages/issuer-registration/welcome_3/index.jsx';
import IssuerRegTypeForm from './pages/issuer-registration/type-form_4/index.jsx';
import BrandCalcResponse from './pages/issuer-registration/brand-calc-response_5/index.jsx';
import ListingApproval from './pages/profile/listing-approval/index.jsx';
import Loading from './pages/loading/index.jsx';

// window resize reflect some elements' ui
window.addEventListener('resize', function (event) {
  store.dispatch({
    type: "ui/windowInnerSize/add",
    payload: { height: window.innerHeight, width: window.innerWidth },
  });
}, true);
// site selector:
const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
const PhoneScreen = vw < 450 && vh < 970;



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root isPhone={PhoneScreen} />} errorElement={<GeneralError />} >

      <Route path="/" element={PhoneScreen ? <Home /> : <Desktop />} />

      <Route path="main" element={<Home />} />

      <Route path="news" element={<Unavailable />} />

      <Route path="about" element={<About />} />

      <Route path="careers" element={<Unavailable />} />

      <Route path="auth/sign-in" element={<AuthForm />} />

      <Route path="auth/log-in" element={<AuthForm action={"log-in"} />} />

      <Route path="auth/sign-up/:step" element={<AuthForm action={"sign-up"} />} />
      <Route path="auth/sign-up/confirmation" element={<Confirm />} />
      <Route path={"auth/sign-up/confirmation/t" + `&` + "cs"} element={<Terms />} />
      <Route path="auth/sign-up/select-currency" element={<SelectACurrency />} />
      <Route path="auth/sign-up/your-passions" element={<YourPassions />} />

      <Route path="auth/reset-password/email-address" element={<ForgotPasswordEmailAddress />} />
      <Route path="auth/reset-password/check-email" element={<ForgotPasswordCheckEmail />} />
      <Route path="auth/reset-password/new-password/:resetData" element={<ResetPassword />} />

      <Route path="loading" element={<Loading />} />

      <Route path="creators-hub" element={<CreatorsHub />} />
      <Route path="creator-registration/intro" element={<IssuerRegIntroMessage />} />
      <Route path="creator-registration/tiktok-username" element={<IssuerRegTiktokUsername />} />
      <Route path="creator-registration/welcome-to-brand-calculator" element={<IssuerRegWelcome />} />
      <Route path="creator-registration/form" element={<IssuerRegTypeForm />} />
      <Route path="creator-registration/loading-your-brand-report" element={<Loading to={"/creator-registration/brand-calculator-response"} text={<p className="glow-yellow-white unnamed-character-style-1" style={{ textAlign: 'center',fontSize:'18px',lineHeight:'25px' }}>Preparing <br /> your brand report…</p>} />} />
      <Route path="creator-registration/brand-calculator-response" element={<BrandCalcResponse />} />

      <Route path="money-calculator" element={<Welcome />} />
      <Route path="money-calculator/step-1" element={<Step1 />} />
      <Route path="money-calculator/sorry" element={<Sorry />} />
      <Route path="money-calculator/step-2" element={<Step2 />} />
      <Route path="money-calculator/step-3" element={<Step3 />} />
      <Route path="money-calculator/congratulations" element={<Congratulations />} />
      <Route path="money-calculator/set-appoinment" element={<SetAppointment />} />
      <Route path="money-calculator/contact-info" element={<MoneyCalcContactInfo />} />

      <Route path="become-an-agency" element={<BecomeAnAgent />} />

      <Route path="unavailable" element={<Unavailable />} />
      <Route path="/demo" element={<Demo />} />


      <Route path="menu" element={<Menu />} />
      <Route path="legal" element={<Legal />} />
      {/* <Route path="faqs" element={<FAQS />} /> */}
      <Route path="help" element={<Help />} />
      <Route path="security" element={<Security />} />
      <Route path="report" element={<Report />} />

      <Route path="education" element={<NotificationForm displayCategory={'Education'} />} />
      <Route path="education/how-does-it-work" element={<HowDoesItWork />} />
      <Route path="education/passion-coin" element={<PassionCoin />} />
      <Route path="education/passion-stock" element={<PassionStock />} />
      <Route path="education/passion-products" element={<PassionProducts />} />
      <Route path="education/passion-coin" element={<PassionCoin />} />
      <Route path="education/passionstocks-vs-regular-stock-market" element={<PSVSSM />} />

      <Route path="notification" element={<NotificationForm displayCategory={'Updates'} />} />

      <Route path="profile" element={<ProfileLayout />} />
      {/* temp!!! waiting for api to indicate first entry*/}
      <Route path="profile/creator" element={<Influencer />} />
      <Route path="profile/edit" element={<EditProfile />} />

      <Route path="listing-approval" element={<ListingApproval />} />

      <Route path="my-brand" element={<MyBrand />} />
      <Route path="my-agency/details" element={<MyAgencyDetails />} />
      <Route path="find-your-manager" element={<FindYourAgency />} />

      <Route path="ppt/convert-product-to-stock" element={<ConvertToStock />} />
      <Route path="ppt/convert-product-to-stock/successful" element={<SuccessfulToStock />} />
      <Route path="ppt/convert-stock-to-product" element={<ConvertToPPT />} />
      <Route path="ppt/convert-stock-to-product/successful" element={<SuccessfulToProduct />} />

      <Route path="wallet" element={<Wallet />} />
      <Route path="wallet/portfolio/extended-view" element={<PortfolioExtendedView />} />
      <Route path="wallet/deposit" element={<Deposit />} />
      <Route path="wallet/deposit/:amount" element={<Deposit />} />
      <Route path="wallet/deposit/success" element={<DepositSuccess />} />
      {/* need to check all deposit options */}
      <Route path="ozow/success" element={<DepositSuccess />} />
      <Route path="ozow/error" element={<Wallet />} />
      <Route path="ozow/cancel" element={<Wallet />} />
      <Route path="paypal/success" element={<DepositSuccess />} />

      <Route path="wallet/withdrawal/beneficiary-selection" element={<BeneficiarySelection />} />
      <Route path="wallet/withdrawal/adding-bank-beneficiary" element={<AddingBankBeneficiary />} />
      <Route path="wallet/withdrawal/amount/:bank" element={<AddingAmount />} />
      <Route path="wallet/withdrawal/request-received" element={<RequestReceived />} />

      <Route path="my-passionstocks" element={<MyPassionstocks />} />

      <Route path="exchange" element={<Exchange />} />
      <Route path="exchange/:influencerUsername" element={<Exchange />} />
      <Route path="exchange/sale-view" element={<SaleView />} />
      <Route path="exchange/sale-view/:brandUsername" element={<SaleView />} />
      <Route path="exchange/ipo-sale/amount-selection" element={<IPOSaleAmountSelection />} />
      <Route path="exchange/sale-completed" element={<SaleCompleted />} />

      <Route path="exchange/portfolio" element={<Portfoilio />} />

      <Route path="exchange/create-trade/:stockId" element={<CreateTrade />} />
      <Route path="exchange/create-trade/confirmation" element={<CreateTradeConfirmation />} />

      <Route path="sale/comments/:saleId" element={<SaleComments />} />

      <Route path="public-profile/:influencerId" element={<PublicProfileLayout />} />

      <Route path="rewards" element={<Unavailable />} />

      <Route path="cookies-policy" element={<CookiesPolicy />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path={`t&cs-policy`} element={<TermsAndCondition />} />

    </Route>
  ), {});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <BrowserRouter> */}
      {/* <SafariView /> */}
      {/* <UpdateVersion />
        <Monitor />

        <OpeningView />
        <CookiesMessage /> */}
      {/* <AgentViewStats /> */}

      {/* <div id='app-frame'> */}
      {/* <NavBarTop /> */}

      <RouterProvider router={router} />
      {/* <Routes>

          </Routes> */}

      {/* <NavBarBottom /> */}

      {/* </div> */}


      {/* </BrowserRouter> */}
    </PersistGate>
  </Provider>
);

