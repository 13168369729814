import "./style.css";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AnimateKeyframes } from "react-simple-animate";

import store from "../../../../store/store";

import StockCreativeCard from "../../../../components/common/creative-cards/Influencer";

import BackPage from "../../../../assets/images/common/back-page.png";
import ArrowLeft from "../../../../assets/images/common/arrow-pointer-left.png";
import ArrowRight from "../../../../assets/images/common/arrow-pointer-right.png";
import InstaLogo from "../../../../assets/images/common/instagram-icon.png";
import { ROLES } from "../../../../tools/role";
import AgentCreativeCard from "../../../../components/common/creative-cards/Agent";

const selectTokens = (state) => state.tokens;
const select3rdProfile = (state) => state.influencer3rdProfile;
const selectTradeData = (state) => state.pptConvertionTradeData;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function ConvertPPTToPS(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const tokens = useSelector(selectTokens);
  const tradeData = useSelector(selectTradeData);
  const smi3rdProfile = useSelector(select3rdProfile);

  useEffect(() => {
    return async () => {
      store.dispatch({ type: "domain/influencer3rdProfile/delete" });
      store.dispatch({ type: "app/pptConvertionTradeData/delete" });
    };
  }, []);

  const [cardData, setcardData] = useState(null);
  useEffect(() => {
    if (smi3rdProfile) {
      let tempCardData = {
        profilePic: smi3rdProfile.user.profilePic,
        name: smi3rdProfile.user.name,
        username: smi3rdProfile.user.username,
        ranking: smi3rdProfile.ranking,
        ringColour: smi3rdProfile.ringColour,
        socialMedia: {
          tiktok: null,
          instagram: { follows: 120 },
          youtube: null,
        } /* profile.data.influencer.socialMedia */,
        niches: smi3rdProfile.niches,
        city: smi3rdProfile.user.city,
        currentPrice: smi3rdProfile.stock.marketPrice
          ? smi3rdProfile.stock.marketPrice
          : 0,
        lastChange: smi3rdProfile.stock.lastChange,
      };
      setcardData(tempCardData);
    }
  }, [smi3rdProfile]);

  const navigate = useNavigate();

  const [displayErrorMessage, setdisplayErrorMessage] = useState(false);
  const handleConfirmTrading = () => {
    // console.log(tradeData);
    var axios = require("axios");
    var data = {
      tradeId: tradeData.id,
      type: tradeData.type,
    };

    var config = {
      method: "delete",
      url: process.env.REACT_APP_BACKEND_URL + "/api/ppt-trades/",
      headers: {
        Authorization: tokens.accessToken,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        navigate("/ppt/convert-product-to-stock/successful", {
          replace: true,
        });
      })
      .catch(function (error) {
        // console.log(error);
        setdisplayErrorMessage(true);
        setTimeout(() => {
          navigate("/profile", { replace: true });
        }, 3000);
      });
  };

  return (
    smi3rdProfile &&
    tradeData && cardData&& (
      <AnimateKeyframes
        play={true}
        iterationCount={1}
        duration={0.42}
        direction="normal"
        keyframes={[
          "transform: translateX(100vw)",
          "transform: translateX(50vw)",
          "transform: translate(0, 0)",
        ]}
      >
        <div
          id="ConvertPPTToPSDiv"
          className="Page headline"
          style={{ height: windowInnerSize.height }}
        >
          <img
            src={BackPage}
            alt=""
            id="BackPageCPPTTPS"
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className="StockCardPosCPPTTPS">{
            smi3rdProfile.user.accountType&ROLES.AGENT?
            <AgentCreativeCard cardData={cardData} />:
            <StockCreativeCard cardData={cardData} />
          }
          </div>

          <p id="ForTxtCPPTTPS">For</p>

          <img src={ArrowLeft} alt="" id="ArrowLeftCPPTTPS" />
          <img src={ArrowRight} alt="" id="ArrowRightCPPTTPS" />

          <div id="SMCardCPPTTPS">
            <img src={InstaLogo} alt="" id="InstaLogoCPPTTPS" />
            <p id="InstaTxtCPPTTPS">
              Instagram <br />
              Story
              <br />
              Mention
            </p>
          </div>

          <svg height="1.8vw" width="41%" id="LineCPPTTPS">
            <line
              x1="0"
              x2="100%"
              y1="0"
              y2="0"
              stroke="white"
              stroke-width="100%"
              stroke-linecap="butt"
            />
          </svg>

          <p id="TradingInTxtCPPTTPS">
            Trading 1x Instagram <br />
            story mention in for
            <br />
            1x @{smi3rdProfile.user.username} PassionStock
          </p>

          <p
            id="CancelBtnCPPTTPS"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </p>
          <p id="ConfirmBtnCPPTTPS" onClick={handleConfirmTrading}>
            Confirm trading
          </p>

          {displayErrorMessage ? (
            <p id="ErrorMessageCPPTTPS">Error to convert to PassionStock</p>
          ) : null}
        </div>
      </AnimateKeyframes>
    )
  );
}
