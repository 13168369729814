import "./style.css";

import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import ValidBrand from "./components/ValidBrand";
import InvalidBrand from "./components/InvalidBrand";

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectTypeFormIds = (state) => state.typeFormIds;

export default function BrandCalcResponse() {
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const typeFormIds = useSelector(selectTypeFormIds);

  useEffect(() => {
    var axios = require("axios");

    var config = {
      method: "get",
      url: process.env.REACT_APP_BACKEND_URL + "/issuers/potential",
      data: { formId: typeFormIds.formId, responseId: typeFormIds.responseId },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return <ValidBrand />;
}
