import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";
import { useLocation } from "react-router";
import "./style.css";

import store from "../../../../store/store";

import HeaderWithLogo from "../../../../components/common/HeaderWithLogo";
import Loader from "../../../../components/common/Loader";

import BackPage from "../../../../assets/images/common/back-page.png";

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectProfile = (state) => state.profile;

export default function Confirm(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });

  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  // prevent enter to the page by insert the url in undesirable cases
  const profile = useSelector(selectProfile);
  const location = useLocation();
  const [processController, setProcessController] = useState(null);
  useEffect(() => {
    if (profile) {
      navigate("/profile");
    }
    if (location.state?.process !== "SIGNUP") {
      navigate("/auth/sign-up");
    } else {
      setProcessController(true);
    }
  }, [location, profile]);

  const [dig1, setdig1] = useState("");
  const [dig2, setdig2] = useState("");
  const [dig3, setdig3] = useState("");
  const [dig4, setdig4] = useState("");

  const [ServerError, setServerError] = useState("");
  const [ShowLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (processController) {
      var input2 = document.getElementById("dig2");
      // Execute a function when the user releases a key on the keyboard
      input2.addEventListener("keyup", function (event) {
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 8) {
          // Cancel the default action, if needed
          event.preventDefault();
          // Trigger the button element with a click
          if (document.getElementById("dig1").inputMode.length === 0) {
            document.getElementById("dig1").focus();
          }
        }
      });
      var input3 = document.getElementById("dig3");
      // Execute a function when the user releases a key on the keyboard
      input3.addEventListener("keyup", function (event) {
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 8) {
          // Cancel the default action, if needed
          event.preventDefault();
          // Trigger the button element with a click
          if (document.getElementById("dig2").inputMode.length === 0) {
            document.getElementById("dig2").focus();
          }
        }
      });
      var input4 = document.getElementById("dig4");
      // Execute a function when the user releases a key on the keyboard
      input4.addEventListener("keyup", function (event) {
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 8) {
          // Cancel the default action, if needed
          event.preventDefault();
          // Trigger the button element with a click
          if (document.getElementById("dig3").inputMode.length === 0) {
            document.getElementById("dig3").focus();
          }
        }
      });
    }
  }, [processController]);

  const handleDig1 = (event) => {
    if (event.target.value === "") {
      setServerError("");
      setdig1("");
    } else {
      const result = +event.target.value;
      if (result >= 0 && result < 10) {
        setServerError("");
        setdig1(result);
        document.getElementById("dig2").focus();
        // console.log(dig1);
      }
    }
  };
  const handleDig2 = (event) => {
    if (event.target.value === "") {
      setServerError("");
      setdig2("");
    } else {
      const result = +event.target.value;
      if (+result >= 0 && +result < 10) {
        setServerError("");
        setdig2(result);
        document.getElementById("dig3").focus();
      }
    }
  };
  const handleDig3 = (event) => {
    if (event.target.value === "") {
      setServerError("");
      setdig3("");
    } else {
      const result = +event.target.value;
      if (+result >= 0 && +result < 10) {
        setServerError("");
        setdig3(result);
        document.getElementById("dig4").focus();
      }
    }
  };
  const handleDig4 = (event) => {
    if (event.target.value === "") {
      setServerError("");

      setdig4("");
    } else {
      const result = +event.target.value;
      if (result >= 0 && result < 10) {
        setServerError("");

        setdig4(result);
      } else {
        let temp = dig4;
        setdig4(temp);
      }
    }
  };

  const [correctInput, setcorrectInput] = useState(true);
  const handleSubmit = async () => {
    let codeDigits = `${dig1}${dig2}${dig3}${dig4}`;
    // console.log(codeDigits);
    if (codeDigits.length === 4) {
      await axios({
        method: "POST",
        url:
          process.env.REACT_APP_BACKEND_URL + `/auth/registration-verification`,
        headers: {
          Authorization: store.getState().signUpToken,
        },
        data: {
          code: codeDigits,
        },
      })
        .then(function (res) {
          setcorrectInput(true);
          // console.log("verified 4 digits");
          // console.log(res);

          navigate("/auth/sign-up/confirmation/t&cs", {
            state: { process: "SIGNUP" },
            replace: true,
          });
        })
        .catch(function (error) {
          setServerError({
            text: "An error occurred, please try again.",
            color: "red",
          });
          setcorrectInput(false);
          document.getElementById("dig1").value = "";
          document.getElementById("dig2").value = "";
          document.getElementById("dig3").value = "";
          document.getElementById("dig4").value = "";
          // console.log(error.response);
          if (error.response) {
            if (error.response.data === "code expired.") {
              setServerError({ text: "Code expired", color: "red" });
            }
            if (error.response.data === "wrong code.") {
              setServerError({ text: "Wrong code", color: "red" });
            }
          }
        });
    } else {
      setcorrectInput(false);
    }
  };

  const [anableResend, setanableResend] = useState(true);
  const [displayResendMessage, setdisplayResendMessage] = useState(null);
  const handleResendCode = async () => {
    if (anableResend) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BACKEND_URL + "/auth/resend-code",
        headers: {
          Authorization: store.getState().signUpToken,
        },
      };

      await axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          setServerError({
            text: "New code was sent to your email.",
            color: "#34c100",
          });
          setanableResend(false);
          setTimeout(() => setanableResend(true), 60000);
          setdig1("");
          setdig2("");
          setdig3("");
          setdig4("");
        })
        .catch(function (error) {
          // console.log(error);
          setServerError({
            text: "Error to send new code to your email.",
            color: "red",
          });
          setanableResend(false);
          setTimeout(() => setanableResend(true), 3000);
        });
    }
  };

  const HandleDecline = async (event) => {
    store.dispatch({ type: "domain/profile/deleteProfile", payload: null });
  };

  // useEffect(() => {
  if (!store.getState().windowInnerSize) {
    store.dispatch({
      type: "ui/windowInnerSize/add",
      payload: { height: window.innerHeight, width: window.innerWidth },
    });
  }
  // }, []);

  useEffect(() => {
    if (processController) {
      let input = document.getElementById("ConfirmRegister__input-container");
      input.addEventListener("focusout", () => {
        window.scrollTo(0, 0);
      });
    }
  }, [processController]);
  return (
    processController && (
      <AnimateKeyframes
        play={true}
        iterationCount={1}
        duration={0.42}
        direction="normal"
        keyframes={[
          "transform: translateX(-100vw)",
          "transform: translateX(-50vw)",
          "transform: translate(0, 0)",
        ]}
      >
        <div
          className="ConfirmRegister Page  headline"
          style={{ minHeight: windowInnerSize.height }}
        >
          <div
            className="Page--flex"
            style={{ minHeight: windowInnerSize.height }}
          >
            <HeaderWithLogo />
            <img
              src={BackPage}
              alt=""
              className="back-btn"
              onClick={() => {
                HandleDecline();
                navigate("/");
              }}
            />
            <div style={{ paddingTop: "20vh" }}>
              <p className="ConfirmRegister__ConfirmTxt headline">
                Please enter the 4 number code sent to your <br /> email to
                confirm your registrations
              </p>
              <svg height="2vw" width="35%" className="ConfirmRegister__Line">
                <line
                  x1="0"
                  x2="100%"
                  y1="0"
                  y2="0"
                  stroke="white"
                  stroke-width="100%"
                  stroke-linecap="butt"
                />
              </svg>
              <div
                id="ConfirmRegister__input-container"
                className="ConfirmRegister__input-container unnamed-character-style-1"
              >
                <input
                  id="dig1"
                  type="number"
                  value={dig1}
                  oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                  className={
                    "ConfirmRegister__InputDigit unnamed-character-style-1 " +
                    (!correctInput && "input-wrong")
                  }
                  onChange={handleDig1}
                />
                <input
                  id="dig2"
                  type="number"
                  value={dig2}
                  className={
                    "ConfirmRegister__InputDigit unnamed-character-style-1 " +
                    (!correctInput && "input-wrong")
                  }
                  onChange={handleDig2}
                />
                <input
                  id="dig3"
                  type="number"
                  value={dig3}
                  className={
                    "ConfirmRegister__InputDigit unnamed-character-style-1 " +
                    (!correctInput && "input-wrong")
                  }
                  onChange={handleDig3}
                />
                <input
                  id="dig4"
                  type="number"
                  value={dig4}
                  className={
                    "ConfirmRegister__InputDigit unnamed-character-style-1 " +
                    (!correctInput && "input-wrong")
                  }
                  onChange={handleDig4}
                />
              </div>
              <p
                className="ConfirmRegister__ResendBtn headline"
                style={{ color: !anableResend ? "gray" : "white" }}
                onClick={handleResendCode}
              >
                Resend
              </p>
            </div>

            <div className="SignUpForm__indicate">
              {ShowLoader ? (
                <div className="SignUpForm__LoaderPos">
                  <Loader />
                </div>
              ) : (
                <p
                  className="error-message"
                  style={{ color: ServerError ? ServerError.color : "" }}
                >
                  {ServerError ? ServerError.text : ""}
                </p>
              )}
            </div>

            <div
              style={{
                position: "relative",
                width: "100%",
                paddingBottom: "6vh",
              }}
            >
              <button
                className="ConfirmRegister__NextBtn headline"
                onClick={handleSubmit}
              >
                Next
              </button>
              <div
                style={{
                  margin: "0 auto",
                  width: "fit-content",
                  paddingTop: "7vh",
                }}
              >
                <svg height="9vw" width="9vw">
                  <circle
                    cx="60%"
                    cy="60%"
                    r="23%"
                    stroke="white"
                    strokeWidth="3"
                    fill="white"
                  />
                </svg>
                <svg height="9vw" width="9vw">
                  <circle
                    cx="60%"
                    cy="60%"
                    r="23%"
                    stroke="white"
                    strokeWidth="3"
                  />
                </svg>
                <svg height="9vw" width="9vw">
                  <circle
                    cx="60%"
                    cy="60%"
                    r="23%"
                    stroke="white"
                    strokeWidth="3"
                  />
                </svg>
              </div>
            </div>

            {displayResendMessage && (
              <div id="ResendMessageSC">
                <p id="ResendMessageTextSC">{displayResendMessage}</p>
              </div>
            )}
          </div>
        </div>
      </AnimateKeyframes>
    )
  );
}
