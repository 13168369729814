import "./style.css";

import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import FacebookLogin from "react-facebook-login";

import store from "../../store/store";

import WhatsappLogoBW from "../../assets/images/common/whatsapp-icon-white.png";
import FacebookLogoBW from "../../assets/images/common/facebook-icon-white.png";
import InstagramLogoBW from "../../assets/images/common/instagram-icon-white.png";
import YoutubeLogoBW from "../../assets/images/common/youtube-icon-white.png";
import LongLogo from "../../assets/images/common/ps-logo-one-row.svg";

import HeadView from "./components/HeadView";
import CreatorsHubBtn from "./components/CreatorsHubBtn";
import AuthBtns from "./components/AuthBtns";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Home(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: true },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Home",
  });

  const windowInnerSize = useSelector(selectWindowInnerSize);

  //display facebook chat logo
  useEffect(() => {
    document.getElementById("fb-root").classList.remove("Hide");
    return () => {
      document.getElementById("fb-root").classList.add("Hide");
    };
  }, []);

  // get real height screen:
  useEffect(() => {
    if (!store.getState().windowInnerSize) {
      store.dispatch({
        type: "ui/windowInnerSize/add",
        payload: { height: window.innerHeight, width: window.innerWidth },
      });
    }
  }, []);

  // const [AlreadyOnExchange, setAlreadyOnExchange] = useState(false)
  // if (store.getState().profile) {
  //   if (store.getState().profile.influencer) {
  //     if (!AlreadyOnExchange) {
  //       setAlreadyOnExchange(true);
  //     }
  //   }
  // }

  const [animateSlide, setanimateSlide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setanimateSlide(true);
    }, 0);
  }, []);

  return (
    <div
      className="Home Page Page--calc-bottom-nav headline"
      style={{ height: windowInnerSize.height, width: "100vw" }}
    >
      <HeadView />
      <CreatorsHubBtn />
      <AuthBtns />
      <p className="Home__Copy">
        <span style={{ fontSize: "1.35em" }}>
          {" "}
          PassionStocks is a personal brand platform.
        </span>
        <br />
        <br />
        Prepare your personal brand for investment and securitization.
        <br />
        <br />
        Earn a share of revenue as an active supporter, contributing to the
        brand’s success.
        <br />
        ___
        <br />
        <br />
        <span style={{ color: "#ffbf12" }}>
          Guiding you at every step,
          <br />
          from following your passion to reaching the stock exchange.
        </span>
        <br />
        <br />
        <span style={{ fontSize: "0.8em" }}>
          (And no. we are not a crypto platform)
        </span>
      </p>
      <svg height="2.5vw" width="48%" className="Home__Line">
        <line
          x1="0"
          x2="100%"
          y1="0"
          y2="0"
          stroke="white"
          stroke-width="100%"
          stroke-linecap="butt"
        />
      </svg>
      <div className="Home__Slogan">
        <img
          src={LongLogo}
          alt="PassionStocks Logo"
          className="Home__SloganLogo"
        ></img>
        <p className="Home__SloganTxt headline">
          Empowering creators and their communities for a stable and secure
          financial future through personal branding.
        </p>
      </div>
      <svg height="2.5vw" width="48%" className="Home__Line">
        <line
          x1="0"
          x2="100%"
          y1="0"
          y2="0"
          stroke="white"
          stroke-width="100%"
          stroke-linecap="butt"
        />
      </svg>
      <div className="Home__CompanyInform headline">
        <Link to="/about">
          <p>About</p>
        </Link>
        <Link to="/careers">
          <p>Careers</p>
        </Link>
        <Link to="https://passionstocks.helpsite.com">
          <p>FAQs</p>
        </Link>
        {/* <Link to="/education">
          <p>Learn More</p>
        </Link> */}
      </div>
      <p id="TsCsApplyTxtH" className="body-copy">
        <Link to={`/t&cs-policy`}>T's {"&"} C's</Link> Apply
      </p>{" "}
      <div className="Home__SM">
        <img src={WhatsappLogoBW} alt="" className="Home__SMItem"></img>
        <a href="https://www.facebook.com/PassionStocks">
          <img src={FacebookLogoBW} alt="" className="Home__SMItem"></img>
        </a>
        <a href="https://www.instagram.com/passionstocks/">
          <img src={InstagramLogoBW} alt="" className="Home__SMItem"></img>
        </a>
        <a href="https://www.youtube.com/channel/UCewSZEyV9qce9dnuUTY5Qqw">
          <img src={YoutubeLogoBW} alt="" className="Home__SMItem"></img>
        </a>

        {/* <p id="CookiesPolicyH" className="body-copy"><Link to="/Cookies_Policy">Cookies Policy</Link></p>
         */}
      </div>
      {/* <!-- Your Chat Plugin code --> */}
    </div>
  );
}
// async function reloadHome(dateUpdate) {
//   await store.dispatch({ type: 'appConfig/reloadPage', payload: dateUpdate });
//   await persistor.purge();

//   setTimeout( window.location.reload(),2000);
// }
