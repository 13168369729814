import "./ValidBrand.css";

import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import store from "../../../../store/store";

import BackPage from "../../../../assets/images/common/back-page.png";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function ValidBrand() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();

  function getFullDate() {
    let date = new Date();
    let text =
      date.getDate() +
      " " +
      getMonthName(date.getMonth()) +
      " " +
      date.getFullYear() +
      " " +
      fixDigits(date.getHours()) +
      ":" +
      fixDigits(date.getMinutes());
    return text;
  }
  function getMonthName(DateNum) {
    const DatesNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return DatesNames[DateNum];
  }
  function fixDigits(num) {
    if (num < 10) {
      return "0" + num;
    } else {
      return num;
    }
  }
  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="ValidBrand Page headline"
        style={{ height: windowInnerSize.height, textAlign: "center" }}
      >
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          style={{ top: "10vw" }}
          onClick={() => {
            navigate(-1);
          }}
        />

        <p
          className="glow-yellow-white unnamed-character-style-1"
          style={{ position: "relative", margin: "50px 0 0", fontSize: "32px" }}
        >
          BRAND REPORT
        </p>
        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "10px 0",
            fontSize: "16px",
            color: "#909090",
          }}
        >
          {getFullDate()}
        </p>
        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "10px 0",
            fontSize: "11px",
            lineHeight: "15px",
            color: "#707070",
          }}
        >
          This short ‘Brand Report’ is based on your information
          <br />
          and our brand calculator, which includes a 1%
          <br />
          conversion rate projection over 12 months
        </p>

        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "30px 0 10px",
            fontSize: "18px",
            lineHeight: "23px",
            color: "#909090",
            fontWeight: "bolder",
          }}
        >
          your current brand value
          <br />
          is in the range of
        </p>
        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "25px 0",
            fontSize: "48px",
            lineHeight: "66px",
            color: "#fff",
            fontWeight: "bolder",
          }}
        >
          ±{}
        </p>

        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "30px 0 10px",
            fontSize: "18px",
            lineHeight: "23px",
            color: "#909090",
            fontWeight: "bolder",
          }}
        >
          Your Approximate earnings <br />
          (next 12 months)
        </p>
        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "25px 0",
            fontSize: "48px",
            lineHeight: "66px",
            color: "#fff",
            fontWeight: "bolder",
          }}
        >
          ±{}
        </p>

        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "30px 0 10px",
            fontSize: "18px",
            lineHeight: "23px",
            color: "#909090",
            fontWeight: "bolder",
          }}
        >
          Your Brand worth Goal <br />
          (next 24 months){" "}
        </p>
        <p
          className="unnamed-character-style-1"
          style={{
            position: "relative",
            margin: "25px 0",
            fontSize: "48px",
            lineHeight: "66px",
            color: "#fff",
            fontWeight: "bolder",
          }}
        >
          ±{}
        </p>

        <p
          className="headline"
          style={{
            position: "relative",
            margin: "25px 5vw",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#909090",
            fontWeight: "lighter",
          }}
        >
          To get listed on PassionStocks Market, book a 15-minute meeting with
          our team for verification, VIP registration & guidance.
        </p>

        <button
          className="btn--yellow-black headline"
          style={{
            position: "relative",
            margin: "25px 0 90px",
            fontSize: "16px",
            lineHeight: "28px",
            fontWeight: "bolder",
            padding:'0 38px'
            ,borderRadius:'75px'
          }}
        >
          Book a meeting
        </button>
      </div>
    </AnimateKeyframes>
  );
}
