import "./style.css";

import store from "../../../store/store";

import StockCreativeCard from "../../../components/common/creative-cards/Influencer";

import CelebrateSmiley from "../../../assets/images/common/smiley-celebrates.png";
import AvatarImage from "../../../assets/images/common/avatar-default.png";

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Congratulations() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  useEffect(() => {
    loadPotenialShareData();
  }, []);

  let profile = store.getState().profile;

  const [cardData, setcardData] = useState(null);
  const [potentialData, setpotentialData] = useState(null);

  return (
    cardData &&
    potentialData ?
     (
      <div id="CongratulationsDiv"className="Page"
      style={{ height: windowInnerSize.height }}>

        <img src={CelebrateSmiley} alt="" id="CelebrateSmileyT" />
        <p id="CongratulationsTxt" className="unnamed-character-style-1">
          Congratulations!
        </p>
        <div id="SCPosC">
          <StockCreativeCard cardData={cardData} />
        </div>
        <div id="OfferingDetails" className="headline">
          <p>Your Inluencer Public Offering is:</p>
          <p>
            <span>
              {potentialData.currency.symbol}{potentialData.IPOprice * potentialData.authorizedStocks}
            </span>{" "}
            / {potentialData.authorizedStocks} STOCKS
          </p>
          <p>
            <span>{potentialData.currency.symbol}{potentialData.IPOprice}</span> / PER STOCK
          </p>
        </div>
        <div id="InitialRDiv" className="headline">
          <p>Your Initial Ranking is:</p>
          <div
            id="RBlue"
            className={potentialData.ringColour === "blue" ? "Surround" : null}
          />
          <div id="RGreen" />
          <div
            id="RPur"
            className={
              potentialData.ringColour === "purple" ? "Surround" : null
            }
          />
          <div
            id="RYellow"
            className={
              potentialData.ringColour === "yellow" ? "Surround" : null
            }
          />
        </div>
        <div id="OverallRDiv" className="headline">
          <p>Your overall ranking is:</p>
          <p>
            <span>{"1"}</span> / 5
          </p>
        </div>

        <Link to="/money-calculator/set-appoinment">
          <p id="NextMCBtnC" className="headline">
            Next
          </p>
        </Link>
      </div>
    ):null
  );

  function loadPotenialShareData() {
    var axios = require("axios");
    var data = JSON.stringify(store.getState().moneyCalcUserSMFollows);
    // console.log(data);
    var config = {
      method: "post",
      url: process.env.REACT_APP_BACKEND_URL + `/api/influencers/potential`,
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        let tempCardData = {
          profilePic: profile ? profile.profilePic : AvatarImage,
          name: profile ? profile.name : "Guest",
          username: profile ? profile.username : "",
          ranking: response.data.ranking,
          ringColour: response.data.ringColour,
          niches: ["SMI in PassionStocks"],
          city: profile ? profile.city : "City",
          currentPrice: response.data.IPOprice,
          currency:response.data.currency.symbol
        };
        setcardData(tempCardData);
        setpotentialData(response.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
}
