import React from "react";
import "./style.css";

import { useState } from "react";
import ChartStock from "../Chart";
import fixFractionDigits from "../../../../helpers/fixFractionDigits";

export default function GraphUserData(props) {
  const [dateRange, setDateRange] = useState("1D");

  const handleClick = (range) => {
    if (dateRange !== range) setDateRange(range);
  };

  const range = (range, classN) => {
    return (
      <div
        className={classN + (dateRange === range ? " SelectedGU" : "")}
        onClick={() => handleClick(range)}
      >
        {range}
      </div>
    );
  };

  return (
    <div
      id={!props.SMI3rdP ? "GraphUserDataDiv" : "GraphUserData3rdPDiv"}
      className="headline"
    >
      {!props.SMI3rdP && (
        <div style={{ height: "22vw" }}>
          <p id="UserGBalance" className="unnamed-character-style-1">
            {props.Currency ? props.Currency : "?"}{" "}
            {props.Balance ? fixFractionDigits(props.Balance) : 0}
          </p>
          <p id="UserGHour">
            {"00"}h{"00"}
          </p>
          <p id="UserGChangePrft">
            +-{0},{0}%
          </p>
        </div>
      )}
      <div id="GraphUserAxis">
        <div className="RangeBtns">
          {range("1D", "RangeBtn")}
          {range("1M", "RangeBtn")}
          {range("6M", "RangeBtn")}
          {range("Max", "RangeBtn")}
        </div>
        <div id="ChartDiv">
          <ChartStock
            dateRange={dateRange}
            influencerId={props.influencerId}
            stockId={props.stockId}
          />
        </div>
      </div>
    </div>
  );
}
