import store from '../store/store';
// import { persistor } from '../store/store';

// import exchange_data from "../../features_redux/exchange_data/exchange_data";

// import unauthorized from "../../../functions/unauthorized";
// import { param } from "jquery";

export default function sale(tokens, saleOfferId) {

    var axios = require('axios');

    var config = {
        method: 'get',
        url: process.env.REACT_APP_BACKEND_URL + '/api/sales/' + saleOfferId,
        headers: {
            Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
        },
    };

    axios(config)
        .then(function (response) {
            // console.log(JSON.stringify(response.data));
            store.dispatch({ type: 'domain/sale/add', payload: response.data })
            // await persistor.purge();
        })
        .catch(function (error) {
            // console.log(error);
            store.dispatch({ type: 'app/salesFeedWaitingForData/toggle', payload: false });
            // await persistor.purge();
        });


}