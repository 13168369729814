import "./NetWorth.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import fixFractionDigits from "../../../../helpers/fixFractionDigits";

const selectWallet = (state) => state.wallet;

export default function NetWorth(props) {
  const wallet = useSelector(selectWallet);

  return (
    <div className="NetWorth headline">
      <p>
        {wallet.currency.symbol + " "}
        {fixFractionDigits(wallet.totalEquity)}

        <span>
          Net Worth
        </span>
      </p>
      <p>
        {wallet.currency.symbol + " "}
        {wallet.withdrawalValue % 1 === 0
          ? wallet.withdrawalValue
          : wallet.withdrawalValue.toFixed(2)}

        <span>Withdraw Value </span>
      </p>

      <div className="NetWorth__BtnsContainer">
        <Link to="/wallet/deposit">
          <button className="NetWorth__DepositBtn">Deposit</button>
        </Link>

        <Link to="/wallet/withdrawal/beneficiary-selection">
          <button className="NetWorth__WithdrawBtn">Withdraw</button>
        </Link>
      </div>
    </div>
  );
}
