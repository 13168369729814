import "./style.css";

import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { datadogRum } from "@datadog/browser-rum";

// import {persistStore} from 'redux-persist';
import store from "../../../store/store";

// import { persistor } from "../../../store/store";

import AavatarImg from "../../../components/common/AvatarImg";

import BackPage from "../../../assets/images/common/back-page.png";
import QuestionCircle from "../../../assets/images/menu/question.png";
import LockCircle from "../../../assets/images/menu/lock.png";
import HelpCircle from "../../../assets/images/menu/float.png";
import LegalCircle from "../../../assets/images/menu/bag.png";
import clearStoreWithoutConst from "../../../utiles/clearStoreWithoutConst";
import loadUser from "../../../loaders/user";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Menu() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Menu",
  });
  const navigate = useNavigate();
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const [DisplayLogOutBtn, setDisplayLogOutBtn] = useState(false);

  if (store.getState().profile !== null && !DisplayLogOutBtn) {
    setDisplayLogOutBtn(true);
  } else {
    if (store.getState().profile === null && DisplayLogOutBtn) {
      setDisplayLogOutBtn(false);
    }
  }
  //find out if user logged in:
  // const selectProfile = state => state.profile;
  // // const selectPrefixURL = state => state.PreURL;
  // const profile = useSelector(selectProfile);
  // // const PreURL = useSelector(selectPrefixURL);
  // const [avatar_image_url, setavatar_image_url] = useState(null);
  // const [avatar_background, setavatar_background] = useState({backgroundColor: 'white'});
  // if(profile===)

  // const [UserLoggedIn, setUserLoggedIn] = useState(false);

  // let yyy={color:"white"};
  // if (profile !== null)
  // {
  //     setUserLoggedIn(true);
  //     let urlImg=profile.data.profile_photo_path;
  //     let preurl = PreURL;
  //     setavatar_image({backgroundImage: 'url(' + preurl + urlImg + ')'});}
  const handleLogOut = async () => {
    if (store.getState().tokensAgentViewMode.accessToken) {
      // when logged out from smi member in the agency
      let tempTokens = store.getState().tokensAgentViewMode;
      // console.log(tempTokens);
      clearStoreWithoutConst();
      datadogRum.clearUser();

      store.dispatch({ type: "domain/tokens/addTokens", payload: tempTokens });
      store.dispatch({
        type: "domain/tokensAgentViewMode/addTokens",
        payload: { accessToken: null, refreshToken: null },
      });
      loadUser(tempTokens);
      navigate("/profile");
    } else {
      clearStoreWithoutConst();
      navigate("/");
    }
  };
  return (
    windowInnerSize && (
      <div
        className="Page  headline"
        style={{
          height: windowInnerSize.height - (windowInnerSize.width / 100) * 1,
        }}
      >
        <div
          className="Page--flex Page--padding-12vw"
          style={{ minHeight: windowInnerSize.height }}
        >
          <img
            src={BackPage}
            alt=""
            // id="BackPageTrade"
            className="back-btn"
            onClick={() => {
              navigate("/");
            }}
          />

          <div className="Menu__Avatar-image ">
            <AavatarImg />
          </div>
          <div className="Menu__options">
            {/* <Link to="/money-calculator">
              <p className="Menu__JoinTExchangeBtn">Join The Exchange</p>
            </Link> */}
            {store.getState().profile && (
              <Link to="/become-an-agency">
                <p className="Menu__BecomeAnAgentBtn">Become an Agency</p>
              </Link>
            )}
            <div className="Menu__list">
              <Link to="https://passionstocks.helpsite.com">
                <div
                  style={{
                    position: "relative",
                    height: "9vw",
                    margin: "4vw 0",
                  }}
                >
                  <img src={QuestionCircle} alt="" id="QuestionCircle" />
                  <p id="FAQSTxt">FAQS</p>
                </div>
              </Link>

              {store.getState().profile && (
                <Link to="/security">
                  <div
                    style={{
                      position: "relative",
                      height: "9vw",
                      margin: "4vw 0",
                    }}
                  >
                    <img src={LockCircle} alt="" id="LockCircle" />
                    <p id="SecurityTxt">Security</p>
                  </div>
                </Link>
              )}
              <Link to="/help">
                <div
                  style={{
                    position: "relative",
                    height: "9vw",
                    margin: "4vw 0",
                  }}
                >
                  <img src={HelpCircle} alt="" id="HelpCircle" />
                  <p id="HelpTxt">Help</p>
                </div>
              </Link>
              <Link to="/legal">
                <div
                  style={{
                    position: "relative",
                    height: "9vw",
                    margin: "4vw 0",
                  }}
                >
                  <img src={LegalCircle} alt="" id="LegalCircle" />
                  <p id="LegalTxt">Legal</p>
                </div>
              </Link>
            </div>
          </div>
          <div className="Menu__log-out-container">
            {DisplayLogOutBtn && (
              <p className="Menu__LogOutBtn" onClick={handleLogOut}>
                Log Out
              </p>
            )}
          </div>
        </div>
      </div>
    )
  );
}
