import "./style.css";

import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";
import { useLocation, useNavigate } from "react-router-dom";
import SelectCurrencyDemo from "react-select-currency";

import store from "../../../../store/store";

import BackPage from "../../../../assets/images/common/back-page.png";

import HeaderWithLogo from "../../../../components/common/HeaderWithLogo";
import { useState } from "react";
import { useEffect } from "react";

const selectSignUpToken = (state) => state.signUpToken;
const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectProfile = (state) => state.profile;

export default function SelectACurrency(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  // prevent enter to the page by insert the url in undesirable cases
  const profile = useSelector(selectProfile);
  const location = useLocation();
  const [processController, setProcessController] = useState(null);
  useEffect(() => {
    if (profile) {
      navigate("/profile");
    }
    if (location.state?.process !== "SIGNUP") {
      navigate("/auth/sign-up");
    } else {
      setProcessController(true);
    }
  }, [location, profile]);

  const signUpToken = useSelector(selectSignUpToken);

  const [selectedCurrency, setselectedCurrency] = useState(null);
  const onSelectedCurrency = (currencyAbbrev) => {
    // console.log(currencyAbbrev);
    setselectedCurrency(currencyAbbrev);
  };
  const handleSubmitCurrency = () => {
    store.dispatch({
      type: "app/selectedCurrency/add",
      payload: selectedCurrency
        ? selectedCurrency
        : { target: { value: "USD" } },
    });
    navigate("/auth/sign-up/your-passions", { state: { process: "SIGNUP" } });
  };
  return (
    processController && (
      <AnimateKeyframes
        play={true}
        iterationCount={1}
        duration={0.42}
        direction="normal"
        keyframes={[
          "transform: translateX(-100vw)",
          "transform: translateX(-50vw)",
          "transform: translate(0, 0)",
        ]}
      >
        <div
          className="SelectCurrency Page"
          id="SelectCurrency"
          style={{
            height:"100vh",
            overflowX: "hidden",
            position: "absolute",
            top: "0",
            left: "0",
          }}
        >
          <HeaderWithLogo />
          <img
            src={BackPage}
            alt=""
            className="back-btn"
            onClick={() => {
              navigate(-1, { state: { process: "SIGNUP" } });
            }}
          />
          <div
            className="Page--flex"
            style={{
              minHeight: windowInnerSize.height,
              justifyContent: "space-between",
              overflowX: "hidden",
              position: "absolute",
              top: "0",
              left: "0",
            }}
          >
            <div style={{ paddingTop: "20vw" }}>
              <p className="SelectCurrency__WhatTxt unnamed-character-style-1">
                WHAT'S YOUR <br /> CURRENCY?
              </p>

              <p className="SelectCurrency__SelectTxt headline">
                Select a type of currency with which you wish to manage your
                balance
              </p>
              <SelectCurrencyDemo value={"USD"} onChange={onSelectedCurrency} />
            </div>
            <button
              className="SelectCurrency__DoneBtn btn btn--yellow-white headline"
              onClick={handleSubmitCurrency}
            >
              Done
            </button>
          </div>
        </div>
      </AnimateKeyframes>
    )
  );
}
