import axios from 'axios';

import store from '../store/store';

export default function loadUser(tokens) {
    axios({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}/api/users`,
        headers: {
            Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
        },

    }).then(function (response) {
        store.dispatch({ type: 'domain/user/addData', payload: response.data })
        // await persistor.purge();
        // console.log(JSON.stringify(response.data));

    }).catch(function (error) {

    });


}