import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

// import Avatar_image from '../../Menu/Avatar_image';
import StockCreativeCard from "../../../../components/common/creative-cards/Influencer";
import AgencyMemberTag from "../../../../components/common/AgentMemberTag";
import GraphUserData from "../../../../components/common/graphs/GraphUserData";
import StockResponses from "../../../../components/common/StockEngagement";
import UpdateDeliveredStoryMention from "../../../../components/common/UpdateDeliveredStoryMention";
import SocialMediaValues from "../../../../components/common/SocialMediaValues";
import SharePopUp from "../../../../components/common/SharePopUp";
import PassionPrdct from "../../../../components/common/PassionPrdct";

import "./style.css";

import store from "../../../../store/store";

import EditIcon from "../../../../assets/images/common/edit.png";
import FullStar from "../../../../assets/images/common/star-full.png";
import EmptyStar from "../../../../assets/images/common/star-empty.png";
import SMIProfileBckgImage from "../../../../assets/images/profile/cover-img-default.jpg";
import SharingBtn from "../../../../assets/images/common/share.png";
import Bull from "../../../../assets/images/common/going-up.png";
import loadProfile from "../../../../loaders/profile";
import loadWallet from "../../../../loaders/wallet";
import fixFractionDigits from "../../../../helpers/fixFractionDigits";

const selectProfile = (state) => state.profile;
const selectTokens = (state) => state.tokens;
const selectWallet = (state) => state.wallet;
const selectPPTPendingList = (state) => state.pptPendingList;
const selectPPTDeliveryList = (state) => state.pptDeliveryList;
const selectAgencyTokenStorage = (state) => state.token_storage;
const selectToRefreshed = (state) => state.toRefresh;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Profile(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: true },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Profile",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const tokens = useSelector(selectTokens);
  useEffect(() => {
    if (tokens) {
      loadProfile(tokens);
    }
  }, []);

  const profile = useSelector(selectProfile);
  const wallet = useSelector(selectWallet);
  const PPTPendingList = useSelector(selectPPTPendingList);
  const PPTDeliveryList = useSelector(selectPPTDeliveryList);
  const isRefreshed = useSelector(selectToRefreshed);

  useEffect(() => {
    if (profile) {
      loadWallet(tokens, profile.accountType);
    }
  }, [profile]);

  const [MyAgency, setMyAgency] = useState(null);

  // // console.log(store.getState().profile);
  // let profile = store.getState().profile;
  if (profile) {
    if (MyAgency !== profile.influencer.agent) {
      // setMyAgency(profile.influencer.agent);
    }
  }
  let cardData = profile
    ? {
        profilePic: profile.profilePic,
        name: profile.name,
        username: profile.username,
        ranking: profile.influencer.ranking,
        ringColour: profile.influencer.ringColour,
        socialMedia: {
          tiktok: null,
          instagram: { follows: 120 },
          youtube: null,
        } /* profile.influencer.socialMedia */,
        niches: profile.influencer.niches,
        city: profile.city,
        currentPrice: profile.issuer.stock.marketPrice,
        currency: profile.influencer.stock.currency.symbol,
        lastChange: profile.influencer.stock.lastChange,
      }
    : null;

  // let engagment =
  //     profile
  //         ?
  //         {
  //             bears: profile.influencer.engagement.bears,
  //             bulls: profile.influencer.engagement.bulls,
  //             comments: profile.influencer.engagement.comments,
  //             views: profile.influencer.engagement.views,
  //         }
  //         :
  //         null;

  const [StatsOrPrdct, setStatsOrPrdct] = useState("Stats");

  // let SMISoMe = store.getState().profile.influencer.social_media;

  // const [yuyu, setyuyu] = useState(null)
  // const [ProfilePhotoUpdate, setProfilePhotoUpdate] = useState(null)

  // useEffect(() => {
  //     // if (yuyu !== props.EditImage) {
  //     //     setyuyu(props.EditImage);
  //     //     setProfilePhotoUpdate(null);
  //     //     setTimeout(setProfilePhotoUpdate(store.getState().profile.profile_photo_path), 1500)
  //     //     // window.location.reload();
  //     // }

  // }, [props.EditImage])

  const [SharePopUpDisplay, setSharePopUpDisplay] = useState(false);
  // console.log(profile);
  // console.log(store.getState().tokens);

  const isAgencyStats = useSelector(selectAgencyTokenStorage);

  //Animation:
  const [animateSlide, setanimateSlide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setanimateSlide(true);
    }, 0);
    return () => {
      setanimateSlide(false);
    };
  }, []);

  return isRefreshed && profile ? (
    <div
      className={
        "InfluencerProfile Page headline " +
        (!animateSlide ? "animate-to-left" : null)
      }
      style={{
        //         height: (windowInnerSize.height-(95*windowInnerSize.width)),
        paddingBottom: "23vw",
        top: isAgencyStats ? "12vw" : "15vw",
        // marginBottom:windowInnerSize.width*0.3
      }}
    >
      <div
        className="InfluencerProfile__Backgorund"
        style={{
          backgroundImage: `url(${
            profile.coverPic ? profile.coverPic : SMIProfileBckgImage
          })`,
        }}
      />
      <Link to="/profile/edit">
        <img src={EditIcon} alt="" className="InfluencerProfile__EditIcon" />
      </Link>

      <div
        style={{
          position: "relative",
          margin: "-30vw 0 0",
          width: "100%",
          height: "55vw",
        }}
      >
        <p
          className={
            "InfluencerProfile__StockPriceChangesTxt " +
            (profile.influencer.stock.lastChange
              ? profile.influencer.stock.lastChange.amount >= 0
                ? "PositiveGreenNum "
                : "NegativeNum "
              : "0")
          }
        >
          {profile.influencer.stock.lastChange
            ? (profile.influencer.stock.lastChange.amount >= 0 ? "+" : "-") +
              profile.influencer.stock.currency.symbol +
              Math.abs(
                fixFractionDigits(profile.influencer.stock.lastChange.amount)
              )
            : "0"}
          <br />
          <span
            className={
              profile.influencer.stock.lastChange
                ? profile.influencer.stock.lastChange.percent >= 0
                  ? "PositiveGreenNum "
                  : "NegativeNum "
                : "0"
            }
          >
            {profile.influencer.stock.lastChange
              ? (profile.influencer.stock.lastChange.percent >= 0 ? "+" : "") +
                (profile.influencer.stock.lastChange.percent
                  ? fixFractionDigits(
                      profile.influencer.stock.lastChange.percent
                    )
                  : "0") +
                "%"
              : "0"}
          </span>
        </p>
        <div className="InfluencerProfile__CreativeCardPos">
          <StockCreativeCard cardData={cardData} />
          {/* ownUser={true} stock={CreativeStockCard} EditImage={props.EditImage} rootImage={ProfilePhotoUpdate} SocialMedia={profile.influencer.socialMedia}  */}
        </div>
        <div className="InfluencerProfile__ResponsesPos">
          <StockResponses engagement={profile.influencer.engagement} />
        </div>
      </div>

      <div className="InfluencerProfile__InteractBtnsContainer">
        <Link
          to={
            // profile.influencer.agent
            //   ? "/my-agency/details"
            //   :
            // "/find-your-manager" /*"/Profile/SMI/Find_Your_Agency"*/
            "/unavailable"
          }
        >
          <p className="InfluencerProfile__MyManagerBtn">My Manager</p>
        </Link>
        {/* <Link to="/my-passionstocks"> */}
        <Link to={"/my-brand"}>
          <p className="InfluencerProfile__MyBrandBtn">My Brand</p>
        </Link>

        {/* <img src={SharingBtn} alt="" id="SMISharingBtnSMIP" /> */}
        <img
          src={SharingBtn}
          alt=""
          className="InfluencerProfile__SharingBtn"
          onClick={() => {
            if (!SharePopUpDisplay) {
              setSharePopUpDisplay(true);
            }
          }}
        />
      </div>
      {SharePopUpDisplay && (
        <div
          className="InfluencerProfile__BackFromShare"
          onClick={() => {
            if (SharePopUpDisplay) {
              setSharePopUpDisplay(false);
            }
          }}
        />
      )}
      {SharePopUpDisplay && (
        <div>
          <SharePopUp
            SMIid={store.getState().profile.influencer.id}
            SMIname={store.getState().profile.influencer.name}
            brandUsername={store.getState().profile.username}
            sharingType={0x2}
          />
        </div>
      )}

      <p className="InfluencerProfile__Metrics headline">
        Growth{" "}
        <span>
          {profile.influencer.stock.growth
            ? fixFractionDigits(profile.influencer.stock.growth)
            : 0}
          %
        </span>
        ROI made for investors{" "}
        <span>{profile.influencer.ROI.toFixed(2) + "%"}</span>
        <img src={Bull} alt="" id="TradeVolumePic" /> <br />
        Trade Frequency <span>{profile.influencer.tradeFrequency}</span>
        Highest stock price{" "}
        <span>
          {profile.influencer.stock.currency.symbol}
          {fixFractionDigits(
            profile.influencer.stock.highestPrice
              ? profile.influencer.stock.highestPrice
              : 0
          )}
        </span>{" "}
        {/* <br /> */}
        Trade Volume <span>{profile.influencer.tradeVolume}</span>{" "}
        <img src={Bull} alt="" id="TradeVolumePic" /> {"  "}
        Stock views <span>{profile.influencer.engagement.stockViews}</span>
      </p>

      <p className="InfluencerProfile__RemainingStocks">
        Remaining Passionstocks
        <span>{" / " + profile.influencer.release.issued}</span>
      </p>

      {MyAgency !== null ? (
        <div className="InfluencerProfile__AgentTagSizePos">
          <AgencyMemberTag OwnProfile={true} />
        </div>
      ) : null}
      <p className="InfluencerProfile__Description">{profile.bio}</p>

      <div
        style={{
          position: "relative",
          margin: "0 auto",
          width: "84vw",
          height: "64vw",
        }}
      >
        <SocialMediaValues
          SocialMedia={profile.influencer.socialMedia}
          dominatingPlatform={profile.influencer.dominatingPlatform.platform}
        />
      </div>
      {/* <div className="InfluencerProfile__StatsPrdctContainer">
        <div className="InfluencerProfile__PrdctStatsBtns">
          <p className="InfluencerProfile__StatsBtn" onClick={setAsStats}>
            Stats
          </p>
          <p className="InfluencerProfile__ProductsBtn" onClick={setAsProducts}>
            Passion Products & Projects
          </p>
        </div>
        <svg
          height="0.25vw"
          width="84%"
          className="InfluencerProfile__BasicLine"
        >
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="#ffbf12"
            stroke-width="10%"
            stroke-linecap="butt"
          />
        </svg>
        <svg
          height="0.65vw"
          width={StatsOrPrdct === "Stats" ? "22vw" : "42vw"}
          className={
            StatsOrPrdct === "Stats"
              ? "InfluencerProfile__StatsSwitchLine"
              : "InfluencerProfile__PrdctSwitchLine"
          }
        >
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="#ffbf12"
            stroke-width="10%"
            stroke-linecap="butt"
          />
        </svg>
        {StatsOrPrdct === "Stats" && (
          <div className="InfluencerProfile__StockInformation">
            <div className="InfluencerProfile__GraphPos">
              <GraphUserData
                Currency={wallet ? wallet.currency.symbol : null}
                Balance={wallet ? wallet.totalEquity : null}
              />
            </div>
            <div className="InfluencerProfile__SocialMediaData">
              <SocialMediaValues
                SocialMedia={profile.influencer.socialMedia}
                dominatingPlatform={
                  profile.influencer.dominatingPlatform.platform
                }
              />
            </div>
          </div>
        )}
        {StatsOrPrdct !== "Stats" && (
          <div className="InfluencerProfile__ProductsInformation">
            <div className="InfluencerProfile__UpdateDeliveredStoryMentionPos">
              <UpdateDeliveredStoryMention deliveryList={PPTDeliveryList} />
            </div>
            {PPTPendingList && (
              <div className="InfluencerProfile__PrdctPendingList">
                <p id="PrdctsPendingTxt" className="headline">
                  Passionproducts pending:
                </p>

                {getPassionPrdctsList(profile)}
              </div>
            )}
          </div>
        )}
      </div> */}
    </div>
  ) : null;

  function setAsStats() {
    if (StatsOrPrdct !== "Stats") {
      setStatsOrPrdct("Stats");
    }
  }
  function setAsProducts() {
    if (StatsOrPrdct !== "Products") {
      setStatsOrPrdct("Products");
    }
  }

  function getPassionPrdctsList() {
    let PPTPLElements = [];
    for (let i = 0; i < PPTPendingList.length; i++) {
      PPTPLElements.push(<PassionPrdct PPTData={PPTPendingList[i]} />);
    }

    return PPTPLElements;
  }
}
function getRateStars() {
  let FullStars = 4;
  let EmptyStars = 1;
  let StarsCounter = 5;
  let StarsLine = [];

  for (let i = 0; i < FullStars; i++, StarsCounter--) {
    StarsLine.push(
      <img
        key={StarsCounter}
        className="RateStars"
        src={FullStar}
        alt=""
        id="FullStar"
      />
    );
  }
  for (let i = 0; i < EmptyStars; i++, StarsCounter--) {
    StarsLine.push(
      <img
        key={StarsCounter}
        className="RateStars"
        src={EmptyStar}
        alt=""
        id="FullStar"
      />
    );
  }

  return StarsLine;
}
function getCurrentPrice(SMIId, Token) {
  let stockdata = async () => {
    await axios({
      method: "GET",
      url:
        `https://web.passionstocks.com/api/influencers/` +
        SMIId +
        "/stocks?=api_token=" +
        Token,
    })
      .then(function (response) {
        // console.log(response);
        let StockData = response.data;
        return StockData[0].current_price;
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
}
