import "./style.css";

import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import store from "../../../store/store";
import BackPage from "../../../assets/images/common/back-page.png";
import emojyCop from "../../../assets/images/common/emojy-cop.png";
import partyPopper from "../../../assets/images/common/party-popper.png";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function IssuerRegWelcome() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="IssuerRegWelcome headline"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          padding: "40px 10vw",
          height: windowInnerSize.height,
        }}
      >
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          style={{ top: "10vw" }}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div>
          <img
            src={emojyCop}
            alt="Emojy Cop"
            style={{
              display: "block",
              margin: "10px auto 15px",
              width: "134px",
            }}
          />
          <p
            className="unnamed-character-style-1"
            style={{
              fontSize: "30px",
              textTransform: "none",
              fontWeight: "bolder",
              textAlign: "center",
              margin: "0 auto 20px",
            }}
          >
            You Are a Creator
          </p>
          <p
            style={{
              fontSize: "16px",
              lineHeight: "22px",
              fontWeight: "lighter",
              textAlign: "center",
              position: "relative",
              margin: "15px auto 5px",
            }}
          >
            <img
              src={partyPopper}
              alt="Party popper"
              style={{ width: "16px" }}
            />{" "}
            Welcome aboard!
            <br />
            <br />
            You now have access to the powerful Brand Calculator.
            <br />
            <br />
            Let’s begin this journey together, and we’ll evaluate your
            eligibility based on the results.
          </p>
        </div>
        <Link to="/creator-registration/form">
          <button
            className="action-btn--yellow-border--white headline"
            style={{
              lineHeight: "42px",
              fontSize: "18px",
              fontWeight: "normal",
              margin: "0 auto",
              display: "block",
            }}
          >
            Brand Calculator
          </button>
        </Link>
      </div>
    </AnimateKeyframes>
  );
}
