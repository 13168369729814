import "./style.css";

import store from "../../../../store/store";

import { useNavigate } from "react-router-dom";
// import axios from 'axios';
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import BackPage from "../../../../assets/images/common/back-page.png";
import Done from "../../../../assets/images/common/confirm.png";

// import loadStockView from '../../Exchange/loadFunctions/loadStockView';

// import SaleOfferCard from '../../Exchange/SaleOfferCard.js';

const selectTokens = (state) => state.tokens;
const selectStockId = (state) => state.pptConvertionToProductData;
const selectProfile = (state) => state.profile;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function CToPPTSuccessful(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();

  const tradeId = useSelector(selectStockId);
  const tokens = useSelector(selectTokens);
  const profile = useSelector(selectProfile);

  const [handleInputValue, sethandleInputValue] = useState(null);
  const [successMessage, setsuccessMessage] = useState(null);

  useEffect(() => {
    if (profile) {
      sethandleInputValue("@" + profile.username);
    }
  }, [profile]);

  const handleConfirmHandle = () => {
    // console.log(tradeId.tradeId + handleInputValue);
    var axios = require("axios");
    var data = {
      tradeId: tradeId.tradeId,
      handle: handleInputValue,
    };

    var config = {
      method: "post",
      url: process.env.REACT_APP_BACKEND_URL + "/api/ppt-trades/sm-handle",
      headers: {
        Authorization: tokens.accessToken,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        setsuccessMessage(true);
        setTimeout(() => {
          navigate("/profile", { replace: true });
        }, 3000);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  return (
    profile && (
      <div id="CToPPTSDiv" className="Page headline"
      style={{height: windowInnerSize.height}}>
        <img
          src={BackPage}
          alt=""
          id="BackPageCToPPTS"
          onClick={() => {
            navigate("/wallet");
          }}
        />

        <p id="PSTxtCToPPTS" className="unnamed-character-style-1">
          PASSIONSTOCK <br />
          SUCCESSFULLY <br />
          TRADED IN
        </p>

        <p id="ConfirmTxtCToPPTS">Confirm Your Instagram Handle</p>
        <p id="HandleSignPPTS">@</p>
        <input
          type="text"
          placeholder={profile.username}
          id="HandleInputCToPPTS"
          onChange={(e) => {
            if (e.target.value.length > 0) {
              sethandleInputValue(e.target.value);
            } else {
              sethandleInputValue(profile.username);
            }
          }}
        />

        <p id="TheProductTxtCToPPTS">
          *The product might take 6 months <br />
          to be delivered. <br />
          <br />
          <br />
          Before then you can trade it back in for <br />
          a Passionstock at anytime from your <br />
          profile and trade it on the exchange.
        </p>

        <svg height="1.8vw" width="41%" id="LineCToPPTS">
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="white"
            stroke-width="100%"
            stroke-linecap="butt"
          />
        </svg>

        <img src={Done} alt="" id="DoneCToPPTS" onClick={handleConfirmHandle} />
        {successMessage ? (
          <p id="successMessageCTPPTS">Handle saved successfully</p>
        ) : null}
      </div>
    )
  );
}
