import "./style.css";

import store from "../../../store/store";

import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";

import BackPage from "../../../assets/images/common/back-page.png";
import SmilyMoney from "../../../assets/images/common/smiley-money.png";
import InstagramBigIcon from "../../../assets/images/common/instagram-icon.png";
import TikTokCLogo from "../../../assets/images/common/tiktok-icon.png";
import YoutubeLink1 from "../../../assets/images/common/youtube-icon.png";

// import Slider, { Range } from 'rc-slider';
// import "rc-slider/assets/index.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

// import "rc-tooltip/assets/bootstrap.css";
import React from "react";
// import Slider
// //  { SliderTooltip }
//   from "rc-slider";
// import "./index.less";

// const { createSliderWithTooltip } = Slider;
// const Range = createSliderWithTooltip(Slider.Range);
// const { Handle } = Slider;

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Step1() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  //   const [SocialMediaFollows, setSocialMediaFollows] = useState({
  //     instagram: 0,
  //     tiktok: 0,
  //     youtube: 0,
  //   });

  const [TotalF, setTotalF] = useState(0);

  const navigate = useNavigate();

  const [InstaFollows, setInstaFollows] = useState(0);
  const [TiktokFollows, setTiktokFollows] = useState(0);
  const [YoutubeSubs, setYoutubeSubs] = useState(0);
  const [MoneyCanMake, setMoneyCanMake] = useState(0);
  const [isEmptyFields, setisEmptyFields] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    getTiers();
  }, []);
  const [tiers, settiers] = useState(null);
  function getTiers() {
    var axios = require("axios");
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_BACKEND_URL + "/api/influencers/money-calculator",
      headers: {},
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        settiers(response.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  // const InstaSliderHandle = props => {
  //     const { value, dragging, index, ...restProps } = props;
  //     setInstaSliderValue(value);
  //     calcMoneyCanMake();
  //     return (
  //         <SliderTooltip
  //             prefixCls="rc-slider-tooltip"
  //             overlay={`${value}`}
  //             visible={false}
  //             placement="top"
  //             key={index}
  //         >
  //             <Handle value={value} {...restProps} />
  //         </SliderTooltip>
  //     );
  // };
  // const TiktokSliderHandle = props => {
  //     const { value, dragging, index, ...restProps } = props;
  //     setTiktokSliderValue(value);
  //     return (
  //         <SliderTooltip
  //             prefixCls="rc-slider-tooltip"
  //             overlay={`${value}`}
  //             visible={false}
  //             placement="top"
  //             key={index}
  //         >
  //             <Handle value={value} {...restProps} />
  //         </SliderTooltip>
  //     );
  // };
  // const YoutubeSliderHandle = props => {
  //     const { value, dragging, index, ...restProps } = props;
  //     setYoutubeSliderValue(value);
  //     return (
  //         <SliderTooltip
  //             prefixCls="rc-slider-tooltip"
  //             overlay={`${value}`}
  //             visible={false}
  //             placement="top"
  //             key={index}
  //         >
  //             <Handle value={value} {...restProps} />
  //         </SliderTooltip>
  //     );
  // };

  // const wrapperStyle = { width: 400, margin: 50 };

  const handleNumberOfFollows = () => {
    if (MoneyCanMake > 0) {
      let temp = {
        Instagram: InstaFollows,
        TikTok: TiktokFollows,
        YouTube: YoutubeSubs,
      };
      store.dispatch({ type: "app/moneyCalcUserSMFollows/add", payload: temp });
      if (TotalF >= 40000) {
        navigate("/money-calculator/step-2");
      } else {
        navigate("/money-calculator/sorry");
      }
    } else {
      setisEmptyFields(true);
      setErrorMessage("Please add the number of followers.");
    }
  };

  return (
    tiers && (
      <AnimateKeyframes
        play={true}
        iterationCount={1}
        duration={0.42}
        direction="normal"
        keyframes={[
          "transform: translateX(-100vw)",
          "transform: translateX(-50vw)",
          "transform: translate(0, 0)",
        ]}
      >
        <div
          className="Step1MC Page headline"
          style={{ height: windowInnerSize.height }}
        >
          <img
            src={BackPage}
            alt=""
            className="back-btn"
            style={{ top: "12vw" }}
            onClick={() => {
              navigate(-1);
            }}
          />
          <div
            className="Page--flex Page--padding-5vw"
            style={{ minHeight: windowInnerSize.height }}
          >
            <div style={{ marginBottom: "8vw" }}>
              <img src={SmilyMoney} alt="" className="Step1MC__Emoji" />
              <p className="Step1MC__MoneyCHeadline unnamed-character-style-1">
                Money Calculator
              </p>
              <p className="Step1MC__MoneyCHeadline Step1MC__HeadlineS1 unnamed-character-style-1">
                Money Calculator
              </p>
              <p className="Step1MC__MoneyCHeadline Step1MC__HeadlineS2 unnamed-character-style-1">
                Money Calculator
              </p>
            </div>

            <div>
              <p className="Step1MC__Step1Txt unnamed-character-style-1">
                STEP 1
              </p>
              <p className="Step1MC__DiscoverTxt">Discover your potential!</p>
              <p className="Step1MC__Step1ExpTxt">
                Insert the numbers of followers/subscribers you have in each
                social media platform accordingly.
                <br />
                Let’s see if you have what it takes to join our exchange.
              </p>
            </div>

            {/* <div style={{ margin: "9vw 0" }}>
              <p className="Step1MC__YouCanMakeTxt">You Can Make Up To:</p>
              <p className="Step1MC__RevenueTxt unnamed-character-style-1">
                ± ${TotalF === 0 ? 0 : MoneyCanMake}
              </p>
            </div> */}

            <div style={{ margin: "0 0 6vw" }}>
              {/* <p id="InstaSFollows">{convertToNumLet(InstaSliderValue)} Followers</p> */}
              <div style={{ position: "relative", marginBottom: "2vw" }}>
                <img
                  src={InstagramBigIcon}
                  alt=""
                  className="Step1MC__InstagramIcon"
                />
                {/* <div id="InstaSliderPos"><Slider min={0} max={4800} defaultValue={InstaSliderValue} handle={InstaSliderHandle} /></div> */}
                <input
                  className={
                    "Step1MC__InputInstaFollows headline " +
                    (isEmptyFields ? "input-wrong" : "")
                  }
                  min={0}
                  step={1}
                  type="number"
                  onKeyDown={(event)=>{if (event.key==="."){event.preventDefault()}}}
                  placeholder="e.g. 12400"
                  onChange={(e) => {
                    ErrorMessage !== "" && setErrorMessage("");
                    isEmptyFields && setisEmptyFields(false);
                    setInstaFollows(
                      e.target.value === "" ? 0 : +e.target.value
                    );
                    calcMoneyCanMake(
                      0,
                      e.target.value === "" ? 0 : +e.target.value
                    );
                  }}
                />
                <p className="Step1MC__InstaFollowsTxt">Followers</p>
              </div>

              <div style={{ position: "relative", marginBottom: "2vw" }}>
                {/* <p id="TiktokSFollows">{convertToNumLet(TiktokSliderValue)} Followers</p> */}
                <img src={TikTokCLogo} alt="" className="Step1MC__TikTokIcon" />
                {/* <div id="TiktokSliderPos"><Slider min={0} max={4800} defaultValue={TiktokSliderValue} handle={TiktokSliderHandle} /></div> */}
                <input
                  className={
                    "Step1MC__InputTikTokFollows headline " +
                    (isEmptyFields ? "input-wrong" : "")
                  }
                  min={0}
                  step={1}
                  onKeyDown={(event)=>{if (event.key==="."){event.preventDefault()}}}
                  type="number"
                  placeholder="e.g. 12400"
                  onChange={(e) => {
                    ErrorMessage !== "" && setErrorMessage("");
                    isEmptyFields && setisEmptyFields(false);
                    setTiktokFollows(
                      e.target.value === "" ? 0 : +e.target.value
                    );
                    calcMoneyCanMake(
                      1,
                      e.target.value === "" ? 0 : +e.target.value
                    );
                  }}
                />
                <p className="Step1MC__TiktokFollowsTxt">Followers</p>
              </div>
              <div style={{ position: "relative" }}>
                {/* <p id="YoutubeSSubs">{convertToNumLet(YoutubeSliderValue)} Subscribers</p> */}
                <img
                  src={YoutubeLink1}
                  alt=""
                  className="Step1MC__YoutubeIcon"
                />
                {/* <div id="YoutubeSliderPos"><Slider min={0} max={4800} defaultValue={YoutubeSliderValue} handle={YoutubeSliderHandle} /></div> */}
                <input
                  className={
                    "Step1MC__InputYoutubeSubs headline " +
                    (isEmptyFields ? "input-wrong" : "")
                  }
                  type="number"
                  min={0}
                  step={"1"}
                  onKeyDown={(event)=>{if (event.key==="."){event.preventDefault()}}}
                  placeholder="e.g. 12400"
                  onChange={(e) => {
                    ErrorMessage !== "" && setErrorMessage("");
                    isEmptyFields && setisEmptyFields(false);
                    setYoutubeSubs(e.target.value === "" ? 0 : +e.target.value);
                    calcMoneyCanMake(
                      2,
                      e.target.value === "" ? 0 : +e.target.value
                    );
                  }}
                />
                <p className="Step1MC__YoutubeSubsTxt">Subscribers</p>
              </div>
            </div>
          </div>
          <div className="SorryMC__indicate-container">
            <p className="error-message">{ErrorMessage}</p>
          </div>

          <p className="Step1MC__NextBtn" onClick={handleNumberOfFollows}>
            Next
          </p>
        </div>
      </AnimateKeyframes>
    )
  );
  function calcMoneyCanMake(Index, FollowsNum) {
    let rendered = false;
    if (!rendered) {
      let ArrayFollows = [InstaFollows, TiktokFollows, YoutubeSubs];
      ArrayFollows[Index] = FollowsNum;
      let totalFollows = ArrayFollows[0] + ArrayFollows[1] + ArrayFollows[2];
      setTotalF(totalFollows);

      let moneyCanMake;
      // let i=0;
      // do {
      //   if(totalFollows<tiers[i].followers.max){
      //     moneyCanMake=
      //   }
      // } while (!moneyCanMake);

      if (totalFollows <= 40000) {
        // moneyCanMake = 8000;
        moneyCanMake = (totalFollows * 0.2).toFixed(2);
      }
      if (totalFollows > 40000 && totalFollows <= 100000) {
        // moneyCanMake = 19500;
        moneyCanMake = (totalFollows * 0.195).toFixed(2);
      }
      if (totalFollows > 100000 && totalFollows <= 250000) {
        // moneyCanMake = 65000;
        moneyCanMake = (totalFollows * 0.26).toFixed(2);
      }
      if (totalFollows > 250000 && totalFollows <= 500000) {
        // moneyCanMake = 146250;
        moneyCanMake = (totalFollows * 0.2925).toFixed(2);
      }
      if (totalFollows > 500000 && totalFollows <= 1000000) {
        // moneyCanMake = 260000;
        moneyCanMake = (totalFollows * 0.26).toFixed(2);
      }
      if (totalFollows > 1000000 && totalFollows <= 2500000) {
        // moneyCanMake = 390000;
        moneyCanMake = (totalFollows * 0.156).toFixed(2);
      }
      if (totalFollows > 2500000 && totalFollows <= 5000000) {
        // moneyCanMake = 585000;
        moneyCanMake = (totalFollows * 0.117).toFixed(2);
      }
      if (totalFollows > 5000000 && totalFollows <= 7500000) {
        // moneyCanMake = 910000;
        moneyCanMake = (totalFollows * 0.12133).toFixed(2);
      }
      if (totalFollows > 7500000 && totalFollows < 10000000) {
        // moneyCanMake = 2600000;
        moneyCanMake = (totalFollows * 2.6).toFixed(2);
      }
      if (totalFollows >= 10000000) {
        moneyCanMake = 2600000;
      }

      if (moneyCanMake % 1 === 0) {
        moneyCanMake = (+moneyCanMake).toFixed(0);
      }
      setMoneyCanMake(moneyCanMake);
      if (moneyCanMake === 0) {
        setisEmptyFields(true);
      }
    }
  }
}
// function convertToNumLet(num) {
//     let temp = + num;
//     // console.log(temp);
//     let toNumLet;

//     if (temp > 4700) {
//         toNumLet = '10M+';
//         // console.log(1);
//     }
//     if (temp === 4700) {
//         toNumLet = '10M'
//     }
//     if ((temp >= 2000) && (temp < 4700)) {
//         toNumLet = (Math.floor((temp - 2000) / '300') + 1) + "." + (Math.floor(((temp - 2000) % 300) / 3) !== 0 ? Math.floor(((temp - 2000) % 300) / 3) : '') + 'M';
//     }
//     if ((temp > 1000) && (temp < 2000)) {
//         toNumLet = (temp % 1000) + 'K';
//     }
//     if (temp < 1000) {
//         toNumLet = temp;
//     }

//     return toNumLet;
// }
// function toNum(temp) {
//     let toExplicitNum;
//     if (temp >= 4700) {
//         toExplicitNum = 10000000;
//     }
//     if ((temp >= 2000) && (temp < 4700)) {
//         toExplicitNum = (Math.floor((temp - 2000) / '300') + 1) * 1000000 + ((Math.floor((temp - 2000) % 300) / 3) !== 0 ? (Math.floor(((temp - 2000) % 300) / 3) * 10000) : 0);
//         // console.log(toExplicitNum);
//     }
//     if ((temp > 1000) && (temp < 2000)) {
//         toExplicitNum = (temp % 1000) * 1000;
//     }
//     if (temp < 1000) {
//         toExplicitNum = temp;
//     }

//     return toExplicitNum;
// }
