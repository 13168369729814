import './style.css';

import LongLogo from "../../../assets/images/common/ps-logo-one-row.svg";

export default function HeaderWithLogo() {
  return (
    <div className="HeaderWithLogo">
      <img src={LongLogo} alt="" className="HeaderWithLogo__Logo" />
    </div>
  );
}
