import "./style.css";

import { useState } from "react";
import axios from "axios";

import store from "../../store/store";

import isValidateEmail from "../../helpers/isValidateEmail.js";
import BackPage from "../../assets/images/common/back-page.png";
import BAABckgImg from "../../assets/images/become-an-agent/background.png";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import Loader from "../../components/common/Loader";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function BecomeAnAgent(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Menu",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  const [FullNameInput, setFullNameInput] = useState(null);
  const [EmailInput, setEmailInput] = useState(null);
  const [PhoneNumberInput, setPhoneNumberInput] = useState(null);

  const [ShowSuccesedMessage, setShowSuccesedMessage] = useState(false);

  const [ShowLoader, setShowLoader] = useState(false);

  return (
    <div
      id="BAADiv"
      className="Page headline"
      style={{ height: windowInnerSize.height }}
    >
      <img
        src={BackPage}
        alt=""
        id="BackPageBAA"
        onClick={() => {
          navigate(-1);
        }}
      />
      <div style={{ height: "300vw" }}>
        <img src={BAABckgImg} alt="" id="BAABckgImg" />
        <p id="BAATxt" className="unnamed-character-style-1">
          Become An Agency
        </p>

        <div id="ShadowDivBAA" />

        <p id="AnAgencyTxtBAA">
          An agency on PassionStocks can:
          <br />
          <br />
          Manage social media influencers’ PS accounts
          <br />
          {"&"} earn commission from their passionstocks
          <br />
          trades.
          <br />
          <br />
          <br />
          <span className="unnamed-character-style-1">20%</span> All IPO
          passionstock sold <br />
          <span className="unnamed-character-style-1">0.5%</span> Everytime
          their passionstock <br />
          <i>is traded </i>
          <br />
          <br />
          <br />
          Leave your details below and one of our
          <br />
          agents will take you through the next steps
        </p>

        <input
          type="text"
          id="FullnameInputBAA"
          placeholder="Full name"
          value={FullNameInput}
          className={"headline " + (FullNameInput === "" ? "input-wrong" : "")}
          onChange={(e) => {
            setFullNameInput(e.target.value);
          }}
        />
        <input
          type="email"
          id="EmailInputBAA"
          placeholder="Business Email"
          value={EmailInput}
          className={
            "headline " +
            (EmailInput === "" ||
            (EmailInput !== null && !isValidateEmail(EmailInput))
              ? "input-wrong"
              : "")
          }
          onChange={(e) => {
            setEmailInput(e.target.value);
          }}
        />
        <input
          type="number"
          id="PhoneNumberInputBAA"
          placeholder="Phone Number"
          value={PhoneNumberInput}
          className={
            "headline " + (PhoneNumberInput === "" ? "input-wrong" : "")
          }
          onChange={(e) => {
            setPhoneNumberInput(e.target.value);
          }}
        />

        <p id="SpeakSoonTxtBAA" className="unnamed-character-style-1">
          SPEAK SOON
        </p>

        <button
          id="RegisterBtnBAA"
          className="headline"
          onClick={handleBAARegisterBtn}
        >
          Register
        </button>

        {ShowLoader && (
          <div id="LoaderPosSizeBAA">
            <Loader />
          </div>
        )}
        {ShowSuccesedMessage && (
          <div id="SuccesedMessageBAA">
            {ShowSuccesedMessage}
            <button
              className="headline"
              onClick={() => {
                navigate(store.getState().profile ? "/exchange" : "/");
              }}
            >
              Done
            </button>
          </div>
        )}
      </div>
    </div>
  );

  async function handleBAARegisterBtn() {
    if (
      EmailInput &&
      EmailInput !== "" &&
      FullNameInput &&
      FullNameInput !== ""
    ) {
      setShowLoader(true);

      var axios = require("axios");
      var data = JSON.stringify({
        name: FullNameInput,
        email: EmailInput,
        phoneNumber: PhoneNumberInput,
      });

      var config = {
        method: "post",
        url: process.env.REACT_APP_BACKEND_URL + "/api/agents/join-as-agent",
        headers: {
          "Content-Type": "application/json",
          Authorization: store.getState().tokens.accessToken,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          setShowSuccesedMessage(
            <p>
              <span>Your request has been received!</span>
              <br />
              <br />
              Our agents will be in touch
              <br /> with you shortly
            </p>
          );
        })
        .catch(function (error) {
          // console.log(error);
          setShowSuccesedMessage(
            <p>
              <span>Sorry!</span>
              <br />
              
              There is a problem
              <br /> sending the request
              <br />
              Please try later
            </p>
          );
        });

      setShowLoader(false);
    } else {
      !FullNameInput && setFullNameInput("");
      !EmailInput && setEmailInput("");
      !PhoneNumberInput && setPhoneNumberInput("");
    }
  }
}
