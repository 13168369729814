import "./ShowMeTheMoney.css";
export default function ShowMeTheMoney(props) {
  return (
    <div className="ShowMeTheMoney">
      <p className="ShowMeTheMoney__StatsShowMoney unnamed-character-style-1">
        {80} / <span>{20}</span>
      </p>
      <p className="ShowMeTheMoney__YouTxt">
        You will receive 80% of the revenue generated on the passionstocks
        market.
        <br />
        as your revenue grows, you can receive up to 95%.
      </p>
      <p className="ShowMeTheMoney__ToTxt"> To learn more, visit our FAQs for Creators</p>
    </div>
  );
}
