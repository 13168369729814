import { Outlet } from "react-router-dom";
import { useState } from "react";
import CookiesMessage from "../../components/CookiesMessage";
import SafariView from "../../components/SafariView";
import Monitor from "../../monitor";
import UpdateVersion from "../../utiles/updateVersion";
import OpeningView from "../opening-view";
import AgentViewStats from "../profile/profile/agentIPOed/components/AgentViewStats";
import NavBarTop from "../../components/NavBars/Top";
import NavBarBottom from "../../components/NavBars/Bottom";
import { useLocation } from "react-router";
import { useEffect } from "react";
import store from "../../store/store";

import { useSelector } from "react-redux";

const selectWindowInnerSize = (state) => state.windowInnerSize;
export default function Root(props) {
  const { pathname } = useLocation();
  const windowInnerSize = useSelector(selectWindowInnerSize);

  useEffect(() => {
    store.dispatch({
      type: "ui/windowInnerSize/add",
      payload: { height: window.innerHeight, width: window.innerWidth },
    });
  }, []);

  return (
    <div className="app-container" style={{ width: "100vw", height: windowInnerSize.height,overflow: "scroll" }}>
      {props.isPhone && <SafariView />}
      <UpdateVersion />
      {/* <Monitor /> */}

      <OpeningView />
      {/* {props.isPhone && <CookiesMessage />} */}
      {/* {props.isPhone && <AgentViewStats />} */}

      {/* <div id="app-frame" /*style={{ height: "100vh" }}> */}
      {pathname !== "/" && props.isPhone && <NavBarTop />}
      <Outlet />
      {pathname !== "/" && props.isPhone && <NavBarBottom />}
      {/* </div> */}
    </div>
  );
}
