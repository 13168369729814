import "./style.css";

import ArrowUp from "../../../../assets/images/common/arrow-up.png";
import ArrowDown from "../../../../assets/images/common/arrow-down.png";

import { useState, useEffect } from "react";

import getRatingColour from "./ringColour.js";

export default function StockCreativeCard(props) {
  const [SMIColour, setSMIColour] = useState("blue");

  let ratingColourValues, ringColour, ratingNumColour, ratingNum;
  if (props.SocialMedia) {
    ratingColourValues = getRatingColour(props.SocialMedia);
    ringColour = ratingColourValues.ringColour;
    ratingNumColour = ratingColourValues.ratingNumColour;
    ratingNum = ratingColourValues.ratingNumber;
  }

  let Stock = props.stock;
  const [cardData, setcardData] = useState(null);
  useEffect(() => {
    setcardData(props.cardData);
  }, [props.cardData]);

  let isGrowth = null;
  if (props.cardData) {
    if (props.cardData?.lastChange?.amount) {
      if (props.cardData?.lastChange?.amount > 0) {
        isGrowth = true;
      } else {
        if (props.cardData?.lastChange.amount < 0) {
          isGrowth = false;
        }
      }
    }
  }
  return cardData ? (
    <div className="StockCreativeCard headline">
      <div
        id="SMIPicCreative"
        className={
          cardData
            ? cardData.ringColour + "RC"
            : props.SocialMedia
            ? ringColour
            : SMIColour
        }
        style={{
          backgroundImage: cardData
            ? `url(${cardData.profilePic})`
            : props.EditImage
            ? `url(${props.EditImage})`
            : `url(${
                Stock
                  ? Stock.SMIPicURL
                  : "https://web.passionstocks.com/" +
                    (props.BuyStock
                      ? props.BuyStock.influencer.profile_photo_path
                      : props.StockHolding
                      ? props.StockHolding.stock.influencer.profile_photo_path
                      : props.AgencyMembers
                      ? props.SMI3rdP
                        ? props.AgencyMembers.profile_photo_path
                        : props.AgencyMembers.influencer.profile_photo_path
                      : props.InfluencersJoinOffer
                      ? props.InfluencersJoinOffer.profile_photo_path
                      : props.Influencers3rdProfile.profile_photo_path)
              })`,
        }}
      />

      <p
        className={
          "SMIRatingSCC " +
          (cardData
            ? cardData.ringColour + "RN"
            : props.SocialMedia
            ? ratingNumColour
            : "")
        }
      >
        {cardData ? cardData.ranking : props.SocialMedia ? ratingNum : 1}
      </p>
      {/* <p id="SMIOverallRating">1</p> */}
      <p className="SMINameCreative">
        {cardData
          ? cardData.name
          : Stock
          ? Stock.SMIName
          : props.BuyStock
          ? props.BuyStock.influencer.name
          : props.StockHolding
          ? props.StockHolding.stock.influencer.name
          : props.AgencyMembers
          ? props.SMI3rdP
            ? props.AgencyMembers.name
            : props.AgencyMembers.influencer.name
          : props.InfluencersJoinOffer
          ? props.InfluencersJoinOffer.name
          : props.Influencers3rdProfile.name}
      </p>
      <p className="StockPriceCreative">
      {isGrowth !== null ? (
          isGrowth ? (
            <img src={ArrowUp} alt="" className="AgentCreativeCard__Arrow" />
          ) : (
            <img src={ArrowDown} alt="" className="AgentCreativeCard__Arrow" />
          )
        ) : null}
        {cardData.currency ? cardData.currency : "?"}
        {cardData
          ? cardData.currentPrice % 1 === 0
            ? cardData.currentPrice
            : cardData.currentPrice.toFixed(2)
          : Stock
          ? Stock.CurrentPrice
          : props.BuyStock
          ? props.BuyStock.price
          : props.StockHolding
          ? props.StockHolding.stock.price
          : props.AgencyMembers
          ? props.SMI3rdP
            ? props.AgencyMembers.current_stock_price
            : props.AgencyMembers.influencer.current_stock_price
          : props.InfluencersJoinOffer
          ? props.InfluencersJoinOffer.current_stock_price
          : props.Influencers3rdProfile.current_stock_price}
        <span style={{ color: "rgb(112, 112, 112)" ,fontSize:'3vw',fontWeight:'lighter'}}> /Unit</span>
       
      </p>

      <p className="SMISNicheCreative">
        {cardData
          ? getNiches(cardData.niches)
          : getNiche(
              Stock
                ? Stock.Niches
                : props.stock
                ? props.stock.Niches
                : props.BuyStock
                ? props.BuyStock.influencer.niches
                : props.StockHolding
                ? props.StockHolding.stock.influencer.niches
                : props.AgencyMembers
                ? props.SMI3rdP
                  ? props.AgencyMembers.niches
                  : props.AgencyMembers.influencer.niches
                : props.InfluencersJoinOffer
                ? props.InfluencersJoinOffer.niches
                : props.Influencers3rdProfile.niches
            )}
        {/*'|' + Stock.SMIPassion*/}
      </p>
      <p className="SMILocationCreative">
        {cardData
          ? cardData.city
          : Stock
          ? Stock.SMILocation
          : props.stock
          ? props.stock.SMILocation
          : props.BuyStock
          ? props.BuyStock.influencer.city
          : props.StockHolding
          ? props.StockHolding.stock.influencer.city
          : props.AgencyMembers
          ? props.SMI3rdP
            ? props.AgencyMembers.city
            : props.AgencyMembers.influencer.city
          : props.InfluencersJoinOffer
          ? props.InfluencersJoinOffer.city
          : props.Influencers3rdProfile.city}
      </p>
    </div>
  ) : null;
}

function getNiches(SMINiches) {
  let niches = "SMI";
  for (let i = 0; i < SMINiches.length; i++) {
    if (i === 0) {
      niches = SMINiches[i];
    } else {
      niches += "|" + SMINiches[i];
    }
  }
  return niches;
}

//edit code and delete this function
function getNiche(SMINiches) {
  let SMString = "";
  if (SMINiches) {
    for (let i = 1; i <= SMINiches.length; i++) {
      SMString += SMINiches[i - 1];
      if (SMINiches.length > i) {
        SMString += " | ";
      }
    }
    SMString = SMINiches.length === 0 ? "PassionStocks SMI |" : SMString;
  } else {
    SMString = "PassionStocks SMI |";
  }
  // if (SMISocialMedia.tiktok !== null)
  //     SMString += ' TikTok |';
  // if (SMISocialMedia.youtube !== null)
  //     SMString += ' Youtube'
  return SMString;
}
