import React from 'react';
import './style.css';

import CheckBtn from './components/CheckBtn';

import InstagramBigIcon from '../../../assets/images/common/instagram-icon.png';

export default function UpdateDeliveredStoryMention(props) {

    return (
        props.deliveryList
            ?
            <div id="UpdateInstaSMDiv" className="headline">
                <div id="DefaultDiv">
                    <p id="DefaultTxt">Default</p>
                    <img src={InstagramBigIcon} alt="" id="InstagramBigIcon" />
                    <p id="ISMTxt">Instagram <br /> Story Mention</p>
                    <p id="OneTxt">1</p>
                    <p id="PSTxt">Passion <br />Stock</p>
                </div>
                <div id="IndicateListDiv">
                    {
                        props.deliveryList.length > 0
                            ?
                            <p id="IndicateExpTxt">Keep your followers happy by delivering their <br /> products. Click on the button to indicate you <br /> have delivered the item.</p>
                            :
                            <p id="IndicateExpTxt">There are no product conversions</p>
                    }
                    {
                        props.deliveryList.length > 0
                        &&
                        <svg id="IndicateListLine" height="1.2vw" width="29%" >
                            <line x1="0" x2="100%" y1="0" y2="0" stroke="#FFFFFF" stroke-width="5vw" stroke-linecap="butt" />
                        </svg>}

                    {GetFollowersList()}
                    {/* <div id="BlurComingSoonSMIP" />
                <p id="CSUDSM">Coming Soon!</p>
                <img src={WinkingSmiley} alt="" id="WinkingSmileyUDSM" />
                <div id="ExstraHeight" /> */}
                </div>
            </div>
            :
            null
    );

    function GetFollowersList() {
        let FollowersListElements = [];
        for (let i = 0; i < props.deliveryList.length; i++) {
            FollowersListElements.push(
                <li key={i}><p>{props.deliveryList[i].handle}</p><spam id="CheckBtnPos" ><CheckBtn deliveryItem={props.deliveryList[i]} /></spam></li>
            )
        }

        return (
            <ul>
                {FollowersListElements}
            </ul>
        )
    }
}