import "./style.css";

import { useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import axios from "axios";

// import LongLogo from '../../../images/icons/LongLogo.svg';
import ForgotPasswordPic from "../../../../assets/images/common/emoji-silence.png";
import eye from "../../../../assets/images/common/eye.png";
import eyeRed from "../../../../assets/images/common/eye-red.png";

import Loader from "../../../../components/common/Loader";

import store from "../../../../store/store";
// import { persistor } from "../../../../store/store";
// import { Link } from "react-router-dom";
import HeaderWithLogo from "../../../../components/common/HeaderWithLogo";

import validatePasswordRules from "../../../../helpers/validatePasswordRules";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function ResetPassword(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  const { resetData } = useParams();
  // console.log(document.URL);
  let token = resetData.split("&")[0].slice(6);
  let userId = document.URL.split("&")[1].slice(7);
  let email = document.URL.split("&")[2].slice(6).replace("%40", "@");

  const [Password, setPassword] = useState(null);
  const [ConfirmPassword, setConfirmPassword] = useState(null);

  const [revelPasswordStats, setrevelPasswordStats] = useState(null);
  const [revelConfirmPasswordStats, setrevelConfirmPasswordStats] =
    useState(null);

  const [displayLoader, setdisplayLoader] = useState(false);
  const [displaySuccessMessage, setdisplaySuccessMessage] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState(null);

  const handleInputPassword = (e) => {
    ErrorMessage !== "" && setErrorMessage("");
    if (validatePasswordRules(e.target.value)) {
      setPassword(e.target.value);
    } else {
      setPassword("");
    }
  };
  const handleInputConfirmPassword = (e) => {
    ErrorMessage !== "" && setErrorMessage("");
    if (e.target.value === Password) {
      setConfirmPassword(e.target.value);
    } else {
      setConfirmPassword("");
    }
  };
  const handleUpdate = () => {
    if (
      Password &&
      Password !== "" &&
      ConfirmPassword &&
      ConfirmPassword !== ""
    ) {
      var axios = require("axios");
      var data = {
        password: Password,
        token: token,

        userId: userId,

      };

      var config = {
        method: "post",
        url: process.env.REACT_APP_BACKEND_URL + "/auth/reset-password",
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          store.dispatch({
            type: "domain/tokens/addTokens",
            payload: response.data,
          });
          // await persistor.purge();

          setdisplaySuccessMessage(true);
        })
        .catch(function (error) {
          // console.log(error);

          setErrorMessage(
            "Error updating password," + "\n" + "Please try again later."
          );
        });
    } else {
      !Password && setPassword("");
      !ConfirmPassword && setConfirmPassword("");
      if (!Password || Password === "") {
        setErrorMessage(
          "Please choose a new password: 8-15 characters, at least one uppercase letter, lowercase letter, number, special character."
        );
      } else {
        setErrorMessage("Please confirm password.");
      }
    }
  };

  async function goToProfile() {
    // console.log(store.getState().tokens);
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/users`,
      headers: {
        Authorization: store.getState().tokens.accessToken,
      },
    })
      .then(function (res) {
        // console.log(res);
        store.dispatch({ type: "domain/user/addData", payload: res.data });
        navigate("/profile", { replace: true });
      })
      .catch(function (error) {
        // console.log(error);
        setdisplaySuccessMessage(false);
        setErrorMessage("Error login to your account, please try again later.");
      });
  }
  function revelPassword(inputId) {
    var x = document.getElementById(inputId);
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  if (!store.getState().windowInnerSize) {
    store.dispatch({
      type: "ui/windowInnerSize/add",
      payload: { height: window.innerHeight, width: window.innerWidth },
    });
  }
  return (
    <div
      className="ResetPassword Page Page--padding-8vw headline"
      style={{ height: store.getState().windowInnerSize.height }}
    >
      <div className="Page--flex" style={{ minHeight: windowInnerSize.height }}>
        <HeaderWithLogo />
        <div>
          <img
            src={ForgotPasswordPic}
            alt=""
            className="ResetPassword__Emoji"
          />

          <p className="ResetPassword__ResetTxt">Reset password for</p>
          <p className="ResetPassword__EmailAddressTxt">{email}</p>

          <div style={{ position: "relative", margin: "6vw 0 0 0" }}>
            <input
              id="ResetPassword__PasswordInput"
              className={
                "ResetPassword__PasswordInput input-default " +
                (Password === "" ? "input-wrong" : "")
              }
              type="password"
              placeholder="Password"
              onChange={handleInputPassword}
              onBlur={() => window.scrollTo(0, 0)}
            />
            <img
              src={revelPasswordStats ? eyeRed : eye}
              alt=""
              className="ResetPassword__EyeImgPassword"
              onClick={() => {
                revelPassword("ResetPassword__PasswordInput");
                let temp = revelPasswordStats;
                setrevelPasswordStats(!temp);
              }}
            />
          </div>
          <div style={{ position: "relative" }}>
            <input
              id="ResetPassword__ConfirmPasswordInput"
              className={
                "ResetPassword__ConfirmPasswordInput input-default " +
                (ConfirmPassword === "" ? "input-wrong" : "")
              }
              type="password"
              placeholder="Confirm password"
              onChange={handleInputConfirmPassword}
              onBlur={() => window.scrollTo(0, 0)}
            />
            <img
              src={revelConfirmPasswordStats ? eyeRed : eye}
              alt=""
              className="ResetPassword__EyeImgPassword"
              onClick={() => {
                revelPassword("ResetPassword__ConfirmPasswordInput");
                let temp = revelConfirmPasswordStats;
                setrevelConfirmPasswordStats(!temp);
              }}
            />
          </div>
        </div>
        <div style={{ minHeight: "20vw" }}>
          {displayLoader ? (
            <div className="loader--5vw">
              <Loader />
            </div>
          ) : (
            <p className="error-message">{ErrorMessage}</p>
          )}
        </div>

        <button
          className="btn btn--yellow-white headline"
          style={{ marginBottom: "1.5vh" }}
          onClick={handleUpdate}
        >
          Update password
        </button>

        {displaySuccessMessage ? (
          <div id="MessageRP">
            <p id="MessageTextRP">
              Your password has been <br />
              successfully updated!
              <br />
              Do you want to continue
              <br />
              to your account?
            </p>
            <span id="YesBtnRP" onClick={goToProfile}>
              Yes
            </span>
            <span id="NoBtnRP" onClick={() => navigate("/", { replace: true })}>
              No
            </span>
          </div>
        ) : null}

        {/* {displayErrorMessage ? (
        <div id="ErrorMessageRP">
          <p id="ErrorMessageTextRP">
            Error updating password,
            <br />
            Please try again later
          </p>
        </div>
      ) : null} */}
      </div>
    </div>
  );
}
