import "./style.css";

import store from "../../../../store/store";

// import axios from 'axios';

// import unauthorized from '../../../functions/unauthorized';

import BackPage from "../../../../assets/images/common/back-page.png";
// import Done from '../../../images/icons/Done.png';

import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import loadWallet from "../../../../loaders/wallet";
import fixFractionDigits from "../../../../helpers/fixFractionDigits";

const selectTokens = (state) => state.tokens;
const selectProfile = (state) => state.profile;
const selectWallet = (state) => state.wallet;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function WithdrawAmount(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const navigate = useNavigate();
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const tokens = useSelector(selectTokens);
  const profile = useSelector(selectProfile);
  const wallet = useSelector(selectWallet);

  let { bank } = useParams();
  // console.log(bank);

  useEffect(() => {
    if (tokens && profile) {
      loadWallet(tokens, profile.accountType);
      // // console.log(accountType);

      // getRate();
    }
  }, []);

  // Not relevant since use the PC currency canceled: 28/12/22
  // const [exchangeToPC, setexchangeToPC] = useState(null);

  // function getRate() {
  //   // console.log("get rate");
  //   var axios = require("axios");
  //   var config = {
  //     method: "get",
  //     url:
  //       process.env.REACT_APP_BACKEND_URL +
  //       "/api/exchange/?volume=1&from=PC&to=ZAR",
  //     headers: {
  //       Authorization: tokens.accessToken,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       // console.log(JSON.stringify(response.data));
  //       setexchangeToPC(response.data);
  //     })
  //     .catch(function (error) {
  //       // console.log(error);
  //       setexchangeToPC(null);
  //     });
  // }

  const [WAmount, setWAmount] = useState(0);
  const [isWrongValue, setisWrongValue] = useState(null);

  const handleWAmount = (event) => {
    if (
      event.target.value > 0 &&
      event.target.value <= +wallet.withdrawalValue
    ) {
      setWAmount(event.target.value);
      setisWrongValue(false);
    } else {
      setWAmount(event.target.value);
      setisWrongValue(true);
    }
  };
  const handleWithdrawalRequest = async (event) => {
    if (WAmount > 0 && WAmount < wallet.withdrawalValue) {
      var axios = require("axios");
      var data = {
        amount: WAmount,
        // "amountTotal":12.2,
        currency: wallet.currency.code,
        bankId: bank,
      };

      var config = {
        method: "post",
        url: process.env.REACT_APP_BACKEND_URL + "/api/withdrawRequests",
        headers: {
          Authorization: tokens.accessToken,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          navigate("/wallet/withdrawal/request-received");
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else {
      // console.log(WAmount);
      setisWrongValue(true);
    }
  };

  return (
    wallet && (
      <div
        className="WithdrawAmount Page headline"
        style={{ height: windowInnerSize.height }}
      >
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className="Page--flex">
          <div style={{ position: "relative", paddingTop: "14vh" }}>
            <p className="WithdrawAmount__Balance unnamed-character-style-1">
              {wallet.currency.symbol + fixFractionDigits(wallet.withdrawalValue)}
            </p>

            <p className="WithdrawAmount__WithdrawalAmountTxt">
              Withdrawal Amount
            </p>
            <div style={{ position: "relative" }}>
              <input
                type="number"
                min={0}
                placeholder="0"
                className={
                  "WithdrawAmount__AmountInput input-default " +
                  (isWrongValue ? "input-wrong" : "")
                }
                onChange={handleWAmount}
              />
              <p className="WithdrawAmount__CurrencyTxt">{wallet.currency.symbol}</p>
            </div>
            {/* <p className="WithdrawAmount__AmountRands">
              {"R " +
                (exchangeToPC && (WAmount || WAmount === 0)
                  ? fixFractionDigits(WAmount * exchangeToPC)
                  : "--")}
            </p> */}
            <svg height="2vw" width="33%" className="WithdrawAmount__Line">
              <line
                x1="0"
                x2="100%"
                y1="0"
                y2="0"
                stroke="white"
                stroke-width="100%"
                stroke-linecap="butt"
              />
            </svg>
            <p className="WithdrawAmount__TransFeesTxt">
              *Transaction Fees Apply: 0.5% - 5%
              <br />
              For More Information Visit Out <Link to="">FAQS</Link>
            </p>
          </div>

          <p
            className="WithdrawAmount__NextBtn"
            onClick={handleWithdrawalRequest}
          >
            NEXT
          </p>
        </div>
      </div>
    )
  );
}
function toDigits(number) {
  let output = "",
    sNumber = number.toString();

  for (var i = sNumber.length, count = 0; i >= 0; i--, count++) {
    output =
      count % 3 === 0
        ? " " + sNumber.charAt(i) + output
        : sNumber.charAt(i) + output;
  }
  return output;
}
