import "./style.css";

import store from "../../../store/store";

import BackPage from "../../../assets/images/common/back-page.png";
import SmilyMoney from "../../../assets/images/common/smiley-money.png";
import InstagramBigIcon from "../../../assets/images/common/instagram-icon.png";
import TikTokCLogo from "../../../assets/images/common/tiktok-icon.png";
import YoutubeLink1 from "../../../assets/images/common/youtube-icon.png";

import { useState } from "react";
import { useNavigate } from "react-router";
import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Step2() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  const [SMLinks, setSMLinks] = useState({
    instagram: "",
    tiktok: "",
    youtube: "",
  });
  const [ErrorMessage, setErrorMessage] = useState("");
  const [errorInputs, seterrorInputs] = useState(false);

  const handleInstaLink = (event) => {
    errorInputs && seterrorInputs(false);
    ErrorMessage !== "" && setErrorMessage("");
    let temp = { ...SMLinks, instagram: event.target.value };
    setSMLinks(temp);
  };
  const handleTiktokLink = (event) => {
    errorInputs && seterrorInputs(false);
    ErrorMessage !== "" && setErrorMessage("");
    let temp = { ...SMLinks, tiktok: event.target.value };
    setSMLinks(temp);
  };
  const handleYoutubeLink = (event) => {
    errorInputs && seterrorInputs(false);
    ErrorMessage !== "" && setErrorMessage("");
    let temp = { ...SMLinks, youtube: event.target.value };
    setSMLinks(temp);
  };

  const handleSMLinks = () => {
    if (
      SMLinks.instagram !== "" ||
      SMLinks.tiktok !== "" ||
      SMLinks.youtube !== ""
    ) {
      store.dispatch({
        type: "app/moneyCalcUserSMLinks/add",
        payload: SMLinks,
      });
      navigate("/money-calculator/step-3");
    } else {
      seterrorInputs(true);
      setErrorMessage("Please add at least one handle name.");
    }
  };
  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(-100vw)",
        "transform: translateX(-50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="Step2MC Page headline"
        style={{ height: windowInnerSize.height }}
      >
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          onClick={() => {
            navigate(-1);
          }}
        />
        <div
          className="Page--flex Page--padding-5vw"
          style={{ minHeight: windowInnerSize.height }}
        >
          {/* <img src={SmilyMoney} alt="" id="SmilyMoneyMCS2"/> */}
          <div style={{ marginBottom: "8vw" }}>
            <img src={SmilyMoney} alt="" className="Step1MC__Emoji" />
            <p className="Step1MC__MoneyCHeadline unnamed-character-style-1">
              Money Calculator
            </p>
            <p className="Step1MC__MoneyCHeadline Step1MC__HeadlineS1 unnamed-character-style-1">
              Money Calculator
            </p>
            <p className="Step1MC__MoneyCHeadline Step1MC__HeadlineS2 unnamed-character-style-1">
              Money Calculator
            </p>
          </div>
          <div>
            <p className="Step2MC__Step2Txt unnamed-character-style-1">
              STEP 2
            </p>
            <p className="Step2MC__LinkItTxt">Link it up!</p>
            <p className="Step2MC__Step2ExpTxt">
              We will be needing your social media analytics to <br />
              formulate an accurate evaluation.
              <br />
              <br />
              Please enter your handle names below.
            </p>
          </div>
          <div style={{ marginBottom: "3vh" }}>
            <div style={{ position: "relative" }}>
              <input
                type="text"
                placeholder="Link your Instagram"
                className={
                  "Step2MC__Input input-default " +
                  (errorInputs ? "input-wrong" : "")
                }
                onChange={handleInstaLink}
              />
              <img
                src={InstagramBigIcon}
                alt=""
                className="Step2MC__InstaIcon"
              />
            </div>
            <div style={{ position: "relative" }}>
              <input
                type="text"
                placeholder="Link your TikTok"
                className={
                  "Step2MC__Input input-default " +
                  (errorInputs ? "input-wrong" : "")
                }
                onChange={handleTiktokLink}
              />
              <img src={TikTokCLogo} alt="" className="Step2MC__TikTokIcon" />
            </div>
            <div style={{ position: "relative" }}>
              <input
                type="text"
                placeholder="Link your YouTube"
                className={
                  "Step2MC__Input input-default " +
                  (errorInputs ? "input-wrong" : "")
                }
                onChange={handleYoutubeLink}
              />
              <img src={YoutubeLink1} alt="" className="Step2MC__YoutubeIcon" />
            </div>
          </div>
          {/* <Link to="/MoneyCalculator/LinkYourInstagram">
                <p id="LinkInsta">Link your Instagram</p>
                <img src={InstagramBigIcon} alt="" id="InstagramBigIconMC"/>
            </Link>
            <Link to="/MoneyCalculator/LinkYourTikTok">
                <p id="LinkTiktok">Link your TikTok</p>
                <img src={TikTokCLogo} alt="" id="TikTokCLogoMC"/>
            </Link>
            <Link to="/MoneyCalculator/LinkYourYoutube">
                <p id="LinkYoutube">Link your YouTube</p>
                <img src={YoutubeLink1} alt="" id="YoutubeLink"/>
            </Link>
    */}
          <div className="SorryMC__indicate-container">
            <p className="error-message">{ErrorMessage}</p>
          </div>
          <p
            className="btn btn--yellow-white"
            style={{ marginBottom: "4vh" }}
            onClick={handleSMLinks}
          >
            Next
          </p>
        </div>
      </div>
    </AnimateKeyframes>
  );
}
