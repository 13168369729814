import "./StockHolding.css";

import StockArrowDown from "../../../../assets/images/common/arrow-down-purple.png";

import StockCreativeCard from "../../../../components/common/creative-cards/Influencer";

import store from '../../../../store/store'

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import SalesLine from "../../../../components/common/SalesLine";
import { ROLES } from "../../../../tools/role";
import AgentCreativeCard from "../../../../components/common/creative-cards/Agent";
import { Link } from "react-router-dom";

const selectProfile = (state) => state.profile;
const selectTokens = (state) => state.tokens;

export default function StockHolding(props) {
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);
  const tokens = useSelector(selectTokens);

  const [stockH, setstockH] = useState(null);

  useEffect(() => {
    setstockH(props.stockHolding);
  }, [props.stockHolding]);

  const [cardData, setCardData] = useState(null);
  useEffect(() => {
    let tempCardData;
    if (stockH) {
      tempCardData = props.isIssuer
        ? {
            profilePic: profile.profilePic,
            name: profile.name,
            username: profile.username,
            ranking: profile.influencer.ranking,
            ringColour: profile.influencer.ringColour,
            socialMedia: profile.influencer.socialMedia,
            niches: profile.influencer.niches,
            city: profile.influencer.city,
            currentPrice: stockH.stock?.marketPrice,
            lastChange: stockH.stock?.lastChange,
            currency: stockH.stock?.currency.symbol,
          }
        : {
            profilePic: stockH.stock.influencer.user.profilePic,
            name: stockH.stock.influencer.user.name,
            username: stockH.stock.influencer.user.username,
            ranking: stockH.stock.influencer.ranking,
            ringColour: stockH.stock.influencer.ringColour,
            socialMedia: stockH.stock.influencer.socialMedia,
            niches: stockH.stock.influencer.niches,
            city: stockH.stock.influencer.user.city,
            currentPrice: stockH.stock.marketPrice,
            lastChange: stockH.stock.lastChange,
            currency: stockH.stock.currency.symbol,
          };
      setCardData(tempCardData);
    }
  }, [stockH, props.isIssuer]);

  useEffect(() => {
   if (stockH && cardData){
    let box = document.querySelector(".StockHolding");
      // store.dispatch({
      //   type: "ui/walletSliderHeight/add",
      //   payload: box.offsetHeight,
      // });
   }
}, [stockH,cardData]);

  return stockH && cardData ? (
    <div className="StockHolding">
      {/* <Link
        to="/wallet/portfolio/extended-view"
        state={{ share: stockH, isUserOwnStock: props.isIssuer }}
      > */}
        <div
          className="StockHolding__StockContainer headline"
          onClick={(e) => {
            const div = e.currentTarget;
            const tradeBtn = div.querySelector(".StockHolding__ListBtn");
            if(tradeBtn===e.target){
              return;
            }else{
              navigate("/wallet/portfolio/extended-view",{state:{ share: stockH, isUserOwnStock: props.isIssuer }})
            }
          }}
        >
          {stockH.stock?.influencer?.user.accountType & ROLES.AGENT ? (
            <AgentCreativeCard cardData={cardData} />
          ) : (
            <StockCreativeCard cardData={cardData} />
          )}
          {/* <Link
          to={
            profile
              ? "/exchange/create-trade/" + stockH.stock.id
              : "/auth/log-in"
          }
        > */}
          <p
            id="TradeSBtn"
            className="StockHolding__ListBtn"
            onClick={() => {
              navigate(
                props.isIssuer
                  ? `/my-passionstocks`
                  : `/exchange/create-trade/${stockH.stock.id}`,
                {
                  state: { process: "CELL" },
                }
              );
            }}
          >
            {props.isIssuer
              ? `Go Live [${props.stockHolding.issued}]`
              : `List [${props.stockHolding.volume}]`}
          </p>
          {/* </Link> */}
          <p className="StockHolding__UnitsAmount">
            {props.isIssuer
              ? stockH.issued + stockH.onSale
              : stockH.stockHolding}
            <span> Units</span>
          </p>
        </div>
      {/* </Link> */}
      {stockH.onSale > 0 && (
        <img src={StockArrowDown} alt="" className="StockHolding__ArrowDown" />
      )}
      {(stockH.saleOffers.length > 0 || stockH.onPPTTrade > 0) && (
        <div className="StockHolding__OnSaleContainer headline">
          {<SalesLine saleOffers={stockH.saleOffers} loadStockH={loadStockH} />}
          {/* {stockH.onPPTTrade > 0 && (
            <div
              className="ForSaleStS"
              style={{
                zIndex: "0",
                height: `${26 + 14 * stockH.saleOffers.length}vw`,
              }}
            >
              <p
                className="ForSaleTxtStS"
                style={{
                  fontSize: "4.5vw",
                  width: "100%",
                  left: "0",
                  textAlign: "center",
                }}
              >
                Pending for{" "}
                <span style={{ color: "#ffbf12" }}>{stockH.onPPTTrade}</span>{" "}
                Passion Product
              </p>
            </div>
          )} */}
        </div>
      )}
    </div>
  ) : (
    <div />
  );
  async function loadStockH() {
    // shares
    var configShares = {
      method: "get",
      url: process.env.REACT_APP_BACKEND_URL + "/api/investors/shares",
      headers: {
        Authorization: tokens.accessToken, // can't call hooks from this function (to get the accessToken)
      },
    };
    await axios(configShares)
      .then(async function (response) {
        // console.log(JSON.stringify(response.data));
        setstockH(response.data.filter((x) => x.id === stockH.id)[0]);
      })
      .catch(function (error) {
        // console.log(error);
      });

    // // console.log(store.getState().profile.data.accountType);
    // IPO
    // if ((accountType === 30) || (accountType === 14)) {
    //     var configIPO = {
    //         method: 'get',
    //         url: process.env.REACT_APP_BACKEND_URL + '/api/influencers/shares-to-release',
    //         headers: {
    //             Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
    //         }
    //     };
    //     await axios(configIPO)
    //         .then(async function (response) {
    //             // console.log(JSON.stringify(response.data));
    //             await store.dispatch({ type: 'wallet/IPOrelease/loadData', payload: response.data })
    //             await persistor.purge();

    //         })
    //         .catch(function (error) {
    //             // console.log(error);
    //         });
    // }
  }
}
