import React from "react";

import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./style.css";

import store from "../../../store/store";

import WalletSlider from "./components/WalletSlider";

import loadWallet from "../../../loaders/wallet";
import NetWorth from "./components/NetWorth";
import PorfolioNavBar from "./components/PorfolioNavBar";
import Breakdown from "./components/Breakdown";
import Transactions from "./components/Transactions";

const selectTokens = (state) => state.tokens;
const selectProfile = (state) => state.profile;
const selectWallet = (state) => state.wallet;
const selectAgencyTokenStorage = (state) => state.token_storage;
const selectToRefreshed = (state) => state.toRefresh;
const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectSliderHeight = (state) => state.walletSliderHeight;

export default function Wallet(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: true },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Wallet",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  // store.dispatch({ type: 'appConfig/edit_image_false', payload: null })

  const tokens = useSelector(selectTokens);
  const profile = useSelector(selectProfile);
  const wallet = useSelector(selectWallet);
  const isRefreshed = useSelector(selectToRefreshed);
  const walletSliderHeight = useSelector(selectSliderHeight);

  useEffect(() => {
    if (tokens && profile) {
      loadWallet(tokens, profile.accountType);
    }
  }, []);

  //Animation:
  const [animateSlide, setanimateSlide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setanimateSlide(true);
    }, 0);
    return () => {
      setanimateSlide(false);
    };
  }, []);

  const isAgencyStats = useSelector(selectAgencyTokenStorage);

  // portfolio mode: 0 - total, 1 - investor, 2 - issuer, 3 - clients
  const [portfolioMode, setPortfolioMode] = useState(0);

  return wallet && isRefreshed ? ( // isRefreshed - using to reset the slide when clicking on the 'Wallet' icon
    <div
      className={"Wallet Page " + (!animateSlide ? "animate-to-left" : null)}
    >
      <div
        style={{
          marginBottom: "70vw",
          //   minHeight:
          //     windowInnerSize.height - (windowInnerSize.width / 100) * 15,
        }}
      >
        <NetWorth />
        <svg className="WalletSlider__Line" height="0.5vw" width="25%">
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="#707070"
            stroke-width="100%"
            stroke-linecap="butt"
          />
        </svg>
        <PorfolioNavBar
          portfolioMode={portfolioMode}
          setPortfolioMode={setPortfolioMode}
        />
        <WalletSlider portfolioMode={portfolioMode} />
        <div
          style={{
            position: "relative",
            top: walletSliderHeight,
            transition: "all 0.5s",
          }}
        >
          <Breakdown />
          <Transactions />
        </div>
      </div>
    </div>
  ) : null;
}
