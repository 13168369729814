import "./style.css";

// import store from '../../store';

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import { useState,useEffect } from "react";
import { useSelector } from "react-redux";

const selectProfile = (state) => state.profile;

export default function SharePopUp(props) {
  // required props:
  // sharingType = { 0x1 (SALE) || 0x2 (PROFILE) }
  // brandUsername = { brandUsername }

  // sharerType: 'Brand itself' || 'Investor'
  const [sharerType, setSharerType] = useState(null);
  const [shareURL, setShareURL] = useState(null);
  const [shareScript, setShareScript] = useState(null);

  const SALE_VIEW_URL = "/sale-view/";
  const PROFILE_URL = "/public-profile/";

  const profile = useSelector(selectProfile);
  const SHARER_TYPES = {
    BRAND: 0x1, // 1
    INVESTOR: 0x2, // 2
  };
  const SHARING_TYPES = {
    SALE: 0x1, // 1
    PROFILE: 0x2, // 2
  };
  useEffect(() => {
    // setSharerType
    if (profile && profile.username === props.brandUsername) {
      setSharerType(SHARER_TYPES.BRAND);
    } else {
      setSharerType(SHARER_TYPES.INVESTOR);
    }
    // setShareURL
    if (props.sharingType & SHARING_TYPES.SALE) {
      setShareURL(SALE_VIEW_URL);
    }
    if (props.sharingType & SHARING_TYPES.PROFILE) {
      setShareURL(PROFILE_URL);
    }
  }, []);

  const brandSharerScriptPart1 =
    "Let's conquer Nasdaq together! Join me as a partner in my brand on PassionStocks ";
  const brandSharerScriptPart2 = " 🚀💸 #partners #success #branding";
  const investorSharerScriptPart1 =
    "Yo, you wanna get in on this? Partner up with me on PassionStocks and let's take ";
  const investorSharerScriptPart2 =
    "'s brand to the top of Nasdaq 🚀💰 #partners #success #branding ";
  useEffect(() => {
    let shareScriptTemp;
    if (sharerType & SHARER_TYPES.BRAND) {
      shareScriptTemp =
        brandSharerScriptPart1 +
        process.env.REACT_APP_BACKEND_URL +
        shareURL +
        props.brandUsername +
        brandSharerScriptPart2;
    }
    if (sharerType & SHARER_TYPES.INVESTOR) {
      shareScriptTemp =
        investorSharerScriptPart1 +
        props.brandUsername +
        investorSharerScriptPart2 +
        process.env.REACT_APP_BACKEND_URL +
        shareURL +
        props.brandUsername;
    }
    setShareScript(shareScriptTemp)
  }, [sharerType,shareURL,props.brandUsername]);

  return (
    shareScript && (
      <div className="SharePopUp headline">
        <p>Share to</p>
        <div className="SharePopUp__Buttons">
          <div className="SharePopUp__Button ">
            <EmailShareButton
              children={<EmailIcon size={"12.5vw"} round={true} />}
              title={
                props.SMIname
                  ? "You can now invest in " +
                    props.SMIname +
                    "'s brand! Check out all the trades on the exchange before they're sold out."
                  : ""
              }
              url={`${
                process.env.REACT_APP_BACKEND_URL +
                (props.SMIid
                  ? "SMI_3rd_Profile/" + props.SMIid
                  : props.Agencyid
                  ? "Agency_3rd_Profile/" + props.Agencyid
                  : "Exchange/Stock_view/" + props.saleId)
              }`}
            />
            <p>Email</p>
          </div>
          <div id="ShareIconL1Pos">
            <FacebookShareButton
              children={<FacebookIcon size={"12.5vw"} round={true} />}
              title={
                props.SMIname
                  ? "You can now invest in " +
                    props.SMIname +
                    "'s brand! Check out all the trades on the exchange before they're sold out."
                  : ""
              }
              url={`${
                process.env.REACT_APP_BACKEND_URL +
                (props.SMIid
                  ? "SMI_3rd_Profile/" + props.SMIid
                  : props.Agencyid
                  ? "Agency_3rd_Profile/" + props.Agencyid
                  : "Exchange/Stock_view/" + props.saleId)
              }`}
            />
            <p>Facebook</p>
          </div>
          <div id="ShareIconL1Pos">
            <TelegramShareButton
              children={<TelegramIcon size={"12.5vw"} round={true} />}
              title={
                props.SMIname
                  ? "You can now invest in " +
                    props.SMIname +
                    "'s brand! Check out all the trades on the exchange before they're sold out."
                  : ""
              }
              url={`${
                process.env.REACT_APP_BACKEND_URL +
                (props.SMIid
                  ? "SMI_3rd_Profile/" + props.SMIid
                  : props.Agencyid
                  ? "Agency_3rd_Profile/" + props.Agencyid
                  : "Exchange/Stock_view/" + props.saleId)
              }`}
            />
            <p>Telegram</p>
          </div>
          <div id="ShareIconL1Pos">
            <TwitterShareButton
              children={<TwitterIcon size={"12.5vw"} round={true} />}
              title={
                props.SMIname
                  ? "You can now invest in " +
                    props.SMIname +
                    "'s brand! Check out all the trades on the exchange before they're sold out."
                  : ""
              }
              url={`${
                process.env.REACT_APP_BACKEND_URL +
                (props.SMIid
                  ? "SMI_3rd_Profile/" + props.SMIid
                  : props.Agencyid
                  ? "Agency_3rd_Profile/" + props.Agencyid
                  : "Exchange/Stock_view/" + props.saleId)
              }`}
            />
            <p>Twitter</p>
          </div>
          <div id="ShareIconL1Pos">
            <WhatsappShareButton
              children={<WhatsappIcon size={"12.5vw"} round={true} />}
              title={""}
              url={shareScript}
            />
            <p>Whatsapp</p>
          </div>
          <div id="ShareIconL1Pos">
            <LinkedinShareButton
              children={<LinkedinIcon size={"12.5vw"} round={true} />}
              title={
                props.SMIname
                  ? "You can now invest in " +
                    props.SMIname +
                    "'s brand! Check out all the trades on the exchange before they're sold out."
                  : ""
              }
              url={`${
                process.env.REACT_APP_BACKEND_URL +
                (props.SMIid
                  ? "SMI_3rd_Profile/" + props.SMIid
                  : props.Agencyid
                  ? "Agency_3rd_Profile/" + props.Agencyid
                  : "Exchange/Stock_view/" + props.saleId)
              }`}
            />
            <p>Linkedin</p>
          </div>
        </div>
      </div>
    )
  );
}
