import "./style.css";

import store from "../../../store/store";

import BackPage from "../../../assets/images/common/back-page.png";
import SorryImage from "../../../assets/images/common/smiley-1.png";
// import { Link } from 'react-router-dom';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimateKeyframes } from "react-simple-animate";

import isValidateEmail from "../../../helpers/isValidateEmail";

import Loader from "../../../components/common/Loader";

export default function SMIValidation() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });

  const navigate = useNavigate();

  // const handleAskForMeet = async event => {
  //     // console.log(SMIDetails);
  //     if ((SMIDetails.email) && (SMIDetails.phone_number)) {
  //         await axios({
  //             method: 'POST',
  //             url: `https://web.passionstocks.com/api/smi/meeting`,
  //             params: {
  //                 email: SMIDetails.email,
  //                 phone_number: SMIDetails.phone_number,
  //                 instagram_link: store.getState().money_calc_input_sm_links.links.instagram,
  //                 youtube_link: store.getState().money_calc_input_sm_links.links.youtube,
  //                 tiktok_link: store.getState().money_calc_input_sm_links.links.tiktok,

  //             },
  //         }).then(function (res) {

  //             // console.log('set up meeting');
  //             // console.log(res);

  //             history.push("/Exchange")

  //         }).catch(function (error) {
  //             // console.log(error.response ? error.response : error);

  //         });
  //     }
  // }

  const [ShowSuccesedMessage, setShowSuccesedMessage] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [ShowLoader, setShowLoader] = useState(false);

  const [Email, setEmail] = useState(null);
  const handleEmailInput = (event) => {
    ErrorMessage !== "" && setErrorMessage("");
    if (isValidateEmail(event.target.value)) {
      setEmail(event.target.value);
      // console.log(event.target.value);
    } else {
      setEmail("");
    }
  };

  const handleSubmit = () => {
    setShowLoader(true);
    if (Email && Email !== "") {
      setShowLoader(false);
      setShowSuccesedMessage(true);
    } else {
      setShowLoader(false);
      setErrorMessage("Please insert your email.");
      !Email && setEmail("");
    }
  };

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.48}
      direction="normal"
      keyframes={[
        "transform: translateY(-100vw)",
        "transform: translateY(-50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div className="SorryMC Page Page--padding-8vw headline">
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className="Page--flex">
          <div style={{ padding: "21vw 0 3vh" }}>
            <img src={SorryImage} alt="emoji" className="SorryMC__Emoji" />
            <p className="SorryMC__ItsTxt">
              It’s not you
              <br /> it’s me
            </p>
            <p className="SorryMC__AtTxtSorry">
              At the moment the exchange has a minimum requirement of 40k
              follows to join.
              <br />
              <br />
              In future everyone with a social media presence will be able to
              join the exchange and allow people to invest in their future!
              <br />
              <br />
              For now add your email to join the waitlist and we’ll keep you in
              the loop.
            </p>
            <input
              className={
                "input-default headline " + (Email === "" ? "input-wrong" : "")
              }
              type="email"
              placeholder="Your Email"
              onChange={handleEmailInput}
              onBlur={() => {
                if (!Email) {
                  setEmail("");
                }
              }}
            />
          </div>
          <div className="SorryMC__indicate-container">
            {ShowLoader ? (
              <div className="loader--5vw">
                <Loader />
              </div>
            ) : (
              <p className="error-message">{ErrorMessage}</p>
            )}
          </div>
          <button
            className="btn btn--yellow-white headline"
            style={{ marginBottom: "5vh" }}
            onClick={handleSubmit}
          >
            Join the waitlist
          </button>

          {ShowSuccesedMessage && (
            <div className="SorryMC__SuccessMsg
            ">
              <p>
                <span>Your request has been received!</span>
                <br />
                <br />
                You have successfully added to
                <br /> the waiting list{" "}
              </p>
              <button
                className="btn btn--white-yellow SorryMC__SuccessMsgDoneBtn headline"
                onClick={() => {
                  navigate(store.getState().profile ? "/Exchange" : "/");
                }}
              >
                Done
              </button>
            </div>
          )}
        </div>
      </div>
    </AnimateKeyframes>
  );
}
