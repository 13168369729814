import '../style.css';

import React from 'react';
import { Link } from 'react-router-dom';
import SearchEducation from '../../../../assets/images/common/search-gray.png';
import EducationBtn from '../../../../assets/images/common/arrow-right.png';

export default function EducationForm() {
  return (
    <div id='EducationForm' className='headline'>
      <input type='text' placeholder='Coming Soon!' id='SearchEducationInput' />
      <img src={SearchEducation} alt='' id='SearchEducation' />

      <div id='EducationNotes'>
        <svg height='0.15vw' width='90%' id='LineEducationNotes'>
          <line
            x1='0'
            x2='100%'
            y1='0'
            y2='0'
            stroke='white'
            stroke-width='100%'
            stroke-linecap='butt'
          />
        </svg>

        <Link to='/education/how-does-it-work'>
          <p className='EducationBtn unnamed-character-style-1'>
            HOW DOES IT WORK
            <img src={EducationBtn} alt='' className='ArrowEducationBtn' />
          </p>
        </Link>

        <Link to='/education/passion-coin'>
          <p className='EducationBtn unnamed-character-style-1'>
            PASSIONCOIN
            <img src={EducationBtn} alt='' className='ArrowEducationBtn' />
          </p>
        </Link>

        <Link to='/education/passion-stock'>
          <p className='EducationBtn unnamed-character-style-1'>
            PASSIONSTOCK
            <img src={EducationBtn} alt='' className='ArrowEducationBtn' />
          </p>
        </Link>

        <Link to='/education/passion-products'>
          <p className='EducationBtn unnamed-character-style-1'>
            PASSIONPRODUCTS
            <img src={EducationBtn} alt='' className='ArrowEducationBtn' />
          </p>
        </Link>

        <Link to='/education/passionstocks-vs-regular-stock-market'>
          <p id='PSVSSMBtn' className='EducationBtn unnamed-character-style-1'>
            PASSIONSTOCKS VS <br /> STOCK MARKET
            <img src={EducationBtn} alt='' className='ArrowEducationBtn' />
          </p>
        </Link>

        <div id='ExtraSpaceEdctn' />
      </div>
    </div>
  );
}
