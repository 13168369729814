import "./style.css";
import store from "../../../store/store";
export default function GeneralAppMessage(props) {
  return (
    <div className="GeneralAppMessage headline" style={{bottom:(store.getState().navBarsDM.bottom?"27vw":"8vw"), left:props.class==="Width-90vw"?"5vw":"0",width:props.class==="Width-90vw"?"90vw":"84vw"}}>
      <p className="GeneralAppMessage__Txt">{props.text}</p>
      <div className="GeneralAppMessage__buttonsContainer">
        <button className="GeneralAppMessage__Btn btn--white-yellow" onClick={props.onClickYes}>Yes</button>
        <button className="GeneralAppMessage__Btn btn--yellow-white" onClick={props.onClickNo}>No</button>
      </div>
    </div>
  );
}
