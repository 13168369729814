import "./style.css";

import store from "../../../store/store";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import BackPage from "../../../assets/images/common/back-page.png";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function CookiesPolicy() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  return (
    <div
      id="CookiesPolicyDiv"
      className="Page headline"
      style={{ height: windowInnerSize.height }}
    >
      <Link to="/">
        <img src={BackPage} alt="" id="BackPageCP" />
      </Link>
      <div id="CookiesScrollDiv" style={{
          top: windowInnerSize.height * 0.09,
          height: windowInnerSize.height * 0.85,
        }}>
        Cookie Policy <br />
        <br />
        <span>Why we use cookies</span>
        <br />
        <br />
        We use cookies and similar technologies (such as web beacons, pixels,
        tags, and scripts) to improve and personalize your experience, provide
        our services, analyze website performance, and for marketing purposes,
        as further detailed below. A cookie is a piece of information (text
        file) stored on your device. When you visit our site, we set our own
        cookies (called first-party cookies) to store information on your
        device, such as your language preferences or login information. We also
        use 3rd-party cookies, which are cookies set by our partners (such as
        Facebook, Linkedin, Google, etc.).
        <br />
        The duration of a cookie depends on its function. “Session cookies” are
        only active during your online session, while “persistent cookies” will
        stay active on your device after closing your browser.
        <br />
        Cookie Settings
        <br />
        <br />
        <span>Blocking or deleting cookies from your browser</span>
        <br />
        Find more information about deleting or controlling cookies at
        www.aboutcookies.org. (Note that this website is a third-party website.
        Therefore, we cannot ensure its accuracy, completeness or availability).
        <br />
        It is also possible to set your browser to block all cookies, including
        cookies associated with our services, or to indicate when a cookie is
        being set by us. You can do this through the browser settings page of
        your the browser you use.
        <br />
        The following links may assist you in managing your cookies settings, or
        you can use the 'help' option in your internet browser for more details:
        <br />
        Internet Explorer
        <br />
        Google Chrome
        <br />
        Firefox
        <br />
        Safari (OS X)
        <br />
        Safari (iOS)
        <br />
        Android
        <br />
        You can prevent Google Analytics from collecting and using data about
        you by downloading and installing the browser plug-in available here .
        This creates an opt- out cookie that prevents the processing of your
        data. For more information about Google Analytics cookies, please see
        Google's help pages and privacy policy.
        <br />
        We may update this Cookie Notice in the future. We encourage you to
        periodically review this page for the latest information about cookies
        set by PassionStocks.
        <br />
        <br />
        <br />
        <span>Types of cookies</span>
        <br />
        <br />
        Essential cookies
        <br />
        <br />
        Some cookies are essential for our service to function and cannot be
        switched off in our systems. They are used to enable core functionality,
        such as authentication, security, connection stability, etc. You can
        always set your browser to block or alert you about these cookies, but
        this may affect how the website functions.
        <br />
        <br />
        <div id="CTable1">
          <table>
            <tr>
              <th>Website</th>
              <th>Cookie Name</th>
              <th>Type</th>
            </tr>
            <tr>
              <td>PassionStocks.com</td>
              <td>
                sign_up_page , crocAll, crocXXXXXX , accepted_order_XXXXXXXXXX ,
                seller, start_selling_ref , splash_screen_hide , campaignXXXXXX,
                campaign, campaign_id, offline_campaign ,
                locale_lihp_message_views , instant_popup , refinfo ,
                redirect_url , collecting , translate_ugc_control ,
                user_closed_wizard , is_facebook_campaign ,
                first_page_after_registration , hide_locale_redirect ,
                translate_ugc , go_to_gigs_create , team_invite_email,
                team_member_email , wpdiscuz_last_visit , _thinkific_session ,
                pending_promo_code , mfa_http_method, mfa_payload ,
                android_smart_banner_hidden , pro_search_toggle,
                XXX_search_toggle , last_content_pages_ , page_views , glfltr,
                rt_clfltr , splash_screen_flag , mobile_seen_splash , fsource ,
                instant_token , guid_ext , pre_unbounce_domain, unbounce_params
                , share_box_XXXXXXXXX , bta ,
                cs_alternative_referral_seen_overlays , session_locale ,
                searches- count , blocked_seller_seen_overlays , PHPSESSID ,
                u_guid, new_guid , was_logged_in , failed_activation_gigs ,
                XXXXXXX-review-added , hide_locale_seller_message , guest_locale
                , blog_affiliate_marketing , referral_program ,
                cancellation_seen_overlays , instant_share_popup ,
                dismissed_pending_delivery_notification , _fiverr_session_key ,
                first- watermark , hodor_creds , visited_fiverr ,
                closed_cookie_approval , logged_out_currency;
                logged_in_currency_v2 , session_referer , last_viewed_gig ,
                visitor_id , order_started , ios_smart_banner_hidden ,
                fb_potential , wordpress_last_visit , external_guid
              </td>
              <td>1st Party</td>
            </tr>
            <tr>
              <td>contently.com</td>
              <td>x_contently_id:XXX, contently_insights_user</td>
              <td>3rd Party</td>
            </tr>
            <tr>
              <td>google.com</td>
              <td>_GRECAPTCHA, _dc_gtm_UA-xxxxxxxx, G_ENABLED_IDPS</td>
              <td>3rd Party</td>
            </tr>
            <tr>
              <td>onetrust.com</td>
              <td>eupubconsent, OptanonAlertBoxClosed, OptanonConsent</td>
              <td>3rd Party</td>
            </tr>
            <tr>
              <td>cloudflare.com</td>
              <td>__cfduid, __cf_bm, cf_ob_info, __cfruid, cf_use_ob</td>
              <td>3rd Party</td>
            </tr>
            <tr>
              <td>vimeo.com</td>
              <td>player, vuid</td>
              <td>3rd Party</td>
            </tr>
            <tr>
              <td>comeet.com</td>
              <td>nlbi_XXXXXXX</td>
              <td>3rd Party</td>
            </tr>
            <tr>
              <td>facebook.com</td>
              <td>fbsr_</td>
              <td>3rd Party</td>
            </tr>
            <tr>
              <td>imperva.com</td>
              <td>incap_ses_, visid_incap_</td>
              <td>3rd Party</td>
            </tr>
            <tr>
              <td>perimeterx.com</td>
              <td>
                _pxvid, _pxde, _pxXX, _pxff_XX,
                akacd_RWASP-default-phased-release, _px3
              </td>
              <td>3rd Party</td>
            </tr>
            <tr>
              <td>soundcloud.com</td>
              <td>sc_anonymous_id</td>
              <td>3rd Party</td>
            </tr>
            <tr>
              <td>nr-data.net</td>
              <td>JSESSIONID</td>
              <td>3rd Party</td>
            </tr>
            <tr>
              <td>forter.com</td>
              <td>forterToken, ftr_blst_XX, ftr_XXX</td>
              <td>3rd Party</td>
            </tr>
            <tr>
              <td>wisepops.com</td>
              <td>wisepops, wisepops_session, wisepops_visits</td>
              <td>3rd Party</td>
            </tr>
          </table>
        </div>
        <br />
        <br />
        Analytics Cookies
        <br />
        <br />
        Analytics cookies help us know which pages are popular and how visitors
        move around the site. We use them to count visits, check traffic
        sources, and measure and improve the website’s performance.
        <br />
        <div id="CTable2">
          <table>
            <tr>
              <th>Website</th>
              <th>Cookie Name</th>
              <th>Type</th>
              <th>Lifespan</th>
            </tr>
            <tr>
              <td>vwo.com</td>
              <td>
                _vis_opt_s, _vwo_uuid_v2, _vwo_sn, _vwo_ds, _vwo_uuid,
                _vwo_referrer
              </td>
              <td>3rd Party</td>
              <td>
                100 days, 366 days, 365 days, 90 days, 3650 days, 365 days
              </td>
            </tr>
            <tr>
              <td>google.com</td>
              <td>
                _gat_gtag_xxx xxxxxxxxxxxxx xxxxxxxxxxx, _gat_UA- XXXXXXX-X,
                __utmc, ___utmvc, _gclxxxx, NID, __utma, _ga, _gat, _gid, __utmt
              </td>
              <td>3rd Party</td>
              <td>
                0 days, 0 days, Session, 0 days, 90 days, 180 days, 730 days,
                730 days, 0 days, 1 days, 1 days
              </td>
            </tr>
            <tr>
              <td>
                quantummet <br /> ric.com
              </td>
              <td>QMUserID, QMSessionID</td>
              <td>3rd Party</td>
              <td>365 days, Session</td>
            </tr>
            <tr>
              <td>tapad.com</td>
              <td>TapAd_3WAY_ SYNCS, TapAd_XX, TapAd_TS, TapAd_DID</td>
              <td>3rd Party</td>
              <td>60 days, 60 days, 60 days, 60 days</td>
            </tr>
            <tr>
              <td>mixpanel.com</td>
              <td>
                __mp_opt_in_ out_XXXXXXX XXXXXXXXXX XXXXXXXXXXXXXXX, mp_XXXXXXX
                XXXXXXXXXX XXXXXXXXXX XXXXX_mixpa nel
              </td>
              <td>3rd Party</td>
              <td>365 days, 365 days</td>
            </tr>
            <tr>
              <td>siteintercept.qualtrics.com</td>
              <td>QSI_SI_XXXX XXXXXXXXXX X_intercept, QSI_HistorySession</td>
              <td>3rd Party</td>
              <td>Session, Session</td>
            </tr>
            <tr>
              <td>smartlook.com</td>
              <td>
                SL_C_XXXXX XXXXXXXX_V ID, SL_C_XXXXX XXXXXXXX_K EY, AWSELB,
                SL_C_XXXXX XXXXXXXX_S ID
              </td>
              <td>3rd Party</td>
              <td>Session, Session, Session, Session</td>
            </tr>
          </table>
        </div>
        <br />
        <br />
        Marketing Cookies
        <br />
        Certain 3rd-party service providers and advertising partners manage our
        advertising on other websites. Such 3rd parties use cookies through our
        site to provide you with advertising on other websites based on your
        interests and preferences.
        <br />
        <br />
        <div id="CTable3">
          <table>
            <tr>
              <th>Website</th>
              <th>Cookie Name</th>
              <th>Type</th>
              <th>Lifespan</th>
            </tr>
            <tr>
              <td>youtube.com</td>
              <td>CONSENT, YSC, VISITOR_INFO 1_LIVE, GPS</td>
              <td>3rd Party</td>
              <td>1 days, 1 days, 1 days, 1 days</td>
            </tr>
            <tr>
              <td>taboola.com</td>
              <td>t_gid:</td>
              <td>3rd Party</td>
              <td>365 days</td>
            </tr>
            <tr>
              <td>twitter.com</td>
              <td>personalization _id</td>
              <td>3rd Party</td>
              <td>730 days</td>
            </tr>
            <tr>
              <td>tvsquared.com</td>
              <td>_tq_id.TV</td>
              <td>3rd Party</td>
              <td>730 days</td>
            </tr>
            <tr>
              <td>doubleclick.net</td>
              <td>test_cookie, IDE</td>
              <td>3rd Party</td>
              <td>365 days, 365 days</td>
            </tr>
            <tr>
              <td>quora.com</td>
              <td>m-b</td>
              <td>3rd Party</td>
              <td>730 days</td>
            </tr>
            <tr>
              <td>facebook.com</td>
              <td>fr, _fbp</td>
              <td>3rd Party</td>
              <td>90 days, 90 days</td>
            </tr>
            <tr>
              <td>pinterest.com</td>
              <td>
                _pin_unauth, _pinterest_ct_u a, _pinterest_ct_rt, _pinterest_cm
              </td>
              <td>3rd Party</td>
              <td>365 days, 365 days, 365 days, 365 days</td>
            </tr>
            <tr>
              <td>atdmt.com</td>
              <td>ATN, AA003</td>
              <td>3rd Party</td>
              <td>730 days, 90 days</td>
            </tr>
            <tr>
              <td>bing.com</td>
              <td>MUID, _uetvid, _uetsid</td>
              <td>3rd Party</td>
              <td>365 days, 9 days, 1 days</td>
            </tr>
            <tr>
              <td>tatari.tv</td>
              <td>
                tatari-cookie- test, tatari- session-cookie, tatari-user- cookie
              </td>
              <td>3rd Party</td>
              <td>3650 days, 3650 days, 3650 days</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}
