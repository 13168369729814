import "./style.css";

import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";

import WellcomeMCBckImg from "../../assets/images/money-calculator/welcome-background.png";
import ArrowLeftBtn from "../../assets/images/common/arrow-left.png";
import QuotationMCBegin from "../../assets/images/common/quotation-begin.png";
import QuotationMCEnd from "../../assets/images/common/quotation-end.png";
import BackPage from "../../assets/images/common/back-page.png";

import WhatsTheVibe from "./components/WhatsTheVibe";
import ShowMeTheMoney from "./components/ShowMeTheMoney";
import TwoSteps from "./components/TwoSteps";

import store from "../../store/store";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function CreatorsHub(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const [VibeMoneyForms, setVibeMoneyForms] = useState("Vibe");
  // stepSelected - refer to <TwoSteps /> component
  const [stepSelected, setstepSelected] = useState(1);

  const navigate = useNavigate();

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(-100vw)",
        "transform: translateX(-50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="CreatorsHub Page headline"
        // style={{ height: windowInnerSize.height }}
      >
        <img
          src={BackPage}
          alt=""
          className="WellcomeMC__BackBtn"
          onClick={() => {
            navigate(-1);
          }}
        />
        <div style={{ display: "block" }}>
          <div
            style={{ backgroundImage: `url(${WellcomeMCBckImg})` }}
            alt=""
            className="WellcomeMC__BackgroundImg"
          />
          <p className="CreatorsHub__WelcomeTxt unnamed-character-style-1">
            WELCOME ALL CREATORS <br />
            TO THE <br />
            CREATORS HUB
          </p>

          <div className="CreatorsHub__Shadow" />
          <img
            src={QuotationMCBegin}
            alt=""
            className="CreatorsHub__QuotationMCBegin"
          />
          <p className="CreatorsHub__CreatingTxt">
            creating a space where <br />
            financial limitations don’t hold back <br />
            self-expression and creativity. <br />
            Let’s keep the fun vibes flowing <br />
            and empower creators to live their passion.
          </p>
          <img
            src={QuotationMCEnd}
            alt=""
            className="CreatorsHub__QuotationMCEnd"
          />
        </div>
        <div style={{ position: "relative", marginTop: "46vw" }}>
          <div className="CreatorsHub__BtnsContainer">
            <p
              className={
                "CreatorsHub__Btn "
                // (VibeMoneyForms === "Vibe" ? "WellcomeMC__selectedBtn" : null)
              }
              onClick={setAsVibe}
            >
              WHAT'S THE VIBE?
            </p>
            <p
              className={
                "CreatorsHub__Btn "
                // (VibeMoneyForms !== "Vibe" ? "WellcomeMC__selectedBtn" : null)
              }
              onClick={setAsShowMoney}
            >
              SHOW ME THE MONEY
            </p>
            <p
              className={
                "CreatorsHub__Btn "
                // (VibeMoneyForms !== "Vibe" ? "WellcomeMC__selectedBtn" : null)
              }
              onClick={setAs2Steps}
            >
              2 STEPS
            </p>
            <p
              className="WellcomeMC__selectedBtn"
              style={{
                left:
                  VibeMoneyForms === "Vibe"
                    ? "1.5vw"
                    : VibeMoneyForms === "ShowMoney"
                    ? "41vw"
                    : "33vw",
                width:
                  VibeMoneyForms === "Vibe"
                    ? "40.5vw"
                    : VibeMoneyForms === "ShowMoney"
                    ? "47.5vw"
                    : "24vw",
                top: VibeMoneyForms === "2Steps" ? "10vw" : "0",
              }}
            />
          </div>
        </div>

        {VibeMoneyForms === "Vibe" && <WhatsTheVibe />}

        {VibeMoneyForms === "ShowMoney" && <ShowMeTheMoney />}

        {VibeMoneyForms === "2Steps" && (
          <TwoSteps
            stepSelected={stepSelected}
            setstepSelected={setstepSelected}
          />
        )}

        <Link to={"/creator-registration/intro"}>
          <button className="CreatorsHub__CheckBtn headline">
            Check if You’re Qualified
          </button>
        </Link>
        {stepSelected === 2 && (
          <div
            style={{
              position: "relative",
              margin: "5vw",
              width: "90vw",
              fontSize: "13px",
              lineHeight: "17px",
              color: "#909090",
              fontWeight: "lighter",
            }}
          >
            _________________________________ <br />
            <br />
            <p>
              PassionStocks will guide you to build a strong record, a crucial
              requirement for expanding and financing your personal brand. We
              understand that economic viability and stock exchange requirements
              can vary significantly. While the vision of ringing the bell and
              opening trading with confetti and balloons falling at Times Square
              might be enticing, it is important to consider the pros and cons
              of each step along the way.
            </p>
            <p>
              At PassionStocks, we are dedicated to helping creators like you
              communicate with the financial world and establish a personal
              brand that stands out. However, it&rsquo;s important to note that
              we do not negotiate deals on your behalf or provide similar
              services. Negotiating deals and managing such aspects remain your
              responsibility and that of your team.
            </p>
            <p>
              We believe in empowering you to take charge of your journey and
              make informed decisions. PassionStocks is here to provide
              guidance, support, and the tools you need to succeed. The work to
              be done is yours, and we are committed to assisting you in
              building a strong foundation for your personal brand.
            </p>
            <p>
              Remember, the road to success requires careful planning and
              consideration. With PassionStocks by your side, you can
              confidently navigate the path towards achieving your goals and
              creating a brand that leaves a lasting impact.
            </p>
            <p>______</p>
            <p>
              In the current stage of PassionStocks, we aim to assist you by
              providing some information from stock exchange websites for your
              convenience as you choose the stock exchange you would like to be
              listed on. While we strive to offer valuable insights and
              resources, it&rsquo;s important to note that conducting your own
              research is crucial to ensure a comprehensive understanding of the
              requirements and processes associated with each stock exchange.
            </p>
            <p>
              As we continue to grow and evolve, we may explore providing
              additional services related to stock exchange listings. However,
              at this stage, our primary focus is on guiding you through the
              process of building and establishing a strong personal brand, and
              offering support along your journey.
            </p>
            <p>
              PassionStocks remains committed to empowering creators like you
              and helping you achieve your financial aspirations. We encourage
              you to leverage the resources we provide while also conducting
              your own due diligence to make well-informed decisions regarding
              the stock exchange listing that aligns with your unique vision and
              goals.
            </p>
            <p>
              Remember, as the CEO of your brand, you hold the power to make all
              the decisions. It is essential to take ownership of your journey
              and embrace the responsibility that comes with it. While
              PassionStocks is here to guide and support you, ultimately, the
              choices and decisions related to your personal brand rest in your
              hands. Trust your instincts, leverage the resources available to
              you, and confidently navigate the path that aligns with your
              vision for success. Your brand&rsquo;s future is in your capable
              hands, and we are here to cheer you on every step of the way.
            </p>
          </div>
        )}
      </div>
    </AnimateKeyframes>
  );

  function setAsShowMoney() {
    if (VibeMoneyForms !== "ShowMoney") {
      setVibeMoneyForms("ShowMoney");
    }
  }
  function setAsVibe() {
    if (VibeMoneyForms !== "Vibe") {
      setVibeMoneyForms("Vibe");
    }
  }
  function setAs2Steps() {
    if (VibeMoneyForms !== "2Steps") {
      setVibeMoneyForms("2Steps");
    }
  }
}
