import "./style.css";

import store from "../../../../store/store";

import { useSelector } from "react-redux";

import VSSlider from "./components/VSSlider";

import BackPage from "../../../../assets/images/common/back-page.png";
import PSVSSMBckgPic from "../../../../assets/images/education/ps-vs-sm/PSVSSMBckgPic.png";
import ShortLogo from "../../../../assets/images/common/ps-logo-2-rows.png";
import VS from "../../../../assets/images/education/ps-vs-sm/VS.png";

import { useNavigate } from "react-router";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function PSVSSM() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Notification",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();

  return (
    <div id="PSVSSMDiv" className="Page headline"
    style={{height: windowInnerSize.height}}>
      <img
        src={BackPage}
        alt=""
        id="BackPageNot"
        onClick={() => {
          navigate(-1);
        }}
      />

      <p id="PSVSSMTitle">PassionStocks VS Regular stock market</p>

      <img src={PSVSSMBckgPic} alt="" id="PSVSSMBckgPic" />
      <p id="WhereTitlePSVSSM" className="unnamed-character-style-1">
        Where
        <br />
        Passion
        <br />
        Meets
        <br />
        Stocks
      </p>

      <img src={ShortLogo} id="ShortLogoPSVSSM" alt=""></img>
      <img src={VS} id="VSPicPSVSSM" alt=""></img>
      <p id="StockMarketTxtPSVSSM" className="unnamed-character-style-1">
        Regular <br></br>Stock market
      </p>
      <div id="VSSliderPosPSVSSM">
        <VSSlider />
      </div>
    </div>
  );
}
