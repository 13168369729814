import "./style.css";

import store from "../../../../store/store";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import BackPage from "../../../../assets/images/common/back-page.png";
import Done from "../../../../assets/images/common/confirm.png";

const selectTokens = (state) => state.tokens;
const selectTradeData = (state) => state.pptConvertionTradeData;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function CPPTTPSuccessful(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const navigate = useNavigate();

  const tradeData = useSelector(selectTradeData);
  const tokens = useSelector(selectTokens);

  return (
    <div
      id="CPPTTPSSDiv"
      className="Page headline"
      style={{ height: windowInnerSize.height }}
    >
      <img
        src={BackPage}
        alt=""
        id="BackPageCPPTTPSS"
        onClick={() => {
          navigate(-1);
        }}
      />

      <p id="PSTxtCPPTTPSS" className="unnamed-character-style-1">
        PASSIONPRODUCT
        <br />
        SUCCESSFULLY
        <br />
        TRADED IN
      </p>

      <p id="TheProductTxtCPPTTPSS">
        The passionstock is now reflected <br />
        in your wallet.
        <br />
        <br />
        You can now trade it again.
      </p>

      <svg height="1.8vw" width="35%" id="LineCPPTTPSS">
        <line
          x1="0"
          x2="100%"
          y1="0"
          y2="0"
          stroke="white"
          stroke-width="100%"
          stroke-linecap="butt"
        />
      </svg>

      <img
        src={Done}
        alt=""
        id="DoneCPPTTPSS"
        onClick={() => navigate("/wallet", { replace: true })}
      />
    </div>
  );
}
