import "./style.css";

import store from "../../../../store/store";

import { Link } from "react-router-dom";
import { AnimateKeyframes } from "react-simple-animate";
import { useState } from "react";
import { useSelector } from "react-redux";

import ForgotPasswordPic from "../../../../assets/images/common/emoji-silence.png";
import Confirm from "../../../../assets/images/common/confirm.png";
import HeaderWithLogo from "../../../../components/common/HeaderWithLogo";
import Loader from "../../../../components/common/Loader";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function ForgotPasswordCheckEmail(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  if (!store.getState().windowInnerSize) {
    store.dispatch({
      type: "ui/windowInnerSize/add",
      payload: { height: window.innerHeight, width: window.innerWidth },
    });
  }

  const [ErrorMessage, setErrorMessage] = useState("");
  const [displayLoader, setdisplayLoader] = useState(false);
  const [anableResend, setanableResend] = useState(true);
  const handleResend = () => {
    setdisplayLoader(true);
    setanableResend(false);

    var axios = require("axios");
    var data = {
      email: store.getState().forgotPasswordEmail,
    };

    var config = {
      method: "post",
      url: process.env.REACT_APP_BACKEND_URL + "/auth/forgot-password",
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        setdisplayLoader(false);
        setErrorMessage("A new email has been sent.");
        setTimeout(() => {
          setanableResend(true);
          setErrorMessage("");
        }, 60000);
      })
      .catch(function (error) {
        setdisplayLoader(false);
        setTimeout(() => {
          setanableResend(true);
          setErrorMessage("");
        }, 4000);
        // console.log(error);
        setErrorMessage("An error occurred, please try again later.");
      });
  };

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.3}
      direction="normal"
      keyframes={[
        "transform: translateX(-100vw)",
        "transform: translateX(-50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="FPCheckEmail Page Page--padding-8vw headline"
        style={{ height: store.getState().windowInnerSize.height }}
      >
        <div
          className="Page--flex"
          style={{ minHeight: windowInnerSize.height }}
        >
          <HeaderWithLogo />

          <div className="FPEmailAddress__instruction">
            <img
              src={ForgotPasswordPic}
              alt=""
              className="FPCheckEmail__Emoji"
            />

            <p className="FPCheckEmail__ClickTxt">
              Click on <br />
              “Reset Password” <br />
              on the email
            </p>
            <p
              className={
                "FPCheckEmail__ResendBtn " +
                (anableResend ? "" : "FPCheckEmail__ResendBtn--gray")
              }
              onClick={handleResend}
            >
              Resend
            </p>
          </div>

          <div className="FPEmailAddress__indicate-container">
            {displayLoader ? (
              <div className="loader--5vw">
                <Loader />
              </div>
            ) : (
              <p className="error-message">{ErrorMessage}</p>
            )}
          </div>

          <div>
            <svg height="2.5vw" width="40%" className="FPCheckEmail__Line">
              <line
                x1="0"
                x2="100%"
                y1="0"
                y2="0"
                stroke="white"
                stroke-width="100%"
                stroke-linecap="butt"
              />
            </svg>

            <Link to="/">
              <img src={Confirm} alt="" className="FPCheckEmail__ConfirnBtn" />
            </Link>
          </div>
        </div>
      </div>
    </AnimateKeyframes>
  );
}
