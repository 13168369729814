import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import axios from "axios";
import { AnimateKeyframes } from "react-simple-animate";
import { Link } from "react-router-dom";

import "./style.css";

import AgencyCreativeCard from "../../../components/common/creative-cards/Agent";

import store from "../../../store/store";

import BackPage from "../../../assets/images/common/back-page.png";
import FullStar from "../../../assets/images/common/star-full.png";
import EmptyStar from "../../../assets/images/common/star-empty.png";
import loadAgenciesData from "../../../loaders/agents";
import loadJoinOffers from "./loaders/joinOffers";
import loadJoinRequests from "./loaders/joinRequests";
import Loader from "../../../components/common/Loader";

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectTokens = (state) => state.tokens;
const selectJoinOffers = (state) => state.joinOffers;
const selectJoinRequests = (state) => state.joinRequests;
const selectAgenciesFeedWaitingForData = (state) => state.joinRequests;
const selectAgenciesListNewPage = (state) => state.agentsListNewPage;

export default function FindYourAgency(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Profile",
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const tokens = useSelector(selectTokens);
  const joinOffers = useSelector(selectJoinOffers);
  const joinRequests = useSelector(selectJoinRequests);
  const waitingForData = useSelector(selectAgenciesFeedWaitingForData);
  const newPage = useSelector(selectAgenciesListNewPage);

  const navigate = useNavigate();

  const [feedListElements, setfeedListElements] = useState(null);

  useEffect(() => {
    const loadData = () => {
      loadAgenciesData(tokens, 1);
      // loadJoinOffers(tokens);
      // loadJoinRequests(tokens);
    };
    loadData();
  }, [tokens]);

  const handleAcceptJoinOffer = async (JO) => {};
  // const handleIgnoreJoinOffer = async (JOId) => {
  //     await axios({
  //         method: 'DELETE',
  //         url: `https://web.passionstocks.com/api/join-offers/` + JOId +`?api_token=` + store.getState().profile.token,

  //     }).then(async function (response) {
  //         // console.log('ignore agency join offer')
  //         // console.log(response.data);
  //         // await store.dispatch({ type: 'find_agency/loadJoinOffers', payload: response.data })

  //     }).catch(function (error) {
  //         // console.log('error to ignore agency join offer')
  //     })
  // }
  // const handleViewProfile = async (JOId) => {
  //     await axios({
  //         method: 'POST',
  //         url: `https://web.passionstocks.com/api/user/influencer?api_token=` + store.getState().profile.token,

  //     }).then(async function (response) {
  //         // console.log('accept agency join offer')
  //         // console.log(response.data);
  //         // await store.dispatch({ type: 'find_agency/loadJoinOffers', payload: response.data })

  //     }).catch(function (error) {
  //         // console.log('error to accept agency join offer')
  //     })
  // }

  // scroll feed event - load new agencies / new search results:
  useEffect(() => {
    const agenciesFeedElement = document.getElementById(
      "FindAgency__AgenciesList"
    );
    agenciesFeedElement.addEventListener("scroll", () => {
      if (
        agenciesFeedElement.offsetHeight + agenciesFeedElement.scrollTop >=
        agenciesFeedElement.scrollHeight - 2
      ) {
        if (
          store.getState().agenciesFeedNewPageData?.length > 0 &&
          !store.getState().agenciesFeedWaitingForData
        ) {
          store.dispatch({
            type: "app/my-agent/find-agency/agenciesFeedWaitingForData/toggle",
            payload: true,
          });
          let temp = store.getState().agenciesFeedPageIndex + 1; // ???
          store.dispatch({
            type: "app/my-agent/find-agency/agenciesFeedPageIndex/add",
            payload: temp,
          });
          new loadAgenciesData(store.getState().tokens, temp);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (newPage) {
      new addAgenciesElements();
    }
  }, [newPage]);

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="FindAgency Page headline"
        style={{
          position: "relative",
          height: windowInnerSize.height,
        }}
      >
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          onClick={() => {
            navigate(-1);
          }}
        />
        <p className="FindAgency__FindTxt unnamed-character-style-1">
          FIND YOUR <br /> AGENCY TODAY
        </p>
        <p className="FindAgency__AgenciesTxt">
          Agencies are a great way of being <br />
          able to manage your profile while
          <br /> you continue doing what you love! <br />
          <br /> Check out our list of recommended <br />
          agencies below.{" "}
        </p>
        <svg className="FindAgency__Line" height="1.2vw" width="40%">
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="#707070"
            stroke-width="5vw"
            stroke-linecap="butt"
          />
        </svg>
        {joinOffers && (
          <div className="FindAgency__JoinOffersList">
            <p className="FindAgency__JoinOffersTxt">Join Offers</p>
            {/* {getAgenciesJoinOffers()} */}
            <svg
              className="FindAgency__JoinOffersLine"
              height="1.2vw"
              width="40%"
            >
              <line
                x1="0"
                x2="100%"
                y1="0"
                y2="0"
                stroke="#707070"
                stroke-width="5vw"
                stroke-linecap="butt"
              />
            </svg>
          </div>
        )}
        {joinRequests && <div className="FindAgency__JoinRequests"></div>}
        <div id="FindAgency__AgenciesList" className="FindAgency__AgenciesList">
          {feedListElements}
          {waitingForData /*|| displayFakeLoader*/ && (
            <div className="SalesFeed__Loader">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </AnimateKeyframes>
  );

  function getAgenciesJoinOffers() {
    let AgenciesJoinOffersSlides = [];
    for (
      let i = 0;
      props.JoinOffersFromAs ? i < props.JoinOffersFromAs.length : i < 0;
      i++
    ) {
      AgenciesJoinOffersSlides.push({
        renderItem: () => {
          return (
            <div id={"JoinOffer" + i} className="JoinOfferInfoAllSlide">
              {/* <p>{props.JoinOffersFromAs[i].agency_id}</p> */}
              <div id="AgencyJoinOfferCard" className="headline">
                <div
                  className="AgencyPicJoinOffer"
                  style={{
                    backgroundImage: `url(${
                      `https://web.passionstocks.com/` +
                      props.JoinOffersFromAs[i].agency.profile_photo_path
                    })`,
                  }}
                />

                {/* <img src={`https://passionstocks.com/` + (props.agencyUserProfile ? props.agencyUserProfile.profile_photo_path : props.AgencyItemProfile.profile_photo_path)} alt="" className="AgencyPicCreative"/> */}
                <p id="RatingNumAJOO">{1}</p>
                <p className="AgencyNameJoinOffer">
                  {props.JoinOffersFromAs[i].agency.name}
                </p>
                {/* <p className="AgencyStockPriceCreative">PC{props.agencyUserProfile ? props.agencyUserProfile.influencer.current_stock_price : '0'}/PS */}
                {/* {
                                        !isGrowth ?
                                            <img src={ArrowUp} alt="" className="AgencySArrowCreative" />
                                            :
                                            <img src={ArrowDown} alt="" className="AgencySArrowCreative" />
                                    } */}
                {/* </p> */}
                <p className="AgencyTxtJoinOffer">Agency</p>
                <p className="AgencyEmailJoinOffer">
                  {props.JoinOffersFromAs[i].agency.email_address}
                </p>
                <p className="AgencyPhoneNJoinOffer">
                  {props.JoinOffersFromAs[i].agency.phone_number}
                </p>
              </div>
              <p
                id="JoinOfferAcceptBtn"
                className="headline"
                onClick={() => {
                  handleAcceptJoinOffer(props.JoinOffersFromAs[i]);
                }}
              >
                Accept
              </p>
              <p id="JoinOfferIgnoreBtn" className="headline">
                Ignore
              </p>
              <p id="JoinOfferViewProfileBtn" className="headline">
                View Profile
              </p>
            </div>
          );
        },
      });
    }
    return (
      <div>
        <ImageGallery
          items={AgenciesJoinOffersSlides}
          showNav={false}
          showThumbnails={false}
          showPlayButton={false}
          showFullscreenButton={false}
          slideDuration={800}
          slideInterval={3000}
          autoPlay={false}
          infinite={false}
        />
      </div>
    );
  }

  function addAgenciesElements() {
    // console.log(newPage);
    let tempFeedElements = [];
    // if (pageIndex === 1) {
    if (!feedListElements) {
      tempFeedElements = [];
    } else {
      tempFeedElements = feedListElements;
    }
    for (let i = 0; i < newPage.length; i++) {
      let cardData = {
        name: newPage[i].user.name,
        city: newPage[i].user.city,
        profilePic: newPage[i].user.profilePic,
        ringColour: newPage[i].influencer?.ringColour,
        ranking: newPage[i].ranking,
        currentPrice: null /*newPage[i].influencer?.stock.marketPrice
          ? newPage[i].influencer.stock.marketPrice
          : newPage[i].influencer.stock.initialIPOprice,*/,
        currency: newPage[i].influencer?.stock.currency.symbol,
        lastChange: newPage[i].influencer?newPage[i].influencer.stock.lastChange:{amount:'0'},
      };
      tempFeedElements.push(
        <div className="AgencyItemList" key={i}>
          <AgencyCreativeCard cardData={cardData} />
          <div id="AgencyItemStarsLine">{getRateStars(newPage[i].ranking)}</div>
          <Link to={"/public-profile/" + newPage[i].id}>
            <p className="ViewAgencyBtn">View</p>
          </Link>
        </div>
      );
    }
    setfeedListElements(tempFeedElements);
    // console.log(tempFeedElements);
  }
}
function getRateStars(fullStarsNum) {
  let FullStars = fullStarsNum?fullStarsNum:0;
  let EmptyStars = fullStarsNum?5-fullStarsNum:5;
  let StarsCounter = 5;
  let StarsLine = [];

  for (let i = 0; i < FullStars; i++, StarsCounter--) {
    StarsLine.push(
      <img
        key={StarsCounter}
        className="RateStars"
        src={FullStar}
        alt=""
        id="FullStar"
      />
    );
  }
  for (let i = 0; i < EmptyStars; i++, StarsCounter--) {
    StarsLine.push(
      <img
        key={StarsCounter}
        className="RateStars"
        src={EmptyStar}
        alt=""
        id="FullStar"
      />
    );
  }

  return StarsLine;
}
