import axios from 'axios';

import store from '../../../../../store/store';
import loadUser from "../../../../../loaders/user";
import ProfileLayout from '../..';
import clearStoreWithoutConst from '../../../../../utiles/clearStoreWithoutConst';
import loadWallet from '../../../../../loaders/wallet';
import loadProfile from '../../../../../loaders/profile';

export default async function loadMemberAccount(tokens, smiId) {

    await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}/api/agents/influencers/` + smiId + `/login`,
        headers: {
            Authorization: tokens.accessToken // can't call hooks from this function (to get the accessToken)
        },

    }).then(function (response) {
        let tokensTemp = tokens;
        // store.dispatch({ type: 'USER_LOGOUT', payload: null })
        // store.dispatch({ type: 'ui/openingViewDM/toggle', payload: false });
        clearStoreWithoutConst();
        // console.log(tokensTemp);
        store.dispatch({ type: 'domain/tokensAgentViewMode/addTokens', payload: tokensTemp })

        // console.log(JSON.stringify(response.data));

        store.dispatch({ type: 'domain/tokens/addTokens', payload: { accessToken: response.data.accessToken, refreshToken: null } })
        // loadUser({ accessToken: response.data.accessToken });
        store.dispatch({ type: 'domain/user/addData', payload: response.data.user });
        loadProfile(response.data);
        loadWallet(response.data,30);
        
    })
        .catch(function (error) {
            // console.log(error);
        });

}