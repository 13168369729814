import "./style.css";

import store from "../../../../../store/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import Loader from "../../../../../components/common/Loader";
import NoMatch from "./components/NoMatch";
import ResultsList from "./components/ResultsList";

const selectNewPage = (state) => state.salesFeedNewPageData;
const selectPageIndex = (state) => state.salesFeedPageIndex;
const selectExchangeSearchDM = (state) => state.exchangeSearchDM;
const selectSalesFeedWaitingForData = (state) => state.salesFeedWaitingForData;

export default function ExSearchResults() {
  const newPage = useSelector(selectNewPage);
  const pageIndex = useSelector(selectPageIndex);
  const exchangeSearchDM = useSelector(selectExchangeSearchDM);
  const waitingForData = useSelector(selectSalesFeedWaitingForData);

  useEffect(() => {
    store.dispatch({
      type: "app/salesFeedNewPageData/add",
      payload: null,
    });
    return () => {
      // store.dispatch({
      //   type: "app/exchangeSearchResult/delete",
      //   payload: null,
      // });
      store.dispatch({
        type: "app/salesFeedNewPageData/add",
        payload: null,
      });
    };
  }, []);

  return (
    <div className="ExchangeSearch">
      {exchangeSearchDM && (
        <div className="ExchangeSearch__ResultsFeed">
          {
            <div className="ExchangeSearch__Results">
              <ResultsList />
              
              {newPage === "error" && (
                <p className="ExchangeSearch__UnableTxt headline">
                  Unable to view data at this time -<br /> server is updating
                </p>
              )}
              {waitingForData && (
                <div className="ExchangeSearch__Loader">
                  <Loader />
                </div>
              )}
            </div>
          }
        </div>
      )}
    </div>
  );
}
