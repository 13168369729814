import "./style.css";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AnimateKeyframes } from "react-simple-animate";

import store from "../../../../store/store";

import StockCreativeCard from "../../../../components/common/creative-cards/Influencer";

import CelebrateSmiley from "../../../../assets/images/common/smiley-celebrates.png";
import CelebrationPic from "../../../../assets/images/exchange/celebrate-background.png";

import Done from "../../../../assets/images/common/confirm.png";
import { useEffect, useState } from "react";
import fixFractionDigits from "../../../../helpers/fixFractionDigits";
import isVarNegative from "../../../../helpers/isVarNegative";

const selectPurchaseP2PData = (state) => state.buyP2PSaleData;
const selectPurchaseIPOData = (state) => state.buyIPOSaleData;
// const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectProfile = (state) => state.profile;

export default function SaleCompleted(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  // const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  // prevent enter to the page by insert the url in undesirable cases
  const profile = useSelector(selectProfile);
  const location = useLocation();
  const [processController, setProcessController] = useState(null);
  useEffect(() => {
    if (!isVarNegative(profile)) {
      navigate("/");
    } else {
      if (location.state?.process !== "BUY") {
        navigate("/exchange");
      } else {
        setProcessController(true);
      }
    }
  }, [location, profile]);
  //   let { Stock } = useParams();

  const purchaseP2PData = useSelector(selectPurchaseP2PData);
  const purchaseIPOData = useSelector(selectPurchaseIPOData);

  useEffect(() => {
    return () => {
      store.dispatch({ type: "app/buyIPOSaleData/add", payload: null });
      store.dispatch({ type: "app/buyP2PSaleData/add", payload: null });
    };
  }, []);

  // useEffect(() => {
  //     // action on update of movies
  //     // console.log(props.saleOffers);
  //     // console.log(Stock);
  //     if (props.saleOffers !== null){
  //         let temp = props.saleOffers.filter(obj => obj.id === +Stock)
  //         // console.log(temp);
  //         temp = {...temp, volume: +props.BuyIPOStockVolume}
  //         setStockDetails(temp[0]);
  //         // console.log(StockDetails);

  //     }
  //     if (props.BuyIPOStockVolume !== null){
  //         setVolume1(props.BuyIPOStockVolume);
  //         // console.log(props.BuyIPOStockVolume);
  //     }
  // }, [props.saleOffers]);
  // console.log(purchaseIPOData);
  const [cardData, setcardData] = useState(null);
  const [purchase, setpurchase] = useState(null);
  useEffect(() => {
    if (purchaseP2PData || purchaseIPOData) {
      let temp = purchaseP2PData ? purchaseP2PData : purchaseIPOData;
      // console.log(purchaseP2PData + " " + JSON.stringify(purchaseIPOData));
      setpurchase(temp);
      let tempCardData = {
        profilePic: temp.saleData.stock.influencer.user.profilePic,
        name: temp.saleData.stock.influencer.user.name,
        username: temp.saleData.stock.influencer.user.username,
        ranking: temp.saleData.stock.influencer.ranking,
        ringColour: temp.saleData.stock.influencer.ringColour,
        socialMedia: {
          tiktok: null,
          instagram: { follows: 120 },
          youtube: null,
        } /* profile.data.influencer.socialMedia */,
        niches: temp.saleData.stock.influencer.niches
          ? temp.saleData.stock.influencer.niches
          : [],
        city: temp.saleData.stock.influencer.user.city,
        currentPrice: temp.purchaseData.marketPrice,
        currency: temp.purchaseData.currency.symbol,
        lastChange: temp.saleData.stock.lastChange,
      };
      setcardData(tempCardData);
    }
  }, [purchaseIPOData, purchaseP2PData]);

  useEffect(() => {
    // console.log(purchase);
  }, [purchase]);

  return (
    purchase &&
    processController && (
      <AnimateKeyframes
        play={true}
        iterationCount={1}
        duration={0.42}
        direction="normal"
        keyframes={[
          "transform: translateY(100vw)",
          "transform: translateY(50vw)",
          "transform: translate(0, 0)",
        ]}
      >
        <div id="SaleComplete" className="Page">
          <img src={CelebrationPic} alt="" id="CelebrationPic" />
          <div id="BlurWrap">
            <div id="BlurDiv"></div>
          </div>
          <img src={CelebrateSmiley} alt="" id="CelebrateSmiley" />
          <div id="SaleMessage" className="headline">
            <h1>CONGRATS!</h1>
            <p id="YouOwnTxt">
              You now own{" "}
              <span>{purchase ? purchase.purchaseData.volume : ""}PS</span> of
            </p>
            <div id="WrapStockCC">
              <StockCreativeCard cardData={cardData} />
            </div>
            <p id="ForTxtPrice">
              For{" "}
              <span>
                {purchase
                  ? purchase.purchaseData.currency.symbol +
                    fixFractionDigits(purchase.purchaseData.price)
                  : ""}
              </span>
            </p>
            <p id="ManageTxt">Manage your stocks in your Wallet</p>
            <img
              src={Done}
              alt=""
              id="DoneComplete"
              onClick={() => {
                navigate("/wallet", { replace: true });
              }}
            />
          </div>
          <p>{"T’s & C’s Apply"}</p>
        </div>
      </AnimateKeyframes>
    )
  );
}
