import "./BrandUnitsRange.css";
export default function BrandUnitsRange(props) {
  let units = props.units;
  let elements = [];
  for (const [key, value] of Object.entries(units)) {
    elements.push(
      <div className="BrandUnitsRange__Block headline">
        <div>
          <p
            style={{
              margin:0,
              color: key === "current" ? "#34c100" : "#ffffff",
              fontSize: "8vw",
            }}
          >
            {value}
          </p>
          <p
            style={{
              margin:0,
              lineHeight:'4vw',
              textTransform: "capitalize",
              color: "#b0b0b0",
              fontSize: "3vw",
            }}
          >
            {key}
          </p>
        </div>
      </div>
    );
    if (key !== "goal") {
      elements.push(<p style={{margin:'2.5vw 3vw', fontSize: "7vw" }}>/</p>);
    }
  }

  return <div className="BrandUnitsRange">{elements}</div>;
}
