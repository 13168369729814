import React from "react";

import "./style.css";

import ArrowUp from "../../../../assets/images/common/arrow-up.png";
import ArrowDown from "../../../../assets/images/common/arrow-down.png";
import fixFractionDigits from "../../../../helpers/fixFractionDigits";

export default function AgentCreativeCard(props) {
  let isGrowth = null;
  if (props.cardData) {
    if (props.cardData?.lastChange.amount > 0) {
      isGrowth = true;
    } else {
      if (props.cardData?.lastChange.amount < 0) {
        isGrowth = false;
      }
    }
  }
  return (
    <div className="AgentCreativeCard headline">
      <div className="AgentCreativeCard__opacityBackground" />

      <div
        className={
          "AgentCreativeCard__Avatar " +
          (props.cardData ? props.cardData.ringColour + "RC" : "")
        }
        style={{
          backgroundImage: `url(${
            props.cardData
              ? props.cardData.profilePic
              : //  : props.AgencyExchangeSearch
                // ? props.AgencyExchangeSearch.profile_photo_path
                // : props.AgencyItemProfile.user.profilePic
                ""
          })`,
        }}
      />

      <p
        className={
          "AgentCreativeCard__Rating " +
          (props.cardData ? props.cardData.ringColour + "RN" : "")
        }
      >
        {props.cardData ? props.cardData.ranking : 1}
      </p>
      <div className="AgentCreativeCard__DataContainer">
        <p
          className={
            (props.cardData
              ? props.cardData.name
              : // : props.AgencyExchangeSearch
                // ? props.AgencyExchangeSearch.name
                // : props.AgencyItemProfile.user.name
                ""
            ).length <= 13
              ? "AgentCreativeCard__Name"
              : "AgentCreativeCard__NameLong"
          }
        >
          {props.cardData
            ? props.cardData.name
            : props.agencyUserProfile
            ? props.agencyUserProfile.user.name
            : props.AgencyExchangeSearch
            ? props.AgencyExchangeSearch.name
            : props.AgencyItemProfile.name}
        </p>
        <p className="AgentCreativeCard__StockPrice">
          {isGrowth !== null ? (
            isGrowth ? (
              <img src={ArrowUp} alt="" className="AgentCreativeCard__Arrow" />
            ) : (
              <img
                src={ArrowDown}
                alt=""
                className="AgentCreativeCard__Arrow"
              />
            )
          ) : null}{" "}
          {props.cardData ? props.cardData.currency : ""}
          {fixFractionDigits(
            props.cardData
              ? props.cardData.currentPrice
              : props.agencyUserProfile
              ? props.agencyUserProfile.influencer.current_stock_price
              : props.AgencyExchangeSearch
              ? props.AgencyExchangeSearch.current_stock_price
              : 0
          )}
          <span
            style={{
              color: "rgb(112, 112, 112)",
              fontSize: "3vw",
              fontWeight: "lighter",
            }}
          >
            {" "}
            /Unit
          </span>
        </p>
        <p className="AgentCreativeCard__AgencyTxt">Agency</p>
        <p className="AgentCreativeCard__Location">
          {props.cardData
            ? props.cardData.city
            : props.agencyUserProfile
            ? props.agencyUserProfile.city
            : props.AgencyExchangeSearch
            ? ""
            : ""}
        </p>
      </div>
    </div>
  );
}
