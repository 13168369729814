import "./style.css";

import store from "../../../store/store";

import SMIValidationPic from "../../../assets/images/common/emojy-cop.png";
import LogSignSmily from "../../../assets/images/common/smiley-1.png";

// import { Link } from 'react-router-dom';
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import isValidateEmail from "../../../helpers/isValidateEmail";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function MoneyCalcContactInfo() {
  store.dispatch({
    type: "appConfig/displayNavBar",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  const [SMIDetails, setSMIDetails] = useState({
    email: null,
    phone_number: null,
  });
  const handleEmailInput = (event) => {
    let temp;
    if (isValidateEmail(event.target.value)) {
      temp = { ...SMIDetails, email: event.target.value };
      setSMIDetails(temp);
    } else {
      temp = { ...SMIDetails, email: "" };
    }
  };
  const handlePhoneNumberInput = (event) => {
    let temp = { ...SMIDetails, phone_number: event.target.value };
    setSMIDetails(temp);
  };
  const handleAskForMeet = async () => {
    // console.log(SMIDetails);
    if (
      SMIDetails.email &&
      SMIDetails.email !== "" &&
      SMIDetails.phone_number &&
      SMIDetails.phone_number !== ""
    ) {
      navigate(store.getState().profile ? "/exchange" : "/", { replace: true });
    } else {
      if (!SMIDetails.email) {
        let temp = { ...SMIDetails, email: "" };
        setSMIDetails(temp);
      }
      if (!SMIDetails.phone_number) {
        let temp = { ...SMIDetails, phone_number: "" };
        setSMIDetails(temp);
      }
    }
  };
  return (
    <div
      id="SMIValidationDiv"
      className="Page headline"
      style={{ height: windowInnerSize.height }}
    >
      <img src={SMIValidationPic} alt="" id="SMIValidationPic" />
      <p id="SMIValidationTxt" className="unnamed-character-style-1">
        Validation
      </p>

      <p id="ConfirmTxtV">Confirm your contact information</p>
      <input
        type="email"
        placeholder=" Email"
        id="EmailInputV"
        className={"headline " + (SMIDetails.email === "" ? "input-wrong" : "")}
        onChange={handleEmailInput}
      />
      <input
        type="number"
        placeholder=" Phone Number"
        id="PhoneNInputV"
        className={
          "headline " + (SMIDetails.phone_number === "" ? "input-wrong" : "")
        }
        onChange={handlePhoneNumberInput}
      />

      <svg height="1.5vw" width="25%" id="LineSMIV">
        <line
          x1="0"
          x2="100%"
          y1="0"
          y2="0"
          stroke="#ffbf12"
          stroke-width="100%"
          stroke-linecap="butt"
        />
      </svg>

      <p id="WaitingVExp">
        Upon validation from our agents your
        <br /> account will be updated to a social
        <br /> media influencer.
        <br />
        <br /> Until then you’ll be able to operate as a <br />
        trader.
      </p>
      <img src={LogSignSmily} alt="" id="LogSignSmilyV" />

      <p id="DoneBtnValid" onClick={handleAskForMeet}>
        Done
      </p>
    </div>
  );
}
