import "./style.css";

import store from "../../../store/store";

// import { Link } from 'react-router-dom'
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import BackPage from "../../../assets/images/common/back-page.png";

const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function TermsAndCondition() {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: false, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();
  return (
    <div
      id="PrivatePolicyDiv"
      className="Page headline"
      style={{ minHeight: windowInnerSize.height }}
    >
      {/* <Link to="/Menu/Legal"> */}
      <img
        src={BackPage}
        alt=""
        id="BackPagePrP"
        onClick={() => {
          navigate(-1);
        }}
      />
      {/* </Link> */}
      <div
        id="PrivateScrollDiv"
        style={{
          top: windowInnerSize.height * 0.09,
          height: windowInnerSize.height * 0.85,
        }}
      >
        Contents Main Terms and Conditions Terms of Main Features Payment Policy
        Standard Contract between SMI &amp; Buyer Standard Contract between SMI
        &amp; Agency Standard Contract between User &amp; Purchasing Group
        Standard Contract between User &amp; User - Merging Networker Program
        PassionStocks Business Regulation Terms Privacy Policy Cookies Policy
        Background of the company Available Countries Terms of Crypto Elements
        Main Terms and Conditions Last Updated: October, 2021 These are the
        terms and conditions of use for https://www.passionstocks.com
        (&quot;Site&quot;). The Site is operated by Virtual Crypto SA (Pty) Ltd
        of 22 St Johns Rd, Sea Point, Cape Town, South Africa,
        8005(“PassionStocks”, ”PS”, “we”, “us”, or “our”) and is a social multi
        vendor marketplace for limited products and/or services for personal
        and/or commercial purposes. These Terms and Conditions of Use,
        our Marketplace FAQs (the “FAQS”), and all other requirements posted on
        our website/s, all of which are incorporated into these Terms and
        Conditions of Use by reference and as amended from time to time
        (collectively, “Terms”) describe the terms and conditions on which we
        provide our websites (the “Sites”), services, data, software,
        applications (including mobile applications) and tools (collectively
        “Services”) to you, whether as a guest or a registered user. If you
        reside outside of the South Africa or the United States of America
        certain country-specific terms may apply to you which are set out in
        section 28 (&quot;Country-Specific Terms&quot;). The Country-Specific
        Terms apply to the United Kingdom, European countries (including France,
        Italy, and Germany) and Japan. The Country-Specific Terms will override
        the main Terms in the event of any inconsistency between the two. In
        these Terms, “you” and “your” refer to (a) you, the individual accessing
        and/or using the Services, (b) any electronic agent accessing the Sites
        and/or using the Services on behalf of an individual or business entity,
        and (c) the business entity on whose behalf an individual or electronic
        agent is accessing the Sites and/or using the Services. Your use of the
        Sites and Services will be subject to these Terms and by using them you
        agree to be bound by them. These Terms create a legal contract between
        you and us. Please read them carefully. We will collect and process
        personal data in accordance with our Privacy Policy
        www.passionstocks.com/privacy. By using our Sites and Services, or by
        clicking to accept these Terms, you accept and agree to be bound and
        abide by these Terms in full. If you do not agree to these Terms, do not
        use our Sites or any portion of the Services. For all purposes, the
        English version of the Terms shall be the original, binding instrument
        and understanding of the parties. In the event of any conflict between
        the English version of the Terms and any translation into any other
        language, the English version shall prevail and control. These Terms
        contain provisions that govern how claims between you and us are
        resolved (see Section 14, Disputes with PassionStocks below). This
        includes an obligation to arbitrate certain claims through binding and
        final arbitration, unless you opt out of the arbitration when you sign
        up with us. Unless you opt out, you will only be permitted to bring
        claims against us and seek relief on an individual basis, not as a
        plaintiff or class member in any class or representative action or
        proceeding, and you will only be permitted to seek relief (including
        monetary, injunctive, and declaratory relief) on an individual basis. As
        set out in the Country-Specific Terms, this clause regarding arbitration
        will not apply to certain countries. This website that you are using or
        going to use is the second version of PassionStocks platform known as
        the MVP (“Minimum Valuable Product”). PassionStocks is the world’s first
        social media exchange, it is a brand new concept. This means, there are
        potential unforeseen risks that could affect the use of the platform. It
        is the nature of new technological advancements and the evolution of the
        society; that currently, there are no laws or legislations that
        explicitly exist or that directly govern the development of the
        PassionStocks platform. At this juncture ,we have been and currently are
        being advised by our law firm, CrankoKarp Attorneys, that we are
        complying with all current South African constitutional laws. We are
        always in the process of adapting and improving our platform to make the
        world a better place for our users. However, to do that, we do
        anticipate adjustments to the Ts and Cs, private policies and user
        information protection. To our best ability, our aim is to shrink the
        probability of major changes, but at the same time in order to develop
        the best product possible - to be innovative, we need to take
        responsible risks to make it accessible for everyone with just a
        smartphone to make a living for themselves and their communities by
        creating a new stream of income. As mentioned, we are still working on
        refining our policies and terms and conditions in a more structured
        fashion to protect all our users. In the interim, you can familiarise
        yourself with the terms and conditions by visiting the FAQ page on the
        website; www.passionstocks.com, on our social media platforms or by
        emailing Service@passionstocks.com Some of the clauses in those T’s
        &amp; C’s and/or the related documents to the T’s &amp; C’s might be
        irrelevant at all or would be delivered in one point of time, since we
        are constantly growing, improving and checking our system. Key Terms
        PassionStocks (with an upper case “S”) and/or PS shall mean the
        PassionStocks platform or/and the company Virtual Crypto SA (pty) Ltd
        which has been registered in the republic of South Africa. The
        Marketplace and/or PassionStocks Live Marketplace shall mean the place
        where passionstocks are offered for sale. Ask shall mean the asked price
        by a Seller. Bid shall mean the offered price by a Buyer. Buyer shall
        mean a User who buys any product/service on PassionStocks. Seller shall
        mean a user who offers any product and/or service on the PassionShop or
        offer a passionstock/s for a sale on the marketplace or. The Social
        Media Entity and/or SMI (Social Media Influencer) and/or the Issuer
        shall mean any User with a decent amount of engagement on social media
        platform/s who is verified and eligible to sell product/s and/or
        service/s via passionstock/s and issue passionstocks. Agency shall mean
        a user who manages an SMI/s. Purchasing Group shall mean a user who
        purchases product/s and/or service/s for commercial purposes on a behalf
        of a User/s for. User shall mean any entity who has registered to the
        platform. PC and/or P and/or Passioncoin shall mean a digital token
        (used just on the platform and not a cryptocurrency) which is equal to
        0.1$ (USD). Passionstock and/or PS ( with a lower case “S”) shall mean a
        digital card/ticket (not a cryptocurrency) which can be redeemed for a
        Passionproduct. Same as buying a ticket, coupon or voucher for a show,
        performance etc. PS can be purchased and redeemed for a product just on
        the PassionStocks platform. (On the Sites - “PS” means passionstock/s
        and not PassionStocks -the company/platform. Just in these terms PS
        means PassionStocks the company). Passionproduct shall mean a product
        and/or a service that is offered by a User on the PassionShop.
        Passionshop shall mean the place (on PassionStocks) where an SMI can
        display products/services for personal and/or commercial use. Influencer
        Product Offering (IPO) Passionstock/s shall mean passionstock/s that are
        sold directly from an SMI(the Issuer) to be redeemed for a
        Passionproduct in its own Passionshop Post-Influencer Product Offering
        (Post-IPO) Passionstock/s shall mean a passionstock/s that are sold by a
        User to be redeemed for a passionproduct in the Issuer PassionShop. 1.
        Changes to Terms and Policies. PassionStocks may in our discretion
        change these Terms (including the FAQs or any policy) at any time, but
        if we do, we will place a notice on our Site. We may also send you an
        email and/or notify you by some other means. Changes take effect on the
        date set out in the Terms. You should view these Terms often to stay
        informed of any changes that may affect you. YOUR CONTINUED USE OF THE
        SITE AND/OR SERVICES AFTER WE CHANGE THESE TERMS CONSTITUTES YOUR
        ACCEPTANCE OF THE CHANGES. IF YOU DO NOT AGREE TO ANY OF THE CHANGES,
        YOU MUST CANCEL YOUR ACCOUNT AND NOT USE ANY PORTION OF THE SERVICES.
        The version of these Terms posted on our Sites on each respective date
        you visit the Sites will be the Terms applicable to your access and use
        of the Services on that date. Our electronically or otherwise properly
        stored copies of these Terms and the Privacy Policy shall be deemed to
        be the true, complete, valid, and authentic copies of the version of the
        Terms and the Privacy Policy that were in force on each respective date
        you visited the Sites. We reserve the right to terminate these Terms, or
        to refuse, restrict, or discontinue access to or use of the Services (or
        any portions, components, or features thereof) to you or any other
        person or entity, if you are in breach of the Terms or for any reason or
        for no reason whatsoever, at any time, without notice or liability. 2.
        About PassionStocks Live Marketplace. The Site and Services are intended
        for use only by persons who are at least 13 years of age. By using the
        Site and Services you confirm that you meet this requirement and that,
        if you are under the age of 13, you have received permission from your
        parent or guardian before using the Site and Services. Some Services and
        Features might not be available to an under age Users by any applicable
        law that might be differed from jurisdiction to other. We might have to
        verify your identity and ask you for providing relevant documents with
        are required by any applicable law. Failure to provide the relevant
        documents might lead us to impose some ramifications on you and/or your
        account which might be required by any applicable law. PassionStocks
        allows third party sellers to list and sell their products and/or
        services via Passionstock in a live marketplace on the Site and
        Services. Sellers&#39; products and/or services will be made available
        for sale on all of our Sites and mobile applications. Buyers and Sellers
        must create an account to purchase or sell items on the PassionStocks
        platform (see Section 3). Buyers and sellers can delete or deactivate
        their account by following the options available on our FAQ page, as
        well as on Terms of Main Features as updated from time to time. The
        PassionStocks Live Marketplace is a place where people can comment,
        like, dislike, sell and buy limited service/s and/or product/s via
        passionstocks. Same as buying a ticket, coupon or voucher for a show,
        performance etc. A further description of the live marketplace process
        and the rules applicable to buyers and sellers is available on our  FAQ
         page as well as on Terms of Main Features as updated from time to time.
        While PassionStocks as the platform provider helps to facilitate
        transactions that are carried out on the PS platform, PS is neither the
        buyer nor the seller of the seller&#39;s products. PS provides a venue
        for sellers and buyers to negotiate and complete transactions.
        Accordingly, the contract formed at the completion of a sale for these
        third party products is solely between the buyer and seller. Although PS
        might provide historical pricing data to the buyer and seller, we do not
        set prices for the items and PS is not an auctioneer. PS acts as a
        commercial agent to conclude the sale on behalf of each buyer and seller
        involved in each transaction. Because Sellers set prices, they may be
        higher than retail value or other similar service provider of the
        products sold on our Site. Any item valuations displayed through the
        Services are estimates only. Since the industry of B2C (and B2B) of
        selling limited social products/services such as a story mention on
        Instagram is still embryonic in a sense, PassionStocks would help the
        Seller by offering a price to the issued Passionstocks. This price can
        be modified by the Seller (Currently, by contacting PassionStocks). More
        fully set on our FAQ page, as well as on Terms of Main Features as
        updated from time to time. PS does not guarantee that any item will
        sell. PS reserves the right to investigate complaints and violations of
        these Terms and, to the extent permitted by applicable law, may take any
        actions we deem appropriate, including suspending a buyer or seller
        account and charging your payment method for costs we incur as a result
        of the violation. Although the Services can be done anonymously and we
        generally do not share your information with other buyers and sellers,
        we may share your information with law enforcement and professional
        advisers under an obligation of confidentiality as part of an
        investigation related to any alleged violation of law or these Terms in
        accordance with our Privacy Policy , and we may respond to all inquiries
        initiated by law enforcement or other governmental agencies. The
        Services are accessible to certain international sellers and buyers. PS
        may provide access to certain features and tools to international
        sellers and buyers, such as estimated local currency conversion and
        integrated international shipping, customs and tax tools. Sellers and
        buyers are responsible for complying with all laws and regulations
        applicable to the international sale, purchase, and shipment of items.
        3. Accounts, Passwords, and Security. To view or browse the live
        marketplace, you must create an account using an email address and
        password or other account creation tools offered on the Services, such
        as a social media or google account. To offer items for sale (“Ask”) or
        offer to purchase items (“Bid”), you must create an account and have a
        valid credit card or other approved payment method on file with our
        third-party payment processors. You agree that we may charge your credit
        card or other payment method for amounts you owe as described in these
        terms, as well as any costs or losses arising from your violation of the
        Terms. When you register to create an account with us, you agree to
        provide accurate information about yourself and must not register under
        a false name or age or use an unauthorized payment method. If you use
        any Service, you are responsible for maintaining the confidentiality of
        your account and password and for restricting access to your computer,
        and you agree to accept responsibility for all activities that occur
        under your account or password. You agree to notify PassionStocks
        immediately upon learning of any unauthorized use of your account or
        password, or any other unauthorized access or breach of security.
        However, you may be held liable for losses incurred by PassionStocks or
        any other person or entity due to another person using your account or
        password. You may not use any other user’s account or password at any
        time without the express permission and consent of the holder of that
        account or password. You may not transfer or assign your account. 4.
        Fees, Promotional/Discount Codes and Taxes. Fees for the Services or
        Products, including applicable shipping fees (in case of a physical
        product which currently is not available), are described in our  FAQ and
        Terms of Main Features, as updated from time to time. PS may sometimes
        furnish an alphanumeric code and/or other ways that can be used for
        promotions or discounts for purchases on our sites. More fully set on
        the “Payment Policy”. (More fully set at the Terms of Main Features and
        the FAQ’s as updated from time to time). These promotional or discount
        codes may be sent via email to our registered users, presented on our
        Sites or circulated at events and through other means and, are subject
        to applicable promotional or discount terms. As a seller you are
        responsible and agree to collect, pay, report and remit any and all
        local, state, provincial, federal, or international taxes (including VAT
        and sales tax) that may be due by you with respect to your sales
        transaction, and as a buyer you agree to pay any and all local, state,
        federal, or international taxes (including sales taxes) that may be due
        by you with respect to your purchase transaction. You agree that
        PassionStocks is not responsible for reporting, collection or payment of
        any taxes on your behalf except for jurisdictions where PassionStocks is
        required as a marketplace facilitator. In accordance with the Privacy
        Policy and only to the extent necessary to complete a transaction, you
        agree to provide PassionStocks with all relevant tax information which
        PassionStocks may provide to any tax authority in connection with
        payments you receive from us and where applicable further authorize
        PassionStocks to release that information to such tax authority or other
        competent governmental body. Your account may be suspended for failure
        to provide PassionStocks with all relevant tax information to support
        filings to the appropriate tax authority. 5. General Authentication; KYC
        and AML PassionStocks might use a third party/parties (or its own
        method) to authenticate and verify any User in order to comply with all
        the applicable laws. As well as, PassionStocks might require some
        documentations in order to comply with the highest standard of KYC
        (“Know Your Client”) and AML (“Anti Money Laundering”). More fully set
        in the Terms of Main Features. 5.1. Authentication and Verification of a
        User PassionStocks might use a third party (or its method) to verify the
        authenticity of a User in order to try its best to avoid any type of
        fraud, misleading and transparency. More fully set on our  FAQ and Terms
        of Main Features, as updated from time to time. 6. Market Manipulation
        and Fraud. Since PassionStocks is a marketplace for consumptive
        product/services via tickets. PassionStocks is not an investment
        platform. You should not approach or use the PassionStocks marketplace
        as an investment platform in any form or shape. You should not approach
        the PassionStocks marketplace as a stock exchange or any other type of a
        financial market and/or investment platform/s. Since it’s a marketplace
        for consumptive products/services. For your sake, and for the sake of
        your money, You should not approach the PassionStocks marketplace as a
        place for a “quick buck” or an investment platform from variety of
        reasons. We have written below a few of them. All the reasons are
        derived from the main reason that PassionStocks Marketplace is not an
        Investment Platform: 1.Absence of Genuine Investors: A very small
        proportion of purchases and sales effected in a general stock exchange
        or investment platforms are by genuine investors. Speculators constitute
        a major portion of the market. Many of the transactions are carried out
        by speculators who plan to derive profits from short term fluctuations
        in prices of securities. 2.Fake shares: Frauds involving forged share
        certificates are quite common on a general stock exchange or investment
        platforms. Investors who buy shares unfortunately may get such fake
        certificates. They would not be able to trace the seller and their
        entire investment in such fake shares would be lost. 3. Insider trading:
        Insider trading is a common occurrence in many stock exchanges or
        investment platforms. Insiders who come to know privileged information
        use it either to buy or sell shares and make a quick profit at the
        expense of common investors. 4.Prevalence of Price Rigging: Companies
        which plan to issue securities artificially try to increase the share
        prices, to make their issue attractive as well as enable them to price
        their issue at a high premium. Promoters enter into a secret agreement
        with the brokers. Bottom line: could be occurred a plot of a fraud
        between SMI and brokers. 5. Thin trading: Though many companies (SMIs)
        are listed in stock exchange, many are not traded. Trading is confined
        to only around 25% of the shares listed on a stock exchange. Therefore
        the investors have restricted choice. This means that only big names
        like Justin Bieber will get investment. Smaller SMIs are left out.
        6.Excessive Speculation: There is excessive speculation in some shares
        which artificially results in increasing or decreasing the prices.
        7.Poor liquidity: The main goal of listing shares in a stock exchange is
        to provide liquidity. 8. Poor broker services: The service of brokers in
        a stock exchange has been seen to be poor. Most of the time, brokers are
        unregulated. If the brokers commit fraud, then investors will lose their
        money.) PS takes counterfeiting, fraud and market manipulation very
        seriously. If a seller provides a attempt to defraud any buyer or PS, PS
        reserves the right to do any or all of the following, in its sole
        discretion: (i) remove any or all of seller’s listings from the
        Services; (ii) cancel any or all of seller’s orders pending through the
        Services; (iii) withhold any payments due to seller; (iv) place limits
        on seller’s buying and selling privileges; (v) charge seller’s payment
        method for costs, expenses and fees incurred by PS as a result of
        seller’s action or inaction, including charging seller for the cost of
        replacement items, the value of coupons and gift certificates provided
        to the buyer, reprinting fees incurred by PS, rerouting charges imposed
        by carriers, and refunds to the buyer; (vi)provide the counterfeit items
        to law enforcement or destroy the counterfeit items, (vii) temporarily
        or permanently suspend seller’s account, and (viii) charge seller’s
        payment method a minimum of $15.00 USD or an amount up to 15% of the
        transaction amount, in PS&#39;s sole discretion, as a service fee.
        Seller hereby agrees that each of the foregoing remedies are reasonable
        and justified upon PS’s discovery of counterfeit items or fraudulent
        actions, even if those items or actions were not known by the Seller at
        the time to be counterfeit or fraudulent (e.g., negligent fraud). Seller
        understands and agrees that if we provide the counterfeit item to law
        enforcement, seller’s communications and any property claim with respect
        to that item must be solely with that law enforcement agency and not
        with us. We may also use fraud protection measures, including algorithms
        and automated systems to monitor for fraud, which might result in
        cancellation of listings or sales or account suspension. If this occurs
        in error please contact our customer service at
        Service@passionstocks.com. We may also require additional identification
        information from you if you engage in certain high- value transactions
        or high overall payment volumes through PassionStocks. PS may monitor
        the integrity of the PS marketplace and may take steps to protect the
        marketplace as determined by PS in its sole discretion (e.g., if PS
        believes a seller or buyer is engaging in market manipulation or fraud,
        including creating false or “dummy” accounts). If PS believes that a
        seller or buyer attempts to interfere with the free and fair operation
        of the PS marketplace, or creates artificial, false or misleading
        information, or information appearing to mislead with respect to pricing
        or demand for a product on the PS marketplace (all of the foregoing in
        PS’s sole discretion), then, without limiting any other rights of PS, PS
        reserves the right to do any or all of the following, in its sole
        discretion: (i) remove any or all of the user’s listings from the
        Services; (ii) cancel any or all of the user’s orders pending through
        the Services; (iii) withhold any payments due to the user; (iv) place
        limits on a user’s buying and selling privileges; (v) charge user’s
        credit card for costs, expenses and fees incurred by PS as a result of
        the user’s actions; (vi) notify law enforcement of the fraudulent
        activity; (vii) temporarily or permanently suspend the user’s account,
        and (viii) charge the user’s credit card an additional service fee of up
        to $1,000 to cover PS’s investigation fees and other related costs and
        expenses. 7. Seller Obligations. 7.1. Influencer Product Offering (IPO)
        Passionstock/s By listing a Passionstock or bunch of Passionstocks for
        sale (each posting, a “New Ask”), you are making a binding offer to sell
        that specific item (In a case the User has requested to redeem the
        Passionstock to a Story Mention on your Instagram - or any other
        specified product as appears on your PassionShop) to a buyer who
        purchases the item for the Ask price and to deliver the “Story Mention”
        in accordance with our  FAQ and the Terms of the Main Features as
        updated from time to time. When a Buyer accepts your offer by purchasing
        your item through our Services, you are contractually and legally bound
        to deliver that exact item for the specified price. SELLERS MUST post a
        story mention on their instagram account, within the specified timeframe
        provided in the sale confirmation email and must obtain a screenshot of
        the story mention to verify the delivery of the Story Mention. In a case
        the SMI has failed to deliver the Story Mention within the relevant
        timeframe, The buyer agrees to settle it by getting a Story Mention on
        the PassionStocks Instagram account and The Buyer would not have any
        claim the SMI. Once a Bid and Ask are matched, under no circumstances
        may a seller cancel the listing. Failure to fulfill your orders will
        result in additional charges to you, including a minimum of $15.00 USD,
        or an amount up to 15% of the transaction amount, in PS’s sole
        discretion. If a seller fails to deliver a Story Mention in accordance
        with these Terms, PS reserves the right to do any or all of the
        following, in its sole discretion: (i) charge seller’s payment method a
        minimum of $15.00 USD or an amount up to 15% of the transaction amount,
        in PS&#39;s sole discretion, as a service fee; (ii) remove any or all of
        seller’s listings from the Services; (iii) cancel any or all of seller’s
        orders pending through the Services; (iv) withhold any payments due to
        seller; (v) place limits on seller’s buying and selling privileges; (vi)
        charge seller’s payment method for costs, expenses and fees incurred by
        PS as a result of seller’s action or inaction, including charging seller
        for the cost of a replacement, coupons and gift certificates provided to
        the buyer, reprinting fees incurred by PS, rerouting charges imposed by
        carriers, and refunds to the buyer; and (vii) temporarily or permanently
        suspend seller’s account. If PS or a buyer reasonably determines that
        your item does not conform to the description (including, without
        limitation, meeting the applicable Condition Standard), then, in PS’s
        sole discretion, we will charge your payment method a minimum of $15.00
        USD or an amount up to 15% of the transaction amount, as a service fee,
        plus additional amounts or, as determined in PS’s discretion, charging
        you for the cost of a replacement, coupons and gift certificates
        provided to the buyer, reprinting fees incurred by PS, rerouting charges
        imposed by carriers, and refunds to the buyer. PS will pay you within a
        reasonable amount of time following completion of the sale, More fully
        set at our Payment Policy. To get paid more quickly, see the fees
        applicable to sales transactions and other commission and fees that
        apply to a transaction, please refer to the relevant section at our
        Payment policy . PassionStocks is acting solely as an intermediary
        between the sellers and the buyers. We do not assume any liability
        regarding the proper performance of the purchase agreement you may
        conclude with a buyer through the platform, except for PS’s specific
        Services described in Section 2 of these Terms and Conditions.
        Professional sellers must comply with all laws and regulations
        applicable to the sale of their products on PassionStocks’s live
        marketplace. (The Social Media Entity MUST post a story mention on their
        instagram account, within the specified timeframe provided in the sale
        confirmation email and must obtain a screenshot of the story mention to
        verify the delivery of the Story Mention.) 7.2. Post-Influencer Product
        Offering (Post-IPO) Passionstock/s By listing a Passionstock/s for sale
        (each posting, a “New Ask”), you are making a binding offer to sell to a
        buyer who purchases the item for the Ask price in accordance with our 
        FAQ and the Terms of the Main Features as updated from time to time.
        When a buyer accepts your offer by purchasing your item through our
        Services, you are contractually and legally bound to finalize the
        transaction for the specified price. Once a Bid and Ask are matched,
        under no circumstances may a seller cancel the listing. You may act with
        a good faith on the market. You may not: - Sell a Passionstock/s that
        you know the Issuer has deleted its account, dead, can’t deliver a
        Passionproduct, unless if you have communicated that to the buyer, the
        buyer is aware of the above mentioned or those Passionstocks are sold in
        a memory of the Issuer or any other justifiable ground that is
        reasonable whether from a social, business or consumptive point of view
        . Plot to manipulate a Passionstock price intentionally. Do any action
        that can cause a lose of money to a buyer by misleading and plotting
        with bad faith. Acting in a way that involves illegal acts i.e. fraud or
        any other illegal actions by law will result in additional charges to
        you, including a minimum of $15.00 USD, or an amount up to 15% of the
        transaction amount, in PS’s sole discretion. If a seller fails to
        perform his duties or/and has been involved in a lawsuit w in accordance
        with these Terms, PS reserves the right to do any or all of the
        following, in its sole discretion: (i) charge seller’s payment method a
        minimum of $15.00 USD or an amount up to 15% of the transaction amount,
        in PS&#39;s sole discretion, as a service fee; (ii) remove any or all of
        seller’s listings from the Services; (iii) cancel any or all of seller’s
        orders pending through the Services; (iv) withhold any payments due to
        seller; (v) place limits on seller’s buying and selling privileges; (vi)
        charge seller’s payment method for costs, expenses and fees incurred by
        PS as a result of seller’s action or inaction, including charging seller
        for the cost of a replacement, coupons and gift certificates provided to
        the buyer, reprinting fees incurred by PS, rerouting charges imposed by
        carriers, and refunds to the buyer; and (vii) temporarily or permanently
        suspend seller’s account. If PS or a buyer reasonably determines that
        your item does not conform to the description (including, without
        limitation, meeting the applicable Condition Standard), then, in PS’s
        sole discretion, we will charge your payment method a minimum of $15.00
        USD or an amount up to 15% of the transaction amount, as a service fee,
        plus additional amounts or, as determined in PS’s discretion, charging
        you for the cost of a replacement, coupons and gift certificates
        provided to the buyer, reprinting fees incurred by PS, rerouting charges
        imposed by carriers, and refunds to the buyer. PS will pay you within a
        reasonable amount of time following completion of the sale, More fully
        set at our Payment Policy. To get paid more quickly, see the fees
        applicable to sales transactions and other commission and fees that
        apply to a transaction, please refer to the relevant section at our
        Payment policy . PassionStocks is acting solely as an intermediary
        between the sellers and the buyers. We do not assume any liability
        regarding the proper performance of the purchase agreement you may
        conclude with a buyer through the platform, except for PS’s specific
        Services described in Section 2 of these Terms and Conditions.
        Professional sellers must comply with all laws and regulations
        applicable to the sale of their products on PassionStocks’s live
        marketplace. In a case the SMI has failed to deliver the Story Mention
        within the relevant timeframe, The buyer agrees to settle it by getting
        a Story Mention on the PassionStocks Instagram account and The Buyer
        would not have any claim the SMI. 8. Buyer Obligations. A buyer may
        place a Bid on an item for sale through the Services. When a Bid matches
        an Ask, the buyer is obligated to pay for that item. WHEN YOU PLACE A
        BID, YOU ARE COMMITTING TO PURCHASE THE ITEM AS SOON AS YOUR BID MATCHES
        A SELLER’S ASK PRICE AND YOU ACKNOWLEDGE THAT PAYMENT OF THE ASK PRICE
        WILL BE TAKEN FROM YOUR PAYMENT METHOD. To be an eligible buyer you must
        have a billing address and shipping address within one of the countries
        that PS supports. You can see a full list of these countries
        at Available Countries, which may be updated from time to time. You are
        responsible for paying all duties, taxes and any other related fees; you
        will be responsible for compliance with all tax requirements as required
        by the jurisdiction that you are subject to; and, when placing your
        order, you authorize us to appoint an agent (if applicable and/or
        needed) to file the tax declarations on your behalf. In general, we
        charge your payment method immediately upon purchase and, when the
        transaction is complete, we pay the seller. More fully set at our
        Payment Policy. You might be required to pay certain fees as described
        when placing your Bid, as updated from time to time. If your payment
        fails or is rejected for any reason (e.g., purchase exceeds credit card
        limit, card is cancelled, chargeback is requested), PS reserves the
        right to do any or all of the following, in its sole discretion: (i)
        charge buyer’s payment method a minimum of $15.00 USD or an amount up to
        15% of the transaction amount, in PS&#39;s sole discretion, as a service
        fee; (ii) remove any or all of buyer’s Bids from the Services; (iii)
        cancel any or all of buyer’s orders pending through the Services; (iv)
        withhold refunds or payments due to buyer; (v) place limits on buyer’s
        buying and selling privileges; (vi) charge buyer’s payment method for
        costs, expenses and fees incurred by PS as a result of buyer’s action or
        inaction; and (vii) temporarily or permanently suspend buyer’s account.
        ALL SALES OF PASSIONSTOCKS ARE FINAL ONCE THE TICKET IS AUTHENTICATED
        AND ON THE MARKETPLACE, AND NO REFUND REQUESTS WILL BE HONORED
        REGARDLESS OF THE CURRENT VALUE OF THE PASSIONSTOCK. If you experience a
        problem with your purchase, please contact us at
        https://help.PassionStocks.com. In a case the SMI has failed to deliver
        the Story Mention within the relevant timeframe, The buyer agrees to
        settle it by getting a Story Mention on the PassionStocks Instagram
        account and The Buyer would not have any claim the SMI. 9. Limitations
        and Restrictions. PassionStocks does not provide any guarantee that your
        Passionstocks will sell and will not provide any compensation for items
        that do not sell through our Services. PassionStocks also does not
        guarantee how long it will take for a listing to appear in the Services
        after it is posted and is not responsible for unsold items resulting
        from any listing delays. PassionStocks conducts periodic scheduled
        maintenance as well as unscheduled emergency maintenance from time to
        time. During maintenance periods the Services (or portions thereof) may
        be temporarily unavailable. You must not and will not do any of the
        following: use our Services if you are not able to form legally binding
        contracts (for example, if you are under 18 years old), or are
        temporarily or indefinitely suspended from using our Services; upload or
        transmit any message, information, data, text, software or images, or
        other content that is unlawful, immoral, harmful, threatening, abusive,
        harassing, tortious, defamatory, vulgar, obscene, libellous, or
        otherwise objectionable, or that may invade another&#39;s right of
        privacy or publicity; engage in abusive treatment of other users of the
        Services or any PassionStocks employee; create a false identity for the
        purpose of misleading others or impersonate any person or entity,
        including, without limitation, any PassionStocks representative, or
        falsely state or otherwise misrepresent your affiliation with a person
        or entity; upload or transmit any material that you do not have a right
        to reproduce, display or transmit under any law or under contractual or
        fiduciary relationships (such as nondisclosure agreements); delete any
        author attributions, legal notices or proprietary designations or labels
        that you upload to any communication feature; use any of the Services’
        communication features in a manner that adversely affects the
        availability of its resources to other users (e.g., excessive shouting,
        use of all caps, or flooding continuous posting of repetitive text);
        upload or transmit any unsolicited advertising, promotional materials,
        “junk mail”, “spam”, “chain letters”, “pyramid schemes” or any other
        form of solicitation, commercial or otherwise; violate any applicable
        local, state, national or international law; upload or transmit any
        material that infringes any patent, trademark, service mark, trade
        secret, copyright, or other proprietary rights of any party; delete or
        revise any material posted by any other person or entity; register,
        subscribe, attempt to register, attempt to subscribe, unsubscribe, or
        attempt to unsubscribe, any party for any Services if you are not
        expressly authorized by such party to do so; harvest or otherwise
        collect information about others, including e-mail addresses; use
        PassionStocks’s trademarks without our written permission; except to the
        extent permitted by applicable law (including, in the United States, the
        Copyright Act 1968 (Cth)), copy, reproduce, reverse engineer, modify,
        decompile, disassemble or otherwise attempt to derive source code from,
        create derivative works from, distribute, or publicly display any
        content (except for your information) or software from our Services
        without the prior express written permission of PassionStocks and the
        appropriate third party, as applicable; Commercialise all or any part of
        the PassionStocks Services; use any modified versions of Services, for
        any reason whatsoever, without the express written consent of
        PassionStocks; upload or distribute files that contain viruses, Trojan
        horses, worms, time bombs, cancel-bots, corrupted files, or any other
        similar software or programs that may damage the operation of
        another&#39;s computer or property of another; use any robot, spider,
        scraper, or other automated or manual means to access our Services, or
        copy any information thereon, for any purpose without our express
        written permission; take any action that imposes or may impose (to be
        determined in our sole discretion) an unreasonable or disproportionately
        large load on our infrastructure or otherwise interferes with the
        functioning of the Services; attempt to gain any unauthorized access to
        the Sites or the Services, including computer systems, software, or
        networks; probe, scan, test the vulnerability of or breach the
        authentication measures of, the Sites or any related networks or
        systems; bypass our robot exclusion headers, robots.txt rules or any
        other measures we may use to prevent or restrict access to our Services;
        or do anything else that we determine, in our sole discretion, misuses
        the Services or otherwise negatively impacts our marketplace.
        PassionStocks reserves the right to take whatever lawful actions it may
        deem appropriate in response to actual or suspected violations of these
        Terms, including, without limitation, the suspension or termination of
        your access and/or account. PassionStocks may cooperate with legal
        authorities and/or third parties in the investigation of any suspected
        or alleged crime or civil wrong. Except as may be expressly limited by
        applicable law, PassionStocks reserves the right at all times to
        disclose any information as PassionStocks deems necessary to satisfy any
        applicable law, regulation, legal process or governmental request, or to
        edit, refuse to post or to remove any information or materials, in whole
        or in part, in PassionStocks’s sole discretion. You also agree to
        reimburse PassionStocks for any damage, loss, cost or expense
        PassionStocks incurs (including fees or costs of attorneys, accountants,
        professional advisors, and other experts incurred in connection with the
        defence or settlement of the foregoing) because of your use of the
        Services for any unlawful or prohibited purpose. 10. Intellectual
        Property. You acknowledge and agree that (i) our patents, copyrights,
        trademarks, service marks, trade secrets and other intellectual property
        (collectively, “Intellectual Property”) are our sole property, and (ii)
        nothing in these Terms shall confer in you any right of ownership or
        license rights in our Intellectual Property. In addition, you shall not
        now or in the future contest the validity of our Intellectual Property.
        Without limiting the previous sentence, the Services, including without
        limitation all text, graphics, logos, buttons, icons, images, audio
        clips, and computer programs, are the property of PassionStocks or its
        licensors or suppliers, and are protected by the republic of South
        Africa and U.S. and international intellectual property laws (including
        copyright and trademark laws). The compilation (meaning the collection,
        arrangement, and assembly) of all content associated with the Services
        is the exclusive property of PassionStocks and protected by the republic
        of South Africa and U.S. and international intellectual property
        (including copyright) law. Any unauthorized reproduction, modification,
        distribution, transmission, republication, display, or performance of
        the software or the content through our Services is strictly prohibited.
        PassionStocks grants you a personal, non-exclusive, non-transferable,
        revocable, limited license to use the Sites and the Services solely for
        your personal or internal use and subject to the condition that you do
        not (and do not allow any third party to) copy, modify, create a
        derivative work from, reverse engineer, reverse assemble, or otherwise
        attempt to discover any source code, sell, assign, sublicense, grant a
        security interest in, or otherwise transfer any right in or access to
        the Sites, the Services, or any other content available via the Sites or
        the Services. All rights not expressly granted to you in these Terms are
        reserved and retained by PassionStocks. 11. User Content. If you provide
        us with any text, graphics, photos or other materials or content (“User
        Content”), you grant us a non-exclusive, perpetual, transferable,
        irrevocable, assignable, royalty-free, fully paid up, sub-licensable
        (through multiple tiers) right and license to use, reproduce, distribute
        (through multiple tiers), create derivative works from, publicly
        perform, display, store, digitally perform, make or deliver digital
        audio transmissions, encode, transcode, publish (online, through the
        Services, on any other website(s), as well as through mobile channels,
        and offline, in print, radio, television or elsewhere), make, have made,
        sell, offer for sale, import and commercialize User Content, or any
        portion thereof, in any manner and context, in any way, in any and all
        media now known or hereinafter developed and on any device(s), whether
        or not portable, wired, or wireless, without limitation, throughout the
        universe. You further agree to waive your right to be identified as the
        author of User Content and your right to object to derogatory use or
        treatment of such User Content. If PassionStocks does decide, in its
        sole discretion, to attribute User Content to you, you hereby grant
        PassionStocks the right to use your name (and/or any user name), image,
        likeness, and/or photo with respect to such attribution, and you waive
        any claims (including, without limitation, any privacy or publicity
        rights claims) with respect to such use of your name, image, likeness
        and/or photos. You hereby agree to waive all claims of moral rights
        associated with being the author of User Content, and to consent to
        PassionStocks doing all acts that would otherwise constitute an
        infringement of your moral rights, as well as waive the right to inspect
        or approve the finished video, photograph, sound track, web site,
        advertising copy or printed matter that may be used in conjunction
        therewith or to the eventual use in any media that it might be applied.
        The rights you grant above are irrevocable during the entire period of
        the protection of your intellectual property rights associated with such
        User Content. You agree that PassionStocks (i) is not under any
        obligation, whether of confidentiality, attribution or otherwise, and
        will not be liable for any use or disclosure of any User Content; (ii)
        is under no obligation to post, display or otherwise use any User
        Content; (iii) has no obligation whatsoever to provide you any
        compensation for the use or display of your User Content or otherwise
        from the exercise of the rights granted under this Section, even if
        PassionStocks receives compensation therefrom; (iv) has the right (but
        not the obligation) to monitor the User Content that you or third
        parties post, and to alter or remove any such User Content; and (v) has
        the right to disclose User Content and the circumstances surrounding its
        transmission to any third party in order to operate the Services, to
        protect ourselves and third parties, and to comply with legal
        obligations or governmental requests. You are prohibited from posting
        any libelous, obscene, defamatory, pornographic, or other materials that
        would violate any laws. You shall be solely responsible for your own
        User Content and the consequences of posting or publishing it. You
        represent and warrant that any information you provide to us, to other
        users, or to visitors, including but not limited to User Content, (a) is
        not false, inaccurate, misleading, obscene or defamatory; (b) is not
        fraudulent; (c) doesn’t involve the sale of counterfeit or stolen items;
        (d) doesn’t infringe any third party’s copyright, patent, trademark,
        trade secret, rights of publicity or privacy, or other right; (e) is
        yours or you have the necessary licenses, rights, consents, and
        permissions to such information and to grant the rights and licenses to
        PassionStocks under all patent, trademark, trade secret, copyright or
        other proprietary or intellectual property rights in and to any and all
        such information in the manner contemplated in these Terms; (f) doesn’t
        violate any law, statute, ordinance or regulation, including without
        limitation those governing consumer protection, unfair competition,
        anti-discrimination or false advertising; and, (g) doesn’t contain any
        viruses or any programming that is intended to damage, interfere with,
        intercept or expropriate any system, data or personal information; and
        (h) is not intended to circumvent or violate the letter and spirit of
        these Terms and the lawful functioning of PassionStocks’s marketplace.
        You further represent and warrant that you have the written consent,
        release, and/or permission of each and every person identified in any
        information you provide, including but not limited to User Content, to
        use the name or likeness of such person or, if such persons are minors,
        the written consent, release, and/or permission of such minor’s parent
        or legal guardian. By submitting ideas, suggestions, documents, and/or
        proposals (“Contributions”) to PassionStocks, you acknowledge and agree
        that: (a) your Contributions don’t contain confidential or proprietary
        information; (b) PS isn’t under any obligation of confidentiality,
        express or implied, with respect to the Contributions; (c) PS shall be
        entitled to use or disclose (or choose not to use or disclose) such
        Contributions for any purpose, in any way, in any media now known or
        later developed and throughout the universe; (d) PS may have something
        similar to the Contributions already under consideration or in
        development; (e) your Contributions automatically become the property of
        PS without any obligation of PS to you; and (f) PS is free to use any
        ideas, concepts, or techniques that you send PS for any purpose,
        including but not limited to, developing and marketing products that
        incorporate such ideas, concepts or techniques; and (g) you aren’t
        entitled to any compensation or reimbursement of any kind from PS under
        any circumstances. 12. Indemnity. You shall indemnify, defend and hold
        PS and our affiliates and their respective officers, directors, agents
        and employees harmless from and against all claims, demands, suits or
        other proceedings, and resulting loss, damage, liability, costs,
        interest and expenses (including reasonable attorneys’ fees) brought by
        any third party or governmental claim or demand that involves, relates
        to or concerns (a) your listing or sale of any counterfeit, stolen, or
        illegal merchandise or goods, (b) your breach of any provision of the
        Terms, (c) your improper use of the Services, (d) your violation of any
        law or the rights of a third party, or (e) federal, state, county, city,
        or other tax obligation or amounts due or owing under any tax
        regulation, law, order or decree. We reserve, and you grant to us, the
        right to assume exclusive defense and control of any matter subject to
        indemnification by you hereunder. All rights and duties of
        indemnification that are set forth herein shall survive termination of
        these Terms. 13. Disclaimer of Warranties; Limitations of Liability. If
        you are based outside of the United States, please refer to the
        Country-Specific Terms below. You covenant not to sue PassionStocks, and
        agree that you will not hold PassionStocks responsible, for other users’
        content, actions, or inactions. PassionStocks is a marketplace for
        consumptive products and/or services. You acknowledge that you are
        buying items from a third party, not PassionStocks. While we may help as
        your commercial agent facilitate the resolution of disputes, we have no
        control over and do not guarantee the accuracy, quality, safety, truth,
        accuracy or legality of User Content, listings, and/or items listed or
        sold. If you have a dispute with one or more users, as a buyer or
        seller, you release and covenant not to sue PS, its affiliated
        companies, and our and their respective officers, directors, agents,
        joint venturers, employees, legal representatives, and suppliers from
        any and all claims, demands and damages (actual and consequential) of
        every kind and nature, known and unknown, suspected and unsuspected,
        disclosed and undisclosed, arising out of or in any way connected with
        such dispute. In entering into this release you expressly waive any
        protections (whether statutory or otherwise – e.g., California Civil
        Code § 1542) that would otherwise limit the coverage of this release to
        include only those claims, which you may know or suspect to exist in
        your favor at the time of agreeing to this release. PASSIONSTOCKS AND/OR
        OUR THIRD PARTY SUPPLIERS OR LICENSORS DO NOT REPRESENT, WARRANT OR
        COVENANT THAT THE SYSTEM AND THE SERVICES ARE OR WILL BE ACCURATE,
        CURRENT, COMPLETE, FREE OF TECHNICAL AND TYPOGRAPHICAL ERRORS, SECURE,
        RELIABLE, OR APPROPRIATE FOR ANY PARTICULAR USE TO WHICH YOU OR ANY
        THIRD PARTY MAY CHOOSE TO PUT THEM, THAT THEY ARE OR WILL BE AVAILABLE
        ON AN UNINTERRUPTED AND ERROR-FREE BASIS, THAT DEFECTS WILL BE
        CORRECTED, OR THAT THE SYSTEM AND SERVICES ARE FREE OF VIRUSES OR OTHER
        DISABLING DEVICES OR HARMFUL COMPONENTS. PASSIONSTOCKS PERIODICALLY
        AMENDS, CHANGES, ADDS, DELETES, UPDATES, OR ALTERS THE SYSTEM AND THE
        SERVICES WITHOUT NOTICE. FURTHER, PASSIONSTOCKS ASSUMES NO LIABILITY OR
        RESPONSIBILITY FOR ANY ERRORS OR OMISSIONS IN THE CONTENT OF THE SYSTEM
        OR THE SERVICES. PASSIONSTOCKS SPECIFICALLY DISCLAIMS ANY DUTY TO UPDATE
        THE CONTENT, OR ANY OTHER INFORMATION ON THE SYSTEM OR THE SERVICES. YOU
        AGREE THAT YOU ARE MAKING USE OF OUR SERVICES AT YOUR OWN RISK, AND THAT
        THE SERVICES ARE BEING PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
        PASSIONSTOCKS (INCLUSIVE OF ANY OF ITS SERVICE PROVIDERS AND LICENSORS)
        MAKES NO WARRANTY OR GUARANTIES OF ANY KIND WITH RESPECT TO OUR
        SERVICES, ANY ITEMS, ANY USER CONTENT, OR THAT SELLERS OR BUYERS WILL
        PERFORM AS PROMISED. ACCORDINGLY, TO THE EXTENT PERMITTED BY APPLICABLE
        LAW, WE EXCLUDE ALL EXPRESS OR IMPLIED WARRANTIES, TERMS AND CONDITIONS
        INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT OF THIRD PARTY
        RIGHTS, AND ANY WARRANTIES IMPLIED BY A COURSE OF PERFORMANCE, COURSE OF
        DEALING, OR USAGE OF TRADE. IN ADDITION, TO THE EXTENT PERMITTED BY
        APPLICABLE LAW, PASSIONSTOCKS (INCLUDING OUR AFFILIATED COMPANIES, AND
        OUR AND THEIR RESPECTIVE OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS,
        EMPLOYEES, LEGAL REPRESENTATIVES, AND SUPPLIERS) ARE NOT LIABLE, AND YOU
        AGREE NOT TO HOLD PASSIONSTOCKS RESPONSIBLE, FOR ANY DAMAGES OR LOSSES
        (INCLUDING, BUT NOT LIMITED TO, LOSS OF MONEY, GOODWILL OR REPUTATION,
        PROFITS, OR OTHER INTANGIBLE LOSSES OR ANY SPECIAL, INDIRECT, OR
        CONSEQUENTIAL DAMAGES) RESULTING DIRECTLY OR INDIRECTLY FROM (A) THE
        USER CONTENT YOU PROVIDE USING THE SERVICES, OR CONTENT OF THIRD PARTIES
        (B) YOUR ACCESS TO, USE OF, INABILITY TO ACCESS OR USE THE SERVICES, OR
        RELIANCE ON THE SERVICES; (C) PRICING, SHIPPING, FORMAT, OR OTHER
        GUIDANCE PROVIDED BY PASSIONSTOCKS, (D) DELAYS OR DISRUPTIONS IN OUR
        SERVICES, (E) VIRUSES OR OTHER MALICIOUS SOFTWARE OBTAINED BY ACCESSING
        OUR SERVICES OR THOSE OF ANY SITE, SERVICES, OR TOOLS LINKED TO OUR
        SERVICES; (F) GLITCHES, BUGS, ERRORS, OR INACCURACIES OF ANY KIND IN OUR
        SERVICES, (G) DAMAGE TO YOUR HARDWARE DEVICE FROM THE USE OF ANY
        SERVICE, (H) THE CONTENT, ACTIONS, OR INACTIONS OF THIRD PARTIES,
        INCLUDING ITEMS LISTED USING OUR SERVICES OR THE DESTRUCTION OF
        ALLEGEDLY FAKE ITEMS, (I) A SUSPENSION OR OTHER ACTION TAKEN WITH
        RESPECT TO YOUR ACCOUNT, (J) THE DURATION OR MANNER IN WHICH ITEMS YOU
        LIST APPEAR ON THE SERVICES, OR (K) YOUR NEED TO MODIFY PRACTICES,
        CONTENT OR BEHAVIOR, OR YOUR LOSS OF ABILITY TO DO BUSINESS, AS A RESULT
        OF CHANGES TO THESE TERMS OR OUR POLICIES. UNDER NO CIRCUMSTANCES SHALL
        PASSIONSTOCKS, OR ITS AFFILIATED COMPANIES, AND OUR AND THEIR RESPECTIVE
        OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES, LEGAL
        REPRESENTATIVES, AND SUPPLIERS, BE LIABLE TO YOU OR ANY OTHER THIRD
        PARTY FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, PUNITIVE, SPECIAL, OR
        SIMILAR DAMAGES OR COSTS (INCLUDING, BUT NOT LIMITED TO, LOST PROFITS OR
        DATA, LOSS OF GOODWILL, LOSS OF OR DAMAGE TO PROPERTY, LOSS OF USE,
        BUSINESS INTERRUPTION, AND CLAIMS OF THIRD PARTIES) ARISING OUT OF OR IN
        CONNECTION WITH THESE TERMS, THE SERVICES, OR ANYTHING DESCRIBED IN THE
        FOREGOING CLAUSES (A) THROUGH (K) OF THIS PARAGRAPH, OR ANY OTHER CAUSE
        BEYOND THE CONTROL PASSIONSTOCKS, EVEN IF PASSIONSTOCKS WAS ADVISED,
        KNEW, OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES OR COSTS.
        SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF WARRANTIES OR
        EXCLUSION OF DAMAGES. IN A JURISDICTION THAT DOES NOT ALLOW THE
        EXCLUSION OR LIMITATION OF LIABILITY FOR CERTAIN DAMAGES, THE AGGREGATE
        LIABILITY OF PASSIONSTOCKS, OR ITS AFFILIATED COMPANIES, AND OUR AND
        THEIR RESPECTIVE OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS,
        EMPLOYEES, LEGAL REPRESENTATIVES, AND SUPPLIERS, SHALL BE LIMITED IN
        ACCORDANCE WITH THESE TERMS OF USE TO THE FULLEST EXTENT PERMITTED BY
        LAW. WITHOUT LIMITING ANY OF THE FOREGOING, IF PASSIONSTOCKS, ITS
        CONTRACTORS, SUPPLIERS, CONTENT PROVIDERS, OR ANY OF THE OFFICERS,
        DIRECTORS, EMPLOYEES, REPRESENTATIVES, OR AGENTS OF ANY OF THE
        FOREGOING, IS FOUND LIABLE TO YOU OR TO ANY THIRD PARTY AS A RESULT OF
        ANY CLAIMS OR OTHER MATTERS ARISING UNDER OR IN CONNECTION WITH THESE
        TERMS OR THE SERVICES, PASSIONSTOCKS AND SUCH PARTIES’ CUMULATIVE,
        AGGREGATE, AND MAXIMUM LIABILITY FOR ALL SUCH CLAIMS AND OTHER MATTERS
        IN ANY CALENDAR YEAR SHALL NOT EXCEED (A) THE AMOUNT OF FEES IN DISPUTE
        NOT TO EXCEED THE TOTAL FEES WHICH YOU PAID TO US IN THE 12 MONTHS PRIOR
        TO THE ACTION GIVING RISE TO THE LIABILITY, OR (B) USD $100. 14.
        Disputes with PassionStocks. If you are based outside of the United
        States or the Republic of South Africa, please refer to the
        Country-Specific Terms below. You and PassionStocks agree that any claim
        or dispute at law or equity that has arisen or may arise between us
        relating in any way to or arising out of the Terms or your use of, or
        access to, the Services, will be resolved in accordance with the
        provisions set forth in this Section 14. PLEASE READ THIS SECTION
        CAREFULLY. IT AFFECTS YOUR RIGHTS AND WILL HAVE A SUBSTANTIAL IMPACT ON
        HOW CLAIMS YOU AND PASSIONSTOCKS HAVE AGAINST EACH OTHER ARE RESOLVED.
        DISPUTES 14.1 Save as otherwise provided in this Agreement, should any
        dispute of whatever nature arise out of or pursuant to this Agreement
        including without limitation its interpretation or its termination or
        cancellation for any reason, the parties to such dispute shall meet as
        soon as possible after such dispute arises in order to attempt to
        negotiate an amicable settlement of such dispute. Such meeting shall
        take place at such time and such place as is agreed upon between such
        parties or, (failing such agreement within fourteen days after all
        parties to the dispute become aware of such dispute), at the Parties
        domicilium (the Company’s - as mentioned in the beginning of this
        Agreement. The user’s will be provided if relevant). Such negotiations
        shall be conducted in good faith. 14.2 If such parties are unable to
        resolve the dispute in question within thirty days after the
        commencement of the negotiations referred to in this clause then the
        dispute shall, unless the parties to the dispute otherwise agree in
        writing, be referred for determination in accordance with the remaining
        provisions of this clause 12. 14.3 The arbitrator shall be MICHAEL
        STANFRED KARP or the senior partner of the firm CRANKO KARP &amp;
        ASSOCIATES INC. (the “Attorneys”) 14.4 The arbitration shall be held at
        the offices of the Attorneys on a date and at such time as the
        Arbitrator may decide having taken regard of the convenience of the
        parties. 14.5 The arbitration shall be held on an informal basis and the
        arbitrator shall decide on such rules as he in his sole discretion deems
        necessary in order to deal effectively with the arbitration in the
        matters before him. 14.6 The arbitrator&#39;s decision shall be final
        and binding and may be made an order of court of competent jurisdiction.
        14.7 This clause constitutes an irrevocable consent by the parties to
        any proceedings in terms thereof and no party shall be entitled to
        withdraw therefrom or to claim at any such proceedings that it is not
        bound by this clause. 14.8 This clause is severable from the rest of
        this Agreement and shall remain in effect even if this Agreement in
        terminated for any reason. 14.9 This clause shall not preclude any party
        from obtaining relief by way of motion proceedings on an urgent basis or
        from instituting any interdict, injunction or any similar proceedings in
        any court of competent jurisdiction pending the decision of the expert.
        14.10 The provisions of the Arbitration Act 1965 as amended, shall not
        apply in respect of this clause or any other provision of this
        Agreement. 14.11 APPLICABLE LAW AND JURISDICTION Save as otherwise
        provided in this Agreement or any of its appendices and, in particular,
        subject to and without limiting the application of : 14.11.1 all matters
        arising from or in connection with this Agreement and/or its appendices
        including, but without any limitation whatsoever, its interpretation,
        validity, existence or termination for any reason shall be determined in
        accordance with the laws of the Republic of South Africa; 14.11.2 the
        parties hereby consent and submit to the non-exclusive jurisdiction of
        the High Court of South Africa, Gauteng Local Division, Johannesburg,
        for the purpose of any action or other legal proceedings which any of
        them may institute with regard to any matters or claims of whatsoever
        nature arising out of or pursuant to this Agreement or its appendices;
        14.11.3 each of the parties agrees that it will recognise any judgment
        or order of court, whether interim or final, granted in the territory
        against it in favour of any of the others of them which is not the
        subject of any appeal arising from or pursuant to this Agreement or its
        cancellation or termination for the purpose of the enforcement thereof
        in the territory or elsewhere. You agree that, except to the extent
        inconsistent with the Federal Arbitration Act (“FAA”) or preempted by
        federal law, without regard to principles of conflict of laws, will
        govern these Terms and any claim or dispute that has arisen or may arise
        between you and PassionStocks. Legal notices shall be served on
        PassionStocks’s national registered agent (in the case of PassionStocks)
        or your email address on file with us (in your case). Notice by us to
        you shall be deemed given twenty-four (24) hours after the email is
        sent. Alternatively, we may give you legal notice by mail to any
        physical address you have on file with us. In such case, notice shall be
        deemed given three (3) days after the date of mailing, regardless of
        whether any such notice is returned to us. It is your responsibility to
        keep your contact information updated. You and PS each agree that any
        and all disputes or claims that have arisen or may arise between you and
        PS relating in any way to or arising out of the Terms or your use of or
        access to the Services, shall be resolved exclusively through final and
        binding arbitration, rather than in court. Alternatively, you may assert
        your claims in small claims court, if your claims qualify and so long as
        the matters remains in such court and advances only on an individual
        (non-class, non- representative) basis. The FAA governs the
        interpretation and enforcement of this Agreement to Arbitrate. IN ALL
        EVENTS, EACH PARTY HEREBY KNOWINGLY, VOLUNTARY AND INTENTIONALLY, WAIVES
        (TO THE EXTENT PERMITTED BY APPLICABLE LAW) ANY RIGHT IT MAY HAVE TO A
        TRIAL BY JURY OF ANY DISPUTE ARISING UNDER OR RELATING TO THESE TERMS OR
        YOUR USE OF OR ACCESS TO THE SERVICES. THE PARTIES FURTHER AGREE THAT,
        IF AND TO THE EXTENT THIS AGREEMENT TO ARBITRATION DOES NOT APPLY TO ANY
        CLAIM, THAT CLAIM WILL BE TRIED BEFORE A JUDGE SITTING WITHOUT A JURY.
        a. PROHIBITION OF CLASS AND REPRESENTATIVE ACTIONS AND
        NON-INDIVIDUALIZED RELIEF (THE “CLASS ACTION WAIVER”). YOU AND
        PASSIONSTOCKS AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER
        ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
        ANY PURPORTED CLASS, REPRESENTATIVE, OR PRIVATE ATTORNEY GENERAL ACTION
        OR PROCEEDING. UNLESS BOTH YOU AND PASSIONSTOCKS AGREE OTHERWISE IN A
        SEPARATE WRITING, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN
        ONE PERSON’S OR PARTY’S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY
        FORM OF A CONSOLIDATED, REPRESENTATIVE, CLASS, OR PRIVATE ATTORNEY
        GENERAL ACTION OR PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF
        (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR
        OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY
        TO PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S). ANY
        RELIEF AWARDED CANNOT AFFECT OTHER PASSIONSTOCKS USERS. IF A COURT
        DECIDES THAT APPLICABLE LAW PRECLUDES ENFORCEMENT OF ANY OF THIS
        PARAGRAPH’S PROHIBITIONS ON CLASS, REPRESENTATIVE, OR PRIVATE ATTORNEY
        GENERAL ACTIONS OR PROCEEDINGS AS TO ANY CLAIM, THEN THAT CLAIM (AND
        ONLY THAT CLAIM) MUST BE SEVERED FROM THE ARBITRATION AND RESOLVED IN
        COURT, SUBJECT TO YOUR AND PASSIONSTOCKS’S RIGHT TO APPEAL THE COURT’S
        DECISIONS. ALL OTHER CLAIMS WILL BE ARBITRATED. 15. DMCA Notice. If
        you’re a copyright owner or an agent of a copyright owner and believe
        that any User Content infringes upon your copyrights, you may submit a
        notification pursuant to the Digital Millennium Copyright Act (“DMCA”)
        by providing PS’s Copyright Agent with the following information in
        writing, (pursuant to 17 U.S.C. 512(c)(3)): a. The physical or
        electronic signature of a person authorized to act on behalf of the
        owner of an exclusive right that is allegedly infringed; b.
        Identification of the copyrighted work claimed to have been infringed,
        or, if multiple copyrighted works are covered by a single notification,
        a representative list of such works; c. Identification of the material
        that is claimed to be infringing or to be the subject of infringing
        activity and that is to be removed or access to which is to be disabled
        and information reasonably sufficient to permit PS, the service
        provider, to locate the material; d. Information reasonably sufficient
        to permit PS to contact you, such as an address, telephone number, and,
        if available, an email address; e. A statement by you that you have a
        good faith belief that use of the material in the manner complained of
        is not authorized by the copyright owner, its agent, or the law; and f.
        A statement, under penalty of perjury, that the information in the
        notification is accurate that you’re authorized to act on behalf of the
        owner of an exclusive right that is allegedly infringed. You must
        provide notice of claimed infringement to PS’s designated Copyright
        Agent at 22 St Johns rd, Cape Town, South Africa, 8005 email:
        Service@passionstocks.com. You acknowledge that if you fail to comply
        with all of the requirements of this Section, your DMCA notice may not
        be valid. If you believe that your User Content that was removed (or to
        which access was disabled) is not infringing, or that you have the
        authorization from the copyright owner, the copyright owner’s agent, or
        pursuant to the law, to post and use the material in your User Content,
        you may send a counter- notice containing the following information to
        the Copyright Agent at the address(es) listed above: i. Your physical or
        electronic signature; ii. Identification of the User Content that has
        been removed or to which access has been disabled and the location at
        which the User Content appeared before it was removed or disabled; iii A
        statement that you have a good faith belief that the User Content was
        removed or disabled as a result of mistake or a misidentification; and
        iv. Your name, address, telephone number, and e-mail address, a
        statement that you consent to the jurisdiction of the High Court of
        South Africa, Gauteng Local Division, Johannesburg, South Africa, and a
        statement that you will accept service of process from the person who
        provided notification of the alleged infringement. If a counter-notice
        is received by PS’s Copyright Agent, PS may send a copy of the
        counter-notice to the original complaining party informing that person
        that it may replace the removed User Content or cease disabling it in
        ten (10) business days. Unless the copyright owner files an action
        seeking a court order against the User Content provider, member or user,
        the removed User Content may be replaced, or access to it restored, in
        ten (10) to fourteen (14) business days or more after receipt of the
        counter-notice, at PS’s sole discretion. 18. Electronic Communications.
        When you use the Services, or send e-mails, text messages, and other
        communications from your computer or mobile device to us, you are
        communicating with us electronically. You consent to receive
        communications from us electronically, such as e-mails, texts, mobile
        push notices, or notices and messages on this site, and you can retain
        copies of these communications for your records. You agree that all
        agreements, notices, disclosures, and other communications that we
        provide to you electronically satisfy any legal requirement that such
        communications be in writing. You further agree that any notices
        provided by us electronically are deemed to be given and received on the
        date we transmit any such electronic communication. When signing up for
        the Services, you will receive a welcome message and instructions on how
        to stop receiving messages. By signing up for the Services and providing
        us with your wireless number, you confirm that you want PassionStocks to
        send you information that we think may be of interest to you, which may
        include PassionStocks using automated dialing technology to text you at
        the wireless number you provided, and you agree to receive
        communications from PassionStocks, and you represent and warrant that
        each person you register for the Services or for whom you provide a
        wireless phone number has consented to receive communications from
        PassionStocks. You agree to indemnify and hold PassionStocks harmless
        from and against any and all claims, liabilities, damages (actual and
        consequential), losses and expenses (including attorneys’ fees) arising
        from or in any way related to your breach of the foregoing.
        19. Typographical Errors. The Sites and the Services could include
        technical inaccuracies or typographical errors. PassionStocks shall have
        no liability in connection with any such inaccuracies or errors, nor
        shall PassionStocks have any obligation to identify and/or correct any
        such inaccuracies or errors. 20. Links to Other Websites. For your
        convenience, certain hyperlinks may be provided on the Sites and
        Services that link to other websites or social media platforms which are
        not under the control of PassionStocks (the “Linked Websites”).
        PassionStocks does not endorse or sponsor any Linked Websites and is not
        responsible for the availability, accuracy, content, or any other aspect
        of the Linked Websites. PassionStocks disclaims all liability for such
        Linked Websites, for all access to and use thereof, and for use of the
        links to such Linked Websites. We also disclaim all liability, and make
        no representations or warranties, with respect to any products or
        services made available, sold, or provided to you by any third party.
        Your use of Linked Websites and any purchases of products or services
        from such Linked Websites are subject to the terms and conditions of
        such other websites. You agree that you will bring no suit or claim
        against PassionStocks arising from or based upon any such use of any
        Linked Websites. Hyperlinks to such Linked Websites on the Sites and
        Services do not imply that: (a) PassionStocks is affiliated or
        associated with any Linked Website; (b) PS is legally authorized to use
        any trademark, trade name, logo, or copyright symbol displayed in
        connection with or accessible through such links; or (c) any Linked Site
        is authorized to use any trademark, trade name, logo, or copyright
        symbol of PassionStocks. 21. Your Personal information Use of your
        personal information submitted to or via the Site is governed by our
        Privacy Policy. We will only use your personal information in accordance
        with our Privacy Policy. Please take the time to read this carefully, as
        it indicates important information about how we collect and use personal
        information. 22. Disclaimer of Third Party Information. To the extent
        that any information, material, or functionality on the Services is
        provided by third party content providers (“Third Party Materials”), PS
        has no editorial control or responsibility over such Third Party
        Materials. Therefore, any opinions, statements, products, services or
        other Third Party Materials are those of the applicable third party. PS
        does not represent or endorse the accuracy or reliability of any
        opinion, statement or other information provided by any third party, or
        represent or warrant that your use of any Third Party Materials will not
        infringe rights of third parties not owned by or affiliated with
        Company. 23. General. These Terms and all terms and policies posted
        through our Services (as each may be revised and amended from time to
        time according to their respective terms) constitute the entire
        agreement between you and PassionStocks relating to your use of our
        Services, and supersede all prior understandings and agreements parties.
        You consent to receive notice by email to the address provided at
        registration. If any provision of the Terms is held to be invalid, void,
        or unenforceable under any circumstances, that provision shall be deemed
        severable and shall not affect the validity and enforceability of the
        remaining provisions of the Terms. Any waiver of any provisions
        contained in these Terms by PassionStocks shall not be deemed to be a
        waiver of any other right, term, or provision of these Terms. Any rights
        not expressly granted herein are reserved. PassionStocks may assign or
        transfer our rights and obligations under these Terms at any time. You
        may not assign or transfer your rights or obligations under these Terms,
        or any of your rights or obligations, without the prior written consent
        of PassionStocks, which we can refuse in our sole discretion. No agency,
        partnership, joint venture, employer-employee or franchisor-franchisee
        relationship is intended or created by these Terms, your use of the
        Services, or the provision of our Services. You may not enter into any
        contract on our behalf or bind us in any way. These Terms are for your
        benefit only, not for the benefit of any third party except for
        PassionStocks’s permitted successors and assigns. These Terms shall be
        governed by the laws of the Republic of South Africa, without regard to
        conflicts of laws provisions, and except as described in Section 14
        exclusive venue is in the courts located in Johannesburg, Gauteng, South
        Africa, . By using the PassionStocks website or acting as a buyer or
        seller you expressly agree to the governing law and venue provision of
        these Terms, irrespective of the state or country in which you reside.
        The parties each hereby disclaim the applicability of the laws of the
        Republic of South Africa 24. Mobile Applications and In-App Purchases
        You acknowledge and agree that the availability of our mobile
        application is dependent on the third party stores from which you
        download the application, e.g., the App Store from Apple or the Android
        app market from Google (each an “App Store”). Each App Store may have
        its own terms and conditions to which you must agree before downloading
        mobile applications from such store, including the specific terms
        relating to Apple App Store set forth below. You agree to comply with,
        and your license to use our application is conditioned upon your
        compliance with, such App Store terms and conditions. To the extent such
        other terms and conditions from such App Store are less restrictive
        than, or otherwise conflict with, the terms and conditions of these
        Terms of Use, the more restrictive or conflicting terms and conditions
        in these Terms of Use apply. Through our mobile applications, you may
        purchase (“In-App Purchase”) certain goods or features designed to
        enhance the performance of the Services. When you make an In-App
        Purchase, you are doing so through either the Apple iTunes service or
        the Google Play service and you are agreeing to their respective Terms
        and Conditions, available at http://www.apple.com/legal/internet-
        services/itunes/us/terms.html and
        http://play.google.com/intl/en_us/about/play-terms.html ). PassionStocks
        is not a party to any In-App Purchase. 25. Apple App Store These Terms
        apply to your use of all the Services, including our iOS applications
        (the “Application”) available via the Apple, Inc. (“Apple”) App Store,
        but the following additional terms also apply to the Application: 1.
        Both you and PassionStocks acknowledge that the Terms are concluded
        between you and PassionStocks only, and not with Apple, and that Apple
        is not responsible for the Application or the Content; 2. The
        Application is licensed to you on a limited, non-exclusive,
        non-transferrable, non- sublicensable basis, solely to be used in
        connection with the Services for your private, personal, non-commercial
        use, subject to all the terms and conditions of these Terms as they are
        applicable to the Services; 3. You will only use the Application in
        connection with an Apple device that you own or control; 4. You
        acknowledge and agree that Apple has no obligation whatsoever to furnish
        any maintenance and support services with respect to the Application; 5.
         In the event of any failure of the Application to conform to any
        applicable warranty, including those implied by law, you may notify
        Apple of such failure; upon notification, Apple’s sole warranty
        obligation to you will be to refund to you the purchase price, if any,
        of the Application; 6. You acknowledge and agree that PassionStocks, and
        not Apple, is responsible for addressing any claims you or any third
        party may have in relation to the Application; 7. You acknowledge and
        agree that, in the event of any third-party claim that the Application
        or your possession and use of the Application infringes that third
        party’s intellectual property rights, PassionStocks, and not Apple, will
        be responsible for the investigation, defense, settlement and discharge
        of any such infringement claim; 8.  You represent and warrant that you
        are not located in a country subject to a U.S. Government embargo, or
        that has been designated by the U.S. Government as a “terrorist
        supporting” country, and that you are not listed on any U.S. Government
        list of prohibited or restricted parties; 9. Both you and PassionStocks
        acknowledge and agree that, in your use of the Application, you will
        comply with any applicable third-party terms of agreement which may
        affect or be affected by such use; and 10. Both you and PassionStocks
        acknowledge and agree that Apple and Apple’s subsidiaries are
        third-party beneficiaries of these Terms, and that upon your acceptance
        of these Terms, Apple will have the right (and will be deemed to have
        accepted the right) to enforce these Terms against you as the
        third-party beneficiary hereof. 11. In the event you use the Application
        to provide you with real-time route guidance, YOUR USE OF THIS REAL-TIME
        ROUTE GUIDANCE APPLICATION IS AT YOUR SOLE RISK. LOCATION DATA MAY NOT
        BE ACCURATE. 26. Ranking, Rating and Metrics etc. Please refer to the
        “Terms of Main Features”. 27. Access to data PassionStocks will have
        access to buyers&#39; and sellers&#39; personal data which it shall use
        in accordance with PassionStocks&#39;s Privacy Policy , as updated from
        time to time. PS will also have access to non-personal data provided by
        buyers and sellers for the use of the Services or which is generated
        through the provision of those Services. This includes, for example,
        information on sellers&#39; products to be displayed for purchase on the
        Sites, information provided by buyers and sellers when creating an
        account and information on all product purchases on the Sites. PS shares
        information it has access to with third party service providers in order
        to provide the Services and improve customer experience. PS will also
        retain such information after a seller deactivates or terminates their
        account, in accordance with applicable law. Sellers will have access to
        certain data through their online profile. This includes, for example,
        detail on their own sales history, including previous asks and bids.
        Sellers can request access to data about them after deactivating or
        terminating their account which PS may be able to provide, provided that
        the data is still available, and it is permitted to do so under
        applicable law. Sellers will not have access to any personal data
        provided by buyers. Sellers will also have access to certain data that
        is publicly made available on the Sites. This includes, for example, ask
        and bid prices, product descriptions and the relative popularity of
        products sold on the Sites. 28. Country-Specific Terms If you reside in
        one of the following countries, the additional terms set out below apply
        to your use of the Site(s) and override any inconsistent terms elsewhere
        in the Terms. All countries outside of the United States and the
        Republic of South Africa Currently the service has been checked and
        acting by the South African law. If by any applicable law or
        jurisdiction you are allowed to use the Sites, you should not use the
        Sites. Such a thing may lead to close all your account with no further
        notice and compensation. 45. Consumptive Use When you purchase or
        otherwise acquire Passionstock/s, you represent, warrant and agree that:
        (i) you are acquiring Passionstock/s solely for consumptive use and not
        for distribution; (ii) you are not acquiring Passionstock/s as an
        investment and you have no expectation of economic benefit or profit as
        a holder of Passionstocks; (iii) you are acquiring Passionstock/s for
        your own use and/or for supporting the Issuer and not with a view to
        sell the Passionstock/s to anyone else; (iv) you are not acquiring any
        equity or other ownership or legal interest in the Interface by virtue
        of owning Passionstock/s; (v) you will not portray Passionstock/s as an
        investment or an opportunity to obtain an economic benefit or profit;
        (vi) you have an adequate understanding of the functionality and
        characteristics of Passionstock/s and the differences between
        Passionstock/s and stocks as securities; (vii) your purchase and use of
        Passionstock/s complies with applicable laws and regulations in your
        jurisdiction, including, without limitation, legal capacity, regulatory
        restrictions and governmental consents; and (viii) you will not use
        Passionstock/s for any illegal purpose within or outside the Interface.
        Promoting Tax compliance and VAT: • General: • We recommend that all
        Creators seek professional advice to ensure you are compliant with your
        local Tax and VAT rules, based on your individual circumstances. • By
        using PassionStocks as a Seller, you warrant (which means you make a
        legally enforceable promise) that you have reported and will report in
        the future the receipt of all payments made to you in connection with
        your use of PassionStocks to the relevant Tax authority in your
        jurisdiction, as required by law. • By using PassionStocks as a User you
        warrant (which means you make a legally enforceable promise) that you
        will at all times comply with all laws and regulations relating to Tax
        which apply to you. If, at any point whilst you have an PassionStocks
        account, any Tax non-compliance occurs in relation to you (including a
        failure by you to report earnings or the imposition on you of any
        penalty or interest relating to Tax) or if any litigation, enquiry, or
        investigation is commenced against you that is in connection with, or
        which may lead to, any occurrence of Tax non-compliance, you agree that
        you will: notify us by email to Service@passionstocks.com in writing
        within 7 days of the occurrence of the Tax non-compliance or the
        commencement of the litigation, enquiry or investigation (as
        applicable); and promptly provide us by email to
        Service@passionstocks.com with: • details of the steps which you are
        taking to address the occurrence of the Tax non-compliance and to
        prevent the same from happening again, together with any mitigating
        factors that you consider relevant; and • such other information in
        relation to the occurrence of the Tax non- compliance as we may
        reasonably require. • For the avoidance of doubt, you are responsible
        for your own Tax affairs and PassionStocks is not responsible or liable
        for any non-payment of Tax by Users. • We reserve the right to close
        your PassionStocks account if we are notified of or become aware of any
        Tax non-compliance by you. If you want to delete your PassionStocks
        account then you may do so from your profile on the menu more fully set
        on the “Terms of Main Features” and the FAQ’s as updated from time to
        time. • If you are an User and not an SMI or Agency, the deletion of
        your account will take place within a reasonable time following your
        request. • If you are an SMI or Agency, then once you initiate the
        &quot;delete account&quot; process you will need to give a Story Mention
        on your Instagram account. More fully set on the Terms of Main Features
        and the FAQs as updated from time to time. • Once your account has been
        deleted you won&#39;t be charged any further amounts or have access to
        your former PassionStocks account or its PassionStocks, You will receive
        an email confirmation upon the successful deletion of your account. Once
        your account has been deleted, we may deal with your Content in any
        appropriate manner in accordance with our Privacy Policy (including by
        deleting it) and you will no longer be entitled to access your Content.
        There is no technical facility on PassionStocks for you to be able to
        access your Content following termination of your account.
        Demonstration, Illustration and checking out the system All the Content
        on the website including pictures, names etc. that appear on the Demo
        mode and the home page (as more fully set on the Terms of the Main
        Features and the FAQ’s as updated from time to time). Are not making any
        guarantee that those entities are using PassionStocks or involved in any
        way or manner. This Content is just for demonstrating and Illustration
        etc.
        <br />
        <br />
        <br />
        STANDARD CONTRACT BETWEEN SELLER AND BUYER Introduction: This Standard
        Contract between Seller and Buyer (&quot;this agreement&quot;) sets out
        the terms which govern each transaction between a Seller and a Buyer on
        PassionStocks. When does this agreement apply? Each time a Seller/Buyer
        Transaction is initiated on PassionStocks, this Standard Contract
        between Seller and Buyer will apply to the exclusion of any other terms
        which the Seller or Buyer may propose, and this Standard Contract
        between Seller and Buyer will legally bind the Seller and Buyer
        participating in the Seller/Buyer Transaction. Parties: The only parties
        to this agreement are the Seller and Buyer participating in the
        Seller/Buyer Transaction. Virtual Crypto SA (Pty) Ltd is not a party to
        this agreement and neither grants any rights in respect of, nor
        participates in, any Seller/Buyer Transaction, except for acting as a
        payment intermediary in accordance with the Seller’s and Buyer’s
        instructions set out in section 5 of this agreement. Interpretation: In
        this agreement, defined terms have the following meanings:
        &quot;PassionStocks&quot; means the website accessed via the URL
        www.PassionStocks.com and/or via any web browser and/or mobile
        application; &quot;Content&quot; means passionstocks and/or a
        Passionproduct from the Issuer’s Passionshop and/or any material
        uploaded to PassionStocks by any User (whether a Seller or a Buyer),
        including any photos, videos, audio (for example music and other
        sounds), livestream material, data, text (such as comments and
        hashtags), metadata, images, interactive features, emojis, GIFs, memes,
        and any other material whatsoever; &quot;Seller&quot; means a User who
        has listed a Passionstock/s on the marketplace ; &quot;Buyer&quot; means
        a User who has bought a Passionstock/s; &quot;The Issuer&quot; means the
        SMI who issued the Passionstock/s in the Seller/Buyer Transaction.
        &quot;Referring User&quot; means a User who participates in the
        PassionStocks Referral Program; &quot;Seller/Buyer Transaction&quot;
        means any transaction between a Seller and a Buyer on PassionStocks by
        which is granting the Buyer to Issuer&#39;s PassionProduct/s;
        &quot;Buyer Payment&quot; means (i) any and all payments made by a Buyer
        in respect of any Seller/Buyer Transaction, and (ii) any and all tips;
        &quot;PS Fee&quot; means the fee charged by Virtual Crypto SA (Pty) LTD
        to any User in accordance with Payment Policy as updated from time to
        time; &quot;VAT&quot; means any other tax imposed in addition to or in
        substitution for it at the rate from time to time imposed and any
        equivalent or similar governmental, state, federal, provincial, or
        municipal indirect tax, charge, duty, impost or levy in any other
        jurisdiction; and &quot;Tax&quot; shall include all forms of tax and
        statutory, governmental, state, federal, provincial, local government or
        municipal charges, duties, imposts, contributions, levies, withholdings
        or liabilities wherever chargeable and whether of the UK or any other
        jurisdiction. Pricing and payment: By entering into a Seller/Buyer
        Transaction, the Buyer agrees to pay the Seller Payment applicable to
        the relevant Seller/Buyer Transaction in accordance with the pricing
        published on the marketplace plus any VAT which is applicable. The
        Seller and Buyer participating in the Seller/Buyer Transaction authorize
        Virtual Crypto SA (Pty) Ltd or any of its subsidiaries or its holding
        company to act as a payment intermediary and to collect, hold, and
        process the Buyer Payment and any applicable VAT, to deduct the PS Fee,
        and to pay out the sums due to Sellers and (if applicable) Referring
        Users, as described in the PassionStocks Terms of Service. License of
        Content: Once the Buyer has made the Buyer Payment for a Buyer/Seller
        Transaction, the Seller grants to the Buyer a license to access and get
        a PassionProduct/s from the Issuer’s PassionShop. The applicable Content
        of that Issuer to which the Seller/Buyer Transaction relates (the
        &quot;Relevant Content&quot;). Ownership of Content: The Buyer
        participating in the Seller/Buyer Transaction acknowledges and agrees
        that the license of the Relevant Content provided in this agreement does
        result in the Buyer acquiring rights in the Relevant Content which the
        passionstock/s and/or the passionproduct/s, which the Issuer has agreed
        upon such rights of the Relevant Content. Expiry of license: The license
        granted to a Buyer in relation to the Relevant Content will expire
        automatically without notice in the following circumstances: if the
        Buyer Payment related to the Seller/Buyer Transaction was unsuccessful,
        or is charged back or reversed for any reason; if the Buyer&#39;s User
        account is suspended or terminated for any reason; if the Buyer acts in
        breach of the PassionStocks Acceptable Use Policy (whether in relation
        to the Relevant Content or at all); if the Content is removed by the
        Issuer from the Issuer&#39;s account; if the Buyer closes their
        PassionStocks User account. Cancellation and refunds: In respect of
        every Seller/Buyer Transaction: The Buyer acknowledges that the
        Seller/Buyer Transaction will result in a supply of the relevant Content
        to the Buyer before the end of the period of 14 days from the date when
        the Seller/Buyer Transaction is entered into, and the Buyer gives his or
        her express consent to this and confirms to the Seller that he or she is
        aware that any statutory right to cancel the Seller/Buyer Transaction
        which the Buyer has under the Consumer Rights Act 2015 or any other
        applicable law will therefore be lost. This agreement does not affect
        any statutory right to receive a refund from the Seller which a Buyer
        may have under the Consumer Rights Act 2015 or any other applicable law.
        Obligations between Seller and Buyer: In respect of every Seller/Buyer
        Transaction: The Buyer and the Seller participating in the Buyer/Seller
        Transaction agree to comply at all times with the PassionStocks
        Acceptable Use Policy in relation to the Relevant Content, including
        when accessing, viewing, interacting and trading in with it. The Seller
        participating in the Buyer/Seller Transaction agrees to make the Buyer
        Payment required in order to receive the Passionstock/s, and agrees not
        to initiate a chargeback other than if the Buyer disputes the
        Buyer/Seller Transaction in good faith. The Issuer participating in the
        Seller/Buyer Transaction by selling the Passionstock of the Issuer,
        whether the transaction directly from the issuer (IPO-Passionstocks) or
        not (Post-IPO Passionstocks), The Issuer agrees to make the Relevant
        Content available to the Buyer once the Buyer has made the Buyer Payment
        applicable to the Relevant Content. The Issuer warrants (makes a legally
        enforceable promise) that it possesses all necessary rights in and to
        the Relevant Content sufficient to license it to the Buyer in the
        territory in which the Buyer will be accessing the Relevant Content, and
        has obtained any and all permissions and consents needed to grant the
        license in section “License of Content” of this agreement whether the
        transaction has made directly between the Issuer to the buyer
        (IPO-Passionstocks) or not (Post- IPO Passionstocks). The Issuer is
        solely responsible for creating and uploading the Relevant Content. The
        Issuer provides no guarantees that it will continue to Issue and upload
        Relevant Content on an ongoing basis. Unless there has been negligence
        or other breach of duty by the Seller, the accessing by the Buyer of the
        Issuer&#39;s Content is entirely at the Buyer&#39;s own risk. No
        guarantees: The Buyer participating in the Seller/Buyer Transaction
        acknowledges that Issuers may add and remove Content at any time from
        their account, and that Issuers have the discretion to decide what sort
        of Content to make available on their account. In addition, the Buyer
        participating in the Seller/Buyer Transaction acknowledges that there
        may be circumstances where it is not possible for the Buyer to access to
        Relevant Content to be provided under the Seller/Buyer Transaction,
        including: if the Issuer&#39;s account is suspended or deleted; if the
        Buyer&#39;s account is suspended or deleted; if the availability of all
        or any part of PassionStocks site is suspended or inaccessible; or if
        the Issuer is unable to create or upload Relevant Content in the future.
        Terms relating to disputes: This agreement is governed by South African
        law and South African law will apply to any claim that arises out of or
        relates to this agreement. The Agency will also be able to rely on
        mandatory rules of the law of the country where he or she lives. Where
        claims can be brought: If this agreement is with a Agency resident in
        the Republic of South Africa, any claim under this agreement may be
        brought in the jurisdiction of the South Gauteng High Court in
        Johannesburg or the courts of the country where the Agency lives. If
        this agreement is with a Agency resident outside of the Republic of
        South Africa any claim under this agreement must be brought in the
        jurisdiction of the South Gauteng High Court in Johannesburg, unless the
        Fan and Creator otherwise agree.
        <br />
        <br />
        <br />
        STANDARD CONTRACT BETWEEN SMI AND AGENCY Introduction: This Standard
        Contract between SMI and Agency (&quot;this agreement&quot;) sets out
        the terms which govern each transaction between a SMI and an Agency on
        PassionStocks. When does this agreement apply? When an SMI is joining an
        Agency on PassionStocks more fully set on the FAQs as updated from time
        to time. Each time an Agency/SMI Collaboration is initiated on
        PassionStocks, this Standard Contract between Agency and SMI will apply
        to the exclusion of any other terms which the Agency or SMI may propose,
        and this Standard Contract between Agency and SMI will legally bind the
        Agency and SMI participating in the Agency/SMI Collaboration. Parties:
        The only parties to this agreement are the Agency and SMI participating
        in the Agency/SMI Collaboration. Virtual Crypto SA (Pty) Ltd is not a
        party to this agreement and neither grants any rights in respect of, nor
        participates in, any Agency/SMI Collaboration, except for acting as a
        payment intermediary in accordance with the Agency’s and SMI’s
        Collaboration set out in the section “Pricing and Payment” of this
        agreement. Interpretation: In this agreement, defined terms have the
        following meanings: &quot;PassionStocks&quot; means the website accessed
        via the URL www.PassionStocks.com and/or via any web browser and/or
        mobile application; &quot;Content&quot; means any material uploaded to
        the Social Media Accounts (namely, Instagram, TikTok and YouTube or any
        other social media account) of SMI by the SMI or to PassionStocks by any
        User, including any passionstocks, photos, videos, audio (for example
        music and other sounds), livestream material, data, text (such as
        comments and hashtags), metadata, images, interactive features, emojis,
        GIFs, memes, and any other material whatsoever; &quot;Agency&quot; means
        a User who is managing a PassionStocks account on a behalf of other
        User/s according to this Agreement; &quot;SMI&quot; means a User who is
        eligible to issue passionstocks (often referred as “the Issuer”);
        &quot;Referring User&quot; means a User who participates in the
        PassionStocks Referral Program; &quot;Agency/SMI Collaboration&quot;
        means the consent of both parties on the PassionStocks platform to
        collaborate as follows: SMI to be managed by the Agency and The Agency
        to manage and access the SMI’s PassionStocks’ account on the behalf of
        the SMI. More fully set further on License of Content &amp; Permission
        of Access . “The Invitation” means an invitation from the SMI to the
        Agency or from the Agency to the SMI in order to come into an Agency/SMI
        Collaboration. More fully set on the FAQ’s as updated from time to time.
        &quot;Agency Fee&quot; means (i) any and all payments made by an SMI in
        respect of any Agency/SMI Collaboration, and (ii) any and all relevant
        commissions as more fully set on the FAQ’s and the Main Terms and
        Conditions as updated from time to time; &quot;PS Fee&quot; means the
        fee charged by Virtual Crypto SA (Pty) LTD to any User in accordance
        with the PassionStocks’ Payment Policy; &quot;VAT&quot; means any other
        tax imposed in addition to or in substitution for it at the rate from
        time to time imposed and any equivalent or similar governmental, state,
        federal, provincial, or municipal indirect tax, charge, duty, impost or
        levy in any other jurisdiction; and &quot;Tax&quot; shall include all
        forms of tax and statutory, governmental, state, federal, provincial,
        local government or municipal charges, duties, imposts, contributions,
        levies, withholdings or liabilities wherever chargeable and whether of
        the UK or any other jurisdiction. Pricing and payment: By entering into
        an Agency/SMI Collaboration, the SMI agrees to pay the Agency Fee
        applicable to the relevant Agency/SMI Collaboration in accordance with
        the pricing that has been agreed upon between both Parties plus any VAT
        (If applicable). The SMI and Agency participating in the Agency/SMI
        Collaboration authorize Virtual Crypto SA (Pty) Ltd or any of its
        subsidiaries to act as a payment intermediary and to collect, hold, and
        process the Agency Fee and any applicable VAT, to deduct the PS Fee, and
        to pay out the sums due to Agencies and (if applicable) Referring Users,
        as described in the PassionStocks Terms of Service, The FAQ’s and the
        Payment Policy as updated from time to time. License of Content &amp;
        Permission of Access: Once the SMI and the Agency have accepted to
        collaborate by accepting The Invitation for an Agency/SMI Collaboration,
        the SMI grants the Agency a non-limited license to access the applicable
        Content of that SMI to which the Agency/SMI Collaboration relates (the
        &quot;Relevant Content&quot;). This license is non-transferable, non-
        sublicensable and non-exclusive, and permits the Agency to access, view
        and manage of the behalf of the SMI the Relevant Content on the
        Agency&#39;s personal and/commercial device and via a normal web browser
        (and to make a temporary copy of such Content only to the extent that
        this is an incidental and technical process forming part of the
        Agency&#39;s accessing the Content, in accordance with the PassionStocks
        Acceptable Use Policy as appears on the Main Terms and Conditions and/or
        the FAQs as updated from time to time. Permission of access to the
        account of the SMI would grant the Agency as part this Agreement as
        follows: (1) Releasing/removing passionstocks on/from the marketplace
        (2) trading-in passionstocks for PassionProducts/PassionCoins and/or
        vice versa. (3) Withdrawal PC (to the SMI’s bank account) (4) editing
        profile (5) merging with an SMI. (6) Marking PassionProducts as
        delivered. More permissions in a detailed way can be found on the FAQ’s
        as updated from time to time. The SMI can grant/remove certain
        permissions. More fully set on the FAQ’s as updated from time to time.
        Ownership of Content: The SMI participating in the Agency/SMI
        Collaboration acknowledges and agrees that the license of the Relevant
        Content provided in section “License of Content &amp; Permission of
        Access” of this agreement does not result in the Agency acquiring any
        rights in or to the Relevant Content, which rights shall be retained by
        the SMI of the Relevant Content. Expiry of license: The license granted
        to an Agency in relation to the Relevant Content will expire
        automatically without notice in the following circumstances: if the
        Agency&#39;s User account and/or the SMI’s User account is suspended or
        terminated for any reason; if the Agency and/or the SMI acts in breach
        of the PassionStocks Acceptable Use Policy (whether in relation to the
        Relevant Content or at all); if the Content is removed by the SMI from
        the SMI&#39;s account; if the Agency and/or the SMI closes their
        PassionStocks User account. if the Agency or the SMI has not complied
        with any applicable law regarding the Agency/SMI Collaboration or
        suspected doing so at the sole discretion of PassionStocks. Cancellation
        and Opting-Out: In respect of every Agency/SMI Collaboration: The Agency
        and the SMI acknowledge that the Agency/SMI Collaboration can be
        cancelled and each Party has the option to opt-out. Opting-out is taking
        place effective immediately unless if the User (who is opting-out) has
        chosen to terminate the Agency/SMI Collaboration after a certain-period
        of time. More fully set on the FAQ’s. In a case of “Exceptional SMI”
        (more fully set further on) The SMI in order to opt-out, it can be done
        by the entity or the entities who have the rights individually or
        collectively to be bound with this contract. In a case more than one
        person, each entity would need to accept the “Opting-Out” unless if they
        have appointed one entity to be eligible and capable to be the decision
        maker on the behalf of the all relevant entities. More fully set on the
        FAQs. Obligations between Agency and SMI: In respect of every
        Seller/Buyer Transaction: The Agency and the SMI participating in the
        Agency/SMI Collaboration agree to comply at all times with the
        PassionStocks Acceptable Use Policy in relation to the Relevant Content,
        including when accessing, viewing and interacting with it. The SMI
        participating in the Agency/SMI Collaboration agrees to pay the Agency
        Fee Payment. The SMI participating in the Agency/SMI Collaboration
        agrees to make the Relevant Content available to the Agency once the
        Agency and the SMI have given their consent to collaborate by accepting
        the Invitation. More fully set on the FAQs. The SMI warrants (makes a
        legally enforceable promise) that it possesses all necessary rights in
        and to the Relevant Content sufficient to license it to the Agency in
        the territory in which the Agency will be accessing the Relevant
        Content, and has obtained any and all permissions and consents needed to
        grant the license in section 6 of this agreement. The SMI is solely
        responsible for creating and uploading the Relevant Content. The SMI
        provides no guarantees that it will continue to create and upload
        Relevant Content on an ongoing basis. Unless there has been negligence
        or other breach of duty by the Agency, the managing by the Agency of the
        SMI&#39;s Content is entirely at the SMI&#39;s own risk. No guarantees:
        The Agency participating in the Agency/SMI Collaboration acknowledges
        that SMI may add and remove Content at any time from their SMI account,
        and that SMI has the discretion to decide what sort of Content to make
        available on their account. In addition, the Agency participating in the
        Agency/SMI Collaboration acknowledges that there may be circumstances
        where it is not possible for the Agency to access to Relevant Content to
        be provided under the Agency/SMI Collaboration, including: if the
        SMI&#39;s account is suspended or deleted; if the Agency&#39;s account
        is suspended or deleted; if the availability of all or any part of
        PassionStocks site is suspended or inaccessible; or if the SMI is unable
        to create or upload Relevant Content in the future from any reason
        including mental issues or other problems relating to body image or
        self-esteem. Exceptional SMIs Non-Personal Content: Animals, Meme Pages
        etc. &amp; Under Age In a case of the SMI can’t be legally bound by a
        contact and can’t be a part of this agreement, then the Agency has to
        comply with all applicable law and warrants (makes a legally enforceable
        promise) to have an applicable legal contract with the guardian or with
        whom who can be bound legally on the behalf of the SMI with such a
        contract for managing the SMI. By registering to the platform as an
        Agency and managing other Users on the platform The Agency warrants
        (makes a legally enforceable promise) it has all the legal rights to
        manage the SMI/s. PassionStocks might ask for such an evidence to be
        provided by the Agency, failure of providing such evidence can cause of
        suspending the account immediately with no further warning. More than
        one person own rights in the relevant Social Media Accounts/Content In a
        case of an SMI who are more than one entity who are having legal rights
        or are known as running together a respective social media account/s
        (for example: Twins, Family etc.) the Agency has to comply with all
        applicable law and warrants (makes a legally enforceable promise) it has
        the consent from each entity in order to manage their PassionStocks
        accounts. PassionStocks might ask for such an evidence to be provided by
        the Agency, failure to prove such evidence can cause suspending of the
        account immediately with no any warning. Terms relating to disputes:
        This agreement is governed by South African law and South African law
        will apply to any claim that arises out of or relates to this agreement.
        The Agency will also be able to rely on mandatory rules of the law of
        the country where he or she lives. Where claims can be brought: If this
        agreement is with a Agency resident in the Republic of South Africa, any
        claim under this agreement may be brought in the jurisdiction of the
        South Gauteng High Court in Johannesburg or the courts of the country
        where the Agency lives. If this agreement is with a Agency resident
        outside of the Republic of South Africa any claim under this agreement
        must be brought in the jurisdiction of the South Gauteng High Court in
        Johannesburg, unless the Fan and Creator otherwise agree.
        <br />
        <br />
        <br />
        Cookie Policy <br />
        <br />
        <span>Why we use cookies</span>
        <br />
        <br />
        We use cookies and similar technologies (such as web beacons, pixels,
        tags, and scripts) to improve and personalize your experience, provide
        our services, analyze website performance, and for marketing purposes,
        as further detailed below. A cookie is a piece of information (text
        file) stored on your device. When you visit our site, we set our own
        cookies (called first-party cookies) to store information on your
        device, such as your language preferences or login information. We also
        use 3rd-party cookies, which are cookies set by our partners (such as
        Facebook, Linkedin, Google, etc.).
        <br />
        The duration of a cookie depends on its function. “Session cookies” are
        only active during your online session, while “persistent cookies” will
        stay active on your device after closing your browser.
        <br />
        Cookie Settings
        <br />
        <br />
        <span>Blocking or deleting cookies from your browser</span>
        <br />
        Find more information about deleting or controlling cookies at
        www.aboutcookies.org. (Note that this website is a third-party website.
        Therefore, we cannot ensure its accuracy, completeness or availability).
        <br />
        It is also possible to set your browser to block all cookies, including
        cookies associated with our services, or to indicate when a cookie is
        being set by us. You can do this through the browser settings page of
        your the browser you use.
        <br />
        The following links may assist you in managing your cookies settings, or
        you can use the 'help' option in your internet browser for more details:
        <br />
        Internet Explorer
        <br />
        Google Chrome
        <br />
        Firefox
        <br />
        Safari (OS X)
        <br />
        Safari (iOS)
        <br />
        Android
        <br />
        You can prevent Google Analytics from collecting and using data about
        you by downloading and installing the browser plug-in available here .
        This creates an opt- out cookie that prevents the processing of your
        data. For more information about Google Analytics cookies, please see
        Google's help pages and privacy policy.
        <br />
        We may update this Cookie Notice in the future. We encourage you to
        periodically review this page for the latest information about cookies
        set by PassionStocks.
        <br />
        <br />
        <br />
        <span>Types of cookies</span>
        <br />
        <br />
        Essential cookies
        <br />
        <br />
        Some cookies are essential for our service to function and cannot be
        switched off in our systems. They are used to enable core functionality,
        such as authentication, security, connection stability, etc. You can
        always set your browser to block or alert you about these cookies, but
        this may affect how the website functions.
        <br />
        <br />
        <br />
        <br />
        Analytics Cookies
        <br />
        <br />
        Analytics cookies help us know which pages are popular and how visitors
        move around the site. We use them to count visits, check traffic
        sources, and measure and improve the website’s performance.
        <br />
        <br />
        <br />
        Marketing Cookies
        <br />
        Certain 3rd-party service providers and advertising partners manage our
        advertising on other websites. Such 3rd parties use cookies through our
        site to provide you with advertising on other websites based on your
        interests and preferences.
        <br />
        <br />
        PassionStocks’s Privacy Policy
        <br />
        Please read this privacy policy (the “Policy”) carefully to understand
        how we use personal information. If you do not agree with this Policy,
        your choice is not to use PassionStocks.com site and mobile applications
        and its related sites, applications, services and goods or any other
        website operated by PassionStocks that links to this Policy
        (the “Site”). By accessing or using this Site, you agree to this Policy.
        This Policy may change from time to time; any changes we make to this
        Policy will be posted on this Site, we will also take any other steps,
        to the extent required by applicable law, including notifying you and/or
        seeking your explicit consent to material changes. Changes to this
        Policy are effective as of the stated "Last Updated" date. Other than
        where we have sought such explicit consent from you, your continued use
        of the Site after we make changes will constitute acceptance of, and
        agreement to be bound by, those changes, so please check the Policy
        periodically for any updates or changes.
        <br />
        The short version
        <br />
        The longer version
        <br />
        Information We Collect
        <br />
        How Do We Collect Information?
        <br />
        How Do We Use The Information Collected?
        <br />
        How Long Do We Keep Personal Information?
        <br />
        Children Under the Age of 13
        <br />
        Sharing Personal Information with Third Parties
        <br />
        Where We Store Your Personal Information
        <br />
        Cookies
        <br />
        Do Not Track
        <br />
        External Links
        <br />
        Security
        <br />
        Rights of EU Users
        <br />
        Specific Provisions for California Residents
        <br />
        Updating Personal Information
        <br />
        Contact Us
        <br />
        <br />
        <span>The short version</span>
        <br />
        <br />
        <span>Information We Collect</span> – When you register to the Site, use
        it, complete forms, or register to our affiliate or influencer or
        similar program, we collect the personal information provided by you. We
        also collect information about your communications with PassionStocks as
        well as communication with other users of PassionStocks.
        <br />
        In addition, we collect information while you access, browse, view or
        otherwise use the Site.
        <br />
        <span>How Do We Collect Information?</span> - You directly provide us
        with most of the information we collect. We also collect technical
        information indirectly and automatically through our systems. We also
        collect information from third party vendors and/or other commercially
        available sources.
        <br />
        <span>How Do We Use the Information Collected?</span> – We use personal
        information to provide you with quality service and security, to operate
        the Site and to perform our obligations to you; to ensure marketplace
        integrity and security; to prevent fraud; to contact you and send you
        direct marketing communications; to promote and advertise the Site and
        PassionStocks marketplace; to comply with lawful requests by public
        authorities and to comply with applicable laws and regulations.
        <br />
        <span>How Long Do We Keep Personal Information </span>– We will keep
        personal information only for as long as is required to fulfil the
        purpose for which it was collected. However, in some cases we will
        retain personal information for longer periods of time.
        <br />
        <span>Children Under the Age of 13</span> - Our Site is not intended for
        children under 13 years of age and we do not knowingly collect personal
        information from children under 13.
        <br />
        <span>Sharing Personal Information with Third Parties </span>– We share
        personal information with third parties in order to operate the Site,
        provide our services to you, fulfil obligations imposed on us by
        applicable laws and regulations, and prevent fraud, infringements and
        illegal activities.
        <br />
        <span>Where We Store Personal Information</span> - Some of the personal
        information you provide to us will be stored or processed on our behalf
        by third party suppliers and data processors and may be located in other
        jurisdictions, such as the United States, Israel or the Republic of
        South Africa.
        <br />
        <span>Cookies</span> - We use cookies and similar technologies (such as
        web beacons, pixels, tags, and scripts) to improve and personalise your
        experience, provide our services, analyse website performance and for
        marketing purposes.
        <br />
        <span>Do Not Track (DNT)</span> – Our Site does not respond to Do Not
        Track (DNT) signals. External Links - the Site contains links to third
        party sites and if you link to a third party site from the Site, any
        data you provide to that site and any use of that data by the third
        party are not under the control of PassionStocks and are not subject to
        this Policy. Security – We take great care in maintaining the security
        of the Site and your information and in preventing unauthorised access,
        loss, misuse, alteration, destruction or damage to it through industry
        standard technologies and internal procedures.
        <br />
        <span>Rights of EU Users</span> – Where we process personal data related
        to EU user, further terms apply to our processing in relation to your
        rights as a data subject under EU data protection laws, as detailed
        below.
        <br />
        <span>Specific Provisions for California Residents</span>- If you are a
        California resident, you are entitled to specific privacy rights as
        detailed below.
        <br />
        <span>Updating Personal Information </span>– We take steps to ensure
        that the personal information we collect is accurate and up to date, and
        we provide you with the opportunity to update your information through
        your account profile settings. In the event that you believe your
        information is in any way incorrect or inaccurate, please let us know
        immediately.
        <br />
        <span>Contact Us</span> - You can exercise your rights over your
        personal information, <i>by contacting our customers service </i>. If
        you do not have an active PassionStocks account, please contact us at 
        <i>privacy@PassionStocks.com</i>. More details about the rights
        applicable to you are in the long version of the Policy.
        <br />
        <br />
        The above are just the highlights. We encourage you to read more about
        the information we collect, how we use it, understand the meaning of
        cookies (no, it can’t be eaten) and more in the long version of our
        Policy below.
        <br />
        <br />
        <span>The longer version</span>
        <br />
        PassionStocks (including Virtual Crypto SA Pty Ltd. with registration
        num.2018/343797/07. and its affiliates) respects your privacy and is
        committed to protect the personal information of its users - buyers,
        sellers, collaborators and other visitors browsing the Site (“Users”) as
        well as affiliates, influencers and other collaborators interacting with
        PassionStocks. We believe that you have a right to know our practices
        regarding the information we collect when you visit and/or use the Site.
        <br />
        This Policy explains our privacy practices for the Site and services
        provided by PassionStocks as well as privacy practices that apply to
        affiliates, influencers and other collaborators who are individuals.
        <br />
        By accessing and/or using the Site and its related sites, applications,
        services, goods and/or registering for a PassionStocks account and/or
        using it as a collaborator to share comments on a specific project
        and/or registering to our affiliate or influencer or similar program,
        you agree to the terms and conditions of this Policy, including to our
        collection, use, disclosure, processing and retention of personal
        information. You can also learn how to limit sharing of information in
        this Policy.
        <br />
        <br />
        <span>Information We Collect</span>
        <br />
        When you register to the Site, use it, complete forms, or use it as
        collaborator, and/or register to our affiliate or influencer or similar
        program, we ask you to provide certain personal information, including a
        valid email address, Facebook or Google account login details and
        username. We will also ask you to provide or otherwise collect
        additional information that you provide us, such as, your profile
        details, physical address or billing information, telephone number or
        other contact details, financial information (such as payment method and
        credit card number), taxpayer information and forms, details about other
        social networks linked accounts, details about your listed products,
        purchases, education, profession and expertise, and additional
        authentication information (such as your government issued ID, passport,
        or driving license, as permitted by applicable laws).
        <br />
        We also collect information about your communications with PassionStocks
        as well as communication with other users of PassionStocks. We also
        collect information while you access, browse, view or otherwise use the
        Site. In other words, when you access the Site we are aware of your
        usage of the Site, and gather, collect and record the information
        relating to such usage, including geo-location information, IP address,
        device and connection information, browser information and web-log
        information, and all communications recorded by Users through the Site.
        We use that information to enhance user experience, personalise your
        browsing experience as well as monitor the Site for preventing fraud and
        inappropriate content or behaviour. We also collect supplemental
        information obtained from third parties such as demographic and
        navigation data, if applicable. We combine your personal information
        with information we collect automatically or obtain from other companies
        and use it to improve and personalise our services, content and
        advertising and/or to prevent fraud. Additionally, in order to improve
        your online experience at PassionStocks, we have implemented impression
        reporting. While you view our ads, we gather user Global Unique
        Identifier, HTTP request data like, user agent, IP, host, URL,
        country/continent from which request made, browser info,
        device/operating system/operating system version. Once you register,
        your username, country, and additional information regarding your
        activity is made public and is visible to all Users of the Site. This
        information includes photos you upload, your published portfolio,
        products {"&"} services information, ratings, and additional information
        you may choose to add to your profile. We also offer you the opportunity
        to participate and post content publicly in forums, blogs and on other
        similar features. Please be aware that certain information, such as your
        user name and profile, together with the content uploaded by you, will
        be publicly displayed when you use such features.
        <br />
        <br />
        <span>How Do We Collect Information?</span>
        <br />
        <span>Information provided by you</span> - You directly provide us with
        most of the information we collect. You do this by filling out the
        registration details on the Site, linking to your PassionStocks account
        accounts of other social networks (please also see the External Links
        section below), completing application forms, skill tests and customer
        surveys (if any) as well as by posting and sharing additional
        information voluntarily. This can include information about sellers and
        their products or services, education and profession, sharing of
        portfolio, ratings and feedbacks by buyers and anything you choose to
        add to your user profile. Information we collect when you use the Site -
        We collect technical information indirectly and automatically through
        our systems. This information includes logging your Internet Protocol
        (IP) address, software configuration, operating system and use of
        cookies (cookies are small files sent from us to your computer and
        sometimes back). Cookies ultimately help us improve your navigation and
        ease of use of our Site. You can find further information about Cookies
        in our Cookie Policy .<br />
        Information we collect from other sources - We receive information from
        third party vendors and/or other commercially available sources, such
        as:
        <br />• Third party vendors who provide us data to supplement the
        information we collect about you, in accordance with applicable laws.
        For example, we receive fraud warnings from service providers for our
        fraud prevention and risk assessment efforts. We also receive
        information from enrichment service providers to enhance and personalize
        your experience as well as from advertising and marketing partners in
        order to manage and measure our campaigns.
        <br />• If you link, connect, or login to your PassionStocks Account
        with a third-party service (e.g. Google, Facebook, LinkedIn), we receive
        certain information, such as your registration and profile information
        from that service. This information varies and is controlled by that
        service or as authorized by you via your privacy settings at that
        service.
        <br />• Advertising and marketing partners in order to monitor, manage
        and measure our campaigns.
        <br />
        • We also receive certain information about you, if you are invited to
        PassionStocks by another user (for example, as part of our collaboration
        tools and networker/referral program), in such case we will receive
        limited information, such as your email address or other contact
        information, and we will use it for the same purpose it was provided and
        in accordance with the terms of this Privacy Policy.
        <br />
        <br />
        <span>How Do We Use The Information Collected?</span>
        Where relevant under applicable laws, all processing of your personal
        information will be justified by a "lawful ground" for processing. In
        the majority of cases, processing will be justified on the basis that:
        <br />• you have provided your consent for us to use your personal
        information for a specific purpose;
        <br />• our use of your personal information is necessary to perform a
        contract or take steps to enter into a contract with you (e.g. to
        provide you with services which you have purchased);
        <br />• the processing is necessary to comply with a relevant legal
        obligation or regulatory obligation that we have (e.g. fraud
        prevention); or
        <br />• the processing is necessary to support our legitimate interests
        as a business (e.g. to improve our services to you), subject to your
        interests and fundamental rights and provided it is conducted at all
        times in a way that is proportionate. We will use your personal
        information for the following purposes:
        <br />• to provide you with quality service and security, to operate the
        Site, to perform our obligations to you and to develop and improve our
        service. For example, we use the information collected from you to
        verify your identity. We also use this information to establish and set
        up your account, verify or re-issue a password, log your activity,
        enable your communications with other members, provide customer support
        and contact you from time to time. The information helps us to develop
        and improve our services to you, to understand and analyse your
        preferences and performance and to customize and personalize your
        experience (including by making Products or Services suggestions,
        ranking search results, etc.).
        <br />• to ensure marketplace integrity, prevent fraud and maintain a
        safe and secure marketplace. For example, we use your information to
        track and prevent fraudulent activities and other inappropriate
        activities, monitor content integrity, conduct security investigations
        and risk assessments, verify or authenticate information provided by
        you, enforce our Terms of Service and comply with applicable laws. We
        conduct certain behavioural analytics to achieve the above objectives
        and in limited cases, if we detect activity that we think poses a risk
        to the PassionStocks marketplace, other users, our community, or third
        parties, automated processes may restrict or limit your ability to use
        PassionStocks. If you would like to challenge any such decision, please
        contact us at privacy@PassionStocks.com.
        <br /> • to contact you, as requested by you or as otherwise approved by
        you or permitted according to this Policy.
        <br />• to promote and advertise the Site and the PassionStocks
        marketplace. For example, we use the information collected from you for
        the purpose of sending direct marketing messages (as detailed below), to
        show you information that may be of interest to you, to organize and
        facilitate referral programs, contests or other promotional activities
        or events.
        <br />• to maintain appropriate business records, to comply with lawful
        requests by public authorities and to comply with applicable laws and
        regulations or as otherwise required by law.
        <br />
        We will ask for your consent before using information for a purpose
        other than those set out in this Policy.
        <br />
        <br />
        <span>Direct marketing:</span>
        <br />
        • We use your personal information to send you direct marketing
        communications about our products, services or promotions from
        PassionStocks that may be of interest to you or our related services.
        This may be via email, post, SMS, telephone or targeted online
        advertisements.
        <br />• Our processing of your personal information for marketing
        purposes is based on our legitimate interest, or on your consent, or as
        otherwise permitted according to applicable laws. You have a right to
        prevent direct marketing of any form at any time - this can be exercised
        by following the opt-out link attached to each communication or by
        sending an email to our customer service.
        <br />• We take steps to limit direct marketing to a reasonable and
        proportionate level, and to send you communications which we believe may
        be of interest or relevance to you, based on the information we have
        about you.
        <br />
        <span>How Long Do We Keep Personal Information?</span>
        <br />
        We apply a general rule of keeping personal information only for as long
        as is required to fulfil the purpose for which it was collected.
        However, in some circumstances, we will retain your personal information
        for longer periods of time. We will retain personal information for the
        following purposes:
        <br />• as long as it is necessary and relevant for our operations, e.g.
        so that we have an accurate record of your dealings with us in the event
        of any complaints or challenge; and
        <br />• in relation to personal information from closed accounts to
        comply with applicable laws, prevent fraud, collect any fees owed,
        resolve disputes, troubleshoot problems, assist with any investigation,
        enforce our Site terms and take other actions as permitted by law.
        <br />
        <br />
        <span>Children Under the Age of 13</span>
        <br />
        Our Site is not intended for children under 13 years of age. No one
        under age 13 should provide any personal information to or on the Site.
        We do not knowingly collect personal information from children under 13.
        Parents and guardians should at all times supervise their children&#39;s
        activities. If we learn we have collected or received personal
        information from a child under 13, we will delete that personal
        information. If you believe we might have any information from or about
        a child under 13, please contact us at privacy@PassionStocks.com.
        <br />
        <br />
        <span>Sharing Personal Information with Third Parties</span>
        <br />
        We do not sell or rent your personal information to third parties for
        their marketing purposes without your explicit consent.
        <br />
        <br />
        <span>
          We share your personal information with third parties as detailed
          below:
        </span>
        <br />
        • We share personal information with service providers that provide us
        with services for the purpose of operating the Site, opening and
        operating your account as well as providing ancillary services and
        solutions. These include, among others, hosting services, billing and
        payment vendors, CRM systems, forum hosting, community management
        services, data and cybersecurity services, web analytics and performance
        tools, translation tools, IT SaaS services, session recording,
        communication systems, mailing systems, data optimization and marketing
        services, data enrichment services, legal and financial advisors or
        technical consultants. Consistent with applicable legal requirements, we
        take commercially reasonable steps to require third parties to
        adequately safeguard your personal information and only process it in
        accordance with our instructions;
        <br />• We share personal information to law enforcement agencies,
        public authorities or other parties in order to respond to a subpoena or
        court order, judicial process or to regulatory authorities, if we
        believe we are required to do so by law, or that doing so is reasonably
        necessary to comply with legal processes; when we believe it necessary
        or appropriate to disclose personal information to law enforcement
        authorities, such as to investigate actual or suspected fraud or
        violations of law, breaches of security, or breaches of this Policy; to
        respond to claims against us; and to protect the rights, property, or
        personal safety of PassionStocks, our customers, or the public;
        <br />• We share personal information with payment processors, fraud
        detection agencies and similar third parties for the purpose of
        facilitating payments done via the Site and securing the Site and
        protecting it against fraud, unauthorized transactions (such as money
        laundering), claims or other liabilities;
        <br />• Our Site includes certain social media features, including
        single sign on features. We also share limited personal information with
        such social media platforms and marketing partners, such as Facebook and
        Google, to promote our services or the PassionStocks marketplace. Such
        features and third party services collect certain information about you
        and may set a cookie to enable their functionality. Such third parties’
        features are governed by their policies.
        <br />• We share personal information to help facilitate interactions
        between Users. For example, if you are a Buyer we may share certain
        limited information about you (such as the fact it is your first
        purchase on PassionStocks) with the Seller to ensure the services you
        receive from the Seller will fit your needs; such sharing of information
        is limited in scope, will not include personal identifying information
        (PII) which is not already available to the Seller via the marketplace,
        and is only intended to improve the services provided by the Seller and
        your user experience;
        <br />• We share personal information with PassionStocks’s corporate
        affiliates (including third- party service providers) that are related
        by common control for the purpose of operating the Site, providing our
        services to you and for other purposes listed herein; and
        <br />• Personal information will also be disclosed if we go through a
        business transition such as a merger, sale, transfer of all or a portion
        of PassionStocks's assets, acquisition, bankruptcy or similar event. In
        the event that we sell any business or assets, we will disclose your
        data to the prospective buyer. If we or substantially all of our assets
        are acquired by a third party, information held by us about our users
        will be one of the transferred assets. Please note, your profile
        information and additional information regarding your activity
        (including services or products, reviews, posts on our forum, etc.) is
        made public and is visible to all Users of the Site.
        <br />
        When you publish your information and/or share your data with other
        PassionStocks Users, you do so at your own risk.
        <br />
        If you choose to share personal information with other users, including
        Sellers providing you a service via PassionStocks, such personal
        information is shared with other users as separate controllers. While
        PassionStocks strives to protect your privacy and our Terms of Service
        require our Users to keep the information shared with them confidential,
        PassionStocks cannot ensure that all Users will do so and it is your
        responsibility to ensure that other users will comply with your
        standards.
        <br />
        This Site is also protected by reCAPTCHA and the  Google Privacy Policy
         and  Terms of Service  apply with respect to data collected by
        reCAPTCHA. The use of Google reCAPTCHA is required in order to prevent
        fraud and misuse of the Site by use of automated machines. To learn
        more, please see  Google's privacy policy .<br />
        <br />
        Where We Store Your Personal Information
        <br />
        Some of the personal information you provide to us will be stored or
        processed on our behalf by third party suppliers and data processors and
        may be located in other jurisdictions, such as the United States, Israel
        and/or the Republic of South Africa, whose laws may differ from the
        jurisdiction in which you live. Whether to third parties or internally,
        any transfers of personal information from the European Economic Area
        (“EEA”) to countries not deemed to provide an adequate level of data
        protection are governed by European Union (EU) standard contractual
        clauses and/or equivalent data transfer regulations to protect the
        security and confidentiality of personal information. We will take all
        steps reasonably necessary to ensure that your personal information is
        treated securely and in accordance with this Policy.
        <br />
        <br />
        <span>Cookies</span>
        We use cookies and similar technologies (such as web beacons, pixels,
        tags, and scripts) to improve and personalize your experience, provide
        our services, analyze website performance and for marketing purposes. To
        learn more about how we and out third party service providers use
        cookies and your control over these Cookies, please see our
        <span> Cookie Policy</span> .
        <br />
        Do Not Track
        <br />
        Do Not Track (DNT) is a privacy preference that users can set in some
        web browsers, allowing users to opt out of tracking by websites and
        online services. We do not honor browser requests not to be tracked
        online (known as “Do Not Track”), but our Cookie Policy describes how
        you can opt out of receiving cookies.
        <br />
        <br />
        <span>External Links</span>
        Please note that the Site contains links to third party sites and if you
        link to a third party site from the Site, any data you provide to that
        site and any use of that data by the third party are not under the
        control of PassionStocks and are not subject to this Policy. You should
        consult the privacy policies of each site you visit. This Policy applies
        solely to personal information collected by our Site. If you upload
        content, including personal information, to a social network and then
        tag the Site, your submission will be subject to that social network's
        terms of use and privacy policy, even where you post on an official
        PassionStocks page on the social network. We do not have control over
        these terms of use and privacy policies and have not reviewed their
        adequacy. You should therefore review these before submitting any
        personal information.
        <br />
        <span>Security</span>
        <br />
        We take great care in maintaining the security of the Site and your
        information and in preventing unauthorized access, loss, misuse,
        alteration, destruction or damage to it through industry standard
        technologies and internal procedures. Among other things, we regularly
        maintain a PCI DSS (Payment Card Industry Data Security Standards)
        certification (with respect to payment by credit cards). In addition, we
        contractually ensure that any third party processing your personal
        information equally provides for confidentiality and integrity of your
        data in a secure way. However, the transmission of data via the internet
        is not completely secure, and although we will do our best to protect
        your personal information, we cannot guarantee the security of your data
        transmitted to the Site; any transmission is at your own risk. Once we
        have received your data, we will use strict procedures and security
        features to try to prevent unauthorized access. Users who have
        registered to the Site agree to keep their password in strict confidence
        and not disclose such password to any third party. Further information
        about our data security practices can be provided on request.
        <br />
        <br />
        <span>Rights of EU Users</span>
        <br />
        Under applicable EU regulation, you have the following rights in respect
        of your personal information:
        <br /> • to obtain a copy of your personal information together with
        information about how and on what basis that personal information is
        processed;
        <br /> • to rectify inaccurate personal information;
        <br />• to erase your personal information in limited circumstances
        where (a) you believe that it is no longer necessary for us to hold your
        personal information; (b) we are processing your personal information on
        the basis of legitimate interests and you object to such processing, and
        we cannot demonstrate an overriding legitimate ground for the
        processing; (c) where you have provided your personal information to us
        with your consent and you wish to withdraw your consent and there is no
        other ground under which we can process your personal information; and
        (d) where you believe the personal information we hold about you is
        being unlawfully processed by us;
        <br />• to restrict processing of your personal information where: (a)
        the accuracy of the personal information is contested; (b) the
        processing is unlawful but you object to the erasure of the personal
        information; (c) we no longer require the personal information for the
        purposes for which it was collected, but it is required for the
        establishment, exercise or defense of a legal claim or (d) you have
        objected to us processing your personal information based on our
        legitimate interests and we are considering your objection;
        <br />• to object to decisions which are based solely on automated
        processing or profiling;
        <br />• where you have provided your personal information to us with
        your consent, to ask us for a copy of this data in a structured,
        machine-readable format and to ask us to share (port) this data to
        another data controller; or
        <br />• to obtain a copy of or access to safeguards under which your
        personal information is transferred outside of the EEA.
        <br />
        In addition to the above, you have the right to lodge a complaint with a
        supervisory authority for data protection.
        <br />
        We will ask you for additional data to confirm your identity and for
        security purposes, before disclosing data requested by you. We reserve
        the right to charge a fee where permitted by law. We will decline to
        process requests that jeopardize the privacy of others, are extremely
        impractical, or would cause us to take any action that is not
        permissible under applicable laws. Additionally, as permitted by
        applicable laws, we will retain where necessary certain personal
        information for a limited period of time for record-keeping, accounting
        and fraud prevention purposes.
        <br />
        <br />
        <span>Specific Provisions for California Residents</span>
        <br />
        This section of the Policy applies to you, if you are a California
        resident. During the last twelve (12) months we have collected the
        following categories of personal information from Users:
        <br />• Information that you chose to upload or otherwise provided by
        you to PassionStocks, which may include: (i) Identifiers and personal
        information, such as name, postal addresses, online identifiers, email
        addresses, passport number or driving license number, social security
        number; (ii) characteristics of protected classifications, such as
        gender; facial image; audio, electronic or similar information;
        professional or employment-related information; and education
        information.
        <br /> • Information we collect when you use PassionStocks, including
        (i) Identifiers and personal information, such as online identifiers,
        internet protocol (IP) addresses, access device and connection
        information such as browser type, version, and time zone setting and
        browser plug-in types and versions; (ii) commercial information,
        including products or services purchased, obtained, or considered, or
        other purchasing or consuming histories or tendencies; (iii) Internet or
        other electronic network activity information, including, but not
        limited to log-in and log-out time, the duration of sessions, the
        content uploaded and downloaded, viewed web-pages or specific content on
        web-pages, activity measures; (iv) Location information, based on your
        IP address.
        <br />• Inferences drawn from any of the information identified above to
        create a profile about you.
        <br />• We obtain personal information listed above from third party
        sources, as detailed in the section titled “How Do We Collect
        Information” above.
        <br />
        We use the personal information that we collect or receive for the
        business purposes as described above under the Section titled “How Do We
        Use the Information Collected?”. We disclose personal information to
        third parties for business purposes as described above under the Section
        titled “Sharing Personal Information with Third Parties” in the Privacy
        Policy. In the preceding twelve (12) months, we have disclosed all the
        categories of personal information detailed above for business purposes.
        As previously mentioned in this Policy, we do not sell (as such term is
        defined in the CCPA) personal information and will not sell it without
        providing Users with a right to opt out. You are entitled to the
        following specific rights under the CCPA in relation to personal
        information related to you:
        <br />• you have a right to request that we will disclose certain
        information to you about our collection and use of personal information
        related to you over the past 12 months. After verifying your request, we
        will disclose to you: (i) The categories of personal information that we
        collect about you; (ii)The categories of sources from which the personal
        information is collected; (iii) The purposes for collecting, using, or
        selling that personal information. (iv) The categories of personal
        information that we disclosed for a business purpose or sold, and the
        categories of third parties to whom we disclosed or sold that particular
        category of personal information. (v) The specific pieces of personal
        information that we have collected about you. (vi) If we disclosed your
        personal information for a business purpose, the categories of personal
        information we have disclosed. (vii) If we sold your personal
        information, the categories of personal information we have sold.
        <br />• You have a right to request that we delete personal information
        related to you that we collected from you under certain circumstances
        and exceptions.
        <br />• You also have a right not to be discriminated against for
        exercising your rights under the CCPA.
        <br />
        To make such requests,
        <i>
          {" "}
          we kindly ask that you would contact our customer service. If you do
          not have an active PassionStocks account, please contact us at
          privacy@PassionStocks.com.
        </i>
        <br />
        We will verify your request using the information associated with your
        account, including email address. Government identification may also be
        required. A request for access can be made by you only twice within a
        12-months period. Any disclosures that we provide will only cover the
        12-months period preceding receipt of your request. We do not charge a
        fee to process or respond to your verifiable User request unless it is
        excessive, repetitive, or manifestly unfounded. If we determine that the
        request warrants a fee, we will inform you of the reasons for such
        decision and provide you with a cost estimate before processing further
        your request.
        <br />
        <br />
        <span>Updating Personal Information</span>
        <br />
        We take steps to ensure that the personal information we collect is
        accurate and up to date, and we provide you with the opportunity to
        update your information through your account profile settings. In the
        event that you believe your information is in any way incorrect or
        inaccurate, please let us know immediately.
        <br />
        We will make sure we investigate the matter and correct any inaccuracies
        as quickly as possible where necessary or give you ways to update it
        quickly or to delete it - unless we have to keep that information for
        legitimate business or legal purposes. When updating your personal
        information, we will ask you to verify your identity before we can act
        on your request.
        <br />
        If for any reason you have a problem with deleting your personal
        information, please contact PassionStocks's Customer Support and we will
        make reasonable efforts to delete any such information pursuant to any
        applicable privacy laws. You can review and change your personal
        information by logging into the Site and visiting your account profile
        page.
        <br />
        <i>
          If you delete your content or any other details in relation/s with you
          - from the Site, copies of your content or detailed may remain
          viewable in cached and archived pages, or might have been copied or
          stored by other Site users. Proper access and use of information
          provided on the Site, including any type content , is governed by our
          Terms of Service.
        </i>
        <br />
        <br />
        <span>Contact Us</span>
        <br />
        To exercise any of your rights in connection with your personal
        information, we kindly ask that you open a  Customer Relations ticket .
        Please include all the relevant details, so your ticket can be handled
        correctly. We will process any requests in line with any local laws and
        our policies and procedures. If you do not have an active PassionStocks
        account, <i>please contact us at privacy@ PassionStocks.com.</i>
        <br />
        If you have any questions (or comments) concerning this Policy, please
        email our team at<i> privacy@ PassionStocks.com</i>, and we will make an
        effort to reply within a reasonable timeframe.
        <br />
        For the purposes of GDPR (Article 27), you may contact us at{" "}
        <i>privacy@ PassionStocks.com.</i>
      </div>
    </div>
  );
}
