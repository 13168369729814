import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AnimateKeyframes } from "react-simple-animate";
import axios from "axios";
import { useSelector } from "react-redux";
import {  useParams } from "react-router";

import "./style.css";

import store from "../../../../store/store";

// import unauthorized from "../../functions/unauthorized";

import SharingBtn from "../../../../assets/images/common/share.png";
// import ProductTag from "../../images/icons/ProductTag.png";

// import getRatingColour from "../../functions/ringColour.js";

// import StockDetails from "../StockDetails.js";
import StockComment from "../../exchange/components/StockComment";
import SaleOfferCard from "../../../../components/common/SaleOfferCard";
import SharePopUp from "../../../../components/common/SharePopUp";
import SocialMediaValues from "../../../../components/common/SocialMediaValues";
import GeneralAppMessage from "../../../../components/common/GeneralAppMessage";

import loadStockView from "../../../../loaders/sale";

const selectTokens = (state) => state.tokens;
const selectStockView = (state) => state.sale;
const selectSaleId = (state) => state.saleId;
const selectWallet = (state) => state.wallet;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function SaleView(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const navigate = useNavigate();

  const tokens = useSelector(selectTokens);
  const stockView = useSelector(selectStockView);
  const saleId = useSelector(selectSaleId);
  const wallet = useSelector(selectWallet);

  let { brandUsername } = useParams();

  useEffect(() => {
    if ((tokens)&&(saleId)) {
      loadStockView(tokens, saleId);
      updateViews1Sec(saleId);
      setTimeout(() => updateViews3Sec(saleId), 3000);
    }
    else{
      navigate("/exchange");
    }
    return () => {
      store.dispatch({ type: "domain/sale/delete", payload: null });
      // store.dispatch({ type: "app/saleId/delete", payload: null });
    };
  }, [saleId]);

  

  const [ErrorMessage, setErrorMessage] = useState(null);

  const [confirmPurchaseMessageDM, setconfirmPurchaseMessageDM] =
    useState(false);

  const handleBuyStockNotIPO = async (event) => {
    setconfirmPurchaseMessageDM(false);

    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/sales/` + saleId + `/buy`,
      headers: {
        Authorization: tokens.accessToken, // can't call hooks from this function (to get the accessToken)
      },
      data: {
        // price: stockView.price,
      },
    })
      .then(function (response) {
        // console.log("bought Non IPO Stocks");
        // console.log(response);
        store.dispatch({
          type: "app/buyP2PSaleData/add",
          payload: { purchaseData: response.data, saleData: stockView },
        });

        navigate("/exchange/sale-completed", { replace: true });
      })
      .catch(async function (error) {
        // console.log("Error to buy IPO stock");
        // console.log(error.response ? error.response.data : error);
        setErrorMessage(
          error.response
            ? error.response.data.errors[0]
            : "Sorry, some problem to complete the process"
        );
        // setTimeout(function(){setPromtMessage(null); }, 3000);
      });
  };

  const [SharePopUpDisplay, setSharePopUpDisplay] = useState(false);

  const [isSellerOrSMI, setisSellerOrSMI] = useState(false);
  useEffect(() => {
    if (stockView) {
      if (stockView.seller.id === store.getState().profile.id) {
        setisSellerOrSMI(true);
      } else {
        setisSellerOrSMI(false);
      }
    }
  }, [stockView]);

  // console.log(stockView);

  return saleId && stockView && wallet ? (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div className="SaleView Page" style={{ height: windowInnerSize.height }}>
        <div className="Page--flex" style={{ paddingBottom: "45vw" }}>
          {getStocksList(stockView)}

          {/* <div id={ErrorMessage ? "ErrorMessageSV" : "HideESV"} className="headline">{ErrorMessage}</div> */}

          <div className="SaleView__BrandDetails headline">
            {getBrandSMIInvPics(stockView)}
            <p
              className={
                stockView.isIpo
                  ? "SaleView__BrandNameIPO"
                  : "SaleView__BrandName"
              }
            >
              Brand: {stockView.isIpo ? <br /> : null}{" "}
              {stockView.stock.influencer.user.name}
            </p>
            {!stockView.isIpo ? (
              <p className="SaleView__TraderName">
                Trader: {stockView.seller.name}
              </p>
            ) : null}
            {stockView.isIpo ? (
              <p className="SaleView__IPOStocksLeftTxt">
                <span>{stockView.volume}</span> left
              </p>
            ) : null}
            <img
              src={SharingBtn}
              alt=""
              className="SaleView__SharingBtn"
              onClick={() => {
                if (!SharePopUpDisplay) {
                  setSharePopUpDisplay(true);
                }
              }}
            />
            {SharePopUpDisplay && (
              <div
                className="SaleView__UnshareByClickingOut"
                onClick={() => {
                  if (SharePopUpDisplay) {
                    setSharePopUpDisplay(false);
                  }
                }}
              />
            )}
            {SharePopUpDisplay && (
              <div className="SharePUStockVPos">
                <SharePopUp
                  saleId={saleId}
                  brandUsername={stockView.stock.influencer.user.username}
                  sharingType={0x1}
                />
              </div>
            )}
          </div>
          <div className="SaleView__SMValuesPos">
            <SocialMediaValues
              SocialMedia={stockView.stock.influencer.socialMedia}
              dominatingPlatform={
                stockView.stock.influencer.dominatingPlatform.platform
              }
            />
          </div>

          {/* <svg id="ProductTagCircle" height="17vw" width="17vw">
                    <circle cx="40%" cy="40%" r="36%" stroke="white" strokeWidth="3" fill="white" />
                </svg>
                <img src={ProductTag} alt="" id="ProductTag" />
                <p id="StoryMention">Story mention</p> */}
          {/*getSMISocialMediaDetails()*/}
          {/* <div id="GrowthData">
                    <div id="ProjectedValue">
                        <p className="ValueOfGrowth">{'R901'}</p>
                        <p className="TypeOfGrowth">Projected Value</p>
                    </div>
                    <div id="GrowthFromIPO">
                        <p className="ValueOfGrowth">{'32 %'}</p>
                        <p className="TypeOfGrowth">Growth from IPO</p>
                    </div>
                    <div id="GrowthRate">
                        <p className="ValueOfGrowth">{'832M'}</p>
                        <p className="TypeOfGrowth">Growth Rate</p>
                    </div>
                    <p id="Hashtags" className="headline">{'#dance #tiktok #growth #instagram'}</p>
                </div> */}
          <div className="SaleView__Buttons headline">
            <Link to={"/public-profile/" + stockView.stock.influencer.id}>
              <p className="SaleView__ViewProfileBtn">VIEW PROFILE</p>
            </Link>
            {/* <Link to={isSellerOrSMI ? null : store.getState().wallet ? (store.getState().wallet.withdrawalValue < stockView.price ? ("/Deposit/" + (stockView.price - store.getState().wallet.withdrawalValue)) : (stockView.isIpo ? ("/Exchange/IPO_stock_view/buy/" + Stock) : null)) : ""}> */}
            {!isSellerOrSMI && (
              <p
                className={
                  //  ? "SaleView__BuyBtn--off" :
                  "SaleView__BuyBtn"
                }
                onClick={() => {
                  let minBalance = stockView.isIpo
                    ? stockView.price
                    : stockView.price * stockView.volume;
                  if (!isSellerOrSMI) {
                    if (wallet.withdrawalValue < minBalance) {
                      navigate(
                        "/wallet/deposit/" +
                          (minBalance - wallet.withdrawalValue)
                      );
                    } else {
                      if (stockView.isIpo) {
                        navigate("/exchange/ipo-sale/amount-selection", {
                          state: { process: "BUY" },
                        });
                      } else {
                        setconfirmPurchaseMessageDM(true);
                      }
                    }
                  }
                }}
              >
                BUY
              </p>
            )}
            {/* </Link> */}
          </div>
        </div>
        {confirmPurchaseMessageDM && (
          <GeneralAppMessage
            text={"Do you approve the purchase?"}
            onClickYes={handleBuyStockNotIPO}
            onClickNo={() => {
              setconfirmPurchaseMessageDM(false);
            }}
            class="Width-90vw"
          />
        )}
      </div>
    </AnimateKeyframes>
  ) : null;

  function getStocksList(Stockt) {
    return (
      <div className="SaleView__SaleCardAndComment">
        <SaleOfferCard SaleOffer={Stockt} />
        <StockComment SaleOffer={Stockt} />
      </div>
    );
  }

  function updateViews1Sec(saleId) {
    var axios = require("axios");

    var config = {
      method: "post",
      url: process.env.REACT_APP_BACKEND_URL + "/api/sales/view",
      headers: {
        Authorization: tokens.accessToken,
      },
      params: {
        type: "EXTENDED",
        saleId: saleId,
      },
    };

    axios(config)
      .then(async function (response) {
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  function updateViews3Sec(saleId) {
    var axios = require("axios");

    var config = {
      method: "post",
      url: process.env.REACT_APP_BACKEND_URL + "/api/sales/3-seconds-view",
      headers: {
        Authorization: tokens.accessToken,
      },
      params: {
        type: "EXTENDED",
        saleId: saleId,
      },
    };

    axios(config)
      .then(async function (response) {
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function getBrandSMIInvPics(sale) {
    let ringColour = sale.stock.influencer.ringColour;
    let ratingNumColour = sale.stock.influencer.ringColour;
    let ratingNum = sale.stock.influencer.ranking;
    return (
      <div className="SaleView__OwnersPics">
        <div
          className={
            (sale.isIpo ? "SaleView__SMIPicIPO " : "SaleView__SMIPicP2P ") +
            ringColour +
            "RC"
          }
          style={{
            backgroundImage: `url(${sale.stock.influencer.user.profilePic})`,
          }}
        />
        <p
          className={
            (sale.isIpo
              ? "SaleView__SMIRatingIPO "
              : "SaleView__SMIRatingP2P ") +
            (ratingNumColour + "RN")
          }
        >
          {ratingNum}
        </p>
        {!sale.isIpo ? (
          <div
            className="SaleView__TraderPic"
            style={{ backgroundImage: `url(${sale.seller.profilePic})` }}
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
  //     function getSMISocialMediaDetails() {
  //         return (
  //             <div id="SMISocialMediaDetails">

  //             </div>
  //         )
  //     }

  //     async function updateViews() {

  //         let renderOnce = false;
  //         if ((props.saleOffers) && (!renderOnce)) {
  //             await axios({
  //                 method: 'POST',
  //                 url: `https://web.passionstocks.com/api/sale-offers/` + StockDetails.id + `/view?api_token=` + store.getState().profile.token,

  //             }).then(async function (response) {
  //                 // console.log("post view");
  //             }).catch(function (error) {
  //                 // console.log('error post view');
  //             })
  //             renderOnce = true;
  //         }
  //     }
}
