import "./style.css";

import store from "../../../../store/store";

import { AnimateKeyframes } from "react-simple-animate";

import BackPage from "../../../../assets/images/common/back-page.png";
import PayPalLogo from "../../../../assets/images/wallet/paypal-icon.png";
import OzowLogo from "../../../../assets/images/wallet/ozow-icon.png";
// import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import loadWallet from "../../../../loaders/wallet";
import fixFractionDigits from "../../../../helpers/fixFractionDigits";

const selectTokens = (state) => state.tokens;
const selectProfile = (state) => state.profile;
const selectWallet = (state) => state.wallet;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Deposit(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const tokens = useSelector(selectTokens);
  const profile = useSelector(selectProfile);
  const wallet = useSelector(selectWallet);

  useEffect(() => {
    if (tokens && profile) {
      loadWallet(tokens, profile.accountType);
    }
  }, []);

  let { amount } = useParams();

  const navigate = useNavigate();

  const [Volume, setVolume] = useState(0);
  // const [exchangeToPC, setexchangeToPC] = useState(null);
  // const [OzzowParams, setOzzowParams] = useState(null);

  useEffect(() => {
    setVolume((+amount).toFixed(2));
  }, [amount]);

  // Not relevant since the use of the PC was cancelled: 28/12/22
  // function getRate() {
  //   // console.log("get rate");
  //   var axios = require("axios");
  //   var config = {
  //     method: "get",
  //     url:
  //       process.env.REACT_APP_BACKEND_URL +
  //       "/api/exchange/?volume=1&from=PC&to=ZAR",
  //     headers: {
  //       Authorization: tokens.accessToken,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       // console.log(JSON.stringify(response.data));
  //       setexchangeToPC(response.data);
  //     })
  //     .catch(function (error) {
  //       // console.log(error);
  //       setexchangeToPC(null);
  //     });
  // }

  const submitOzzow = async () => {
    if (Volume) {
      // console.log(Volume+wallet.currency.symbol);

      var axios = require("axios");
      var data = {
        amount: Volume,
        currency_code: wallet.currency.code,
      };

      var config = {
        method: "post",
        url: process.env.REACT_APP_BACKEND_URL + "/api/ozow/create-payment",
        headers: {
          Authorization: tokens.accessToken,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          if(response.data.redirectUrl){
          window.location.replace(response.data.redirectUrl);
          }else{
            alert(response.data.errorMessage);
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  };

  // const [finishTyping, setfinishTyping] = useState(false);
  // useEffect(() => {
  //     if (Volume) {
  //         setTimeout(() => { setfinishTyping(true) }, 1000); // waiting for the user finish typing
  //     }
  // }, [Volume])
  // useEffect(() => {
  //     if (finishTyping) {
  //         getRate();
  //     }
  // }, [finishTyping])

  const handleInsertVolume = (event) => {
    setVolume(+event.target.value);
  };

  const handleSubmitPayPal = () => {
    if (Volume) {
      // console.log(Volume);
      // let volumeInUSD = getRate() * 0.1;
      var axios = require("axios");
      var data = {
        total: Volume, // +volumeInUSD,
        currency: wallet.currency.code,
      };

      var config = {
        method: "post",
        url: process.env.REACT_APP_BACKEND_URL + "/api/paypal/create-payment",

        headers: {
          Authorization: tokens.accessToken,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          window.location.replace(response.data.redirectUrl);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  };
  return (
    wallet && (
      <AnimateKeyframes
        play={true}
        iterationCount={1}
        duration={0.42}
        direction="normal"
        keyframes={[
          "transform: translateX(100vw)",
          "transform: translateX(50vw)",
          "transform: translate(0, 0)",
        ]}
      >
        <div
          className="Deposit Page headline"
          style={{ height: windowInnerSize.height }}
        >
          <img
            src={BackPage}
            alt=""
            className="back-btn"
            // style={{top}}
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className="Page--flex">
            <p className="Deposit__Balance unnamed-character-style-1">
              {wallet.currency.symbol + fixFractionDigits(wallet.withdrawalValue)}
            </p>

            <div style={{ position: "relative",height:'9vw' }}>
              <p className="Deposit__DepositTxt">Deposit amount</p>
              <div style={{ position: "relative" }}>
                <input
                  min={0}
                  type="number"
                  value={Volume === 0 ? null : Volume}
                  placeholder={0}
                  className="Deposit__AmountInput input-default"
                  onChange={handleInsertVolume}
                />
                <p className="Deposit__CurrenceyCodeTxt">{wallet.currency.symbol}</p>
              </div>
              {/* Not relevant since the use of the PC was cancelled: 28/12/22
              <p className="Deposit__AmountInPC">
                {wallet.currency +
                  (exchangeToPC && (Volume || Volume === 0)
                    ? fixFractionDigits(Volume / exchangeToPC)
                    : "--")}
              </p> */}
            </div>

            <svg height="2vw" width="33%" className="Deposit__Line">
              <line
                x1="0"
                x2="100%"
                y1="0"
                y2="0"
                stroke="white"
                stroke-width="100%"
                stroke-linecap="butt"
              />
            </svg>
            <div style={{ position: "relative" }}>
              <p className="Deposit__ChooseTxt unnamed-character-style-1">
                CHOOSE YOUR <br /> PAYMENT METHOD
              </p>
              <div className="Deposit__btns-conatainer">
                <div style={{ position: "relative" }}>
                  <p
                    className="Deposit__PayPalBtn"
                    onClick={handleSubmitPayPal}
                  >
                    PayPal
                  </p>
                  <img
                    src={PayPalLogo}
                    alt=""
                    className="Deposit__PayPalLogo"
                  />
                </div>
                <div style={{ position: "relative" }}>
                  <p className="Deposit__OzowBtn" onClick={submitOzzow}>
                    Ozow
                  </p>
                  <img src={OzowLogo} alt="" className="Deposit__OzowLogo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AnimateKeyframes>
    )
  );
}
