import "./style.css";

import { AnimateKeyframes } from "react-simple-animate";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import axios from "axios";

import store from "../../store/store";
import fixFractionDigits from "../../helpers/fixFractionDigits";

import StockCreativeCard from "../../components/common/creative-cards/Influencer";
import StockEngagement from "../../components/common/StockEngagement";

import BackPage from "../../assets/images/common/back-page.png";
import unicorn from "../../assets/images/common/unicorn.png";
import info from "../../assets/images/common/info.png";
import greenArrow from "../../assets/images/common/arrow-up.png";
import redArrow from "../../assets/images/common/arrow-down.png";

import BrandUnitsRange from "./components/BrandUnitsRange";
import Tabs from "../../components/common/Tabs";

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectProfile = (state) => state.profile;
const selectTransactions = (state) => state.transactions;

export default function MyBrand(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });

  const windowInnerSize = useSelector(selectWindowInnerSize);
  const profile = useSelector(selectProfile);
  const transactions = useSelector(selectTransactions);

  const navigate = useNavigate();

  const [tabSelected, settabSelected] = useState("Stats");

  const [cardData, setcardData] = useState(null);
  useEffect(() => {
    let cardData = profile
      ? {
          profilePic: profile.profilePic,
          name: profile.name,
          username: profile.username,
          ranking: profile.influencer.ranking,
          ringColour: profile.influencer.ringColour,
          socialMedia: {
            tiktok: null,
            instagram: { follows: 120 },
            youtube: null,
          } /* profile.influencer.socialMedia */,
          niches: profile.influencer.niches,
          city: profile.city,
          currentPrice: profile.issuer.stock.marketPrice,
          currency: profile.influencer.stock.currency.symbol,
          lastChange: profile.influencer.stock.lastChange,
        }
      : null;
    setcardData(cardData);
  }, [profile]);

  // fake data:
  let myBrand = {
    worth: {
      current: "$100K",
      goal: "$5M",
    },
    desiredWorth: {
      current: "$100K",
      goal: "$280M",
    },
    theoreticalWorth: "$24.9M",
    growth: {
      average: "2.5%",
      current: "3%",
      goal: "5%",
    },
    earnings: {
      average: "$1.5K",
      current: "$1K",
      goal: "$1.8K",
    },
    annuallyGrowth: {
      current: "45%",
      goal: "180%",
    },
    annualEarnings: {
      current: "10K",
      goal: "538K",
    },
    fundamentals: {
      totalAuthorized: "25k",
      authorized: "24k",
      availableToGoLive: "800",
      onSaleFirstHand: "100",
      sold: "40",
      deleted: "60",
    },
    metrics: {
      growth: { status: 1, volume: "103%" },
      profit: { status: 1, volume: "$7.5K" },
      highestPrice: { status: 1, volume: "$800" },
      frequency: { status: -1, volume: "1.2K" },
      views: { status: 1, volume: "4.3K" },
      volume: { status: 1, volume: "2.3K" },
    },
    vitality: {
      engagement: {
        shares: 20,
        myCreation: 2,
        storyMention: 40,
      },
      others: {
        score: "50M",
        tier: "5",
      },
      color: "#ffbf12",
    },
  };

  let colors = ["#ff2424", "#fc8935", "#ffbf12", "#34c100", "#0200ff"];

  // date:
  function getFullDate() {
    let date = new Date();
    let text =
      date.getDate() +
      " " +
      getMonthName(date.getMonth()) +
      " " +
      date.getFullYear();
    return text;
  }

  //
  function getElementByKeyValue(obj) {
    let elements = [];
    for (const [key, value] of Object.entries(obj)) {
      let convertKey = key.charAt(0).toUpperCase();
      for (let i = 1; i < key.length; i++) {
        convertKey =
          key.charAt(i).toUpperCase() === key.charAt(i)
            ? convertKey + " " + key.charAt(i)
            : convertKey + key.charAt(i);
      }
      elements.push(
        <div className="MyBrand__Stats__Block">
          <p style={{ color: "#707070", fontSize: "3.5vw" }}>{convertKey}</p>
          <p
            style={{
              color:
                typeof value === "object"
                  ? value.status > 0
                    ? "#34c100"
                    : "#e66100"
                  : "#ffffff",
            }}
          >
            {typeof value === "object" ? (
              <div style={{ width: "fit-content", margin: "2vw auto" }}>
                <img
                  src={value.status > 0 ? greenArrow : redArrow}
                  style={{ width: "4.25vw", paddingRight: "1vw" }}
                />
                {value.volume}
              </div>
            ) : (
              value
            )}
          </p>
        </div>
      );
    }
    return elements;
  }

  // going live history table - functions:
  function getTrnsOfMonth(monthYear) {
    // var configTransactions = {
    //   method: "get",
    //   url: process.env.REACT_APP_BACKEND_URL + "/api/wallet/history",
    //   headers: {
    //     Authorization: tokens.accessToken,
    //   },
    //   params: {
    //     year: monthYear.split(".")[1],
    //     month: monthYear.split(".")[0],
    //   },
    // };
    // axios(configTransactions)
    //   .then(async function (response) {
    //     // console.log(JSON.stringify(response.data));
    //     store.dispatch({
    //       type: "domain/transactionHistory/addData",
    //       payload: response.data,
    //     });
    //   })
    //   .catch(function (error) {
    //     // console.log(error);
    //   });
  }
  function getMonthsOptions() {
    let amountOfAvailableMonths =
      1 +
      getMonthDiff(new Date(store.getState().profile.createdAt), new Date());
    let monthsOptionsList = [];
    let monthIndex = new Date().getMonth();
    let yearIndex = new Date().getFullYear();
    let isPastYearAvailable = monthIndex + 2 - amountOfAvailableMonths <= 0;
    for (let i = 0; i < amountOfAvailableMonths; i++) {
      monthsOptionsList.push(
        <option value={monthIndex + 1 + "." + yearIndex}>
          {" "}
          {getMonthName(monthIndex) +
            (isPastYearAvailable ? " " + yearIndex : "")}
        </option>
      );
      yearIndex = monthIndex - 1 === -1 ? yearIndex - 1 : yearIndex;
      monthIndex = monthIndex - 1 === -1 ? 11 : monthIndex - 1;
    }

    return monthsOptionsList;
  }
  function getMonthDiff(d1, d2) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }
  function getMonthName(DateNum) {
    const DatesNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return DatesNames[DateNum];
  }
  function getTransactionsListMain() {
    const TrnsListItem = transactions
      ? transactions
      : [
          {
            created_at: "2021-11-04T12:26:06.000000Z",
            result: "Successful",
            worth: "100K",
            currency: { symbol: "$" },
            amount: 500,
          },
          {
            created_at: "2021-11-05T12:26:06.000000Z",
            result: "Partly Successful",
            worth: "93K",
            currency: { symbol: "$" },
            amount: 500,
          },
          {
            created_at: "2021-11-06T12:26:06.000000Z",
            result: "Failure",
            worth: "90K",
            currency: { symbol: "$" },
            amount: 0,
          },
        ];

    let TransListHtml = [];
    for (let i = 0; i < TrnsListItem.length; i++) {
      let date = new Date(TrnsListItem[i].date).getDate();
      date = date < 10 ? "0" + date : date;
      TransListHtml.push(
        <tr key={"T" + i}>
          <td style={{ paddingRight: "4vw" }}>{date}</td>
          <td>{TrnsListItem[i].result}</td>
          {/* <td>{TrnsListItem[i].currency_code}</td> */}
          <td
            className={
              TrnsListItem[i].amount === 0 ? "NegativeNum" : "PositiveGreenNum"
            }
            style={{paddingRight:'15vw'}}
          >
            {/* {TrnsListItem[i].amount < 0 ? "-" : ""} */}
            <i style={{ padding: "0 0.25vw 0 0" }}>
              {TrnsListItem[i].currency.symbol}
            </i>
            {Math.abs(fixFractionDigits(TrnsListItem[i].amount))}
          </td>
          <td style={{textAlign:'center',width:'20vw'}}>{TrnsListItem[i].worth}</td>
        </tr>
      );
    }
    //push another li for skip from the black shadow
    TransListHtml.push(<tr style={{ height: "5vw", width: "100%" }} />);

    return TransListHtml;
  }

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="MyBrand Page headline"
        // style={{ height: windowInnerSize.height }}
      >
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          onClick={() => {
            navigate("/profile");
          }}
          style={{ top: "5vw" }}
        />

        <p className="MyBrand__Headline glow-yellow-white unnamed-character-style-1">
          MY BRAND
        </p>

        <Tabs
          tab1={"Stats"}
          tab2={"Management"}
          state={tabSelected}
          setState={settabSelected}
        />
        {tabSelected === "Stats" ? (
          <div className="MyBrand__StatsContainer">
            <p className="MyBrand__Stats__Date unnamed-character-style-1">
              {getFullDate()}
            </p>
            <p
              className="MyBrand__Stats_Username glow-yellow-white unnamed-character-style-1"
              style={{ fontSize: "6.95vw" }}
            >
              @{profile.username}
            </p>
            <p
              className="MyBrand__Stats__BrandWorthTxt glow-yellow-white unnamed-character-style-1"
              style={{ fontSize: "5vw" }}
            >
              BRAND WORTH
            </p>
            <p className="MyBrand__Stats__BrandWorthValue glow-yellow-white unnamed-character-style-1">
              {"$100,000"}
            </p>
            <div style={{ position: "relative", width: "90vw" }}>
              <StockCreativeCard cardData={cardData} />
              <div style={{ position: "relative", top: "-1vw" }}>
                <StockEngagement engagement={profile.influencer.engagement} />
              </div>
            </div>
            <div className="MyBrand__Stats__IpoProgressContainer">
              <p
                className="glow-yellow-white unnamed-character-style-1"
                style={{ margin: "14vw auto 4vw", fontSize: "6.5vw" }}
              >
                IPO PROGRESS
              </p>
              <div className="MyBrand__Stats__IpoProgressSliderContainer unnamed-character-style-1">
                <div>
                  <div
                    style={{
                      left: `${100000 / 1000000000 + 4 /* brandWorth*/}%`,
                      color: "#ffbf12",
                      margin: 0,
                      width: "0",
                      fontSize: "3vw",
                      position: "relative",
                      lineHeight: "3.2vw",
                    }}
                  >
                    <p>{"100k"}</p>
                    <p>|</p>
                  </div>
                  <div className="MyBrand__Stats__IpoProgressSlider">
                    <div
                      style={{
                        background: "#ffbf12",
                        margin: 0,
                        borderRadius: "10vw",
                        height: "3vw",
                        width: `${100000 / 10000000 + 4 /* brandWorth*/}%`,
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: "inline" }}>
                  <img src={unicorn} alt="Unicorn" style={{ width: "9vw" }} />
                  <p
                    style={{
                      color: "#ffbf12",
                      fontSize: "3vw",
                      lineHeight: "0vw",
                    }}
                  >
                    $1B
                  </p>
                </div>
              </div>
            </div>
            <div className="MyBrand__Stats__MetricsContainer">
              <div className="MyBrand__Stats__BrandProgMetrics">
                <h2 className="unnamed-character-style-1">BRAND PROGRESS</h2>
                <div className="MyBrand__Stats__Block">
                  <h3>Brand Worth</h3>
                  <BrandUnitsRange units={myBrand.worth} />
                  <p>Only {"5"} months remaining to achieve youe goal</p>
                </div>
                <div className="MyBrand__Stats__Block">
                  <h3>Desired Brand Worth</h3>
                  <BrandUnitsRange units={myBrand.desiredWorth} />
                  <p> Only {"17"} months remaining to achieve your goal</p>
                </div>
                <div className="MyBrand__Stats__Block">
                  <h3>Theoretical Brand Worth </h3>
                  <p>{myBrand.theoreticalWorth}</p>
                  <p>
                    Achieving the theoretical brand worth depends on selling all
                    {"24.9K"} units at the current market price of {"1K"}.{" "}
                  </p>
                </div>
              </div>

              <div className="MyBrand__Stats__BrandProgMetrics headline">
                <h2 className="unnamed-character-style-1">
                  BRAND UNITS PROGRESS
                </h2>
                <div className="MyBrand__Stats__Block">
                  <h3>Monthly Growth Worth</h3>
                  <BrandUnitsRange units={myBrand.growth} />
                  <p>Only {"14"} months remaining to achieve youe goal</p>
                </div>
                <div className="MyBrand__Stats__Block">
                  <h3>Monthly Earnings</h3>
                  <BrandUnitsRange units={myBrand.earnings} />
                  <p> Only {"14"} months remaining to achieve your goal</p>
                </div>
                <div className="MyBrand__Stats__Block">
                  <h3>Annually Growth Rate</h3>
                  <BrandUnitsRange units={myBrand.annuallyGrowth} />
                  <p> Only {"14"} months remaining to achieve your goal</p>
                </div>
                <div className="MyBrand__Stats__Block">
                  <h3>Annual Earnings</h3>
                  <BrandUnitsRange units={myBrand.annualEarnings} />
                  <p> Only {"5"} months remaining to achieve your goal</p>
                </div>
              </div>

              <div>
                <h2 className="unnamed-character-style-1">
                  BRAND UNITS FUNDAMENTALS
                </h2>
                <div className="MyBrand__FundamentalsContainer">
                  {" "}
                  {getElementByKeyValue(myBrand.fundamentals)}
                </div>
              </div>

              <div style={{ marginTop: "5vw" }}>
                <h2 className=" unnamed-character-style-1">
                  BRAND UNITS METRICS
                </h2>
                <div className="MyBrand__FundamentalsContainer">
                  {getElementByKeyValue(myBrand.metrics)}
                </div>
              </div>

              <div style={{ marginTop: "5vw" }}>
                <h2 className="unnamed-character-style-1">BRAND VITALITY</h2>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "55vw",
                    margin: "2vw auto 5vw",
                    position: "relative",
                  }}
                >
                  <img src={info} alt="Info" style={{ width: "6vw" }} />
                  {colors.map((color) => {
                    return (
                      <div
                        className={
                          myBrand.vitality.color === color
                            ? "MyBrand__Vitality__Colour"
                            : ""
                        }
                        style={{
                          width: "5vw",
                          height: "5vw",
                          borderRadius: "10vw",
                          background: color,
                          boxShadow:
                            myBrand.vitality.color === color
                              ? "0 0 0 0.5vw black,0 0 0 1vw white"
                              : 0,
                        }}
                      />
                    );
                  })}
                </div>

                <div
                  className="MyBrand__FundamentalsContainer"
                  style={{ justifyContent: "center" }}
                >
                  {getElementByKeyValue({
                    ...profile.influencer.engagement,
                    ...myBrand.vitality.engagement,
                  })}
                </div>
                <svg height="0.3vw" width="50%" style={{ margin: "3vw 0" }}>
                  <line
                    x1="0"
                    x2="100%"
                    y1="0"
                    y2="0"
                    stroke="rgb(112, 112, 112)"
                    stroke-width="5vw"
                    stroke-linecap="butt"
                  />
                </svg>
                <div
                  className="MyBrand__Stats__Block"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "rgb(112, 112, 112)",
                    fontSize: "3.5vw",
                    height: "24vw",
                  }}
                >
                  <div>
                    <p>Vitality Score</p>
                    <p style={{ fontSize: "3vw" }}>
                      <span style={{ color: "white", fontSize: "4.5vw" }}>
                        {"50M"}
                      </span>{" "}
                      / 1B
                    </p>
                  </div>
                  <p
                    style={{
                      lineHeight: 0,
                      margin: 0,
                      fontSize: "6vw",
                    }}
                  >
                    |
                  </p>
                  <div>
                    <p>Brand Tier</p>
                    <p style={{ fontSize: "3vw" }}>
                      <span style={{ color: "white", fontSize: "4.5vw" }}>
                        {"5"}
                      </span>{" "}
                      / 17
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  position: "relative",
                  margin: "15vw auto",
                  width: "90vw",
                }}
              >
                <div className="MyBrand__GoingLiveHistory__HeaderContainer">
                  <div style={{ margin: "0 " }}>
                    <p
                      className="MyBrand__GoingLiveHistory__Title"
                      style={{ textAlign: "left", fontWeight: "lighter" }}
                    >
                      Going Live History
                    </p>
                    <svg
                      className="MyBrand__GoingLiveHistory__TitleLine"
                      style={{ margin: "7vw 0 3vw", display: "block" }}
                      height="0.5vw"
                      width="33%"
                    >
                      <line
                        x1="0"
                        x2="100%"
                        y1="0"
                        y2="0"
                        stroke="white"
                        stroke-width="100%"
                        stroke-linecap="butt"
                      />
                    </svg>
                  </div>
                  <select
                    name="month"
                    id="MonthSelectListStS"
                    className="headline"
                    onChange={(e) => {
                      getTrnsOfMonth(e.target.value);
                      const table = document.querySelector(
                        ".MyBrand__GoingLiveHistory__DataContainer"
                      );
                      table.scrollTo(0, 0);
                    }}
                  >
                    {getMonthsOptions()}
                  </select>
                </div>
                <table className="MyBrand__GoingLiveHistory__Table">
                  <div className="MyBrand__GoingLiveHistory__Table" style={{display:'flex',justifyContent:'space-between'}}>
                      <p style={{ textAlign: "left",margin:0 }}>Date</p>
                      <p style={{ textAlign: "left",margin:0 }}>Result</p>
                      <p style={{ textAlign: "left",margin:0 }}>Earnings</p>
                      <p style={{ textAlign: "left" ,margin:0}}>Brand Worth</p>
                  </div>
                  <svg height="0.4vw" width="100%">
                    <line
                      x1="0"
                      x2="100%"
                      y1="0"
                      y2="0"
                      stroke="white"
                      stroke-width="5vw"
                      stroke-linecap="butt"
                    />
                  </svg>
                  <div
                    className="MyBrand__GoingLiveHistory__DataContainer"
                    style={{
                      position: "relative",
                      // height: "100vw",
                      overflow: "scroll",
                    }}
                  >
                    {/* {transactions?.length > 0 ? ( */}
                      {getTransactionsListMain()}
                    {/* ) : (
                      <p id="NoTrnsStS">
                        There are no transactions to display
                        <br />
                        in this month.
                      </p>
                    )} */}
                  </div>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </AnimateKeyframes>
  );
}
