import React from "react";
import ImageGallery from "react-image-gallery";
import axios from "axios";

import "./style.css";
import { useState, useEffect } from "react";
// import { Chart } from 'react-google-charts';

import { useSelector } from "react-redux";

import store from "../../../../../store/store";

import isVarNegative from "../../../../../helpers/isVarNegative";

// import ChartStock from '../common/ChartStock';
import ArrowToSlide from "../ArrowToSlide";
// import arrowsSlideStatus from '../../features_redux/wallet/arrowsSlideStatus';
// import SalesLine from '../common/SalesLine';
import MainSlide from "./components/MainSlide";
import IPOShares from "./components/IPOShares";
import ShareHolding from "./components/ShareHolding";
import { ROLES } from "../../../../../tools/role";
import StockHolding from "../StockHolding";
import Loader from "../../../../../components/common/Loader";
import { current } from "immer";

const selectTokens = (state) => state.tokens;
const selectWallet = (state) => state.wallet;
const selectInvestmentStocks = (state) => state.investedShares;
const selectIPORelease = (state) => state.ipoSharesInformation;
const selectUser = (state) => state.user;
const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectProfile = (state) => state.profile;

// const selectArrowsSlideStatus = (state) => state.walletArrowsSlideStatus;

export default function WalletSlider(props) {
  const tokens = useSelector(selectTokens);
  const wallet = useSelector(selectWallet);
  const investmentStocks = useSelector(selectInvestmentStocks);
  const IPOrelease = useSelector(selectIPORelease);
  const user = useSelector(selectUser);
  const windowInnerSize = useSelector(selectWindowInnerSize);
  const profile = useSelector(selectProfile);

  // const arrowsSlideStatus = useSelector(selectArrowsSlideStatus);

  const [marginBySlide, setmarginBySlide] = useState([
    windowInnerSize.width * 0.65,
  ]);

  useEffect(() => {
    if (investmentStocks) {
      store.dispatch({
        type: "ui/walletArrowsSlidesStatus/add",
        payload: { left: false, right: investmentStocks.length > 0 },
      });
    }
  }, [investmentStocks]);

  // console.log(IPOrelease);
  const [StockHoldingA, setStockHoldingA] = useState(null);
  if (props.portfolioData && StockHoldingA === null) {
    let StockHAmount = 0;
    for (let i = 0; i < props.portfolioData.length; i++) {
      StockHAmount = StockHAmount + props.portfolioData[i].volume;
    }
    setStockHoldingA(StockHAmount);
  }
  // const [TrnsOfMonth, setTrnsOfMonth] = useState(getMonthName(new Date().getMonth()));

  // prepare slides

  // props.portfolio mode: 0 - total, 1 - investor, 2 - issuer, 3 - clients
  const [loading, setLoading] = useState(true);
  const [investorData, setInvestorData] = useState([]);
  const [issuerData, setIssuerData] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const [lastClickTime, setLastClickTime] = useState(0);

  useEffect(() => {
    // console.log(props.portfolioMode);
    fetchData();

    // Cleanup function to cancel ongoing requests if component unmounts or category changes
    return () => {
      axios.CancelToken.source().cancel("Request canceled");
    };
  }, [props.portfolioMode]);

  const fetchData = async () => {
    // const currentTime = Date.now();
    // if (currentTime - lastClickTime < 5000) {
    //   return; // Ignore the click if less than 5 seconds have passed since the last click
    // }
    // setLastClickTime(currentTime);

    setLoading(true);

    try {
      let arrayMargin = [];
      //get investor shares
      if (props.portfolioMode === 0 || props.portfolioMode === 1) {
        const responseInvestorData = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/api/investors/shares",
          {
            headers: {
              Authorization: tokens.accessToken,
            },
          }
        );
        // console.log(responseInvestorData.data);
        arrayMargin = responseInvestorData.data.map((stockItem, index) => {
          return (
            ((65 + 15 * responseInvestorData.data[index].saleOffers.length) *
              windowInnerSize.width) /
            100
          );
        });
        console.log(arrayMargin);
        // let margini = (55+15*responseInvestorData.data[0].saleOffers)*windowInnerSize.width/100
        setmarginBySlide(arrayMargin);
        store.dispatch({
          type: "ui/walletSliderHeight/add",
          payload: arrayMargin[0],
        });
        investorData.length === 0 && setInvestorData(responseInvestorData.data);
        store.dispatch({
          type: "domain/investedShares/addData",
          payload: responseInvestorData.data,
        });
      }
      // get issuer share
      if (
        user.accountType & ROLES.ISSUER &&
        (props.portfolioMode === 0 || props.portfolioMode === 2)
      ) {
        const responseIssuerData = await axios.get(
          process.env.REACT_APP_BACKEND_URL +
            "/api/influencers/shares-to-release",
          {
            headers: {
              Authorization: tokens.accessToken,
            },
          }
        );
        let issuerMargin =
          ((65 + 15 * responseIssuerData.data.saleOffers.length) *
            windowInnerSize.width) /
          100;
        console.log(arrayMargin);
        arrayMargin = [issuerMargin, ...arrayMargin];
        setmarginBySlide(arrayMargin);
        store.dispatch({
          type: "ui/walletSliderHeight/add",
          payload: issuerMargin,
        });
        console.log(arrayMargin);
        console.log(responseIssuerData.data);
        issuerData.length === 0 && setIssuerData(responseIssuerData.data);
      }
      // get clients
      if (
        user.accountType & ROLES.MANAGER &&
        (props.portfolioMode === 0 || props.portfolioMode === 3)
      ) {
        // const responseClientsData = await axios.get(
        //   process.env.REACT_APP_BACKEND_URL +
        //     "/api/agent/"+profile.id +"/contracts",
        //   {
        //     headers: {
        //       Authorization: tokens.accessToken,
        //     },
        //   }
        // );
        // let clientsMargin = responseClientsData.data.map((stockItem, index) => {
        //   return (
        //     ((65 + 15 * responseClientsData.data[index].saleOffers.length) *
        //       windowInnerSize.width) /
        //     100
        //   );
        // });
        // arrayMargin=[...arrayMargin,...clientsMargin];
        // console.log(arrayMargin);
        // // let margini = (55+15*responseInvestorData.data[0].saleOffers)*windowInnerSize.width/100
        // setmarginBySlide(arrayMargin);
        // store.dispatch({
        //   type: "ui/walletSliderHeight/add",
        //   payload: arrayMargin[0],
        // });
        // setClientsData(responseClientsData);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
    setLoading(false);
  };
  const [slidesAmount, setslidesAmount] = useState(1);
  useEffect(() => {
    let tempAmount = 0;
    if (investmentStocks) {
      tempAmount += investmentStocks.length;
    }
    if (issuerData) {
      tempAmount++;
    }
    setslidesAmount(tempAmount);
    // console.log(tempAmount);
  }, [investmentStocks, IPOrelease, issuerData]);

  // useEffect(() => {
  //   store.dispatch({
  //     type: "ui/walletSliderHeight/add",
  //     payload: marginBySlide[0],
  //   });
  // }, [marginBySlide])

  const imageGallery = React.createRef();

  const [marginFinal, setmarginFinal] = useState(206);
  useEffect(() => {
    if (!loading) {
      // setmarginFinal(marginBySlide[imageGallery.current.getCurrentIndex()]);
    }
  }, [imageGallery, loading]);

  const [protfolioIndex, setprotfolioIndex] = useState(null);
  const renderData = () => {
    
    let slidesData = [];
    let slidesItems = [];
    if (props.portfolioMode === 0 || props.portfolioMode === 1) {
      slidesData = investorData;
    }
    if (props.portfolioMode === 0 || props.portfolioMode === 2) {
      // console.log(issuerData);
      slidesItems = isVarNegative(issuerData)
        ? [
            {
              renderItem: () => {
                return (
                  <StockHolding
                    key={0}
                    isIssuer={true}
                    stockHolding={issuerData}
                  />
                );
              },
            },
          ]
        : [];
    }
    if (props.portfolioMode === 0 || props.portfolioMode === 3) {
      //       slidesData=[...slidesData,...clientsData]
    }
    // console.log(slidesData);
    let tempItems = slidesData.map((share, index) => {
      return {
        renderItem: () => {
          return (
            <StockHolding
              className={`WalletSlider__StockHolding`}
              key={index}
              stockHolding={share}
            />
          );
        },
      };
    });
    slidesItems = [...slidesItems, ...tempItems];
    console.log(slidesItems);
    setprotfolioIndex(props.portfolioMode);

    return (
      <div
        style={
          {
            // marginBottom: marginFinal,
          }
        }
      >
        <ImageGallery
          ref={imageGallery}
          onSlide={(index) => {
            console.log(index);
            // updateArrowSlideStatus(index);
          }}
          flickThreshold={0.5}
          swipingTransitionDuration={1000}
          items={slidesItems}
          showNav={false}
          showThumbnails={false}
          showPlayButton={false}
          showFullscreenButton={false}
          slideDuration={800}
          slideInterval={3000}
          autoPlay={false}
          infinite={false}
        />
      </div>
    );
  };

  return (
    <div
      id="ImgGalleryPos"
      style={
        {
          /*  marginBottom: marginFinal,*/
        }
      }
    >
      {marginBySlide.length > 1 && (
        <ArrowToSlide slideToRight={slideToRight} slideToLeft={slideToLeft} />
      )}
      {loading ? (
        <div id="LoaderPosNoti">
          <Loader />
        </div>
      ) : (
         renderData()
      )}
    </div>
  );

  function slideToRight() {
    store.dispatch({
      type: "ui/walletSliderIndex/add",
      payload: imageGallery.current.getCurrentIndex() + 1,
    });
    // // console.log( imageGallery.current.getCurrentIndex()+1,

    imageGallery.current.slideToIndex(
      imageGallery.current.getCurrentIndex() + 1
    );
    // setmarginFinal(marginBySlide[imageGallery.current.getCurrentIndex()-1]);
    if (imageGallery.current.getCurrentIndex() <= marginBySlide.length - 1) {
      store.dispatch({
        type: "ui/walletSliderHeight/add",
        payload: marginBySlide[imageGallery.current.getCurrentIndex() + 1],
      });
    } else {
      store.dispatch({
        type: "ui/walletSliderHeight/add",
        payload: marginBySlide[0],
      });
    }
    console.log(marginBySlide[imageGallery.current.getCurrentIndex() + 1]);
  }

  function slideToLeft() {
    store.dispatch({
      type: "ui/walletSliderIndex/add",
      payload: imageGallery.current.getCurrentIndex() - 1,
    });
    console.log(imageGallery.current.getCurrentIndex() - 1);
    imageGallery.current.slideToIndex(
      imageGallery.current.getCurrentIndex() - 1
    );
    // setmarginFinal(marginBySlide[imageGallery.current.getCurrentIndex()-1]);
    if (imageGallery.current.getCurrentIndex() - 1 >= 0) {
      store.dispatch({
        type: "ui/walletSliderHeight/add",
        payload: marginBySlide[imageGallery.current.getCurrentIndex() - 1],
      });
    } else {
      store.dispatch({
        type: "ui/walletSliderHeight/add",
        payload: marginBySlide[marginBySlide.length - 1],
      });
    }
    console.log(marginBySlide[imageGallery.current.getCurrentIndex() - 1]);
  }

  function updateArrowSlideStatus(i) {
    if (i === 0) {
      store.dispatch({
        type: "ui/walletArrowsSlidesStatus/add",
        payload: {
          left: false,
          right: store.getState().walletArrowsSlideStatus.right,
        },
      });
    } else {
      if (i === marginBySlide.length - 1) {
        store.dispatch({
          type: "ui/walletArrowsSlidesStatus/add",
          payload: {
            left: store.getState().walletArrowsSlideStatus.left,
            right: false,
          },
        });
      } else {
        //   store.dispatch({
        //     type: "ui/walletArrowsSlidesStatus/add",
        //     payload: {
        //       left: store.getState().walletArrowsSlideStatus.left,
        //       right: true,
        //     },
        //   });
        // }
        store.dispatch({
          type: "ui/walletArrowsSlidesStatus/add",
          payload: {
            left: true,
            right: store.getState().walletArrowsSlideStatus.right,
          },
        });
      }
    }
  }
}
