import wallet from '../../../../assets/images/notification/wallet.svg';
import bomb from '../../../../assets/images/notification/bomb.svg';
import fireImg from '../../../../assets/images/notification/fire.svg';
import logo from '../../../../assets/images/notification/logo.svg';
import ppt from '../../../../assets/images/notification/ppt.svg';
import axios from 'axios';

export const navOptions = {
  updates: 'Updates',
  education: 'Education',
};

export const notificationsUrl = `${process.env.REACT_APP_BACKEND_URL}/api/notifications`;

export function markAsRead(notifications, axiosConfig) {
  notifications.forEach((noti) => {
    if (noti.isActive)
      axios.post(notificationsUrl + `/${noti.notificationId}/markAsRead`, {}, axiosConfig);
  });
}

const notificationsTypes = {
  SMI_VALIDATED: 1,
  TRADE_SUCCESS_BUYER: 2,
  TRADE_SUCCESS_SELLER: 3,
  TRADE_SUCCESS_SMI: 4,
  TRADE_FAIL: 5,
  SMI_SOLD_OUT: 6,
  SALE_EXPIRED: 7,
  DEPOSIT_SUCCESS: 8,
  DEPOSIT_FAIL: 9,
  WITHDRAW_SUCCESS: 10,
  WITHDRAW_FAIL: 11,

  PPT_REQUEST_SMI: 12,
  PPT_REMINDER: 13,
  PPT_REQUEST_INVESTOR: 14,
  PPT_RECEIVED: 15,
  PPT_FAIL: 16,
};

export const notificationsConfig = (type) => {
  if (type === notificationsTypes.SMI_VALIDATED) {
    return {
      src: logo,
      btn: {
        txt: 'Go Live',
        href: () => '/my-passionstocks', // TODO: check the url function
      },
    };
  }

  if (type === notificationsTypes.TRADE_FAIL || type === notificationsTypes.SALE_EXPIRED) {
    return {
      src: bomb,
      btn: {
        txt: 'Explore',
        href: ({ influencerName }) => `/exchange/${influencerName}`, // TODO: check the url function
      },
    };
  }

  if (type === notificationsTypes.SMI_SOLD_OUT) {
    return { src: bomb };
  }
  if (type === notificationsTypes.WITHDRAW_SUCCESS || type === notificationsTypes.DEPOSIT_SUCCESS) {
    return { src: wallet };
  }
  if (type === notificationsTypes.DEPOSIT_FAIL) {
    return {
      src: wallet,
      btn: {
        txt: 'Retry',
        href: () => '/wallet/deposit',
      },
    };
  }
  if (type === notificationsTypes.WITHDRAW_FAIL) {
    return {
      src: wallet,
      btn: {
        txt: 'Retry',
        href: () => '/wallet/withdrawal/beneficiary-selection',
      },
    };
  }
  if (type === notificationsTypes.PPT_FAIL) {
    return {
      src: ppt,
      btn: {
        txt: 'Retry',
        href: () => '/wallet', // TODO: check the url function
      },
    };
  }

  if (
    type === notificationsTypes.TRADE_SUCCESS_BUYER ||
    type === notificationsTypes.TRADE_SUCCESS_SELLER ||
    type === notificationsTypes.TRADE_SUCCESS_SMI
  ) {
    return { src: fireImg };
  }

  if (type === notificationsTypes.PPT_REQUEST_SMI) {
    return {
      src: ppt,
      btn: {
        txt: 'View',
        href: () => '/profile', // TODO: check the url function
      },
    };
  }

  if (type === notificationsTypes.PPT_REMINDER) {
    return {
      src: ppt,
      btn: {
        txt: 'View',
        href: () => '/profile', // TODO: check the url function
      },
    };
  }
  if (type === notificationsTypes.PPT_REQUEST_INVESTOR) {
    return { src: ppt };
  }
  if (type === notificationsTypes.PPT_RECEIVED) {
    return { src: ppt };
  }

  return { src: logo };
};
