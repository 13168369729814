import { useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";

import store from "../../../../store/store";

import fixFractionDigits from "../../../../helpers/fixFractionDigits";

import "./Transactions.css";

const selectWallet = (state) => state.wallet;
const selectTokens = (state) => state.tokens;
const selectTransactions = (state) => state.transactionHistory;
const selectWindowInnerSize = (state) => state.windowInnerSize;

export default function Transactions(props) {
  const tokens = useSelector(selectTokens);
  const wallet = useSelector(selectWallet);
  const transactions = useSelector(selectTransactions);
  const windowInnerSize = useSelector(selectWindowInnerSize);

  const [ShowWalletBDetails, setShowWalletBDetails] = useState(false);

  function getTrnsOfMonth(monthYear) {
    var configTransactions = {
      method: "get",
      url: process.env.REACT_APP_BACKEND_URL + "/api/wallet/history",
      headers: {
        Authorization: tokens.accessToken,
      },
      params: {
        year: monthYear.split(".")[1],
        month: monthYear.split(".")[0],
      },
    };
    axios(configTransactions)
      .then(async function (response) {
        // console.log(JSON.stringify(response.data));
        store.dispatch({
          type: "domain/transactionHistory/addData",
          payload: response.data,
        });
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  function getMonthsOptions() {
    let amountOfAvailableMonths =
      1 +
      getMonthDiff(new Date(store.getState().profile.createdAt), new Date());
    let monthsOptionsList = [];
    let monthIndex = new Date().getMonth();
    let yearIndex = new Date().getFullYear();
    let isPastYearAvailable = monthIndex + 2 - amountOfAvailableMonths <= 0;
    for (let i = 0; i < amountOfAvailableMonths; i++) {
      monthsOptionsList.push(
        <option value={monthIndex + 1 + "." + yearIndex}>
          {" "}
          {getMonthName(monthIndex) +
            (isPastYearAvailable ? " " + yearIndex : "")}
        </option>
      );
      yearIndex = monthIndex - 1 === -1 ? yearIndex - 1 : yearIndex;
      monthIndex = monthIndex - 1 === -1 ? 11 : monthIndex - 1;
    }

    return monthsOptionsList;
  }
  function getMonthDiff(d1, d2) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }
  function getMonthName(DateNum) {
    const DatesNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return DatesNames[DateNum];
  }
  function getTransactionsListMain() {
    const TrnsListItem = transactions
      ? transactions
      : [
          // { created_at: '2021-11-04T12:26:06.000000Z', name: 'Stock Purchase', description: "Purchase of Stock in @sam.johnson", currency_code: "PAC", amount: 120 },
        ];

    let TransListHtml = [];
    for (let i = 0; i < TrnsListItem.length; i++) {
      let date = new Date(TrnsListItem[i].date).getDate();
      date = date < 10 ? "0" + date : date;
      TransListHtml.push(
        <tr key={"T" + i}>
          <td style={{ paddingRight: "4vw" }}>{date}</td>
          <td>{TrnsListItem[i].title}</td>
          <td>{TrnsListItem[i].description}</td>
          {/* <td>{TrnsListItem[i].currency_code}</td> */}
          <td
            className={
              TrnsListItem[i].amount < 0 ? "NegativeNum" : "PositiveGreenNum"
            }
          >
            {TrnsListItem[i].amount < 0 ? "-" : ""}
            <i style={{ padding: "0 0.25vw 0 0" }}>
              {TrnsListItem[i].currency.symbol}
            </i>
            {Math.abs(fixFractionDigits(TrnsListItem[i].amount))}
          </td>
        </tr>
      );
    }
    //push another li for skip from the black shadow
    TransListHtml.push(<tr style={{ height: "5vw", width: "100%" }} />);

    return TransListHtml;
  }

  return (
    <div className="WalletTransactions headline">
      <div className="WalletTransactions__HeaderContainer">
        <div>
          <p className="WalletTransactions__Title">Transactions</p>
          <svg
            className="WalletTransactions__TitleLine"
            height="0.5vw"
            width="33%"
          >
            <line
              x1="0"
              x2="100%"
              y1="0"
              y2="0"
              stroke="white"
              stroke-width="100%"
              stroke-linecap="butt"
            />
          </svg>
        </div>
        <select
          name="month"
          id="MonthSelectListStS"
          className="headline"
          onChange={(e) => {
            getTrnsOfMonth(e.target.value);
            const table = document.querySelector(
              ".WalletTransactions__DataContainer"
            );
            table.scrollTo(0, 0);
          }}
        >
          {getMonthsOptions()}
        </select>
      </div>
      <table className="WalletTransactions__Table">
        <table className="WalletTransactions__Table">
          <tr>
            <th style={{ textAlign: "left" }}>Date</th>
            <th style={{ textAlign: "left" }}>Name</th>
            <th style={{ textAlign: "left" }}>Description</th>
            <th style={{ textAlign: "left" }}>Amount</th>
          </tr>
        </table>
        <svg height="0.4vw" width="100%">
          <line
            x1="0"
            x2="100%"
            y1="0"
            y2="0"
            stroke="white"
            stroke-width="5vw"
            stroke-linecap="butt"
          />
          3
        </svg>
        <div
          className="WalletTransactions__DataContainer"
          style={{ position: "relative", height: "100vw", overflow: "scroll" }}
        >
          {transactions?.length > 0 ? (
            getTransactionsListMain()
          ) : (
            <p id="NoTrnsStS">
              There are no transactions to display
              <br />
              in this month.
            </p>
          )}
        </div>
      </table>
      <p id="ColumnsW" className="unnamed-character-style-1"></p>

      {/* <div id="TransactionsListW">
        {transactions?.length > 0 ? (
          getTransactionsListMain()
        ) : (
          <p id="NoTrnsStS">
            There are no transactions to display
            <br />
            in this month.
          </p>
        )}
      </div> */}
      <svg id="TransListLine2" height="0vw" width="100%">
        <line
          x1="0"
          x2="100%"
          y1="0"
          y2="0"
          stroke="white"
          stroke-width="5vw"
          stroke-linecap="butt"
        />
      </svg>
    </div>
  );
}
