import axios from 'axios';
import './style.css';
import React, { useEffect, useState } from 'react';
import store from '../../../../store/store';
import Chart from 'react-apexcharts';
import { fillData, options, startDate, sortGraph } from '../graph.conf';

export default function ChartStock({ influencerId = 39, stockId = 30, dateRange }) {
  const [index, setIndex] = useState(0);
  const [series, setSeries] = useState([
    { name: 'ROI', data: [] },
    { name: 'stock price', data: [] },
    { name: 'Liquidity', data: [] },
  ]);
  const yParameters = ['%', 'value', 'count'];

  useEffect(() => {
    const getGraph = async () => {
      const baseUrl = process.env.REACT_APP_BACKEND_URL + `/api/wallet`;
      const token = store?.getState().tokens.accessToken;
      // const dateQuery = startDate[dateRange] ? `?dateStart=${startDate[dateRange]}` : '';
      const headers = { headers: { Authorization: token } };
      const url = (url) => `${baseUrl}/${url}?range=${dateRange}&stockId=${stockId}`;

      if (index === 0) {
        const { data: rois } = await axios.get(url(`stockproi-graph`), headers);
        setSeries([
          {
            name: 'ROI',
            data: fillData[dateRange](rois)
              .sort(sortGraph)
              .map((roi) => ({ x: new Date(roi.date), y: parseInt(roi.value * 100) })),
          },
        ]);
      }
      if (index === 1) {
        const { data: stockPrices } = await axios.get(url('stockprice-graph'), headers);
        setSeries([
          {
            name: 'Stock Price',
            data: fillData[dateRange](stockPrices)
              .sort(sortGraph)
              .map((stockPrice) => ({ x: new Date(stockPrice.date), y: stockPrice.value })),
          },
        ]);
      }

      if (index === 2) {
        const { data: liquidity } = await axios.get(url('liquidity-graph'), headers);

        setSeries([
          {
            name: 'Liquidity',
            data: fillData[dateRange](liquidity)
              .sort(sortGraph)
              .map((liquid) => ({ x: new Date(liquid.date), y: liquid.value })),
          },
        ]);
      }
    };

    getGraph();
  }, [dateRange, influencerId, stockId, index]);

  const chartProps = {
    options: options('datetime', yParameters[index], dateRange),
    series: series,
    type: 'line',
    width: '95%',
    height: 228,
  };

  const btnProps = (i) => ({
    key: i,
    className: `Category ${i === index ? 'Select' : ''}`,
    onClick: () => setIndex(i),
  });

  return (
    <>
      <Chart {...chartProps} />
      <div className='Options'>
        {['ROI', 'Stock Price', 'Liquidity'].map((name, i) => (
          <bottom {...btnProps(i)}>{name}</bottom>
        ))}
      </div>
    </>
  );
}
