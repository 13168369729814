import React from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router";
import { AnimateKeyframes } from "react-simple-animate";

import "./style.css";

import loadMyAgentData from "./loaders";

import store from "../../../store/store";
import AvatarImg from "../../../components/common/AvatarImg";

import BackPage from "../../../assets/images/common/back-page.png";
import FullStar from "../../../assets/images/common/star-full.png";
import EmptyStar from "../../../assets/images/common/star-empty.png";
import { useEffect } from "react";
import fixFractionDigits from "../../../helpers/fixFractionDigits";

const selectWindowInnerSize = (state) => state.windowInnerSize;
const selectTokens = (state) => state.tokens;
const selectProfile = (state) => state.profile;
const selectMyAgentLastActiveOn = (state) => state.myAgentLastActiveOn;
const selectMyAgentData = (state) => state.myAgentData;
const selectMyAgentMetrics = (state) => state.myAgentMetrics;
const selectMyAgentActivityLog = (state) => state.myAgentActivityLog;

export default function MyAgencyDetails(props) {
  store.dispatch({
    type: "ui/navBarsDM/toggle",
    payload: { top: true, bottom: false },
  });
  store.dispatch({
    type: "ui/navBarsSelectedSection/update",
    payload: "Profile",
  });

  const windowInnerSize = useSelector(selectWindowInnerSize);
  const tokens = useSelector(selectTokens);
  const lastActiveOn = useSelector(selectMyAgentLastActiveOn);
  const agentData = useSelector(selectMyAgentData);
  const metrics = useSelector(selectMyAgentMetrics);
  const activityLog = useSelector(selectMyAgentActivityLog);

  useEffect(() => {
    loadMyAgentData(tokens);

    return () => {
      store.dispatch({
        type: "app/my-agent/lastActiveOn/delete",
        payload: null,
      });
      store.dispatch({
        type: "app/my-agent/agentData/delete",
        payload: null,
      });
      store.dispatch({
        type: "app/my-agent/agentMetrics/delete",
        payload: null,
      });
      store.dispatch({
        type: "app/my-agent/activityLog/delete",
        payload: null,
      });
    };
  }, []);

  const navigate = useNavigate();
  const handleLeave = async () => {
    await axios({
      method: "DELETE",
      url:
        `https://web.passionstocks.com/api/agencies/` +
        store.getState().profile.data.influencer.agency.agency_id +
        `/influencers?api_token=` +
        store.getState().profile.token,
      params: {
        influencer_id: store.getState().profile.data.influencer.id,
      },
    })
      .then(async function (response) {
        // console.log("influencer leave is own agency");
        // console.log(response.data);
        // await store.dispatch({ type: 'find_agency/loadJoinOffers', payload: response.data })
        navigate("/profile");
      })
      .catch(function (error) {
        // console.log("error to influencer leave is own agency");
      });
  };

  return (
    <AnimateKeyframes
      play={true}
      iterationCount={1}
      duration={0.42}
      direction="normal"
      keyframes={[
        "transform: translateX(100vw)",
        "transform: translateX(50vw)",
        "transform: translate(0, 0)",
      ]}
    >
      <div
        className="MyAgencyDetails Page headline"
        style={{
          position: "relative",
          height: windowInnerSize.height,
        }}
      >
        <img
          src={BackPage}
          alt=""
          className="back-btn"
          onClick={() => {
            navigate(-1);
          }}
        />

        <div className="MyAgencyDetails__BasicDetails">
          <p className="MyAgencyDetails__ActiveSinceTxt">
            Active since: {lastActiveOn ? lastActiveOn : "--/--/--"}
          </p>
          <div className="MyAgencyDetails__AvatarPosition">
            <AvatarImg pic={agentData?.user.profilePic} />
          </div>
          <div className="MyAgencyDetails__StarsLine">
            {getRateStars(agentData?.ranking)}
          </div>
          <p className="MyAgencyDetails__Name">{agentData?.user.name}</p>
        </div>

        <p className="MyAgencyDetails__Email">{agentData?.user.email}</p>
        <p className="MyAgencyDetails__PhoneNumber">
          {agentData?.user.phoneNumber}
        </p>

        <div className="MyAgencyDetails__Metrics">
          <div className="MyAgencyDetails__SquareData">
            <p>
              {metrics ? fixFractionDigits(metrics.contract.totalMade) : ""}{" "}
              {metrics?.contract.currency.symbol}
            </p>
            <p>Total Made</p>
          </div>
          <div className="MyAgencyDetails__SquareData">
            <p>{metrics?.contract.growth}%</p>
            <p>Growth since joining</p>
          </div>
          <div className="MyAgencyDetails__SquareData">
            <p>{metrics?.activityCount}</p>
            <p>Agency activities</p>
          </div>
        </div>

        <div className="MyAgencyDetails__ActivityLog">
          <p className="MyAgencyDetails__ActivityLogTxt">Activity log</p>
          <select
            name="month"
            className="MyAgencyDetails__MonthSelectList headline"
            onChange={(e) => getTrnsOfMonth(e.target.value)}
          >
            {getMonthsOptions()}
          </select>

          <svg
            className="MyAgencyDetails__ActivityLine"
            height="0.5vw"
            width="27%"
          >
            <line
              x1="0"
              x2="100%"
              y1="0"
              y2="0"
              stroke="white"
              stroke-width="10%"
              stroke-linecap="butt"
            />
          </svg>
          <p className="MyAgencyDetails__ActivityColumnsHeads unnamed-character-style-1">
            <span>Date</span>
            <span>Activity</span>
            <span>Time</span>
          </p>
          <svg
            className="MyAgencyDetails__ActivityLListLine"
            height="0.4vw"
            width="90vw"
          >
            <line
              x1="0"
              x2="100%"
              y1="0"
              y2="0"
              stroke="#b8b8b8"
              stroke-width="5vw"
              stroke-linecap="butt"
            />
          </svg>
          <div className="MyAgencyDetails__ActivityLogList">
            {getActivityLogList()}
          </div>
        </div>

        {/* <Link to="/Profile/SMI/Find_Your_Agency"> */}
        <p className="MyAgencyDetails__LeaveBtn" onClick={handleLeave}>
          Leave
        </p>
        {/* </Link> */}
      </div>
    </AnimateKeyframes>
  );

  function getRateStars(Rating) {
    let FullStars = Rating;
    let EmptyStars = 5 - Rating;
    let StarsCounter = 5;
    let StarsLine = [];

    for (let i = 0; i < FullStars; i++, StarsCounter--) {
      StarsLine.push(
        <img
          key={StarsCounter}
          className="RateStars"
          src={FullStar}
          alt=""
          id="FullStar"
        />
      );
    }
    for (let i = 0; i < EmptyStars; i++, StarsCounter--) {
      StarsLine.push(
        <img
          key={StarsCounter}
          className="RateStars"
          src={EmptyStar}
          alt=""
          id="FullStar"
        />
      );
    }

    return StarsLine;
  }

  function getTrnsOfMonth(monthYear) {
    var configTransactions = {
      method: "get",
      url: process.env.REACT_APP_BACKEND_URL + "/api/wallet/history",
      headers: {
        Authorization: tokens.accessToken,
      },
      params: {
        year: monthYear.split(".")[1],
        month: monthYear.split(".")[0],
      },
    };
    axios(configTransactions)
      .then(async function (response) {
        // console.log(JSON.stringify(response.data));
        store.dispatch({
          type: "domain/transactionHistory/addData",
          payload: response.data,
        });
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  function getMonthsOptions() {
    let amountOfAvailableMonths =
      1 + getMonthDiff(new Date(metrics?.contract.createdAt), new Date());
    let monthsOptionsList = [];
    let monthIndex = new Date().getMonth();
    let yearIndex = new Date().getFullYear();
    let isPastYearAvailable = monthIndex + 2 - amountOfAvailableMonths <= 0;
    for (let i = 0; i < amountOfAvailableMonths; i++) {
      monthsOptionsList.push(
        <option value={monthIndex + 1 + "." + yearIndex}>
          {" "}
          {getMonthName(monthIndex) +
            (isPastYearAvailable ? " " + yearIndex : "")}
        </option>
      );
      yearIndex = monthIndex - 1 === -1 ? yearIndex - 1 : yearIndex;
      monthIndex = monthIndex - 1 === -1 ? 11 : monthIndex - 1;
    }

    return monthsOptionsList;
  }
  function getMonthDiff(d1, d2) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }
  function getMonthName(DateNum) {
    const DatesNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return DatesNames[DateNum];
  }
  function getActivityLogList() {
    // const ActLListItem = [{ IDate: "--", IActivity: "--", ITime: "--" }];
    let activityLogArray = activityLog ? activityLog : [];
    let activityLogItems = [];
    for (let i = 0; i < activityLogArray.length; i++) {
      let date = new Date(activityLogArray[i].date);
      let day = date.getDay()+1;
      let hours = date.getHours();
      let minutes = date.getMinutes();
      activityLogItems.push(
        <li className="MyAgencyDetails__ActivityItem" key={"AL" + i}>
          <span>{day < 10 ? "0" + day : day}</span>
          <span>{activityLogArray[i].description}</span>
          <span>
            {(hours < 10 ? "0" + hours : hours) +
              "h" +
              (minutes < 10 ? "0" + minutes : minutes)}
          </span>
        </li>
      );
    }

    return <ul>{activityLogItems}</ul>;
  }
}
